// Components
import Modal from "../../../Modal";
import { ModalRechazoContainer } from "../../../../pages/styled";
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import InputBasic from "../../../InputBasic";
import InputCalendar from "../../../InputCalendar";
import InputTextarea from "../../../InputTextarea";
import InputSelect from "../../../InputSelect";
import InputFileRec from "../../../InputFileRec";
import Button from "../../../Button";
import FileLoaded from "../../../FileLoaded";
import InputFileButton from "../../../InputFileButton";
import NewInputSelect from "../../../NewInputSelect"
import NewInputTextarea from "../../../NewInputTextarea"
// Hooks
import { useForm } from "../../../../hooks/useForm";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import useButtonMessage from "../../../../hooks/useButtonMessage";
import useFiles from "../../../../hooks/useFiles";
// Utils
import { modalRechazoReglas, objetivosPremiosReglas } from "../../../../utils/formRules";
import { modalRechazoForm, objetivosPremiosCsvForm } from "../../../../utils/formInitialValues";
// Services
import {
  getPolizaInformation,
  getProductoSiniestroId,
} from "../../../../services/Siniestros";
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";
import InputFile from "../../../InputFile";
import { useEffect, useState } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import { meses } from "../../../../constants/data";
import NewTabs from "../../../NewTabs";
import { useTabs } from "../../../../hooks/useTabs";
import { CSVLink } from "react-csv";
import { FiDownload, FiFile } from "react-icons/fi";

const ModalModificarPremios = ({
  modal,
  refreshData,
  csvData,
  integrantes
}) => {
  const exitoModal = useModal();
  const { isModalOpen, handleModal } = modal;
  const [exampleCSVFile, setExampleCSVFile] = useState([])

  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
  } = useForm(handleSubmit, objetivosPremiosReglas, objetivosPremiosCsvForm);
  const {loading, loadingText, setLoading, changeLoadingText } = useLoading()


  const headers = [
    { label: "ID Premio", key: "id_premio" },
    { label: "Premio", key: "premio"},
    { label: "Mes", key: "mes" },
    { label: "Operaciones Desde", key: "operaciones_desde" },
    { label: "Operaciones Hasta", key: "operaciones_hasta" },
    { label: "Valor Premio", key: "valor_premio" },
    { label: "Emitido", key: "emitido" },
    { label: "Contratado", key: "contratado" },
    { label: "Fecha Desde", key: "fecha_desde" },
    { label: "Fecha Hasta", key: "fecha_hasta" },
    { label: "Cantidad Operaciones", key: "cantidad_operaciones" },
    { label: "Mínimo Prima", key: "minimo_prima" },
    { label: "Cantidad Puntos Sumados", key: "cantidad_puntos_sumados" },
    { label: "Porcentaje a Superar", key: "porcentaje_a_superar" },
    { label: "Porcentaje Mínimo Prima", key: "porcentaje_minimo_prima" },
    { label: "Mínimo Prima Grupal", key: "minimo_prima_grupal" },
    { label: "Prima Vendedores Competencias", key: "prima_vendedores_competencias" },
    { label: "Cantidad Puntos Sumados", key: "cantidad_puntos_sumados" },
    { label: "Producto", key: "producto" },
  ]

  async function handleSubmit() {
    changeLoadingText("Actualizando premios...")
    setLoading(true);

    const file = inputValues.objetivos.image;
    const reader = new FileReader();

    reader.onload = async function(e) {
        const text = e.target.result;
        const content = text.slice(300, text.lastIndexOf(text.slice(-1))).split(/\r\n|\n/)
        console.log(content)

        const premios = content.map((obj) => {
            const values = obj.split(",")
            console.log(values)
            let data = {}
            values.forEach((item, index) => data[headers[index]?.key] = item)
            return data
        })


        if(premios.length > 0){
            const obj = await axiosRequest.put(`/premios/grupo/integrantes`, { premios, integrantes })
            if(obj.status === 200){
              changeLoadingText("¡Premios actualizados!")
              setLoading(false)
              setTimeout(() => {
                changeLoadingText(null)
                handleModal()
              }, 1000)
            }
        }
    }
    reader.readAsText(file);
  }

  return (
    <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
          <NewTitle>
            Modificar premios
          </NewTitle>
          </div>
          <hr />
          
          
          <div className="data-container">
          <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={12}>
                  <CSVLink data={csvData[1]} headers={headers} className="csv-link" filename="csv-premios-grupo">
                    <div className="csv-link-container">
                      <div className="csv-info-container">
                        <span className="icon-file"><FiFile /></span> 
                        <div className="info-container">
                          <h2 className="csv-link-title">Archivo CSV de premios</h2>
                          <span>Descargá el archivo para completar los premios</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="icon"><FiDownload /></span> 
                    </div>
                  </CSVLink>
                  </Col>
                  <Col desktop={12}>
                    <InputFile
                      name="objetivos"
                      text="Adjuntar archivo .csv de objetivos"
                      labelName="Objetivos del mes"
                      accept=".csv"
                      onChange={handleChangeExternal}
                      file={inputValues.objetivos}
                    />
                    {formErrors.objetivos && <ErrorInput>{formErrors.objetivos}</ErrorInput>}
                  </Col>
                  
                </Grid>
          </div>
          <hr />
          <div className={"actionButtonsCont"}>
            <div className={"buttonsCont"}>
              <NewButton
                onClick={handleModal}
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
              >
                Cancelar
              </NewButton>
              <NewButton
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={handleValidation}
                disabled={loading}
              >
              {loadingText ? loadingText : "Actualizar premios"}
            </NewButton>
            </div>
          </div>
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalModificarPremios;
