import { useState } from "react";

export const useActionButton = () => {

  const [isActionButtonOpen, setIsActionButtonOpen] = useState(null);

  const handleToggle = (index) => setIsActionButtonOpen(isActionButtonOpen === index ? null : index)

  return { isActionButtonOpen, handleToggle }

}