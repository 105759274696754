import { useState } from "react";
import { Container } from "./styled";
import { useOutsideClick } from "../../hooks/useClickOutside";

const ButtonCalc = ({ name, value, handleChange }) => {

  const [menuOperadores, setMenuOperadores] = useState(false)
  const menuOperadoresRef = useOutsideClick(() => setMenuOperadores(false))

  const handleMenu = () => {
    setMenuOperadores(false)
  }

  return (
    <Container>
      <div className="seleccion-operador-container" ref={menuOperadoresRef}>
        {menuOperadores ? (
          <div className="operadores">
            <span
              className={`plan-operador ${
                value === "X" && "selected"
              }`}
              onClick={() => handleChange(name, "X", handleMenu)}
            >
              X
            </span>
            <span
              className={`plan-operador ${
                value === "/" && "selected"
              }`}
              onClick={() => handleChange(name, "/", handleMenu)}
            >
              /
            </span>
            <span
              className={`plan-operador ${
                value === "+" && "selected"
              }`}
              onClick={() => handleChange(name, "+", handleMenu)}
            >
              +
            </span>
            <span
              className={`plan-operador ${
                value === "-" && "selected"
              }`}
              onClick={() => handleChange(name, "-", handleMenu)}
            >
              -
            </span>
          </div>
        ) : null}
        <span
          className="plan-operador"
          onClick={() => setMenuOperadores(!menuOperadores)}
        >
          {value ? value : "Seleccionar operador"}
        </span>
      </div>
    </Container>
  );
};

export default ButtonCalc;
