import { useState, useEffect } from "react";
import axiosRequest from "../utils/axiosConfig";

export default function useFilters(
  values,
  data,
  setData,
  setHayBusquedas,
  setPageSelected,
  setFilterParams,
  requiredFilters
) {
  const [inputValues, setInputValues] = useState(values);
  const [buttonDisabledStatus, setButtonDisabledStatus] = useState(true);
  const [showDeleteFilters, setShowDeleteFilters] = useState(false);

  useEffect(() => {
    let valuesCompleted = [];
    for (const value in inputValues) {
      if (inputValues[value] !== "" && data) {
        valuesCompleted.push(value);
      }
    }
    console.log(valuesCompleted);

    if (requiredFilters) {
      if (valuesCompleted.length === Object.keys(inputValues).length) {
        setShowDeleteFilters(true);
        setButtonDisabledStatus(false);
      } else {
        setShowDeleteFilters(false);
        setButtonDisabledStatus(true);
      }
    } else {
      if (
        (valuesCompleted.length > 0 &&
          valuesCompleted.includes("desde") &&
          valuesCompleted.includes("hasta")) ||
        (valuesCompleted.length > 0 &&
          !valuesCompleted.includes("desde") &&
          !valuesCompleted.includes("hasta"))
      ) {
        setShowDeleteFilters(true);
        setButtonDisabledStatus(false);
      } else {
        setShowDeleteFilters(false);
        setButtonDisabledStatus(true);
      }
    }
  }, [inputValues, data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const clearFilters = () => {
    if (requiredFilters) {
      requiredFilters(values);
      setHayBusquedas(false);
      setInputValues(values);
      if (setData.length) {
        setData.forEach((setDataFunction) => setDataFunction([]));
      } else {
        setData([]);
      }
    } else {
      setInputValues(values);
      setFilterParams("");
      setHayBusquedas(true);
      setPageSelected(1);
    }
  };

  const handleSearch = async () => {
    if (requiredFilters) {
      requiredFilters(inputValues);
    } else {
      let valuesCompleted = [];
      for (const value in inputValues) {
        if (inputValues[value] !== "") {
          valuesCompleted.push(value);
        }
      }

      let query = "";
      valuesCompleted.forEach((value, index) => {
        query += `${value}=${inputValues[value]}`;
        if (index != valuesCompleted.length - 1) query += `&`;
      });

      setPageSelected(1); // al iniciar una nueva busqueda va a la pagina 1
      setFilterParams(query);
    }
  };

  return {
    inputValues,
    handleSearch,
    clearFilters,
    handleChange,
    buttonDisabledStatus,
    showDeleteFilters,
  };
}
