import React from "react";
import { Col, Grid } from "../../../Grid";
import NewInputSelect from "../../../NewInputSelect";
import { meses } from "../../../../constants/data";
import NewTable from "../../../NewTable";
import { objetivosVendedores } from "../../../../utils/tableData";
import { useTable } from "../../../../hooks/useTable";
import { useState } from "react";
import NewInputBasic from "../../../NewInputBasic";
import useModal from "../../../../hooks/useModal";
import ModalConversorPrima from "../../../Modals/Premios/ModalConversorPrima";
import NewButton from "../../../NewButton";
import { Container } from "./styled";

const PremiosObjetivosTab = ({ data }) => {
  const { mes, setMes, objetivos, loadingObjetivos } = data;
  const tableData = objetivosVendedores();
  const { order, handleTableOrder } = useTable();
  const conversorPrimaModal = useModal();

  return (
    <>
      <Container>
      {conversorPrimaModal.isModalOpen ? <ModalConversorPrima modal={conversorPrimaModal} /> : null}
        <NewInputSelect
          name={"mes"}
          labelName={"Mes"}
          onChange={(e) => setMes(e.target.value)}
          placeholder={"Mes"}
          options={meses}
          value={mes}
        />
        <NewButton
          backgroundColor={true}
          onClick={conversorPrimaModal.handleModal}
        >
          Conversor prima
        </NewButton>
      </Container>
      <NewTable
        columns={tableData}
        data={objetivos}
        loading={loadingObjetivos}
        order={handleTableOrder}
        orderParams={order}
      />
    </>
  );
};

export default PremiosObjetivosTab;
