import React, { useState, useEffect } from "react";
import {
  InputBasic,
  InputSelect,
  InputCalendar,
  ErrorInput,
  Subtitulo,
  Grid,
  Col,
  Button,
  Modal,
  InputFileRec,
  InputCheckbox,
  CreditCard,
} from "../../Index";
import { AclaracionInput, Icon } from "./styled";
import CreditCardNew from "../../CreditCardNew";
import NewInputBasic from "../../NewInputBasic";

import visaimg from "../../../assets/images/visa.svg";
import masterimg from "../../../assets/images/mastercard.svg";
import ameximg from "../../../assets/images/amex.svg";
import cabalimg from "../../../assets/images/cabal.svg";
import naranjaimg from "../../../assets/images/naranja.svg";

import { useSolicitudContext } from "../../../context/SolicitudContext";
import { getMediosPago } from "../../../services/Emision";
import NewInputSelect from "../../NewInputSelect";
import SectionLandings from "../../SectionLandings";

import { FiDollarSign } from "react-icons/fi";
import Subtitle from "../../Subtitle";

const PagoForm = () => {
  const { seguro, pago } = useSolicitudContext();
  const { values, formErrors, handleChange } = pago;

  const [mediosPago, setMediosPago] = useState([]);

  useEffect(() => {
    const getMedios = async () => {
      try {
        const mediosPago = await getMediosPago(
          seguro.values.cia_cobertura_asegurada,
          seguro.values.producto_cobertura_asegurada
        );
        setMediosPago(mediosPago);
      } catch (error) {
        console.log(error);
      }
    };
    getMedios();
  }, []);

  return (
    <>
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={12}>
          <Subtitle
            title={"Forma de pago"}
            description={
              "Completá los datos sobre la forma de pago del asegurado"
            }
            icon={<FiDollarSign />}
          />
        </Col>

        <Col desktop={4}>
          <NewInputSelect
            name={"medio"}
            labelName={"Medio"}
            color={formErrors.medio ? "error" : "normal"}
            onChange={handleChange}
            options={mediosPago}
            placeholder={"Medio de pago"}
            value={values.medio}
          />
          <AclaracionInput>Recordá corroborar que el medio de pago no sea un CVU o una Tarjeta de Débito. <a href="https://www.datacels.com/cbu" target="_blank" rel="noreferrer">Podes verificar el cbu acá</a></AclaracionInput>
          {formErrors.medio && <ErrorInput>{formErrors.medio}</ErrorInput>}
        </Col>

        {(parseInt(values.medio) === 2 || parseInt(values.medio) === 3) && (
          <>
            <Col desktop={3}>
              <NewInputBasic
                type={"number"}
                name={"num_dato_facturacion"}
                labelName={parseInt(values.medio) === 2 ? "CBU" : "CVU"}
                color={formErrors.num_dato_facturacion ? "error" : "normal"}
                placeholder={"0000000000000000000000"}
                onChange={handleChange}
                value={values.num_dato_facturacion}
              />
              {formErrors.num_dato_facturacion && (
                <ErrorInput>{formErrors.num_dato_facturacion}</ErrorInput>
              )}
            </Col>
            <Col desktop={3}>
              <NewInputBasic
                type={"banco"}
                name={"banco_dato_facturacion"}
                labelName={"Banco emisor"}
                color={formErrors.banco_dato_facturacion ? "error" : "normal"}
                placeholder={"Banco Galicia"}
                onChange={handleChange}
                value={values.banco_dato_facturacion}
              />
              {formErrors.banco_dato_facturacion && (
                <ErrorInput>{formErrors.banco_dato_facturacion}</ErrorInput>
              )}
            </Col>
            <Col desktop={3}>
                <NewInputBasic
                  type={"text"}
                  name={"titular_dato_facturacion"}
                  labelName={"Titular"}
                  color={
                    formErrors.titular_dato_facturacion ? "error" : "normal"
                  }
                  placeholder={"Nombre del titular"}
                  onChange={handleChange}
                  value={values.titular_dato_facturacion}
                />
                {formErrors.titular_dato_facturacion && (
                  <ErrorInput>{formErrors.titular_dato_facturacion}</ErrorInput>
                )}
              </Col>
              <Col desktop={3}>
                <NewInputBasic
                  type={"number"}
                  name={"cuil_titular_dato_facturacion"}
                  labelName={"CUIL del titular"}
                  color={
                    formErrors.cuil_titular_dato_facturacion ? "error" : "normal"
                  }
                  placeholder={"CUIL del titular"}
                  onChange={handleChange}
                  value={values.cuil_titular_dato_facturacion}
                />
                {formErrors.cuil_titular_dato_facturacion && (
                  <ErrorInput>{formErrors.cuil_titular_dato_facturacion}</ErrorInput>
                )}
              </Col>
          </>
        )}
      </Grid>

      {(parseInt(values.medio) === 1 || parseInt(values.medio) === 6) && (
        <Grid colGap={21} rowGap={21} narrow={true}>
          <Col desktop={6} center={true}>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={6}>
                <NewInputBasic
                  type={"text"}
                  name={"marca_dato_facturacion"}
                  labelName={"Proveedor de la tarjeta"}
                  color={formErrors.marca_dato_facturacion ? "error" : "normal"}
                  placeholder={"Visa"}
                  onChange={handleChange}
                  value={values.marca_dato_facturacion}
                />
                {formErrors.marca_dato_facturacion && (
                  <ErrorInput>{formErrors.marca_dato_facturacion}</ErrorInput>
                )}
              </Col>
              <Col desktop={6}>
                <NewInputBasic
                  type={"number"}
                  name={"num_dato_facturacion"}
                  labelName={"Número de tarjeta"}
                  color={formErrors.num_dato_facturacion ? "error" : "normal"}
                  placeholder={"0000 0000 0000 0000"}
                  onChange={handleChange}
                  value={values.num_dato_facturacion}
                />
                {formErrors.num_dato_facturacion && (
                  <ErrorInput>{formErrors.num_dato_facturacion}</ErrorInput>
                )}
              </Col>
              <Col desktop={6}>
                <NewInputBasic
                  type={"text"}
                  name={"titular_dato_facturacion"}
                  labelName={"Titular"}
                  color={
                    formErrors.titular_dato_facturacion ? "error" : "normal"
                  }
                  placeholder={"Nombre del titular"}
                  onChange={handleChange}
                  value={values.titular_dato_facturacion}
                />
                {formErrors.titular_dato_facturacion && (
                  <ErrorInput>{formErrors.titular_dato_facturacion}</ErrorInput>
                )}
              </Col>
              <Col desktop={6}>
                <NewInputBasic
                  type={"text"}
                  name={"vencimiento_dato_facturacion"}
                  labelName={"Vencimiento"}
                  color={
                    formErrors.vencimiento_dato_facturacion ? "error" : "normal"
                  }
                  placeholder={"06/23"}
                  onChange={handleChange}
                  value={values.vencimiento_dato_facturacion}
                  maxLength={"5"}
                />
                {formErrors.vencimiento_dato_facturacion && (
                  <ErrorInput>
                    {formErrors.vencimiento_dato_facturacion}
                  </ErrorInput>
                )}
              </Col>

              <Col desktop={6}>
                <NewInputBasic
                  type={"number"}
                  name={"dni_dato_facturacion"}
                  labelName={"DNI"}
                  color={formErrors.dni_dato_facturacion ? "error" : "normal"}
                  placeholder={"DNI del titular"}
                  onChange={handleChange}
                  value={values.dni_dato_facturacion}
                />
                {formErrors.dni_dato_facturacion && (
                  <ErrorInput>{formErrors.dni_dato_facturacion}</ErrorInput>
                )}
              </Col>
              <Col desktop={6}>
                <NewInputBasic
                  type={"banco"}
                  name={"banco_dato_facturacion"}
                  labelName={"Banco emisor"}
                  color={formErrors.banco_dato_facturacion ? "error" : "normal"}
                  placeholder={"Banco Galicia"}
                  onChange={handleChange}
                  value={values.banco_dato_facturacion}
                />
                {formErrors.banco_dato_facturacion && (
                  <ErrorInput>{formErrors.banco_dato_facturacion}</ErrorInput>
                )}
              </Col>
            </Grid>
          </Col>
          <Col desktop={6}>
            <CreditCardNew
              titular={values.titular_dato_facturacion}
              vencimiento={values.vencimiento_dato_facturacion}
              numero={values.num_dato_facturacion}
            />
          </Col>
        </Grid>
      )}
    </>
  );
};

export default PagoForm;
