import React from "react";
import { GridContainer, ColContainer } from "./styled";

const Grid = ({ children, ...props }) => {
  return (
    <GridContainer
      colGap={props.colGap}
      rowGap={props.rowGap}
      narrow={props.narrow}
      centerItems={props.centerItems}
      className={props.className}
    >
      {children}
    </GridContainer>
  );
};

const Col = ({ children, ...props }) => {
  return (
    <ColContainer
      desktop={props.desktop}
      tablet={props.tablet}
      mobile={props.mobile}
      spaced={props.spaced}
      alignBottom={props.alignBottom}
      center={props.center}
    >
      {children}
    </ColContainer>
  );
};

export { Grid, Col };