import React from "react";
import PropTypes from "prop-types";
import { InputDiv, Input, Icon} from "./styled";
import { MdInfoOutline } from "react-icons/md";

function InputTextarea({ labelName, type, name, placeholder, onChange, color, height, value, readOnly, maxLength, showIcon, iconImage }) {
  
  return (
    <InputDiv height={height}>
      <textarea
        type={type}
        name={name}
        color={color}
        height={height}
        value={value}
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        readOnly={readOnly}
        maxLength={maxLength}
      />
      <div className="label-container">
        <label
          className={
            value == null || value == ""
              ? "label-down"
              : "label-top"
          }
          htmlFor={name}
          color={color}
        >
          {labelName}
        </label>
        {showIcon && <Icon>
            <img className="tooltip" src={iconImage} alt="dale" />
            <MdInfoOutline className="image"></MdInfoOutline>
        </Icon>}
      </div>
    </InputDiv>
  );
}

// InputTextarea.propTypes = {
//   children: PropTypes.string.isRequired
// };

export default InputTextarea;
