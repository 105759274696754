import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const InputDiv = styled.div`
  width: 100%;
  //max-width: 260px;
  //margin: 0px 20px 23px 0px;
  position: relative;
  background: none;

  label{
    font-family: 'DM Sans', sans-serif;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 15px;
    line-height: 0.75;
    font-size: 0.8em;
    font-weight: 400;
    color: ${leerColor(colores.gris)};
    cursor: text;
    z-index: 60;
  }

  textarea {
    width: 100%;
    height: ${(props) => (props.height ? props.height : `200px`)};
    padding: 15px 15px 0px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    //align-content: center;
    // 
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8em;
    color: ${leerColor(colores.gris)};
    border: 1px solid ${leerColor(colores.gris)};
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px !important;
    //background-color: white;
    background-color: none;
    border: ${(props) => (props.color === "error" ? `1px solid ${leerColor(colores.rojo)}` : `1px solid ${leerColor(colores.gris)}`)};
    resize: none;

    ::placeholder { 
      opacity: 0; 
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder{
    color: ${leerColor(colores.gris)};
    }
    &:focus-visible{
      outline: none;
      border-color: ${leerColor(colores.celeste)};
    }
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

    // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px !important;
    font-weight: 600 !important;
    font-size: 0.7em !important;
    color: ${leerColor(colores.gris)} !important;
    z-index: 59 !important;
  }

  textarea:focus{
    border: 1px solid ${leerColor(colores.celeste)};
    outline: none;
    z-index: 59 !important;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
    }
  }

  textarea:focus + .label-container label{
    top: -0.6em;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7em;
    color: ${leerColor(colores.celeste)} !important;
    z-index: 59 !important;
  }

  
  /*
  width: 100%;
  display: flex;
  flex-direction: column;

  .label-container{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  label{
     
    //color: ${leerColor(colores.negro)};
    color: ${(props) => (props.children[0].props.color === "error" ? `${leerColor(colores.rojo)}` : `${leerColor(colores.negro)}`)};
    font-size: 18px;
    padding-bottom: 10px;
  }
  */
`
export const Icon = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  position: absolute;
  top: 8px;
  right: 18px;

  .image{
    width: 13px !important;
    padding-bottom: 8px;
    margin-left: 5px;
    color: ${leerColor(colores.celeste)}
  }

  .tooltip{
    display: none;
    position: absolute;
    background-color: rgba(30, 30, 30, 0.5);
    color: #fff;
    top: -280px;
    right: 0;
    padding: 8px;
    font-size: 14px;
    text-align: center;
     
    font-weight: 500;
    border-radius: 5px;
    width: 500px;

    ::after {
      content: "";
      display: block;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
      top: 100%;
      left: 50%;
      position: absolute;
    }
  }

  :hover{
      .tooltip{
        display:block;
      }
  }
`

export const Input = styled.textarea` 
  /*
   
  height: ${(props) => (props.height ? props.height : `200px`)};
  font-weight: 400;
  font-size: 16px;
  padding: 12px 20px;
  //border: 1px solid ${leerColor(colores.gris)};
  border: ${(props) => (props.color === "error" ? `1px solid ${leerColor(colores.rojo)}` : `1px solid ${leerColor(colores.gris)}`)};
  resize: none;
  &::placeholder{
    color: ${leerColor(colores.gris)};
  }
  &:focus-visible{
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  }
  @media (min-width: ${breakpoints.mobileGrande}) {

  }
  @media (min-width: ${breakpoints.tablet}) {
    
  }
  @media (min-width: ${breakpoints.laptop}) {

  }
  @media (min-width: ${breakpoints.laptopL}) {
    
  }
  @media (min-width: ${breakpoints.notebook}) {

  }
  */
`;
