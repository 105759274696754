import React from "react";
import Subtitle from "../../Subtitle";
import { FiArrowLeft, FiUser } from "react-icons/fi";
import { Col, Grid } from "../../Grid";
import NewButton from "../../NewButton";
import ErrorInput from "../../ErrorInput";
import NewInputBasic from "../../NewInputBasic"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const EditarGrupoForm = ({ form, data }) => {
  const { formErrors, handleValidation } = form
  const { integrantes, eliminarVendedor, handleChangeVendedor, vendedores, vendedoresSeleccionados, seleccionarVendedor, loading } = data
  return (
    <>
      <Subtitle
        icon={<FiUser />}
        title={"Integrantes actuales"}
        description="Lista de vendedores que forman parte del grupo"
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={true}>
        {integrantes.length > 0
          ? integrantes.map((vendedor) => {
              return (
                <Col desktop={3}>
                  <div className={`vendedor-container`}>
                    <span className="icon">
                      <FiUser />
                    </span>
                    <span>{vendedor.nombre}</span>
                    <NewButton
                      backgroundColor={false}
                      textColor={true}
                      borderColor={true}
                      onClick={() => eliminarVendedor(vendedor.id_vendedor)}
                    >
                      Eliminar
                    </NewButton>
                  </div>
                </Col>
              );
            })
          : null}
        {formErrors.integrantes && (
          <Col desktop={12}>
            <ErrorInput>{formErrors.integrantes}</ErrorInput>
          </Col>
        )}
      </Grid>
      <hr />
      <Subtitle
        icon={<FiUser />}
        title={"Agregar nuevos integrantes"}
        description="Seleccioná los vendedores que formarán parte del grupo"
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={12}>
          <NewInputBasic
            type={"text"}
            name={"nombre"}
            labelName={"Buscar vendedor"}
            placeholder={"Nombre del vendedor"}
            onChange={handleChangeVendedor}
          />
        </Col>
        {vendedores.length > 0
          ? vendedores.map((vendedor) => {
              const seleccionado = vendedoresSeleccionados.find(
                (ven) => ven === vendedor.numero
              );
              return (
                <Col desktop={3}>
                  <div
                    className={`vendedor-container ${
                      seleccionado ? "seleccionado" : ""
                    }`}
                    onClick={() => seleccionarVendedor(vendedor.numero)}
                  >
                    <span className="icon">
                      <FiUser />
                    </span>
                    <span>{vendedor.nombre}</span>
                  </div>
                </Col>
              );
            })
          : null}
      </Grid>
      <hr />
      <div className="buttons-container">
        <NewButton
          backgroundColor={true}
          onClick={handleValidation}
          disabled={loading}
        >
          {loading ? "Actualizando grupo..." : "Actualizar grupo"}
        </NewButton>
        <Link to="/Premios">
          <NewButton borderColor={true} textColor={true}>
            <FiArrowLeft /> Volver a Premios
          </NewButton>
        </Link>
      </div>
    </>
  );
};

export default EditarGrupoForm;
