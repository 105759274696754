import React, { useRef, useState } from "react";
import { Redirect } from "react-router";
import IdleTimer from "react-idle-timer";
import Modal from "../Modal";
import { useHistory } from "react-router-dom";
import { ModalIdle } from "./styled";
import NewTitle from "../NewTitle";
import NewButton from "../NewButton";

const Index = () => {
  const [reDirect, setReDirect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState();
  const history = useHistory(); // Hook de router

  const IdleTimerRef = useRef(null);

  const onIdle = () => {
    const user = localStorage.getItem("userInfo");
    if (user) {
      localStorage.clear();
      setIsModalOpen(true);
      setReDirect(true);
    }
  };

  const redirectToLogin = () => {
    setIsModalOpen(false);
    history.push("/");
  };

  return (
    <>
      <IdleTimer ref={IdleTimerRef} timeout={60 * 1000 * 60} onIdle={onIdle} />

      {reDirect ? (
        <Modal isModalOpen={isModalOpen} hideCross={true}>
          <ModalIdle>
            <div className="header-container">
              <NewTitle>El tiempo de sesión expiró </NewTitle>
              <p>
                Volvé a iniciar sesión con tu cuenta para continuar usando el
                CRM
              </p>
            </div>
            <hr />
            <div className={"actionButtonsCont"}>
              <div className={"buttonsCont"}>
                <NewButton
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  onClick={redirectToLogin}
                >
                  Volver a iniciar sesión
                </NewButton>
              </div>
            </div>
          </ModalIdle>
        </Modal>
      ) : null}
    </>
  );
};

export default Index;
