import { useEffect, useState } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import dayjs from "dayjs";
import { FiUser } from "react-icons/fi";

export const usePremio = (premioId, location) => {
  const [operaciones, setOperaciones] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [descripcion, setDescripcion] = useState(null);
  const [initial, setInitial] = useState({});

  const formatter = new Intl.NumberFormat("es-ES", {});

  const labels = [
    {
      name: "nombre_canal",
      header: "Canal",
    },
    {
      name: "nombre",
      header: "Cliente",
      component: (item) => {
        if (item.nombre) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.nombre}</span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
    },
    {
      name: "numero",
      header: "Operación",
    },
    {
      name: "prima_bruta",
      header: "Prima Bruta",
      component: (item) => {
        return (
          <td width="100px">
            ${formatter.format(Math.round(item.prima_bruta))}
          </td>
        );
      },
    },
    {
      name: "prima_neta",
      header: "Prima Neta",
      component: (item) => {
        return (
          <td width="100px">
            ${formatter.format(Math.round(item.prima_neta))}
          </td>
        );
      },
    },
    {
      name: "forma_pago",
      header: "Medio Pago",
    },
    {
      name: "fechauc",
      header: "F. Contratado",
      component: (item) => {
        return <td width="100px">{item.fechauc}</td>;
      },
    },
    {
      name: "fechaucac",
      header: "F. Emisión",
      component: (item) => {
        const fecha = item?.fechaucac?.includes("/")
          ? item?.fechaucac
          : dayjs(item?.fechaucac).format("DD/MM/YYYY");
        return <td width="100px">{fecha}</td>;
      },
    },
    {
      name: "estado",
      header: "Est. Novedades",
    },
    {
      name: "estado_ac",
      header: "Est. Emisión",
    },
    {
      name: "producto",
      header: "Producto",
    },
  ];

  useEffect(() => {
    const getPremio = async () => {
      if (premioId) {
        try {
          const operaciones = await axiosRequest.get(
            `/premio/${premioId.id_premio}/${premioId.id}/${
              parseInt(location.state.mes) + 1
            }`
          );
          if (operaciones.data.length > 0) {
            const op = operaciones.data.map((item) => {
              const fecha = item?.fechaucac ? "fechaucac" : "fechauc";
              const obj = item;
              obj[fecha] = dayjs(item[fecha]).format("DD/MM/YYYY");
              return obj;
            });
            setOperaciones(op);
          }
          setOperaciones(operaciones.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getPremio();
  }, [premioId]);

  useEffect(() => {
    if (operaciones.length > 0) {
      const headerKeys = Array.isArray(operaciones[0])
        ? operaciones[1][0]
        : operaciones[0];
      const headers = Object.keys(headerKeys).map((item) => {
        const headerData = labels.find((label) => item === label.name);
        let size = null;
        if (item === "numero") {
          size = "20px";
        } else if (item === "nombre") {
          size = "100px";
        } else {
          size = "80px";
        }

        let data = {
          header: labels.find((label) => item === label.name)?.header ?? item,
          name: item,
          size: size,
        };

        if (headerData?.component) {
          data.component = headerData.component;
        }

        return data;
      });
      setTableData(headers);
    }
  }, [operaciones]);

  useEffect(() => {
    let obj = {};
    let descripcionKeys = {};
    let duplicates = false;
    location.state.data.data.forEach((item, index) => {
      const keys = Object.keys(item).filter(
        (item) =>
          item !== "id_premio" &&
          item !== "estado" &&
          item !== "id_vendedor_premio"
      );

      keys.forEach((key) => {
        if (location.state.data[key] === 1) {
          duplicates = Object.keys(obj).some((item) => item.includes(key));
          descripcionKeys[key] = item[key];
          obj[key + "_" + index] = item[key];
        }
      });
    });
    let desc = location.state.data.descripcion;
    const keysForDescription = duplicates ? obj : descripcionKeys;
    Object.keys(keysForDescription).forEach(
      (key) =>
        (desc = desc.replace(
          `{${key}}`,
          keysForDescription[key] ? keysForDescription[key].toString() : ""
        ))
    );

    setDescripcion(desc);
    setInitial(obj);
  }, []);

  return {
    operaciones,
    tableData,
    descripcion,
    initial
  };
};
