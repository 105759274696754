import React from "react";
import { Container } from "./styled";
import Subtitulo from "../Subtitulo";

const CardSinResultados = ({ icon, title, description, disableBorder }) => {
  return (  
    <Container disableBorder={disableBorder} >
      <div className="sin-solicitudes-container">
        <span className="icon-sin-solicitudes">
          {icon}
        </span>
        <Subtitulo>{title}</Subtitulo>
        <span className="description">
          {description}
        </span>
      </div>
    </Container>
  );
};

export default CardSinResultados;
