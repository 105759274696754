import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
      responsive: true,
      title: {
        display: true,
        text: 'Operaciones emitidas con medios de pago',
      },
    },
  };

  /*
const data = {
    labels: [
      'Red',
      'Blue',
      'Yellow'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [300, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  };
  */

const PieChart = ({ data, optionsData }) => {
  return (
    <Doughnut data={data} options={optionsData ? optionsData : options} />
  )
}

export default PieChart