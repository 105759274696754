import { useState } from "react";

export const useViewMore = (initialValue) => {

  const [itemsToView, setItemsToView] = useState(initialValue);

  const handleItemsToView = (items) => {
    setItemsToView((prev) => prev + items)
  }

  return { itemsToView, handleItemsToView }

}