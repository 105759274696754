import styled, { css } from "styled-components";
import { leerColor, colores } from "../../../constants";

export const TickWrapper = styled.div`
    border: 5px solid #3D9F54;
    padding: 42px;
    border-radius: 50%;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 94px;
`;

export const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    height: 50vh;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
`;

export const IconErrorContainer = styled.span`
    font-size: 100px;
    color: ${leerColor(colores.rojo)};
    margin: 0;
    display: flex;
`;

export const SuccessContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
    a {
      text-decoration: none;
    }

    p {
      font-size: 14px;
      color: ${leerColor(colores.grisEstados)};
    }
`;


export const SuccessTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: center;
`;

export const ErrorTitle = styled.div`
   font-size:36px; color: ${leerColor(colores.rojo)};
   font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  margin: 0;
`;

export const LoadingContainer = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
