import axiosRequest from "../utils/axiosConfig";
import formatSelectData from "../utils/formatSelectData";

// Usuarios
export const getAllUsuarios = async (filterParams, orderParams, page) => {
    const pageFilter = page ? `page=${page === undefined ? 1 : page}&` : ''
    const usuariosData = await axiosRequest.get(`/operadores_usuarios/?${pageFilter}${filterParams}&${orderParams}`)
    return usuariosData.data
}

// Roles
export const getAllRoles = async () => {
  const rolesData = await axiosRequest.get(`/roles`);
  const formatRoles = formatSelectData(rolesData.data, 'id_rol', 'titulo_rol', 'id_rol')
  return formatRoles
}

// Crear Usuario
export const crearNuevoUsuario = async (usuario) => {
  return await axiosRequest.post(`/operadores`, usuario);
}