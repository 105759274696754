import React, { useState, useEffect } from "react";
import {
  InputTextarea,
  InputFileButton,
  ErrorInput,
  Subtitulo,
  Grid,
  Col,
  InputBasic,
  Modal,
  FileLoaded,
} from "../../Index";

import Subtitle from "../../Subtitle";
import { FiArrowLeft, FiEye, FiFilePlus } from "react-icons/fi";
import NewInputTextarea from "../../NewInputTextarea";
import InputFile from "../../InputFile";
import NewInputSelect from "../../NewInputSelect";
import NewInputBasic from "../../NewInputBasic";
import { useForm } from "../../../hooks/useForm";
import {
  getCompanias,
  postMensajeHistorial,
  postSolicitud,
} from "../../../services/Emision";
import { endosoFormReglas } from "../../../utils/formRules";
import { endosoForm } from "../../../utils/formInitialValues";
import NewButton from "../../NewButton";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useAuthContext } from "../../../context/AuthContext";
import axiosRequest from "../../../utils/axiosConfig";
import { useLoading } from "../../../hooks/useLoading";
import { LoadingContainer } from "../EnviarSolicitud/styled";
import SpinnerLoading from "../../SpinnerLoading";
import { useSolicitudContext } from "../../../context/SolicitudContext";
import { Container } from "./styled";

const EndosoForm = () => {
  const { endoso } = useSolicitudContext();
  const {
    values,
    formErrors,
    handleChange,
    handleChangeExternal,
    handleValidation,
    data,
  } = endoso;
  const { companias } = data;

  const [success, setSuccess] = useState(false);
  const location = useLocation();

  // User Data
  const { authState } = useAuthContext();
  const { userInfo } = authState;

  const { loading, setLoading } = useLoading();

  const id_operacion = new URLSearchParams(location.search).get("operacion");
  const tipoSolicitud = new URLSearchParams(location.search).get(
    "tipoSolicitud"
  );

  /*
  const getData = async () => {
    try {
      const companias = await getCompanias();
      setCompanias(companias);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  */

  const postearEndoso = async (endoso, solicitudID) => {
    let poliza = null;
    let bodyFormData = new FormData();
    try {
      bodyFormData.append("recfile", endoso.poliza.image, endoso.poliza.name);
      const createDoc = await axiosRequest.post(
        "/endoso_solicitudes/cargar",
        bodyFormData
      );
      let path = createDoc.data[0].path;
      poliza = path;
    } catch (error) {
      console.log(error);
      throw new Error();
    }

    const endosoData = {
      poliza: poliza,
      id_compania: endoso.id_compania,
      numero_poliza: endoso.numero_poliza,
      id_solicitud: solicitudID,
      cambios_poliza: endoso.cambios_poliza,
    };

    try {
      const endoso = await axiosRequest.post("/endoso_solicitudes", endosoData);
      return endoso;
    } catch (error) {
      console.log(error);
    }
  };

  async function handleSubmit() {
    setLoading(true);
    try {
      const solicitudData = {
        id_asegurado: null,
        id_asegurado_adicional: null,
        id_dato_facturacion: null,
        vendedor_solicitud: userInfo.id_operador,
        operador_solicitud: 66,
        operacion_solicitud: id_operacion,
        ingreso_solicitud: dayjs(new Date()).format("DD/MM/YYYY hh:mm:ss"),
        id_estado: 1,
        id_cobertura_asegurada: null,
        rechazo_solicitud: null,
        id_motivo: null,
        premio: 0,
        prima: 0,
        id_tipo_solicitud: tipoSolicitud,
      };
      const solicitud = await postSolicitud(solicitudData);
      await postearEndoso(values, solicitud.solicitudID);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={12}>
          <Subtitle
            title="Endoso"
            description="Información importante para realizar el endoso"
            icon={<FiFilePlus />}
          />
        </Col>
      </Grid>
      <Container>
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={4}>
          <NewInputSelect
            name={"id_compania"}
            labelName={"Compañía"}
            color={formErrors.id_compania ? "error" : "normal"}
            placeholder="Compañia"
            onChange={handleChange}
            options={companias}
            value={values.id_compania}
          />
          {formErrors.id_compania && (
            <ErrorInput>{formErrors.id_compania}</ErrorInput>
          )}
        </Col>
        <Col desktop={4}>
          <NewInputBasic
            type={"number"}
            name={"numero_poliza"}
            labelName={"Número de póliza"}
            color={formErrors.numero_poliza ? "error" : "normal"}
            placeholder={"Número de la póliza"}
            onChange={handleChange}
            value={values.numero_poliza}
          />
          {formErrors.numero_poliza && (
            <ErrorInput>{formErrors.numero_poliza}</ErrorInput>
          )}
        </Col>
      </Grid>
      <hr />
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={8}>
          <NewInputTextarea
            type={"text"}
            name={"cambios_poliza"}
            labelName={"Cambios a realizar en la póliza"}
            placeholder={
              "Describí cuáles son las modificaciones necesarias en la póliza mencionada"
            }
            maxLength={"1000"}
            onChange={handleChange}
            value={values.cambios_poliza}
          />
          {formErrors.cambios_poliza && (
            <ErrorInput>{formErrors.cambios_poliza}</ErrorInput>
          )}
        </Col>
      </Grid>
      <hr />
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={4}>
          <InputFile
            name={"archivo_dni"}
            labelName={"Foto del DNI"}
            required={true}
            onChange={handleChangeExternal}
            file={values.archivo_dni}
            text={"Foto del DNI"}
            accept={".jpg, .png, .jpeg"}
          />
          {formErrors.archivo_dni && (
            <ErrorInput>{formErrors.archivo_dni}</ErrorInput>
          )}
        </Col>
        <Col desktop={4}>
          <InputFile
            name={"archivo_riesgo"}
            labelName={"Foto del riesgo"}
            required={true}
            onChange={handleChangeExternal}
            file={values.archivo_riesgo}
            text={"Foto del riesgo"}
            accept={".jpg, .png, .jpeg"}
          />
          {formErrors.archivo_riesgo && (
            <ErrorInput>{formErrors.archivo_riesgo}</ErrorInput>
          )}
        </Col>
        <Col desktop={4}>
          <InputFile
            name={"archivo_documentacion_importante"}
            labelName={"Documentación importante"}
            required={true}
            onChange={handleChangeExternal}
            file={values.archivo_documentacion_importante}
            text={"Documentación importante"}
            accept={".jpg, .png, .jpeg, .pdf"}
          />
          {formErrors.archivo_documentacion_importante && (
            <ErrorInput>
              {formErrors.archivo_documentacion_importante}
            </ErrorInput>
          )}
        </Col>
      </Grid>
      </Container>
    </>
  );
};

export default EndosoForm;
