export const aseguradoLabels = {
    nombre_asegurado: "Nombre",
    apellido_asegurado: "Apellido",
    nacimiento_asegurado: "Fecha Nacimiento",
    dni_asegurado: "DNI",
    cuit_asegurado: "CUIT",
    email_asegurado: "Email",
    telefono_asegurado: "Teléfono",
    codigo_area_asegurado: "Código de Área",
    condicion_fiscal_asegurado: "Condición Fiscal",
    provincia_asegurado: "Provincia",
    localidad_asegurado: "Localidad",
    direccion_calle_asegurado: "Calle",
    direccion_numero_asegurado: "Número",
    direccion_piso_asegurado: "Piso",
    direccion_dpto_asegurado: "Depto",
    codigo_postal_asegurado: "Código Postal",
    genero_asegurado: "Género",
    profesion_asegurado: "Profesión",
    estado_civil_asegurado: "Estado Civil",
    nacionalidad_asegurado: "Nacionalidad",
    lugar_nacimiento_asegurado: "Lugar de Nacimiento",
    tipo_persona_asegurada: "Tipo persona"
}

export const seguroLabels = {
    cia_cobertura_asegurada: 'Compañia',
    producto_cobertura_asegurada: 'Producto',
    cobertura_cobertura_asegurada: 'Cobertura',
    tipo_facturacion_cobertura_asegurada: 'Tipo facturación',
    vigencia_cobertura_asegurada: 'Vigencia',
    cuotas_cobertura_asegurada: 'Cuotas',
    valor_cobertura_asegurada: 'Valor'
}

export const pagoLabels = {
    medio: "Medio",
    num_dato_facturacion: "Número",
    banco_dato_facturacion: "Banco",
    vencimiento_dato_facturacion: "Vencimiento",
    titular_dato_facturacion: "Titular",
    dni_dato_facturacion: "DNI",
    marca_dato_facturacion: "Proveedor de tarjeta",
    cuil_titular_dato_facturacion: "CUIL titular"
}

export const anulacionLabels = {
  //poliza: "Póliza",
  numero_poliza: "Número póliza",
  nombre_compania: "Compañia",
}

export const endosoLabels = {
  numero_poliza: "Número póliza",
  nombre_compania: "Compañia",
  //poliza: "Póliza",
  cambios_poliza: "Modificaciones"
}

export const CUOTAS = {
    anual: "Medio",
    semestral: "Número",
    cuatrimestral: "Banco",
    trimestral: "Vencimiento",
}

export const SELECT_OPTIONS_CERRADURAS = [
    {
      'label': "Doble Paleta",
      'value': "Doble Paleta",
      'key': 1
    }, 
    {
      'label': "No Corresponde",
      'value': "No Corresponde",
      'key': 2
    },
    {
      'label': "No Posee Cerradura",
      'value': "No Posee Cerradura",
      'key': 3
    },
    {
      'label': "No Posee Puerta",
      'value': "No Posee Puerta",
      'key': 4
    },
    {
      'label': "Otras Cerraduras de Seguridad",
      'value': "Otras Cerraduras de Seguridad",
      'key': 5
    },
    {
      'label': "Tambor - Paleta Simple",
      'value': "Tambor - Paleta Simple",
      'key': 6
    }
]

export const SELECT_OPTIONS_VENTANAS = [
    {
      'label': "Cortina Plastico",
      'value': "Cortina Plastico",
      'key': 1
    }, 
    {
      'label': "Cortina / Postigon",
      'value': "Cortina / Postigon",
      'key': 2
    },
    {
      'label': "No Corresponde",
      'value': "No Corresponde",
      'key': 3
    },
    {
      'label': "Rejas",
      'value': "Rejas",
      'key': 4
    },
    {
      'label': "Sin Protección",
      'value': "Sin Protección",
      'key': 5
    },
    {
      'label': "Vidrios de Seguridad",
      'value': "Vidrios de Seguridad",
      'key': 6
    }
  ]

export const SELECT_OPTIONS_CLARABOYA_BANDOLERA = [
    {
      'label': "No Existe",
      'value': "No Existe",
      'key': 1
    }, 
    {
      'label': "No Tiene Rejas",
      'value': "No Tiene Rejas",
      'key': 2
    },
    {
      'label': "Tiene Rejas",
      'value': "Tiene Rejas",
      'key': 3
    }
  ]

export const SELECT_OPTIONS_TECHOS = [
    {
      'label': 'Losa',
      'value': 'Losa',
      'key': 1
    }, 
    {
      'label': 'Tejas',
      'value': 'Tejas',
      'key': 2
    },
    {
      'label': 'Otra',
      'value': 'Otra',
      'key': 3
    }
  ];

export const SELECT_OPTIONS_RC = [
  {
    'label': "Comprensiva",
    'value': "Comprensiva",
    'key': 1
  }, 
  {
    'label': "Praxis médica",
    'value': "Praxis médica",
    'key': 2
  },
  {
    'label': "Profesional",
    'value': "Profesional",
    'key': 3
  },
  {
    'label': "Construcción",
    'value': "Construcción",
    'key': 4
  },
  {
    'label': "Refacción",
    'value': "Refacción",
    'key': 5
  },
  {
    'label': "Demolición",
    'value': "Demolición",
    'key': 6
  }
]

export const SELECT_OPTIONS_MASCOTAS = [
  {
    'label': "Canino",
    'value': "Canino",
    'key': 1
  }, 
  {
    'label': "Felino",
    'value': "Felino",
    'key': 2
  }
]

export const SELECT_OPTIONS_TIPO_TRANSPORTE = [
  {
    'label': "Camión",
    'value': "Camión",
    'key': 1
  }, 
  {
    'label': "Camioneta",
    'value': "Camioneta",
    'key': 2
  }
]

export const SELECT_OPTIONS_VIA = [
  {
    'label': "Marítima",
    'value': "Marítima",
    'key': 1
  }, 
  {
    'label': "Terrestre",
    'value': "Terrestre",
    'key': 2
  },
  {
    'label': "Aérea",
    'value': "Aérea",
    'key': 3
  }
]

export const SELECT_OPTIONS_TIPO_EMBARCACION = [
  {
    'label': "Jet-Ski",
    'value': "Jet-Ski",
    'key': 1
  }, 
  {
    'label': "Velero",
    'value': "Velero",
    'key': 2
  },
  {
    'label': "Gomón",
    'value': "Gomón",
    'key': 3
  },
  {
    'label': "Lancha",
    'value': "Lancha",
    'key': 4
  },
  {
    'label': "Crucero",
    'value': "Crucero",
    'key': 5
  },
]

export const SELECT_OPTIONS_TIPO_VEHICULO = [
  {
    'label': "Auto",
    'value': "Auto",
    'key': 1
  }, 
  {
    'label': "Camión",
    'value': "Camión",
    'key': 2
  },
  {
    'label': "Semirremolque",
    'value': "Semirremolque",
    'key': 3
  },
  {
    'label': "Pick-Up",
    'value': "Pick-Up",
    'key': 4
  }
]

export const SELECT_OPTIONS_MATERIAL_CUADRO = [
  {
    'label': "Acero",
    'value': "Acero",
    'key': 1
  }, 
  {
    'label': "Aluminio",
    'value': "Aluminio",
    'key': 2
  },
  {
    'label': "Carbono",
    'value': "Carbono",
    'key': 3
  }
]

export const SELECT_OPTIONS_USO = [
  {
    'label': "Comercial",
    'value': "Comercial",
    'key': 1
  }, 
  {
    'label': "Particular",
    'value': "Particular",
    'key': 2
  }
]