import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Div = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
`;

export const InputDiv = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid ${leerColor(colores.gris)};
  border-style: dashed;
  border-radius: 6px;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
     
    color: ${leerColor(colores.negro)};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }

  label span {
    margin-right: 10px;
  }

  label span img {
    width: 15px;
    display: block;
  }
`;

export const Input = styled.input`
  display: none;
  /*  
  width: fit-content;
  font-weight: 400;
  font-size: 12px;
  border: 1px solid ${leerColor(colores.gris)};
  &::placeholder{
    color: ${leerColor(colores.gris)};
  }
  &:focus-visible{
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  } */

  &:disabled {
    padding: 500px;
  }

  @media (min-width: ${breakpoints.mobileGrande}) {
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.laptop}) {
  }
  @media (min-width: ${breakpoints.laptopL}) {
  }
  @media (min-width: ${breakpoints.notebook}) {
  }
`;
