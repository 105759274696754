import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  FileLoaded,
  InputSelect,
  ProgressBar,
  Menu,
  Wrapper,
  Modal,
  NewProgressBar,
  BurgerMenu, 
  NavMenu,
  InputTextarea,
  InputFileRec,
  InputFileRec2
} from "../components/Index";
import { CargaSolicitud } from "../components/ProgressBar/styled";
import { ClearFilter, ClearFilterContainer, EditOperadorIcon, SolEmisionTitleContainer, ModalEditarOperador, ModalBorrarExcepcion, OperadorItem, GridCumplimientoContainer, GridCumplimientoContainerHeader } from './styled'
import axios from "axios";
import axiosRequest from "../utils/axiosConfig";
import IconButton from "../components/IconButton";
import editIcon from "../assets/iconos/edit.svg";
import deleteIcon from "../assets/iconos/delete.svg";
import Filters from "../components/Filters"
import ModalSuccess from "../components/ModalSuccess";
import { AuthContext } from '../context/AuthContext'
import { useHistory } from 'react-router-dom';
import { anios, meses } from "../constants/data";
import Pagination from "../components/Pagination";
import { rolesObjetivos } from "../constants/roles";
import SpinnerLoading from "../components/SpinnerLoading";
import docImage from '../assets/images/doc_cuadrado.png'
import { ImFilter } from "react-icons/im";
import { MdFileUpload } from "react-icons/md";
import { HiPlus } from "react-icons/hi";

const Objetivos = () => {
     // Context
     const authContext = useContext(AuthContext)
     const user = JSON.parse(localStorage.getItem('userInfo'))
     const allowedRoles = rolesObjetivos;
 
     const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
     const history = useHistory(); // Hook de router
 
     const [dataComercial, setDataComercial] = useState([])
     const [dataMarketing, setDataMarketing] = useState([])
     const [open, setOpen] = useState(false)
     const [data, setData] = useState([]);
     const [hayBusquedas, setHayBusquedas] = useState(true);
     const [productos, setProductos] = useState([])
     const [allProductos, setAllProductos] = useState([])
     const [canales, setCanales] = useState([])
     const [requiredFilters, setRequiredFilters] = useState({
         anio: '2022',
         mes: '8',
         producto: '18',
         canal: '10'
     })
     const [popUpMessage, setPopUpMessage] = useState('')
     const [isSuccessPopUpOpen, setIsSuccessPopUpOpen] = useState(false)
     const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1)
     const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
     const [postObjetivosLoading, setPostObjetivosLoading] = useState(false)

     const [csvFile, setCsvFile] = useState();

     const [errors, setErrors] = useState({})
     const [inputValues, setInputValues] = useState({
        mes: currentMonth - 1,
        anio: currentYear,
        canal: '',
        leads: '',
        operaciones: '',
        producto: ''
      })
     const [isCrearObjetivoModalOpen, setIsCrearObjetivoModalOpen] = useState(false)
     const [isEditarObjetivoModalOpen, setIsEditarObjetivoModalOpen] = useState(false)
     const [editMode, setEditMode] = useState(false)
     const [isDeleteObjetivoModalOpen, setIsDeleteObjetivoModalOpen] = useState(false)
     const [deleteObjetivoData, setDeleteObjetivoData] = useState('')

    // Pagination
    const [pageSelected, setPageSelected] = useState(1)
    const [solicitudesLength, setSolicitudesLength] = useState(0)
    const [filterParams, setFilterParams] = useState('')
 
     const values = {
         anio: '',
         mes: '',
         producto: '',
         canal: ''
     }

     const inputs = [
        {
          name: "anio",
          labelName: "Año",
          options: anios,
          component: InputSelect
        },
        {
          name: "mes",
          labelName: "Mes",
          options: meses,
          component: InputSelect 
        },
        {
            name: "producto",
            labelName: "Producto",
            options: allProductos,
            component: InputSelect 
        },
        {
            name: "canal",
            labelName: "Canal",
            options: canales,
            component: InputSelect 
        },
    ]

    const headers = ["Id", "Año", "Mes", "Canal", "Producto", "Leads", "Operaciones", "Acciones"]

        // Chequear si el usuario está autenticado, caso contrario lo manda al login. 
        useEffect(() => {
            if(!authContext.isAuthenticated()){
            history.push("/");
            }
        }, [])
    
        // Chequear el rol del usuario autenticado para saber si puede acceder a la página
        useEffect(() => {
            if(user){
                if(!allowedRoles.includes(user.id_rol) && user.id_rol != 8){
                    history.push("/Rechazadas")
                } else if(!allowedRoles.includes(user.id_rol) && user.id_rol == 8) {
                    history.push("/Rechazos")
                }
            }
        }, [])

    function handleChange(event){
        const { name, value } = event.target;
        setInputValues(prev => ({...prev, [name]: value, }))
    }

    /*
    function handleChange(event){
        const { name, value } = event.target;
        setInputValues(prev => ({...prev, [name]: value, }))
    }
    */

    const getObjetivos = async () => {
        try{
            const allObjetivos = await axiosRequest.get(`/all_objetivos?${filterParams}`)
            const data = await axiosRequest.get(`/objetivos?page=${pageSelected}&${filterParams}`)
            setSolicitudesLength(allObjetivos.data.length)
            data.data.forEach(objetivo => {
                if(objetivo.anio === currentYear && objetivo.mes === currentMonth){   
                    objetivo.acciones = <div className="actionsContainer"><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={editIcon} tooltipText="Editar Objetivo" action={() => openEditModal(objetivo)} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={deleteIcon} tooltipText="Borrar Objetivo" action={() => openDeleteModal(objetivo)} /></div>
                } else {
                    objetivo.acciones = "-"
                }
                const monthName = meses.find(mes => parseInt(mes.value) + 1 === objetivo.mes)
                objetivo.mes = monthName.label
            })
            
            setData(data.data)
            setHayBusquedas(false)
        } catch (error) {
            console.log(error)
            setHayBusquedas(false)
        }
    }

    useEffect(() => {
        getObjetivos()
    }, [pageSelected, filterParams])

    useEffect(() => {
        const getData = async () => {
            try{
                const allProductos = await axiosRequest.get("/objetivos-productos")
                const productos = await axiosRequest.get("/estadisticas-productos")
                const canales = await axiosRequest.get("/canales")
                const canalesList = canales.data.map(canal => {
                    return {
                        'label': canal.descripcion,
                        'value': canal.numero,
                        'key': canal.numero
                    }
                })
                const productosList = productos.data.map(producto => {
                    return {
                        'label': producto.grupo == 8 ? "Dispositivos" : producto.descripcion,
                        'nombre_producto': producto.descripcion,
                        'value': producto.grupo,
                        'key': producto.numero
                    }
                })
                const allProductosList = allProductos.data.map(canal => {
                    return {
                        'label': canal.descripcion,
                        'value': canal.numero,
                        'key': canal.numero
                    }
                })
                setAllProductos(allProductosList)
                setProductos(productosList)
                setCanales(canalesList)
            } catch (error) {
                console.log(error)
            }
        }
        getData()
    }, [])

    useEffect(() => {
          if(!isCrearObjetivoModalOpen){
            setInputValues({
             canal: '',
             producto: '',
             anio: '',
             operaciones: '',
             leads: '',
           })
           setErrors('')
           setEditMode(false)
         }
    }, [isCrearObjetivoModalOpen])

    const validateObjetivoData = () => {
        let errors = {}
    
        if(!inputValues.producto){
            errors.producto = 'Este campo es requerido.'
        }
        if(!inputValues.canal){
            errors.canal = 'Este campo es requerido.'
        }
        
        if(!inputValues.operaciones){
            errors.operaciones = 'Este campo es requerido.'
        } 
    
        if(!inputValues.leads){
            errors.h2 = 'Este campo es requerido.'
        } 
    
        if(!inputValues.mes){
            errors.mes = 'Este campo es requerido.'
        }

        if(!inputValues.anio){
            errors.anio = 'Este campo es requerido.'
        }
    
        setErrors(errors)
    
        if(Object.keys(errors).length == 0){
          return true
        } 
    }

    const cargarObjetivos = async () => {
        const file = csvFile;
        const reader = new FileReader();

        reader.onload = function(e) {
            const text = e.target.result;
            const headers = text.slice(0, text.indexOf('\n')).split(",")
            headers.splice(headers.indexOf("canal"), 1)
            headers.splice(headers.indexOf("producto"), 1)
            const content = text.slice(63, text.lastIndexOf(text.slice(-1))).split('\n')

            const objetivos = content.map(obj => {
                const values = obj.split(";")
                values.splice(1, 1)
                values.splice(2, 1)

                return {
                    canal_obj_lead: values[0],
                    prod_obj_lead: values[1],
                    lead_obj_lead: values[4],
                    op_obj_lead: values[5].slice(0, -1),
                    mes_obj_lead: values[2],
                    anio_obj_lead: values[3],
                }
            })
            if(objetivos.length > 0){
                objetivos.forEach(async (obj, index) => {
                    setPostObjetivosLoading(true)
                    try {
                        await axiosRequest.post('/objetivo', obj)
                        if(index == objetivos.length - 1){
                            setPostObjetivosLoading(false)
                            getObjetivos()
                            setIsCrearObjetivoModalOpen(false)          
                            setPopUpMessage('¡Objetivos cargados!')
                            setIsSuccessPopUpOpen(true);
                            setTimeout(() => {
                              setIsSuccessPopUpOpen(false)
                            }, 1200);
                        }
                    } catch (error) {
                        console.log(error)
                    }
                })
            }
        }
        reader.readAsText(file);
    }
    
    const openEditModal = (objetivo) => {
        const monthNumber = meses.find(mes => mes.label === objetivo.mes)
        setEditMode(true)
        setInputValues({
            id: objetivo.id,
            canal: objetivo.id_canal, 
            producto: objetivo.id_producto,
            mes: monthNumber.value,
            anio: objetivo.anio,
            leads: objetivo.leads,
            operaciones: objetivo.operaciones
        })
        setIsEditarObjetivoModalOpen(true)
    }

    const openDeleteModal = (objetivo) => {
        setDeleteObjetivoData(objetivo)
        setIsDeleteObjetivoModalOpen(true)
    }

    const editObjetivo = async () => {
        const validation = validateObjetivoData()
        if(validation){
          try {
            const data = {
                canal_obj_lead: inputValues.canal,
                prod_obj_lead: inputValues.producto,
                lead_obj_lead: inputValues.leads,
                op_obj_lead: inputValues.operaciones,
                mes_obj_lead: parseInt(inputValues.mes) + 1,
                anio_obj_lead: inputValues.anio,
            }        
            const postNewURL = await axiosRequest.put(`/objetivo/${inputValues.id}`, data)
            if(postNewURL.status === 200){   
                getObjetivos()
                setIsEditarObjetivoModalOpen(false)          
                setPopUpMessage('!Objetivo editado!')
                setIsSuccessPopUpOpen(true);
                setTimeout(() => {
                  setIsSuccessPopUpOpen(false)
                }, 1200);
            }
          } catch (error) {
              console.log(error)
              console.log(error.response)
              console.log(error.response.data)
          } 
        }
    }

    const deleteObjetivo = async () => {
        try {
            const deleteUrl = await axiosRequest.delete(`/objetivo/${deleteObjetivoData.id}`)
            if(deleteUrl.status === 200){ 
                getObjetivos()    
                setIsDeleteObjetivoModalOpen(false)          
                setPopUpMessage('¡Objetivo eliminado!')
                setIsSuccessPopUpOpen(true);
                setTimeout(() => {
                  setIsSuccessPopUpOpen(false)
                }, 1200);
            }
        } catch (error) {
            console.log(error)
        }
      }

    useEffect(() => {
        console.log(deleteObjetivoData)
    }, [deleteObjetivoData])

    return (
        <div>
            <BurgerMenu open={open} setOpen={setOpen}/>
            <NavMenu open={open} setOpen={setOpen} user={user} active={"objetivos"} />

            <ModalSuccess isSuccessPopUpOpen={isSuccessPopUpOpen} setIsSuccessPopUpOpen={setIsSuccessPopUpOpen} popUpMessage={popUpMessage} />

            {isCrearObjetivoModalOpen ? (
                <Modal
                isModalOpen={isCrearObjetivoModalOpen}
                setIsModalOpen={setIsCrearObjetivoModalOpen}
                >
                <ModalEditarOperador>
                    <Titulo margin={true}>
                        Cargar Objetivos
                    </Titulo>
                    <Grid colGap={21} rowGap={21}>
                        {!postObjetivosLoading ? 
                            <>
                            <Col desktop={12}>
                                <InputFileRec2
                                    type={"file"}
                                    name={"objetivos"}
                                    onChange={(e) => { 
                                        setCsvFile(e.target.files[0])
                                        e.target.value = "";
                                    }}
                                    accept={".csv"}
                                    text={"Cargar archivo .csv"}
                                />
                                {csvFile ? 
                                    <FileLoaded filename={csvFile.name} file={docImage} link={URL.createObjectURL(csvFile)} deleteFile={() => setCsvFile('')} />
                                : 
                                    null 
                                }
                            </Col>
                            
                            <Col desktop={12}>
                                <div className="buttonsContSiniestro">
                                    <Button
                                    backgroundColor={false}
                                    borderColor={true}
                                    textColor={true}
                                    icon={false}
                                    onClick={() => {
                                        setCsvFile('')
                                        setIsCrearObjetivoModalOpen(false)
                                    }}
                                    >
                                    Cancelar
                                    </Button>
                                    <Button
                                        backgroundColor={true}
                                        borderColor={false}
                                        textColor={false}
                                        icon={false}
                                        onClick={cargarObjetivos}
                                        disabled={csvFile ? false : true}
                                    >
                                        Cargar Objetivos
                                    </Button>
                                </div>
                            </Col>
                            </>
                        : 
                            <Col desktop={12}>
                                <SpinnerLoading text="Cargando objetivos..." />
                            </Col>
                        }
                    </Grid>
                </ModalEditarOperador>
                </Modal>
            ) : null}

    
    {isEditarObjetivoModalOpen ? (
        <Modal
          isModalOpen={isEditarObjetivoModalOpen}
          setIsModalOpen={setIsEditarObjetivoModalOpen}
        >
          <ModalEditarOperador>
            <Titulo margin={true}>
              Editar Objetivo
            </Titulo>
            <Grid colGap={21} rowGap={21}>
                <Col desktop={6}>
                    <InputSelect
                      name={"canal"}
                      labelName={"Canal"}
                      options={canales}
                      onChange={handleChange}
                      value={inputValues.canal}
                    />
                    {errors.canal && <span className="error">{errors.canal}</span>}
                </Col>
                <Col desktop={6}>
                    <InputSelect
                      name={"producto"}
                      labelName={"Producto"}
                      options={allProductos}
                      onChange={handleChange}
                      value={inputValues.producto}
                    />
                    {errors.producto && <span className="error">{errors.producto}</span>}
                </Col>
                <Col desktop={6}>
                    <InputSelect
                      name={"mes"}
                      labelName={"Mes"}
                      options={meses}
                      onChange={handleChange}
                      value={inputValues.mes}
                    />
                    {errors.mes && <span className="error">{errors.mes}</span>}
                </Col>
                <Col desktop={6}>
                    <InputSelect
                      name={"anio"}
                      labelName={"Año"}
                      options={anios}
                      onChange={handleChange}
                      value={inputValues.anio}
                    />
                    {errors.anio && <span className="error">{errors.anio}</span>}
                </Col>
                <Col desktop={6}>
                    <InputBasic
                      type={"number"}
                      name={"leads"}
                      labelName={"Leads"}
                      placeholder={"Ej: 150"}
                      onChange={handleChange}
                      value={inputValues.leads}
                    />
                    {errors.leads && <span className="error">{errors.leads}</span>}
                </Col>
                <Col desktop={6}>
                    <InputBasic
                      type={"number"}
                      name={"operaciones"}
                      labelName={"Operaciones"}
                      placeholder={"Ej: 150"}
                      onChange={handleChange}
                      value={inputValues.operaciones}
                    />
                    {errors.operaciones && <span className="error">{errors.operaciones}</span>}
                </Col>
                <Col desktop={12}>
                      <div className="buttonsContSiniestro">
                        <Button
                          backgroundColor={false}
                          borderColor={true}
                          textColor={true}
                          icon={false}
                          onClick={() => setIsEditarObjetivoModalOpen(false)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          backgroundColor={true}
                          borderColor={false}
                          textColor={false}
                          icon={false}
                          onClick={editObjetivo}
                        >
                            Guardar cambios
                        </Button>
                      </div>
                  </Col>
            </Grid>
          </ModalEditarOperador>
        </Modal>
    ) : null}
    

    {isDeleteObjetivoModalOpen ? (
        <Modal isModalOpen={isDeleteObjetivoModalOpen} setIsModalOpen={setIsDeleteObjetivoModalOpen} >
            <ModalBorrarExcepcion>
            <h2>¿Desea borrar el objetivo?</h2>
            <Grid colGap={21} rowGap={8}>
                <Col desktop={4}>
                  <p><span className="data">Canal:</span> {deleteObjetivoData.canal}</p>
                </Col>
                <Col desktop={4}>
                  <p><span className="data">Producto:</span> {deleteObjetivoData.producto}</p>
                </Col>
                <Col desktop={4}>
                  <p><span className="data">Mes:</span> {deleteObjetivoData.mes}</p>
                </Col>
                <Col desktop={4}>
                  <p><span className="data">Año:</span> {deleteObjetivoData.anio}</p>
                </Col>
                <Col desktop={4}>
                  <p><span className="data">Leads:</span> {deleteObjetivoData.leads}</p>
                </Col>
                <Col desktop={4}>
                  <p><span className="data">Operaciones:</span> {deleteObjetivoData.operaciones}</p>
                </Col>
            </Grid>
            <div className={'buttonsCont'}>
                <Button backgroundColor={false} borderColor={true} textColor={true} icon={false} onClick={() => setIsDeleteObjetivoModalOpen(false)}> Cancelar </Button>
                <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={deleteObjetivo}>Borrar Objetivo</Button>
            </div>
            </ModalBorrarExcepcion>
        </Modal>
    ) : null}

            <Wrapper>
                <Inner>
                <Grid colGap={21} rowGap={21} narrow={true}>
                    <Col desktop={12}>
                        <SolEmisionTitleContainer>
                            <Titulo textColor={false}>Objetivos</Titulo>
                            <Button
                                backgroundColor={true}
                                borderColor={false}
                                textColor={false}
                                icon={false}
                                onClick={() => setIsCrearObjetivoModalOpen(true)}
                            >
                                <span>
                                    <MdFileUpload></MdFileUpload>
                                </span>
                                Cargar objetivos
                            </Button>
                        </SolEmisionTitleContainer>
                    </Col>
                    <Col desktop={12}>
                        <Subtitulo>
                            <span><ImFilter></ImFilter></span>
                            Filtrar objetivos
                        </Subtitulo>
                    </Col> 
                    <Filters inputs={inputs} values={values} data={data} setData={setData} setHayBusquedas={setHayBusquedas} setFilterParams={setFilterParams} setPageSelected={setPageSelected} />          
                </Grid>
                </Inner>
                <InfoBoard borderTop={true} hayBusquedas={hayBusquedas} headers={headers} data={data} loading={hayBusquedas} page="Landings" />
                {solicitudesLength > 10 && <Pagination data={data} dataLength={solicitudesLength} setPageSelected={setPageSelected} />}
            </Wrapper>
        </div>
    )
}

export default Objetivos