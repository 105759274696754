import { Container, SinResultadosContainer } from './styled'
import SkeletonLoading from '../SkeletonLoadingNew'
import PaginationNew from '../PaginationNew'

const TableInfo = ({ columns, data, loading, order, orderParams, pagination, title, total }) => {

    return (
        <Container columns={columns.map(item => item.size)}>
            {title && <h2>{title}</h2>}
            <table>
                <thead>
                    <tr>
                        {columns.map((header, index) => {
                            return (
                                <th key={index} onClick={()=> order(header.name)}>
                                    {header.header}
                                    {orderParams.includes(header.name) && orderParams.includes("ASC") && <span>↑</span>}
                                    {orderParams.includes(header.name) && orderParams.includes("DESC") && <span>↓</span>}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 && data.map((item, index) => {
                        return (
                            <tr key={index}>
                                {columns.map((column, indexValue) => {
                                    return (
                                        column?.component ?
                                            column.component && column.component(item, index)
                                        :
                                        <td key={indexValue} className={column.header.toLowerCase()}>
                                            <span className={column?.itemClassName && item[column.header.toLowerCase()].toLowerCase().replace(/\s/g,'')}>{item[column.name.toLowerCase()]}</span>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}

                    {total && Object.values(total).map(item => { return <td className="total"><span>{item}</span></td>})}
                </tbody>
            </table>
            {data.length <= 0 && loading && <SkeletonLoading rows={columns.length} />}
            {data.length === 0 && !loading &&   
                <SinResultadosContainer>           
                    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.9593 0C9.85123 0 0.000976562 9.85025 0.000976562 21.9583C0.000976562 34.0663 9.85123 43.9166 21.9593 43.9166C34.0673 43.9166 43.9176 34.0663 43.9176 21.9583C43.9176 9.85025 34.0674 0 21.9593 0ZM21.9593 41.3333C11.2746 41.3333 2.58427 32.643 2.58427 21.9583C2.58427 11.2736 11.2746 2.58329 21.9593 2.58329C32.644 2.58329 41.3343 11.2736 41.3343 21.9583C41.3343 32.643 32.644 41.3333 21.9593 41.3333Z" fill="#787878"/>
                        <path d="M61.6211 59.7962L37.485 35.66C36.9813 35.1563 36.1623 35.1563 35.6586 35.66C35.1548 36.1638 35.1548 36.9827 35.6586 37.4865L59.7946 61.6225C60.0478 61.8731 60.3784 61.9997 60.7091 61.9997C61.0398 61.9997 61.3704 61.8732 61.6211 61.6225C62.1248 61.1188 62.1248 60.3 61.6211 59.7962Z" fill="#787878"/>
                        <path d="M31.1667 23H12.8333C12.3733 23 12 22.552 12 22C12 21.448 12.3733 21 12.8333 21H31.1667C31.6267 21 32 21.448 32 22C32 22.552 31.6267 23 31.1667 23Z" fill="#787878"/>
                    </svg>
                </SinResultadosContainer>    
            }
            {pagination && pagination.dataLength > 10 && <PaginationNew currentPage={pagination.currentPage} lastPage={pagination.lastPage} previousPage={pagination.previousPage} nextPage={pagination.nextPage} dataLength={pagination.dataLength} selectPage={pagination.selectPage}/>}
        </Container>
    )
}

export default TableInfo
