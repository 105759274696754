import { Container } from "./styled"

const InfoBoardRow = ({column, value, allValues, styleClass, page, selectRegister, checkboxesStatus, item}) => {

    const userRol = JSON.parse(localStorage.getItem('userInfo'))

    if(column === "CBU"){
        styleClass = "breakWord"
    }
    if(column === "dni"){
        styleClass = "breakWord"
    }

    // Tabla Cumplimiento Anual --> Columna "Diferencia"
    if(column === "diferencia"){
        styleClass = value[0] === "-" ? "negativeDifference" : "positiveDifference"
    }
    // Tabla de Solicitud Emisión / Emisiones --> Muestra el color del estado
    if(column === "estado"){
        styleClass = value ? value.toLowerCase().replace(/\s/g, '') : ''
    }
    if(value === "Subtotal" || value === "Total"){
        styleClass = "subtotal"
    }   

    return (
        <Container>
            {column === "id" && page === "Solicitud Emisión" && (userRol.id_rol == 1 || userRol.id_rol == 14) ? <input type="checkbox" onClick={(event)=> selectRegister(event, item)} checked={checkboxesStatus[item.id]} /> : null}
            {column === "url" ? 
                <a href={allValues.full_url_marketing_url} target="_blank" rel="noreferrer"><span className={styleClass ? styleClass : ''}>{value}</span></a>
            : 
                <span className={styleClass ? styleClass : ''}>{value}</span>
            }
        </Container>
    )
}

export default InfoBoardRow