import React, { forwardRef } from "react";
import { Boton } from "./styled";

const Button = ({tamaño, children, backgroundColor, borderColor, textColor, icon, direction, onClick, href, disabled, download, type}, ref) => {
  return (
    <Boton
      data-backgroundcolor={backgroundColor ? true : false}
      data-bordercolor={borderColor}
      data-textcolor={textColor}
      icon={icon}
      direction={direction}
      onClick={onClick}
      href={href}
      data-disabled={disabled ? true : false}
      ref={ref}
      download={download}
      target={"_blank"}
      tamaño={tamaño}
      type={type}
    >
      {children}
    </Boton>
    );
}

export default forwardRef(Button);
