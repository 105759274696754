import { useState } from "react";

export const useLoading = (initialState) => {
  const [loading, setLoading] = useState(initialState ? initialState : false)
  const [loadingText, setLoadingText] = useState(null)

  const handleLoading = () => {
    setLoading(!loading)
  }

  const changeLoadingText = (message) => {
    setLoadingText(message)
  }

  return {
    loading,
    handleLoading, 
    setLoading,
    changeLoadingText,
    loadingText
  }
};
