import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`

border-top: 1px solid #ebebeb;
padding-top: calc(40px - 1em);
margin-top: ${props => (props.disabledMarginTop ? `0` : "calc(40px + 1em)")};

table {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  font-size: 14px;
  grid-template-columns: ${({ columns }) => columns.join(' ') };
}

thead,
tbody,
tr {
  display: contents;
}

th {
  position: sticky;
  top: 0;
  text-align: left;
  //font-weight: 600;
  font-size: 0.9rem;
  color: ${leerColor(colores.celeste)};
  padding: 20px 0;
  cursor: pointer;
  background-color: ${leerColor(colores.blanco)};
  z-index: 10;
}

td {
  padding: 20px 0;
  color: ${leerColor(colores.negro)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #f1f1f1;
  font-weight: 300;
  overflow: revert;
  position: relative;
}

.actionsContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  svg {
    fill: ${leerColor(colores.celeste)};
  }
}

td img { 
    margin-right: 10px;
    width: 90px;
}

.compania-siniestro {
  width: 70px;
}

.cobertura, .cliente, .vendedor, .operador, .producto, .compania {
  white-space: pre-wrap;
  padding-right: 20px;
}

.asegurado, .tipo, .nombre {
  white-space: pre-wrap;
  padding-right: 20px;
  box-sizing: border-box;
}

.total {
  background-color: #f3fbff;

  span {
    color: ${leerColor(colores.celeste)};
    font-weight: 600;
    position: relative;
  }
}

// *** PRIORIDAD *** //

.baja {
  background-color: ${leerColor(colores.verde)};
  color: ${leerColor(colores.blanco)};
  border-radius: 10px;
  padding: 6px 12px;
  font-weight: 600;
}

.media {
  background-color: ${leerColor(colores.naranja)};
  color: ${leerColor(colores.blanco)};
  border-radius: 10px;
  padding: 6px 12px;
  font-weight: 600;
}

.alta {
  background-color: ${leerColor(colores.rojo)};
  color: ${leerColor(colores.blanco)};
  border-radius: 10px;
  padding: 6px 12px;
  font-weight: 600;
}

// **** LOGOS **** //

.fedpat, .prudencia, .nacion {
  width: 100px;
}
.experta, .orbis, .rus {
  width: 70px;
}
.hdi, .atm {
  width: 50px;
}

  // Estados // 
  .emitido, .cerradopositivo, .cerradopositivo-conformeparcial, .cerradopositivo-conforme, .cerradopositivo-disconforme {
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
    white-space: nowrap;
  }
  .pendiente-sin-asignar{
    font-weight: 600;
    background-color: #fff7ed;
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
    //border:1px solid ${leerColor(colores.naranjaEstados)};
    width: min-content;
  }
  .pendiente, .ingresado {
    font-weight: 600;
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
  }
  .rechazado, .rechazado-sin-asignar, .cerradonegativo {
    font-weight: 600;
    background-color: #fff6f6;
    color: ${leerColor(colores.rojo)};
    border-radius: 20px;
    padding: 6px 12px;
    //border:1px solid ${leerColor(colores.rojo)};
    width: min-content;
  }
  .emisiónprovisoria, .pendientededocumentación, .pendientedepago, .pendientederesolución {
    font-weight: 600;
    background-color: #f0faff;
    color: ${leerColor(colores.celeste)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
  }
  .pendientesuscripción {
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 700;
  }
  .pendienteaprobación, .ofrecimiento{
    font-weight: 600;
    background-color: #ecf0ff;
    color: ${leerColor(colores.azul)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
  }
  /*
  .pendientedepago {
    font-weight: 600;
    background-color: #ecf0ff;
    color: ${leerColor(colores.azul)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
  }
  */
  .emisiónprovisoria {
    font-weight: 600;
    background-color: #f8f3ff;
    color: ${leerColor(colores.violeta)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
  }

  .activo{
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .inactivo{
    font-weight: 600;
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .estado_previo_rechazado {
    position: absolute;
    top: -15px;
    right: 0;
    //background: #fff7ed;
    background: ${leerColor(colores.blanco)};
    border-radius: 100px;
    color: hsl(36.10000000000002,100%,66.1%);
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    border: 1px solid hsl(36.1deg 100% 82.49%);
  }


.actions {
    display: flex;
    align-items: center;
    span {
        margin-right: 20px;
        font-size: 24px;
    }

    .icon {
      cursor: pointer;
    }
}

tr:nth-child(odd) td {
  background: #fdfdfd;
}

`

export const SinResultadosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  svg{
    padding: 50px 0 20px;
  }
  h3{
     
    font-size: 18px;
    font-weight: 600;
    color: ${leerColor(colores.grisEstados)};
  }
`
