import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  InputSelect,
  ProgressBar,
  Menu,
  Wrapper,
  NewProgressBar,
  NavMenu,
  BurgerMenu,
  Modal,
  InputCalendar,
  PopUp,
} from "../components/Index";

import { AuthContext } from '../context/AuthContext'
import dayjs from 'dayjs'
import axiosRequest from "../utils/axiosConfig";
import { CalendarContainer, GridCalendarContainer, GridFirstDay, GridCalendarContainerHeader, BarColor, ModalEditarOperador, TabsContainer, Tab, GridCumplimientoContainerHeader, GridCumplimientoContainer, Error, ModalBorrarExcepcion, FichajesContainer, ModalFichaje } from './styled'
import CancelReminder from "../assets/iconos/cancelReminder.svg";
import CalendarPicker from "../assets/iconos/calendarPicker.svg";
import Check from "../assets/iconos/tic.svg";
import edit from "../assets/iconos/edit.svg";
import deleteIcon from "../assets/iconos/delete.svg";
import { NoHayBusquedas } from "../components/InfoBoard/styled";
import orderAlphabetically from "../utils/alphabeticalOrder";
import IconButton from "../components/IconButton";
import CalendarDay from "../components/CalendarDay";
import SpinnerLoading from "../components/SpinnerLoading";
import Fichaje from "../components/Fichaje";
import getExcepciones from "../services/Excepciones";
import formatSelectData from "../utils/formatSelectData";
import { rolesFichajes } from "../constants/roles";
import TitlePageSection from "../components/TitlePageSection";

const Fichajes = () => {

    const [excepciones, setExcepciones] = useState([])

    const fichajesStatus = {
        ingreso: {
            selected: false,
            status: false,
            time: ''
        },
        inicio_almuerzo: {
            selected: false,
            status: false, 
            time: ''
        },
        fin_almuerzo: {
            selected: false,
            status: false, 
            time: ''
        },
        fin_jornada: {
            selected: false,
            status: false,
            time: ''
        },
        salida_especial: {
            selected: false,
            status: false,
            time: ''
        },
        reingreso_especial: {
            selected: false,
            status: false,
            time: ''
        },
    }

    // Context
    const user = JSON.parse(localStorage.getItem('userInfo'))
    const [fichajesRealizados, setFichajesRealizados] = useState(fichajesStatus)
    const [comercialRole, setComercialRole] = useState(user.id_rol == 10 ? true : false)

    const [excepcionValues, setExcepcionValues] = useState({
        periodo: 'Desde / Hasta',
        usuario: '',
        tipo: '',
        hora: 'Hora',
        hora_desde: '09:00',
        hora_hasta: '18:00'
    })
    const [fichajes, setFichajes] = useState([])

    const [monthDays, setMonthDays] = useState([])
    const [month, setMonth] = useState(new Date().getMonth())
    const authContext = useContext(AuthContext)

    // roles que permiten cargar una excepcion 1: super, 16 rrhh
    const rolesAuth = [1, 16]
    const allowedRoles = rolesFichajes;
    const [open, setOpen] = useState(false)
    const [fichaje, setFichaje] = useState('')
    const [year, setYear] = useState(new Date().getFullYear())
    const [editMode, setEditMode] = useState(false)
    const [isSuccessPopUpOpen, setIsSuccessPopUpOpen] = useState(false)
    const [popUpMessage, setPopUpMessage] = useState('')
    const [userId, setUserId] = useState(user.id_usuario)
    const [errorsFichaje, setErrorsFichaje] = useState('')
    const [errorsExcepcion, setErrorsExcepcion] = useState({})
    const [excepcionIdToDelete, setExcepcionIdToDelete] = useState(null)
     
    const [firstDay, setFirstDay] = useState('')
    const [isFichajePosteado, setIsFichajePosteado] = useState(false)
    const [selectedTab, setSelectedTab] = useState('calendario')
    const [selectedTabFichajeRRHH, setSelectedTabFichajeRRHH] = useState('fichaje_propio')
    const [isButtonFicharDisabled, setIsButtonFicharDisabled] = useState(true)
    const [fichajesCardsLoading, setFichajesCardsLoading] = useState(false)
    const [isExcepcionModalOpen, setIsExcepcionModalOpen] = useState(false)
    const [isFicharModalOpen, setIsFicharModalOpen] = useState(false)
    const [isDeleteExcepcionModalOpen, setIsDeleteExcepcionModalOpen] = useState(false)
    const [cumplimientoAnualData, setCumplimientoAnualData] = useState([])

    const [fichajeRRHH, setFichajeRRHH] = useState({
        usuario: '',
        fecha: '',
        hora: '', 
        usuario_rol: ''
    })
    const [fichajeRRHHErrors, setFichajeRRHHErrors] = useState({})
    const [loadingCumplimientoAnual, setLoadingCumplimientoAnual] = useState(false)
    const [loadingExcepciones, setLoadingExcepciones] = useState(false)

    const [usuarios, setUsuarios] = useState([])

    const cumplimientoAnualHeaders = ["Mes", "Objetivos Horas", "Objetivos Personales", "Horas Cumplidas", "Diferencia", "Porcentaje"]
    const excepcionesHeaders = ["Id", "Tipo", "Periodo", "Días", "Fecha", "F. Desde", "F. Hasta", "Hora", "H. Desde", "H. Hasta", "Acciones"]

    /* SELECTS */

    const fichajesOptions = [
        {
          'label': 'Ingreso',
          'value': 'Ingreso',
          'key': 1
        }, 
        {
          'label': 'Inicio almuerzo',
          'value': 'Inicio almuerzo',
          'key': 2
        },
        {
          'label': 'Fin almuerzo',
          'value': 'Fin almuerzo',
          'key': 3
        },
        {
          'label': 'Fin jornada',
          'value': 'Fin jornada',
          'key': 4
        },
        {
          'label': 'Salida especial',
          'value': 'Salida especial',
          'key': 5
        },
        {
          'label': 'Reingreso especial',
          'value': 'Reingreso especial',
          'key': 6
        }
    ];

    const monthsOptions = [
        {
          'label': 'Enero',
          'value': 0,
          'key': 1
        }, 
        {
            'label': 'Febrero',
            'value': 1,
            'key': 2
        },
        {
          'label': 'Marzo',
          'value': 2,
          'key': 3
        },
        {
          'label': 'Abril',
          'value': 3,
          'key': 4
        },
        {
          'label': 'Mayo',
          'value': 4,
          'key': 5
        },
        {
          'label': 'Junio',
          'value': 5,
          'key': 6
        },
        {
          'label': 'Julio',
          'value': 6,
          'key': 7
        },
        {
          'label': 'Agosto',
          'value': 7,
          'key': 8
        },
        {
          'label': 'Septiembre',
          'value': 8,
          'key': 9
        },
        {
          'label': 'Octubre',
          'value': 9,
          'key': 10
        },
        {
          'label': 'Noviembre',
          'value': 10,
          'key': 11
        },
        {
          'label': 'Diciembre',
          'value': 11,
          'key': 12
        }
    ];

    const anioOptions = [
        {
          'label': '2022',
          'value': '2022',
          'key': 1
        },
        {
          'label': '2023',
          'value': '2023',
          'key': 2
        },
        {
            'label': '2024',
            'value': '2024',
            'key': 3
          }
    ];

    const periodosOptions = [
        {
          'label': 'Día',
          'value': 'Día',
          'key': 1
        }, 
        {
          'label': 'Fecha',
          'value': 'Fecha',
          'key': 2
        },
        {
          'label': 'Desde / hasta',
          'value': 'Desde / Hasta',
          'key': 3
        }
    ];

    const diasOptions = [
        {
          'label': 'Lunes',
          'value': 'Lunes',
          'key': 1
        }, 
        {
          'label': 'Martes',
          'value': 'Martes',
          'key': 2
        },
        {
          'label': 'Miércoles',
          'value': 'Miércoles',
          'key': 3
        },
        {
          'label': 'Jueves',
          'value': 'Jueves',
          'key': 4
        }, 
        {
          'label': 'Viernes',
          'value': 'Viernes',
          'key': 5
        }
    ];

    const tipoOptions = [
        {
          'label': 'Vacaciones',
          'value': 'Vacaciones',
          'key': 1
        }, 
        {
          'label': 'Licencia',
          'value': 'Licencia',
          'key': 2
        },
        {
          'label': 'Acuerdo',
          'value': 'Acuerdo',
          'key': 3
        } 
    ];

    const horaOptions = [
        {
          'label': 'Día justificado',
          'value': 'Día justificado',
          'key': 1
        }, 
        {
          'label': 'Hora (desde / hasta)',
          'value': 'Hora',
          'key': 2
        }
    ];

    /* ---------------------------------- */

    function handleChange(e){
        setFichaje(e.target.value)
    }

    function handleMonthChange(e){
        setMonth(e.target.value)
    }

    function handleYearChange(e){
        setYear(parseInt(e.target.value))
    }

    // ************ FICHAJES ************** //

    const selectFichaje = (fichaje, tituloFichaje) => {
        for (const value in fichajesRealizados) {
            if(value !== fichaje){
                fichajesRealizados[value].selected = false;
                setIsButtonFicharDisabled(true)
            } 
        }
        setFichajesRealizados(prev => ({...prev, [fichaje]: { selected: !fichajesRealizados[fichaje].selected, status: false, time: '' }, }))
        setFichaje(tituloFichaje)
    }

    const validateUserFichajeFields = () => {
        let errors = {}
        if(!fichajeRRHH.usuario){
            errors.usuario = 'Campo requerido.'
        }
        if(!fichajeRRHH.fecha){
            errors.fecha = 'Campo requerido.'
        }
        if(!fichajeRRHH.hora){
            errors.hora = 'Campo requerido.'
        } else if(fichajeRRHH.hora[2] !== ":"){
            errors.hora = 'El formato de la hora debe ser: hh:mm.'
        } else if(fichajeRRHH.hora.length !== 5){
            errors.hora = 'El valor ingresado es incorrecto.'
        } else if(!/^[0-9-:]*$/.test(fichajeRRHH.hora)){
            errors.hora = 'El valor ingresado es incorrecto.'
        } 

        setFichajeRRHHErrors(errors)

        if(Object.keys(errors).length == 0){
            return true
        } else {
            return false
        }
    }

    const sendFichaje = async () => {
        let data
        let userIdData
        let postFichajeAvailable = true 

        if(selectedTabFichajeRRHH === "fichaje_por_usuario" && fichajeRRHH.usuario){
            // Juntar fecha y hora
            const formattedDate = dayjs(new Date(`${dayjs(fichajeRRHH.fecha).format("YYYY-MM-DD")} ${fichajeRRHH.hora}`)).format("DD/MM/YYYY HH:mm:ss")
            data = {
                id_usuario_fichaje: fichajeRRHH.usuario,
                descripcion_fichaje: fichaje,
                dia_fichaje: formattedDate
            }
            userIdData = fichajeRRHH.usuario
            postFichajeAvailable = validateUserFichajeFields()
        } else {
            data = {
                id_usuario_fichaje: user.id_usuario,
                descripcion_fichaje: fichaje
            }
            userIdData = userId
        }
        const currentMonth = new Date().getMonth()
        const currentYear = new Date().getFullYear()
        const currentDayNumber = parseInt(dayjs(new Date()).format("DD"))
        const monthId = parseInt(currentMonth) + 1

        if(postFichajeAvailable){
            try{
                const fichajesByUser = await axiosRequest.get(`/fichajes/${userIdData}/${parseInt(currentMonth) + 1}/${currentYear}`)
                const postFichaje = await axiosRequest.post('/fichaje', data)
                if(postFichaje.status == 200){
                    try{
                        const fichajesByUser = await axiosRequest.get(`/fichajes/${userIdData}/${parseInt(currentMonth) + 1}/${currentYear}`)
                        setFichajes(fichajesByUser.data)
                        getUserFichajes()
                        generateCalendar()
                    } catch (error){
                        console.log(error)
                    }
                    checkFichajesDay()
                    setIsFicharModalOpen(false)
                    setPopUpMessage('¡Fichaje cargado!')
                    setIsSuccessPopUpOpen(true);
                    setTimeout(() => {
                        setIsSuccessPopUpOpen(false)
                    }, 1200);  
                }     
            } catch (error){
                console.log(error)
            }
        }
    }

    const checkFichajesDay = async () => {
        resetFichajesCards()

        const currentMonth = new Date().getMonth()
        const currentYear = new Date().getFullYear()
        let userIdData
        let monthId
        let year
        let currentDayNumber

        if(fichajeRRHH.usuario && fichajeRRHH.fecha && selectedTabFichajeRRHH === "fichaje_por_usuario"){
            userIdData = fichajeRRHH.usuario
            monthId = dayjs(fichajeRRHH.fecha).format("M")
            year = dayjs(fichajeRRHH.fecha).format("YYYY")
            currentDayNumber = parseInt(dayjs(fichajeRRHH.fecha).format("DD"))
        } else {
            userIdData = user.id_usuario
            monthId = parseInt(currentMonth) + 1
            year = currentYear
            currentDayNumber = parseInt(dayjs(new Date()).format("DD"))
        }
        
        try{
            const getUserRol = await axiosRequest.get(`/usuarios/${userIdData}`)
            if(getUserRol.data.id_rol == 10){
                setComercialRole(true)
            } else {
                setComercialRole(false)
            }
        } catch (error){
            console.log(error.response)
        }

        try{
            // CHEQUEAR SI EL FICHAJE YA EXISTE PARA EL DÍA ACTUAL
            const checkIfFichajeExist = await axiosRequest.get(`/fichajes/${userIdData}/${parseInt(monthId)}/${year}`)
            const fichajesDay = checkIfFichajeExist.data.filter(fichaje => {
                let dayFormatted = dayjs(fichaje.dia_fichaje).format("DD/MM/YYYY")
                let currentDay = `${currentDayNumber < 10 ? `0${currentDayNumber}` : currentDayNumber}/${monthId < 10 ? `0${monthId}` : `${monthId}`}/${year}`
                return dayFormatted === currentDay
            }) 
            // SETEAR FICHAJES REALIZADOS 
            if(fichajesDay.length){
                // Setear la información correspondiente en las cards de fichajes
                fichajesDay.forEach(fichaje => {
                    const descripcionFichaje = fichaje.descripcion_fichaje.toLowerCase().replace(/ /g, '_');
                    const fichajeTime = dayjs(fichaje.dia_fichaje).format("HH:mm")
                    setFichajesRealizados(prev => ({...prev, [descripcionFichaje]: { selected: false, status: true, time: fichajeTime }, }))
                })
            } else {
                // Si no se encuentran fichajes para el día seleccionado se resetean las cards de fichajes
                resetFichajesCards()
            }
            setFichajesCardsLoading(false)
        } catch(error){
            // Si no hay fichajes para el día mostrar las cards de fichajes vacias
            if(fichajeRRHH.usuario && selectedTabFichajeRRHH === "fichaje_por_usuario"){
                resetFichajesCards()
            }
            setFichajesCardsLoading(false)
        }
    }

    const resetFichajesCards = () => {
        setFichajesCardsLoading(true)
        Object.keys(fichajesRealizados).forEach(fichaje => {
            setFichajesRealizados(prev => ({...prev, [fichaje]: { selected: false, status: false, time: '' }, }))
        })
    }

    // Habilitación de botón de fichaje
    useEffect(() => {
        const checkSelected = Object.keys(fichajesRealizados).some(fichaje => fichajesRealizados[fichaje].selected == true)
        !checkSelected ? setIsButtonFicharDisabled(true) : setIsButtonFicharDisabled(false)
    }, [fichajesRealizados])

    // CHEQUAR FICHAJES DEL DÍA
    useEffect(() => {
      checkFichajesDay()
    }, [fichajeRRHH.usuario, fichajeRRHH.fecha, selectedTabFichajeRRHH])

    // ************ EXCEPCIONES ************** //

    const formatExcepciones = (excepcionesByUser) => {
        const excepcionesFormatted = excepcionesByUser.map(excepcion => {
            const data = {
                id: excepcion.id_excepcion,
                tipo: excepcion.tipo_excepcion,
                periodo: excepcion.periodo_excepcion === "Fecha (desde / hasta)" ? "Desde / Hasta" : excepcion.periodo_excepcion,
                dias: excepcion.periodo_dia_excepcion ? excepcion.periodo_dia_excepcion : '-',
                fecha: excepcion.periodo_fecha_excepcion ? dayjs(excepcion.periodo_fecha_excepcion).format("DD/MM/YYYY") : '-',
                usuario: excepcion.id_usuario_excepcion,
                f_desde: excepcion.periodo_fecha_desde_excepcion ? dayjs(excepcion.periodo_fecha_desde_excepcion).format("DD/MM/YYYY") : '-',
                f_hasta: excepcion.periodo_fecha_hasta_excepcion ? dayjs(excepcion.periodo_fecha_hasta_excepcion).format("DD/MM/YYYY") : '-',
                hora: excepcion.hora_excepcion,
                h_desde: excepcion.hora_desde_excepcion ? dayjs(excepcion.hora_desde_excepcion).format("HH:mm") : '-',
                h_hasta: excepcion.hora_hasta_excepcion ? dayjs(excepcion.hora_hasta_excepcion).format("HH:mm") : '-',
                acciones: <div className="actionsContainer"><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} tooltipText="Editar Excepción" action={() => editExcepcion(excepcion.id_excepcion, data)}/><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={deleteIcon} tooltipText="Borrar Excepción" action={() => handleDeleteExcepcionModal(excepcion)} /></div>
            }
            return data
        })
        return excepcionesFormatted
    }

    // Postear excepción
    const postExcepcion = async () => {
        const day = dayjs(new Date()).format("YYYY-MM-DD")
        let errors = {}

        if(!excepcionValues.usuario){
            errors.usuario = 'Campo requerido.'
        }
        if(!excepcionValues.tipo){
            errors.tipo = 'Campo requerido.'
        }
        if(!excepcionValues.hora){
            errors.hora = 'Campo requerido.'
        } 

        if(excepcionValues.hora === "Hora"){
            if(!excepcionValues.hora_desde){
                errors.hora_desde = 'Campo requerido.'
            } else if(excepcionValues.hora_desde[2] !== ":"){
                errors.hora_desde = 'El formato debe ser --> horas:minutos.'
            } else if(excepcionValues.hora_desde.length !== 5){
                errors.hora_desde = 'El valor ingresado es incorrecto.'
            } else if(!/^[0-9-:]*$/.test(excepcionValues.hora_desde)){
                errors.hora_desde = 'El valor ingresado es incorrecto.'
            } else if(excepcionValues.hora_desde === excepcionValues.hora_hasta){
                errors.hora_desde = 'No puede tener el mismo valor que el campo "Hasta".'
            }

            if(!excepcionValues.hora_hasta){
                errors.hora_hasta = 'Campo requerido.'
            } else if(excepcionValues.hora_hasta[2] !== ":"){
                errors.hora_hasta = 'El formato debe ser --> horas:minutos.'
            } else if(excepcionValues.hora_hasta.length !== 5){
                errors.hora_hasta = 'El valor ingresado es incorrecto.'
            } else if(!/^[0-9-:]*$/.test(excepcionValues.hora_hasta)){
                errors.hora_hasta = 'El valor ingresado es incorrecto.'
            } else if(excepcionValues.hora_desde === excepcionValues.hora_hasta){
                errors.hora_hasta = 'No puede tener el mismo valor que el campo "Desde".'
            }
        }

        if(!excepcionValues.periodo){
            errors.periodo = 'Campo requerido.'
        } 

        if(excepcionValues.periodo === "Fecha" && !excepcionValues.periodo_fecha){
            errors.periodo_fecha = 'Campo requerido.'
        } else if(excepcionValues.periodo_fecha){
            const yearExcepcion = parseInt(excepcionValues.periodo_fecha.slice(0, 4))
            if(yearExcepcion < year){
                errors.periodo_fecha = `El año no puede ser menor a ${year}.`
            }
        }

        if(excepcionValues.periodo === "Día" && !excepcionValues.periodo_dia){
            errors.periodo_dia = 'Campo requerido.'
        }

        if((excepcionValues.periodo === "Día" || excepcionValues.periodo === "Desde / Hasta") && !excepcionValues.periodo_desde){
            errors.periodo_desde = 'Campo requerido.'
        } else if(excepcionValues.periodo_desde){
            const yearExcepcion = parseInt(excepcionValues.periodo_desde.slice(0, 4))
            if(yearExcepcion < year){
                errors.periodo_desde = `El año no puede ser menor a ${year}.`
            } else if(excepcionValues.periodo_desde === excepcionValues.periodo_hasta){
                errors.periodo_desde = 'No puede tener el mismo valor que el campo "Hasta".'
            }
        } 

        if((excepcionValues.periodo === "Día" || excepcionValues.periodo === "Desde / Hasta") && !excepcionValues.periodo_hasta){
            errors.periodo_hasta = 'Campo requerido.'
        } else if(excepcionValues.periodo_hasta){
            const yearExcepcion = parseInt(excepcionValues.periodo_hasta.slice(0, 4))
            if(yearExcepcion < year){
                errors.periodo_hasta = `El año no puede ser menor a ${year}.`
            } else if(excepcionValues.periodo_desde === excepcionValues.periodo_hasta){
                errors.periodo_hasta = 'No puede tener el mismo valor que el campo "Desde".'
            }
        } 
    
        setErrorsExcepcion(errors)

        if(Object.keys(errors).length == 0){
            const data = {
                id_usuario_excepcion: excepcionValues.usuario,
                tipo_excepcion: excepcionValues.tipo,
                periodo_excepcion: excepcionValues.periodo,
                periodo_dia_excepcion: excepcionValues.periodo_dia && excepcionValues.periodo === "Día" ? excepcionValues.periodo_dia : null,
                periodo_fecha_excepcion: excepcionValues.periodo_fecha && excepcionValues.periodo === "Fecha" ? excepcionValues.periodo_fecha : null,
                periodo_fecha_desde_excepcion: excepcionValues.periodo_desde && (excepcionValues.periodo === "Día" || excepcionValues.periodo === "Desde / Hasta") ? excepcionValues.periodo_desde : null,
                periodo_fecha_hasta_excepcion: excepcionValues.periodo_hasta && (excepcionValues.periodo === "Día" || excepcionValues.periodo === "Desde / Hasta") ? excepcionValues.periodo_hasta : null,
                hora_excepcion: excepcionValues.hora,
                hora_desde_excepcion: excepcionValues.hora_desde && excepcionValues.hora === "Hora" ? dayjs(new Date(`${day} ${excepcionValues.hora_desde}`)).format("YYYY-MM-DD HH:mm:ss") : null,
                hora_hasta_excepcion: excepcionValues.hora_hasta && excepcionValues.hora === "Hora" ? dayjs(new Date(`${day} ${excepcionValues.hora_hasta}`)).format("YYYY-MM-DD HH:mm:ss") : null,
                estado_excepcion: 1,
            }
            try{
                const postData = await axiosRequest.post('/excepcion', data)
                if(postData.status === 200){
                    try{
                        const excepcionesByUser = await getExcepciones(userId, year)
                        const excepcionesFormatted = formatExcepciones(excepcionesByUser)
                        setExcepciones(excepcionesFormatted)
                        getUserFichajes()
                        generateCalendar()
                    } catch (error){
                        console.log(error)
                    }
                    setIsExcepcionModalOpen(false)
                    setPopUpMessage('¡Excepción cargada!')
                    setIsSuccessPopUpOpen(true);
                    setTimeout(() => {
                    setIsSuccessPopUpOpen(false)
                    }, 1200);
                }
            } catch (error){
                console.log(error)
            }
        } 
    }

    const editExcepcion = (id, data) => {
        setIsExcepcionModalOpen(true)
        setEditMode(true)
        
        /*
        console.log(excepciones)

        const findExcepcionById = excepciones.find(excepcion => {
            return excepcion.id_excepcion === id
        })

        console.log(findExcepcionById)
        */

        let fecha
        let fecha_desde
        let fecha_hasta

        if(data.fecha){
            const [day, month, year] = data.fecha.split('/');
            fecha = `${year}-${month}-${day}`
        }

        if(data.periodo === "Desde / Hasta" || data.periodo === "Día"){
            const [dayDesde, monthDesde, yearDesde] = data.f_desde.split('/');
            fecha_desde = `${yearDesde}-${monthDesde}-${dayDesde}`

            const [dayHasta, monthHasta, yearHasta] = data.f_hasta.split('/');
            fecha_hasta = `${yearHasta}-${monthHasta}-${dayHasta}`
        }

        setExcepcionValues({
            id_excepcion: id,
            periodo: data.periodo,
            periodo_dia: data.dias !== "-" ? data.dias : null,
            periodo_desde: data.f_desde !== "-" ? fecha_desde : null,
            periodo_hasta: data.f_hasta !== "-" ? fecha_hasta : null,
            periodo_fecha: data.fecha !== "-" ? fecha : null,
            usuario: data.usuario,
            tipo: data.tipo,
            hora: data.hora,
            hora_desde: data.h_desde !== "-" ? data.h_desde : null,
            hora_hasta: data.h_hasta !== "-" ? data.h_hasta : null
        })
    
    }

    const handleDeleteExcepcionModal = (excepcion) => {
        setIsDeleteExcepcionModalOpen(true)
        setExcepcionIdToDelete(excepcion)
    }

    const deleteExcepcion = async (excepcion) => {
        try{
            const deleteData = await axiosRequest.delete(`/excepcion/${excepcion.id_excepcion}`)
            if(deleteData.status === 200){
                setIsDeleteExcepcionModalOpen(false)
                setPopUpMessage('¡Excepción borrada!')
                setIsSuccessPopUpOpen(true);
                setTimeout(() => {
                setIsSuccessPopUpOpen(false)
                }, 1200);
                const excepcionIndex = excepciones.findIndex(excepcionInfo => { 
                    return excepcionInfo.id_excepcion == excepcion.id_excepcion
                })
                const excepcionesArray = [...excepciones]
                excepcionesArray.splice(excepcionIndex, 1)
                setExcepciones(excepcionesArray)
                getUserFichajes()
                generateCalendar()
            }
        } catch (error){
            console.log(error)
        }
    }

    const updateExcepcion = async () => {
        const day = dayjs(new Date()).format("YYYY-MM-DD")

        const data = {
            id_usuario_excepcion: excepcionValues.usuario,
            tipo_excepcion: excepcionValues.tipo,
            periodo_excepcion: excepcionValues.periodo,
            periodo_dia_excepcion: excepcionValues.periodo_dia ? excepcionValues.periodo_dia : null,
            periodo_fecha_excepcion: excepcionValues.periodo_fecha ? excepcionValues.periodo_fecha : null,
            periodo_fecha_desde_excepcion: excepcionValues.periodo_desde ? excepcionValues.periodo_desde : null,
            periodo_fecha_hasta_excepcion: excepcionValues.periodo_hasta ? excepcionValues.periodo_hasta : null,
            hora_excepcion: excepcionValues.hora,
            hora_desde_excepcion: excepcionValues.hora_desde ? dayjs(new Date(`${day} ${excepcionValues.hora_desde}`)).format("YYYY-MM-DD HH:mm:ss") : null,
            hora_hasta_excepcion: excepcionValues.hora_hasta ? dayjs(new Date(`${day} ${excepcionValues.hora_hasta}`)).format("YYYY-MM-DD HH:mm:ss") : null,
            estado_excepcion: 1,
        }
   
        try{
            const updateData = await axiosRequest.put(`/excepcion/${excepcionValues.id_excepcion}`, data)
            if(updateData.status === 200){
                setIsExcepcionModalOpen(false)
                setPopUpMessage('¡Excepción editada!')
                setIsSuccessPopUpOpen(true);
                setTimeout(() => {
                setIsSuccessPopUpOpen(false)
                }, 1200);
                try{
                    const excepcionesByUser = await getExcepciones(userId, year)
                    const excepcionesFormatted = formatExcepciones(excepcionesByUser)
                    setExcepciones(excepcionesFormatted)
                    getUserFichajes()
                    generateCalendar()
                } catch (error){
                    console.log(error)
                }
            }
        } catch (error){
            console.log(error)
        }
    }

    const handleChangeExcepcion = (event) => {
        const { name, value } = event.target
        setExcepcionValues(prev => ({...prev, [name]: value, }))
    }

    const handleUsuarioChange = (event) => {
        const { value } = event.target
        setUserId(value)
    }

    /* ----------------------------------

    ---------------------------------- */

    const getUserFichajes = async () => {
    if(fichajes && excepciones && userId){
      const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
      const monthsNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

      setCumplimientoAnualData([])
      setLoadingCumplimientoAnual(true)
      let cumplimientoAnualByMonth = []
      try{
          // Agregar Año a la query --> /fichajes/73/2022
          const fichajesByUser = await axiosRequest.get(`/fichajes/${userId}/${year}`)
          let fichajesByMonth = []
          
          // Separar fichajes por mes
          months.forEach(month => {
              const f = fichajesByUser.data.filter(fichaje => {
                  const fichajeMonth = dayjs(fichaje.dia_fichaje).format("M")
                  return fichajeMonth === month
              })
              //if(f.length > 0){
              fichajesByMonth.push({fichajes: f, mes: month})
              //}
          })

          // Separar fichajes por día
          fichajesByMonth.forEach( async (fichajes) => {
              //const year = new Date().getFullYear()

              const yearSelected = parseInt(year)

              const monthLength = new Date(yearSelected, parseInt(fichajes.mes), 0).getDate() 
              let fichajesByDay = []
              // Separar fichajes según el día
              for(let i = 1; i <= monthLength; i++){
                  let dayId = new Date(`${yearSelected}/${fichajes.mes.toString()}/${i}`).getDay()
                  const fichajesDay = fichajes.fichajes.filter(fichaje => {
                      let dayFormatted = dayjs(fichaje.dia_fichaje).format("DD/MM/YYYY")
                      let currentDay = `${i < 10 ? `0${i}` : i}/${fichajes.mes < 10 ? `0${fichajes.mes}` : `${fichajes.mes}`}/${yearSelected}`
                      return dayFormatted === currentDay
                  }) 
                  let dateFichaje = { fichajes: fichajesDay, day: i, weekend: dayId === 0 || dayId === 6 ? true : false}
                  fichajesByDay.push(dateFichaje)
              }

              const jornadaCompleta = 9

              // Calculo de horas
              let totalHours = 0;
              fichajesByDay.map(fichaje => {
                  if(fichaje.fichajes.length){
                      const filterJornada = fichaje.fichajes.filter(fichajeJornada => {
                          return fichajeJornada.descripcion_fichaje === "Ingreso" || fichajeJornada.descripcion_fichaje === "Fin jornada"
                      })
                      // CHEQUEAR SI EXISTE FICHAJE DE INGRESO Y SALIDA ANTES DE CALCULAR
                      if(filterJornada.length == 2){
                          const filterIngresoSalidaEspecial = fichaje.fichajes.filter(fichajeJornada => {
                              return fichajeJornada.descripcion_fichaje === "Salida especial" || fichajeJornada.descripcion_fichaje === "Reingreso especial"
                          })
                          // Obtener minutos
                          //totalHours = totalHours + (values[1] - values[0])
                          // Tiempo en minutos entre el ingreso y la salida
                          const date1 = dayjs(filterJornada[0].dia_fichaje)
                          const date2 = dayjs(filterJornada[1].dia_fichaje)
                          const result = date2.diff(date1, 'minutes', true)
  
                          if(filterIngresoSalidaEspecial.length == 2){
                              const date1Especial = dayjs(filterIngresoSalidaEspecial[0].dia_fichaje)
                              const date2Especial = dayjs(filterIngresoSalidaEspecial[1].dia_fichaje)
                              const resultEspecial = date2Especial.diff(date1Especial, 'minutes', true)
                              totalHours += result - resultEspecial;
                          } else {
                              totalHours += result;
                          }
                      }
                  }
              })
              // Obtener horas y minutos
              const hours = (totalHours / 60)
              const formattedHours = Math.floor(hours);
              const minutes = (hours - formattedHours) * 60;
              const formattedMinutes = Math.round(minutes);
              const totalHoursAndMinutes = `${formattedHours}hs ${formattedMinutes}m` 

              // Hora para sacar diferencia
              totalHours = totalHours / 60

              // Dias habiles para objetivos
              const diasHabilesData = {
                  month: fichajes.mes,
                  year: year
              }

              const getDiasHabiles = await axiosRequest.post('/dias_habiles', diasHabilesData)

              const diasHabiles = getDiasHabiles.data.dias_habiles
              const objetivosGenerales = diasHabiles * jornadaCompleta

              // Objetivos personales (objetivos horas teniendo en cuenta las excepciones)
              let horasExcepciones = 0
              let excepcionesByUser;
              try{
                  excepcionesByUser = await getExcepciones(userId, yearSelected)

                  if(excepcionesByUser){

                      // Filtrar las excepciones que corresponde al mes de la iteración
                      const filterExcepcionesUserByMonth = excepcionesByUser.filter(excepcion => {
                          if(excepcion.periodo_excepcion === "Fecha"){
                              const excepcionMonth = dayjs(excepcion.periodo_fecha_excepcion).format("M") 
                              return excepcionMonth === fichajes.mes
                          } else {
                              const excepcionMonthDesde = dayjs(excepcion.periodo_fecha_desde_excepcion).format("M") 
                              const excepcionMonthHasta = dayjs(excepcion.periodo_fecha_hasta_excepcion).format("M") 
                              //return excepcionMonthDesde === fichajes.mes || excepcionMonthHasta === fichajes.mes
                              return fichajes.mes >= excepcionMonthDesde || fichajes.mes <= excepcionMonthHasta
                          }
                      })

                      // Chequear excepciones del mes
                      if(filterExcepcionesUserByMonth.length > 0){
                          filterExcepcionesUserByMonth.forEach(excepcion => {
                              let hours = 0
                              // Chequea si la excepción tiene un horario o si es dia justificado (else) para determinar las horas por las que hay que multiplicar
                              if(excepcion.hora_excepcion === "Hora"){
                                  const firstHour = dayjs(excepcion.hora_desde_excepcion).format("HH")
                                  const lastHour = dayjs(excepcion.hora_hasta_excepcion).format("HH")
                                  // Se resta las horas de jornada completa (9) - la cantidad de horas de la excepción
                                  hours = jornadaCompleta - (lastHour - firstHour)
                              } else {
                                  hours = jornadaCompleta
                              }
                              
                              if(excepcion.periodo_excepcion === "Fecha"){
                                  horasExcepciones += hours
                              } else {
                                  // DIA
                                  const firstDayExcepcionDia = dayjs(excepcion.periodo_fecha_desde_excepcion).format("D")
                                  const lastDayExcepcionDia = parseInt(dayjs(excepcion.periodo_fecha_hasta_excepcion).format("D"))
                                  const firstDayExcepcionMonth = dayjs(excepcion.periodo_fecha_desde_excepcion).format("M")
                                  const lastDayExcepcionMonth = dayjs(excepcion.periodo_fecha_hasta_excepcion).format("M")
                                  const firstDayExcepcionYear = dayjs(excepcion.periodo_fecha_desde_excepcion).format("YYYY")
                                  const lastDayExcepcionYear = dayjs(excepcion.periodo_fecha_hasta_excepcion).format("YYYY")

                                  // CHEQUEAR DIAS NO LABORALES
                                  let diasNoLaborales = 0
                                  let excepcionDayId = new Date(excepcion.periodo_fecha_desde_excepcion).getDay()

                                  if(excepcion.periodo_excepcion === "Día"){
                                      // Obtiene todos los días correspondientes al día de la excepción
                                      let totalDaysExceptions = []
                                      for(let i = 1; i <= monthLength; i++){
                                          let dayId = new Date(`${yearSelected}/${fichajes.mes.toString()}/${i}`).getDay()
                                          if(dayId == excepcionDayId){
                                              totalDaysExceptions.push(i)
                                          }
                                      }
      
                                      if(firstDayExcepcionMonth == fichajes.mes || lastDayExcepcionMonth == fichajes.mes){
                                          let exceptionDays = 0
                                          const currentMonthDay = firstDayExcepcionMonth == fichajes.mes ? parseInt(firstDayExcepcionDia) : parseInt(lastDayExcepcionDia)
                                          totalDaysExceptions.forEach(day => {
                                              const checkDays = firstDayExcepcionMonth == fichajes.mes ? currentMonthDay <= day : currentMonthDay >= day
                                              if(checkDays){
                                                  exceptionDays += 1
                                              }
                                          })
                                          horasExcepciones += (hours * exceptionDays)
                                      }
                                      // Chequea que el mes actual no sea ni el primero de la excepción ni el último y también corrobora que el mes actual se encuentre entre el primer y último mes. 
                                      if(firstDayExcepcionMonth !== fichajes.mes && lastDayExcepcionMonth !== fichajes.mes && (fichajes.mes >= parseInt(firstDayExcepcionMonth) && fichajes.mes <= parseInt(lastDayExcepcionMonth))){
                                          horasExcepciones += (hours * totalDaysExceptions.length)
                                      }
                                  }

                                  // Obtiene la diferencia entre la fecha de la excepción (sea "desde" o "hasta") y el fin de mes o principio de mes según corresponda al mes de la iteración actual. 
                                  if(excepcion.periodo_excepcion === "Desde / Hasta" && (firstDayExcepcionMonth == fichajes.mes || lastDayExcepcionMonth == fichajes.mes)){

                                      let diff
                                      let initLoop
                                      let lengthLoop
                                      if(firstDayExcepcionMonth == fichajes.mes && lastDayExcepcionMonth == fichajes.mes){
                                          const dateMonth = dayjs(excepcion.periodo_fecha_desde_excepcion)
                                          const dateExcepcion = dayjs(excepcion.periodo_fecha_hasta_excepcion)
                                          diff = dateExcepcion.diff(dateMonth, 'day')
                                          initLoop = parseInt(dayjs(excepcion.periodo_fecha_desde_excepcion).format("D"))
                                          lengthLoop = parseInt(dayjs(excepcion.periodo_fecha_hasta_excepcion).format("D"))
                                          for(let i = initLoop; i <= lengthLoop; i++){
                                              let dayId = new Date(`${yearSelected}/${fichajes.mes.toString()}/${i}`).getDay()
                                              if(dayId == 0 || dayId == 6){
                                                  diasNoLaborales += 1
                                              }
                                          }
                                          const daysQuantity = (diff + 1) - diasNoLaborales
                                          horasExcepciones += (hours * daysQuantity)
                                      } else {

                                          const dateMonth = dayjs(`${yearSelected}-${fichajes.mes}-${firstDayExcepcionMonth == fichajes.mes ? monthLength : "01"}`)
                                          const dateExcepcion = firstDayExcepcionMonth == fichajes.mes ? dayjs(excepcion.periodo_fecha_desde_excepcion) : dayjs(excepcion.periodo_fecha_hasta_excepcion)
                                          diff = firstDayExcepcionMonth == fichajes.mes ? dateMonth.diff(dateExcepcion, 'day') : dateExcepcion.diff(dateMonth, 'day')
                                          if(fichajes.mes == firstDayExcepcionMonth && fichajes.mes !== lastDayExcepcionMonth){
                                            initLoop = parseInt(dayjs(excepcion.periodo_fecha_desde_excepcion).format("D"))
                                            lengthLoop = monthLength
                                          } else {
                                            initLoop = 1
                                            lengthLoop = parseInt(dayjs(excepcion.periodo_fecha_hasta_excepcion).format("D"))
                                          }
                                          //initLoop = 1
                                          //lengthLoop = diff
                                          for(let i = initLoop; i <= lengthLoop; i++){
                                              let dayId = new Date(`${yearSelected}/${fichajes.mes.toString()}/${i}`).getDay()
                                              if(dayId == 0 || dayId == 6){
                                                  diasNoLaborales += 1
                                              }
                                          }
                                          const daysQuantity = (diff + 1) - diasNoLaborales
                                          horasExcepciones += (hours * daysQuantity)
                                      }

                                  }
                              }
                          })
                      }
                  }
              } catch (error){
                  console.log(error)
              }

              const objetivosPersonales = objetivosGenerales - horasExcepciones

              const diferenciaHours = (totalHours - objetivosPersonales) 
              const diferenciaformattedHours = diferenciaHours > 0 ? Math.floor(diferenciaHours) : Math.round(diferenciaHours)
              const diferenciaMinutes = (diferenciaHours - diferenciaformattedHours) * 60;
              const diferenciaFormattedMinutes = diferenciaMinutes > 0 ? Math.round(diferenciaMinutes) : -(Math.round(diferenciaMinutes));
              const diferencia = `${diferenciaformattedHours}hs ${diferenciaFormattedMinutes}m` 

              // Porcentaje
              const percentage = (totalHours * 100) / objetivosPersonales

              // Objeto final con la información
              const data = {
                  mes: monthsNames[parseInt(fichajes.mes) - 1],
                  objetivos_horas: objetivosGenerales,
                  objetivos_personales: objetivosPersonales,
                  horas_cumplidas: totalHoursAndMinutes,
                  diferencia: diferencia,
                  porcentaje: `${percentage.toFixed(2)} %`,
                  mesId: fichajes.mes
              }

              cumplimientoAnualByMonth.push(data)

              if(fichajesByMonth.length === cumplimientoAnualByMonth.length){
                  cumplimientoAnualByMonth.sort(function(a, b){return a.mesId - b.mesId});
                  setCumplimientoAnualData(cumplimientoAnualByMonth)
                  setLoadingCumplimientoAnual(false)
              }
          })
      } catch (error){
          console.log(error)
          setCumplimientoAnualData([])
          setLoadingCumplimientoAnual(false)
      }
    }
  }

    // Traer TODOS los fichajes del usuario para calcular los valores de cumplimiento anual
    useEffect(() => {
        getUserFichajes()
    }, [userId, year])

    const generateCalendar = async () => {
        if(month.toString() && userId){
            setMonthDays([])                
            let excepcionesByUser = []

            try{
                const getAllExcepciones = await getExcepciones(userId, year)
                excepcionesByUser = getAllExcepciones
            } catch (error){
                console.log(error)
            }

            const monthId = parseInt(month) + 1
            //const year = 2022
            const currentMonthFirstDay = new Date(`${year}/${monthId.toString()}/01`).getDay() + 1
            setFirstDay(currentMonthFirstDay)
            const monthLength = new Date(year, monthId, 0).getDate() 
            let totalDays = []

            let fichajesByUser;
            try{
                fichajesByUser = await axiosRequest.get(`/fichajes/${userId}/${parseInt(month) + 1}/${parseInt(year)}`)
                
                    for(let i = 1; i <= monthLength; i++){
                        // Filtrar array de fichajes según el día (valor de i junto al mes y año)
                        let d = new Date(`${year}/${monthId.toString()}/${i}`).getDay()
                        // Filtrar excepciones
                        let excepcionDay = []
                        if(excepcionesByUser.length > 0){
                            excepcionDay = excepcionesByUser.filter(excepcion => {
                                // Chequea excepciones por el periodo FECHA
                                if(excepcion.periodo_excepcion === "Fecha"){
                                    let dayFormatted = dayjs(excepcion.periodo_fecha_excepcion).format("DD/MM/YYYY")
                                    let currentDay = `${i < 10 ? `0${i}` : i}/${monthId < 10 ? `0${monthId}` : `${monthId}`}/${year}`
                                    return dayFormatted === currentDay
                                } else {

                                    
                                    // Chequea excepciones por los periodos DÍA y DESDE-HASTA


                                    const excepcionMonthDesde = dayjs(excepcion.periodo_fecha_desde_excepcion).format("M") 
                                    const excepcionMonthHasta = dayjs(excepcion.periodo_fecha_hasta_excepcion).format("M") 
                                    // fichajes.mes >= excepcionMonthDesde || fichajes.mes <= excepcionMonthHasta
                                    // monthId >= excepcionMonthDesde || monthId <= excepcionMonthHasta
                                    if(monthId >= excepcionMonthDesde && monthId <= excepcionMonthHasta){
                                        const firstDay = dayjs(excepcion.periodo_fecha_desde_excepcion).format("DD")
                                        const lastDay = dayjs(excepcion.periodo_fecha_hasta_excepcion).format("DD")
                                        const days = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sabado"]
                                        const daySelected = days[d]
                                        if(excepcionMonthHasta != monthId){
                                            //console.log("EL MES HASTA ES DISTINTO DEL MES SELECCIONADO -------")
                                            // Mayor o igual al primer día de la excepción (05/04), menor o igual al último día del mes (30/04) y que sea igual al día de la excepción (martes)
                                            // Si la excepción es del periodo "DIA" entonces chequea que el dia seleccionado y el del periodo de excepción 
                                            //return excepcion.periodo_excepcion === "Día" ? i >= firstDay && i <= monthLength && daySelected === excepcion.periodo_dia_excepcion : i >= firstDay && i <= monthLength
                                            if(monthId == excepcionMonthDesde || monthId == excepcionMonthHasta){
                                                return excepcion.periodo_excepcion === "Día" ? i >= firstDay && i <= monthLength && daySelected === excepcion.periodo_dia_excepcion : i >= firstDay && i <= monthLength
                                            } else {
                                                return excepcion.periodo_excepcion === "Día" ? daySelected === excepcion.periodo_dia_excepcion : i >= firstDay && i <= monthLength
                                            }
                                        } else if(excepcionMonthDesde != monthId){
                                            // Mayor o igual al primer día del mes (1), menor o igual al último día de la excepción (10/04) y que sea igual al día de la excepción (martes)
                                            if(monthId == excepcionMonthDesde || monthId == excepcionMonthHasta){
                                                return excepcion.periodo_excepcion === "Día" ? i >= 1 && i <= lastDay && daySelected === excepcion.periodo_dia_excepcion : i >= 1 && i <= lastDay
                                            } else {
                                                return excepcion.periodo_excepcion === "Día" ? daySelected === excepcion.periodo_dia_excepcion : i >= 1 && i <= lastDay
                                            }
                                        } else {
                                            // Retorna todos los días de la excepción que estén comprendidos entre la primera fecha y la última (ejemplo --> todos los martes entre el 05/04/22 y el 19/04/22)
                                            return excepcion.periodo_excepcion === "Día" ? i >= firstDay && i <= lastDay && daySelected === excepcion.periodo_dia_excepcion : i >= firstDay && i <= lastDay
                                        }
                                    }

                                    // CHECK DIAS DE DIFERENTES MESES
                                    if(excepcion.periodo_excepcion === "Desde / Hasta" && (excepcionMonthDesde == monthId || excepcionMonthHasta == monthId)){
                                        if(excepcionMonthHasta == monthId){
                                            const dayExcepcion = parseInt(dayjs(excepcion.periodo_fecha_hasta_excepcion).format("D"))
                                            const anioExcepcion = parseInt(dayjs(excepcion.periodo_fecha_hasta_excepcion).format("YYYY"))
                                            return i <= dayExcepcion && (d !== 0 && d !== 6) && year === anioExcepcion
                                        } else if(excepcionMonthDesde == monthId){
                                            const dayExcepcion = parseInt(dayjs(excepcion.periodo_fecha_desde_excepcion).format("D"))
                                            const anioExcepcion = parseInt(dayjs(excepcion.periodo_fecha_desde_excepcion).format("YYYY"))
                                            return i >= dayExcepcion && (d !== 0 && d !== 6) && year === anioExcepcion
                                        }
                                    }
                                }
                            })
                        }
                        let fichajesDay = []
                        if(fichajesByUser.data){
                            fichajesDay = fichajesByUser.data.filter(fichaje => {
                                let dayFormatted = dayjs(fichaje.dia_fichaje).format("DD/MM/YYYY")
                                let currentDay = `${i < 10 ? `0${i}` : i}/${monthId < 10 ? `0${monthId}` : `${monthId}`}/${year}`
                                return dayFormatted === currentDay
                            })
                        }     
                        let data = { date: i, dayNumber: d, year: year, fichajes: fichajesDay, excepciones: excepcionDay}
                        totalDays.push(data)
                    }
          
                    setMonthDays(totalDays)
                
            } catch (error){
                console.log(error)
            }
        }
    }

    // Generar Calendario
    useEffect(() => {
        generateCalendar()
    }, [year, month, userId])

    useEffect(() => {
      const getUsuarios = async () => {
        const usuarios = await axiosRequest.get('/operadores') 
        const filterUsuarios = usuarios.data.filter(usuario => !usuario.nombre_operador.toLowerCase().includes("prueba"))
        const formatUsuarios = formatSelectData(filterUsuarios, 'id_usuario', ['nombre_operador', 'apellido_operador'], 'id_usuario')
        setUsuarios(formatUsuarios.sort(orderAlphabetically))
      }
      getUsuarios()
    }, [])

    useEffect(() => {
        const getAllExcepciones = async () => {
            setLoadingExcepciones(true)
            setExcepciones([])
            try{
                const excepcionesByUser = await getExcepciones(userId, year)
                const excepcionesFormatted = formatExcepciones(excepcionesByUser)
                setExcepciones(excepcionesFormatted)
                setLoadingExcepciones(false)
            } catch (error){
                console.log(error)
                setLoadingExcepciones(false)
            }
        }
        getAllExcepciones()
    }, [userId, year])

    const openCargarExcepcionModal = () => {
        setEditMode(false)
        setIsExcepcionModalOpen(true)
    }

    const handleChangeFichajeRRHH = (event) => {
        const { name, value } = event.target
        setFichajeRRHH(prev => ({...prev, [name]: value, }))
    }

    return(
        <div className="App">

        <Modal isModalOpen={isSuccessPopUpOpen} setIsModalOpen={setIsSuccessPopUpOpen} hideCross={true} background={false}>
            <PopUp
              icono={Check}
              backgroundColor={true}
              mensaje={popUpMessage}
            />
          </Modal>

            {isExcepcionModalOpen ? 
                <Modal isModalOpen={isExcepcionModalOpen} setIsModalOpen={setIsExcepcionModalOpen} >
                    <ModalEditarOperador>
                        <Titulo margin={true}>{editMode ? "Editar Excepción" : "Cargar Excepción"}</Titulo>
                        <Grid colGap={21} rowGap={21}>

                            <Col desktop={6}>
                                <InputSelect name={"usuario"} labelName={"Usuario"} color={errorsExcepcion.usuario ? "error" : "normal"} options={usuarios} onChange={handleChangeExcepcion} value={excepcionValues.usuario} />
                                {errorsExcepcion.usuario && <span className="error">{errorsExcepcion.usuario}</span>}
                            </Col>
                            
                            <Col desktop={6}>
                                <InputSelect name={"tipo"} labelName={"Tipo"} color={errorsExcepcion.tipo ? "error" : "normal"} options={tipoOptions} onChange={handleChangeExcepcion} value={excepcionValues.tipo} />
                                {errorsExcepcion.tipo && <span className="error">{errorsExcepcion.tipo}</span>}
                            </Col>

                            <Col desktop={12}>
                                <Subtitulo>Periodo</Subtitulo>
                            </Col>
                            <Col desktop={4}>
                                <InputSelect name={"periodo"} labelName={"Periodo"} color={errorsExcepcion.periodo ? "error" : "normal"} options={periodosOptions} onChange={handleChangeExcepcion} value={excepcionValues.periodo}/>
                                {errorsExcepcion.periodo && <span className="error">{errorsExcepcion.periodo}</span>}
                            </Col>
                            {excepcionValues.periodo === "Día" ? 
                                <>
                                    <Col desktop={4}>
                                        <InputSelect name={"periodo_dia"} labelName={"Dia"} color={errorsExcepcion.periodo_dia ? "error" : "normal"} options={diasOptions} onChange={handleChangeExcepcion} value={excepcionValues.periodo_dia} />
                                        {errorsExcepcion.periodo_dia && <span className="error">{errorsExcepcion.periodo_dia}</span>}
                                    </Col>
                                    <Col desktop={4}>
                                        <InputCalendar type={"date"} name={"periodo_desde"} labelName={"Desde Fecha"} color={errorsExcepcion.periodo_desde ? "error" : "normal"} onChange={handleChangeExcepcion} readOnly={false} calendarPicker={CalendarPicker} cancelReminder={CancelReminder} value={excepcionValues.periodo_desde} />
                                        {errorsExcepcion.periodo_desde && <span className="error">{errorsExcepcion.periodo_desde}</span>}
                                    </Col>
                                    <Col desktop={4}>
                                        <InputCalendar type={"date"} name={"periodo_hasta"} labelName={"Hasta Fecha"} color={errorsExcepcion.periodo_hasta ? "error" : "normal"} onChange={handleChangeExcepcion} readOnly={false} calendarPicker={CalendarPicker} cancelReminder={CancelReminder} value={excepcionValues.periodo_hasta} />
                                        {errorsExcepcion.periodo_hasta && <span className="error">{errorsExcepcion.periodo_hasta}</span>}
                                    </Col>
                                </>
                            : null}
                            {excepcionValues.periodo === "Fecha" ? 
                                <Col desktop={4}>
                                    <InputCalendar type={"date"} name={"periodo_fecha"} color={errorsExcepcion.periodo_fecha ? "error" : "normal"} labelName={"Fecha"} onChange={handleChangeExcepcion} readOnly={false} calendarPicker={CalendarPicker} cancelReminder={CancelReminder} value={excepcionValues.periodo_fecha} /> 
                                    {errorsExcepcion.periodo_fecha && <span className="error">{errorsExcepcion.periodo_fecha}</span>}
                                </Col>
                            : null}
                            {excepcionValues.periodo === "Desde / Hasta" ? 
                                <>
                                    <Col desktop={4}>
                                        <InputCalendar type={"date"} name={"periodo_desde"} color={errorsExcepcion.periodo_desde ? "error" : "normal"} labelName={"Desde"} onChange={handleChangeExcepcion} readOnly={false} calendarPicker={CalendarPicker} cancelReminder={CancelReminder} value={excepcionValues.periodo_desde} /> 
                                        {errorsExcepcion.periodo_desde && <span className="error">{errorsExcepcion.periodo_desde}</span>}
                                    </Col>
                                    <Col desktop={4}>
                                        <InputCalendar type={"date"} name={"periodo_hasta"} color={errorsExcepcion.periodo_hasta ? "error" : "normal"} labelName={"Hasta"} onChange={handleChangeExcepcion} readOnly={false} calendarPicker={CalendarPicker} cancelReminder={CancelReminder} value={excepcionValues.periodo_hasta} /> 
                                        {errorsExcepcion.periodo_hasta && <span className="error">{errorsExcepcion.periodo_hasta}</span>}
                                    </Col>
                                </>
                            : null}

                            <Col desktop={12}>
                                <Subtitulo>Hora</Subtitulo>
                            </Col>
                            <Col desktop={4}>
                                <InputSelect name={"hora"} labelName={"Hora"} color={errorsExcepcion.hora ? "error" : "normal"} options={horaOptions} onChange={handleChangeExcepcion} value={excepcionValues.hora} />
                                {errorsExcepcion.hora && <span className="error">{errorsExcepcion.hora}</span>}
                            </Col>
                            {excepcionValues.hora === "Hora" ? 
                                <>
                                    <Col desktop={4}>
                                        <InputBasic type={"text"} name={"hora_desde"} color={errorsExcepcion.hora_desde ? "error" : "normal"} labelName={"Desde"} placeholder={"11:00"} onChange={handleChangeExcepcion} value={excepcionValues.hora_desde} />
                                        {errorsExcepcion.hora_desde && <span className="error">{errorsExcepcion.hora_desde}</span>}
                                    </Col>
                                    <Col desktop={4}>
                                        <InputBasic type={"text"} name={"hora_hasta"} color={errorsExcepcion.hora_hasta ? "error" : "normal"} labelName={"Hasta"} placeholder={"18:00"} onChange={handleChangeExcepcion} value={excepcionValues.hora_hasta} />
                                        {errorsExcepcion.hora_hasta && <span className="error">{errorsExcepcion.hora_hasta}</span>}
                                    </Col>
                                </>
                            : null}

                            <Col desktop={12}>
                                <div className={'buttonsCont'}>
                                    <Button backgroundColor={false} borderColor={true} textColor={true} icon={false} onClick={() => setIsExcepcionModalOpen(false)}> Cancelar </Button>
                                    <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={editMode ? updateExcepcion : postExcepcion}>{editMode ? "Editar excepción" : "Cargar excepción"}</Button>
                                </div>
                            </Col>
                        </Grid>
                    </ModalEditarOperador>
                </Modal>
            : null}

            {isFicharModalOpen ? 
                <Modal isModalOpen={isFicharModalOpen} setIsModalOpen={setIsFicharModalOpen} >
                    <ModalFichaje>
                        <Titulo margin={true}>Cargar fichaje</Titulo>
                        <Grid colGap={21} rowGap={1}>
                        {user.id_rol == 1 || user.id_rol == 16 ? 
                                <>
                                    <Col desktop={12}>
                                        <TabsContainer disabledMargin >
                                            <Tab selected={selectedTabFichajeRRHH === "fichaje_propio" ? true : false} onClick={()=> setSelectedTabFichajeRRHH('fichaje_propio')}>Fichaje propio</Tab>
                                            <Tab selected={selectedTabFichajeRRHH === "fichaje_por_usuario" ? true : false} onClick={()=> setSelectedTabFichajeRRHH('fichaje_por_usuario')}>Fichar por usuario</Tab>
                                        </TabsContainer>
                                    </Col>

                                    {selectedTabFichajeRRHH === "fichaje_por_usuario" ? 
                                        <>
                                            <Col desktop={12}>
                                                <Subtitulo className="remove-margin-top">Datos del fichaje</Subtitulo>
                                            </Col>  
                                            <Col desktop={4}>
                                                <InputSelect name={"usuario"} labelName={"Usuario"} options={usuarios} onChange={handleChangeFichajeRRHH} value={fichajeRRHH.usuario} color={fichajeRRHHErrors.usuario ? "error" : "normal"} />
                                                {fichajeRRHHErrors.usuario && <span className="error error-message">{fichajeRRHHErrors.usuario}</span>}
                                            </Col>
                                            <Col desktop={4}>
                                                <InputCalendar type={"date"} name={"fecha"} labelName={"Fecha"} onChange={handleChangeFichajeRRHH} readOnly={false} calendarPicker={CalendarPicker} cancelReminder={CancelReminder} value={fichajeRRHH.fecha} color={fichajeRRHHErrors.fecha ? "error" : "normal"}/> 
                                                {fichajeRRHHErrors.fecha && <span className="error error-message">{fichajeRRHHErrors.fecha}</span>}
                                            </Col>
                                            <Col desktop={4}>
                                                <InputBasic type={"text"} name={"hora"} labelName={"Hora"} placeholder={"11:00"} onChange={handleChangeFichajeRRHH} value={fichajeRRHH.hora} color={fichajeRRHHErrors.hora ? "error" : "normal"}/>
                                                {fichajeRRHHErrors.hora && <span className="error error-message">{fichajeRRHHErrors.hora}</span>}
                                            </Col>
                                            {user.id_rol !== 1 || selectedTabFichajeRRHH === "fichaje_propio" || (selectedTabFichajeRRHH === "fichaje_por_usuario" && fichajeRRHH.usuario && fichajeRRHH.fecha) ?
                                                <Col desktop={12}>
                                                    <Subtitulo  className="subtitulo-margin">Tipo de fichaje</Subtitulo>
                                                </Col>
                                            : null}
                                        </>
                                    : 
                                        null
                                    }
                                </>
                            : 
                                null
                            }

                        </Grid>

                        {user.id_rol !== 1 || selectedTabFichajeRRHH === "fichaje_propio" || (selectedTabFichajeRRHH === "fichaje_por_usuario" && fichajeRRHH.usuario && fichajeRRHH.fecha) ? 
                            <FichajesContainer>
                                <Grid colGap={21} rowGap={21}>
                                    {!fichajesCardsLoading ? 
                                        <>
                                            <Col desktop={!comercialRole ? 4 : 6}>
                                                <Fichaje onClick={()=> selectFichaje('ingreso', 'Ingreso')} title="Ingreso" status={fichajesRealizados.ingreso.status} selected={fichajesRealizados.ingreso.selected} time={fichajesRealizados.ingreso.time} />
                                            </Col>
                                            {!comercialRole ? 
                                                <>
                                                    <Col desktop={4}>
                                                        <Fichaje onClick={()=> selectFichaje('inicio_almuerzo', 'Inicio almuerzo')} title="Inicio de almuerzo" status={fichajesRealizados.inicio_almuerzo.status} selected={fichajesRealizados.inicio_almuerzo.selected} disabled={fichajesRealizados.ingreso.status ? false : true} time={fichajesRealizados.inicio_almuerzo.time} />
                                                    </Col>
                                                    <Col desktop={4}>
                                                        <Fichaje onClick={()=> selectFichaje('fin_almuerzo', 'Fin almuerzo')} title="Fin de almuerzo" status={fichajesRealizados.fin_almuerzo.status} selected={fichajesRealizados.fin_almuerzo.selected} disabled={fichajesRealizados.ingreso.status && fichajesRealizados.inicio_almuerzo.status ? false : true} time={fichajesRealizados.fin_almuerzo.time} />
                                                    </Col>
                                                </>
                                            : 
                                                null
                                            }
                                            <Col desktop={!comercialRole ? 4 : 6}>
                                                <Fichaje onClick={()=> selectFichaje('fin_jornada', 'Fin jornada')} title="Fin de jornada" status={fichajesRealizados.fin_jornada.status} selected={fichajesRealizados.fin_jornada.selected} disabled={(!comercialRole && fichajesRealizados.ingreso.status && fichajesRealizados.inicio_almuerzo.status && fichajesRealizados.fin_almuerzo.status) || (comercialRole && fichajesRealizados.ingreso.status) ? false : true} time={fichajesRealizados.fin_jornada.time} />
                                            </Col>
                                            <Col desktop={!comercialRole ? 4 : 6}>
                                                <Fichaje onClick={() => selectFichaje('salida_especial', 'Salida especial')} title="Salida especial" selected={fichajesRealizados.salida_especial.selected} />
                                            </Col>
                                            <Col desktop={!comercialRole ? 4 : 6}>
                                                <Fichaje onClick={() => selectFichaje('reingreso_especial', 'Reingreso especial')} title="Reingreso especial" selected={fichajesRealizados.reingreso_especial.selected} />
                                            </Col>
                                        </>
                                    : 
                                        <Col desktop={12}>
                                            <SpinnerLoading text="Cargando fichajes..." margin={true} />
                                        </Col>
                                    }
                                </Grid>
                            </FichajesContainer>
                        : null}

                        <div className={'buttonsCont'}> 
                            <Button backgroundColor={false} borderColor={true} textColor={true} icon={false} onClick={() => setIsFicharModalOpen(false)}> Cancelar </Button>
                            <Button
                                backgroundColor={true}
                                borderColor={false}
                                textColor={false}
                                icon={false}
                                onClick={sendFichaje}
                                disabled={isButtonFicharDisabled}
                            >
                                Fichar
                            </Button>
                        </div>
                    </ModalFichaje>
                </Modal>
            : null}

            {isDeleteExcepcionModalOpen ? 
                <Modal isModalOpen={isDeleteExcepcionModalOpen} setIsModalOpen={setIsDeleteExcepcionModalOpen} >
                    <ModalBorrarExcepcion>
                        <h2>¿Estás seguro de borrar la excepción {excepcionIdToDelete.id_excepcion}?</h2>
                        {excepcionIdToDelete ?  
                            <div className="excepcionData">
                                <Grid colGap={21} rowGap={8}>
                                    <Col desktop={4}>
                                        <p>User: <span className="data">{usuarios.find(usuario => { return usuario.value === excepcionIdToDelete.id_usuario_excepcion}).label}</span></p>
                                    </Col>
                                    <Col desktop={4}>
                                        <p>Tipo: <span className="data">{excepcionIdToDelete.tipo_excepcion}</span></p>
                                    </Col>
                                    <Col desktop={4}>
                                        <p>Hora: <span className="data">{excepcionIdToDelete.hora_excepcion}</span></p>
                                    </Col>
                                    <Col desktop={4}>
                                        <p>Periodo: <span className="data">{excepcionIdToDelete.periodo_excepcion === "Fecha (desde / hasta)" ? "Desde / Hasta" : excepcionIdToDelete.periodo_excepcion}</span></p>
                                    </Col>
                                {excepcionIdToDelete.periodo_excepcion === "Día" || excepcionIdToDelete.periodo_excepcion === "Fecha (desde / hasta)" ? 
                                    <>
                                        {excepcionIdToDelete.periodo_excepcion === "Día" && <Col desktop={4}><p>Todos los: <span className="data">{excepcionIdToDelete.periodo_dia_excepcion}</span></p></Col> }
                                        <Col desktop={4}>
                                            <p>Desde el: <span className="data">{dayjs(excepcionIdToDelete.periodo_fecha_desde_excepcion).format("DD/MM/YYYY")}</span></p>
                                        </Col>
                                        <Col desktop={4}>
                                            <p>Hasta el: <span className="data">{dayjs(excepcionIdToDelete.periodo_fecha_hasta_excepcion).format("DD/MM/YYYY")}</span></p>
                                        </Col>
                                    </>
                                : null}
                                {excepcionIdToDelete.periodo_excepcion === "Fecha" ? 
                                    <>
                                        <Col desktop={4}>
                                            <p>Fecha: <span className="data">{dayjs(excepcionIdToDelete.periodo_fecha_excepcion).format("DD/MM/YYYY")}</span></p>
                                        </Col>
                                    </>
                                : null}
                                {excepcionIdToDelete.hora_excepcion === "Hora" ? 
                                    <>
                                        <Col desktop={4}>
                                            <p>Desde las: <span className="data">{dayjs(excepcionIdToDelete.hora_desde_excepcion).format("HH:mm")}hs</span></p>
                                        </Col>
                                        <Col desktop={4}>
                                            <p>Hasta las: <span className="data">{dayjs(excepcionIdToDelete.hora_hasta_excepcion).format("HH:mm")}hs</span></p>
                                        </Col>
                                    </>
                                : null}
                                </Grid>
                            </div>
                        : null}
                        <div className={'buttonsCont'}>
                            <Button backgroundColor={false} borderColor={true} textColor={true} icon={false} onClick={() => setIsDeleteExcepcionModalOpen(false)}> Cancelar </Button>
                            <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={() => deleteExcepcion(excepcionIdToDelete)}>Borrar excepción</Button>
                        </div>
                    </ModalBorrarExcepcion>
                </Modal>
            : null}

            <BurgerMenu open={open} setOpen={setOpen}/>
            <NavMenu open={open} setOpen={setOpen} user={user} active={"fichajes"} />

            <Wrapper>
                <Inner>
                <Grid colGap={21} rowGap={21}>

                <Col desktop={12} spaced={true}>
                    <TitlePageSection title="Fichajes">
                    <div className="buttonsContainer">
                            <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={() => setIsFicharModalOpen(true)}>
                                Fichar
                            </Button>
                            {rolesAuth.find(id => id === user.id_rol) && <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={openCargarExcepcionModal}>
                                Cargar excepcion
                            </Button>}
                        </div>
                    </TitlePageSection>
                    </Col>

                    {/*
                    <Col desktop={12} spaced={true}>
                        <Titulo textColor={false}>Fichajes</Titulo>
                        <div className="buttonsContainer">
                            <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={() => setIsFicharModalOpen(true)}>
                                Fichar
                            </Button>
                            {rolesAuth.find(id => id === user.id_rol) && <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={openCargarExcepcionModal}>
                                Cargar excepcion
                            </Button>}
                        </div>
                    </Col>
                        */}
                    {rolesAuth.find(id => id === user.id_rol)  ?
                        <>
                            <Col desktop={3}>
                                <InputSelect
                                    name={"usuarios"}
                                    labelName={"Usuario"}
                                    options={usuarios}
                                    onChange={handleUsuarioChange}
                                    value={userId}
                                />
                            </Col>
                            <Col desktop={3}>
                                <InputSelect
                                    name={"anio"}
                                    labelName={"Año"}
                                    options={anioOptions}
                                    onChange={handleYearChange}
                                    value={year}
                                />
                            </Col>
                        </>
                    : null}

                    <Col desktop={12}>
                        <TabsContainer>
                            <Tab selected={selectedTab === "calendario" ? true : false} onClick={()=> setSelectedTab('calendario')}>Calendario</Tab>
                            <Tab selected={selectedTab === "cumplimiento" ? true : false} onClick={()=> setSelectedTab('cumplimiento')}>Cumplimiento Anual</Tab>
                            {rolesAuth.find(id => id === user.id_rol)  && <Tab selected={selectedTab === "excepciones" ? true : false} onClick={()=> setSelectedTab('excepciones')}>Excepciones</Tab>}
                        </TabsContainer>
                    </Col>

                    {selectedTab === "calendario" ?
                        <>
                            <Col desktop={3}>
                                <InputSelect
                                    name={"mes"}
                                    labelName={"Mes"}
                                    options={monthsOptions}
                                    onChange={handleMonthChange}
                                    value={month}
                                />
                            </Col>
                        </>
                    : null}

                    {isFichajePosteado ? 
                      <Col desktop={12}>
                        <p>Fichaje posteado</p>
                      </Col>
                    : null
                    }
                </Grid>

                {selectedTab === "calendario" ?
                    <>
                        <GridCalendarContainerHeader>
                            <div className="dayCont"><span>Domingo</span></div>
                            <div className="dayCont"><span>Lunes</span></div>
                            <div className="dayCont"><span>Martes</span></div>
                            <div className="dayCont"><span>Miércoles</span></div>
                            <div className="dayCont"><span>Jueves</span></div>
                            <div className="dayCont"><span>Viernes</span></div>
                            <div className="dayCont"><span>Sábado</span></div>
                        </GridCalendarContainerHeader>
                        {monthDays.length > 0 ? 
                            <GridCalendarContainer className="calendar-container">
                                {monthDays && monthDays.map(day => {
                                    return (
                                        <CalendarDay day={day} month={month} firstDay={firstDay} />
                                    )
                                })}
                            </GridCalendarContainer>
                        : 
                            <SpinnerLoading text="Cargando fichajes..." margin={true} />
                        }
                    </>
                : null}

                {selectedTab === "cumplimiento" ?
                    <InfoBoard headers={cumplimientoAnualHeaders} data={cumplimientoAnualData} loading={loadingCumplimientoAnual} page="Fichajes" />
                : null}

                {selectedTab === "excepciones" && excepciones && (user.id_rol === 1 || user.id_rol === 16)  ?
                    <InfoBoard headers={excepcionesHeaders} data={excepciones} loading={loadingExcepciones} page="Fichajes" />       
                : null}
                </Inner>
            </Wrapper>
        </div>
    )
}

export default Fichajes
