import {DetailsMain, ScrollX} from '../Details/styled';
import {Table} from '../Table/styled'
import Button from '../Button';
import { useEffect, useState } from 'react';


function Historial({details, historial, setShowHistorial, estados}){
    const [historialToShow, setHistorialToShow] = useState([]);


    const setearModificaciones =  (historialToCheck)=>{
        //como primer elemento del historial a mostrar es el rechazo como esta acualmente
        let historialTemp = [];
        historialTemp.push(details) // guardo como primer dato el regisro como esta actualmente

        historialToCheck.map(reg => {
            //paso a un objeto literal las modificaciones
            const cambios = JSON.parse(reg.modificados);
            //traigo la modificacion previa
            const estadoAnterior = historialTemp[historialTemp.length-1];
            let registroAux = {...estadoAnterior}
            
            for (const propAnterior in estadoAnterior) {
                for (const cambio in cambios) {
                    if(cambio==='eliminado'){
                        registroAux['deletedAt'] = null;
                    }
                    if (propAnterior === cambio){
                        registroAux['updatedAt'] = reg.fecha_valida_valor_previo
                        registroAux[propAnterior] = cambios[propAnterior]
                    }
                }
            }
            historialTemp.push(registroAux)

            //console.log('modificaciones', cambios)
            
        })
        return historialTemp;
    }

    useEffect(()=>{
        // console.log('dentro de historial es: ', historial)
        const result = setearModificaciones(historial);
        setHistorialToShow(result)
    },[]);
    return  (
    
    <DetailsMain className="historial">
        <h4>Historial</h4>

        <div>
            {
                historialToShow.length === 0?
                <>
                <hr/>
                'No se ingresaron modificaciones en este registro'
                </>
                :
                <ScrollX>
                <Table border={true}>
                    <thead>
                    <tr>
                        <th> Datos válidos desde </th>
                        <th> Nombre </th>
                        <th> Email </th>
                        <th> Poliza </th>
                        <th> Estado </th> 
                        <th> Importe </th>
                        <th> Notificación Abierta </th>                                           
                        <th> Id_compania </th>
                        <th> Usuario </th>
                        <th> Fecha de Borrado </th>
                        <th> CBU </th>
                        <th> Tarjeta </th>
                        <th> Fecha </th>
                        <th> Ramo </th>
                        <th> Desc Rechazo </th>
                        <th> Cuota Nº </th>
                        <th> Saldo Total </th>

                    </tr>
                    </thead>
                    <tbody>
                        {
                            historialToShow.length!==0?
                                historialToShow.map(reg => (
                                    <tr  className={reg.deletedAt?'deleted':''}>
                                        <td> {
                                            reg.deletedAt?
                                            new Date(reg.deletedAt).toLocaleString()
                                            :
                                            new Date(reg.updatedAt).toLocaleString()
                                            }  </td>
                                        <td> {reg.nombre} </td>
                                        <td> {reg.mail} </td>
                                        <td> {reg.poliza} </td>
                                        <td> {(estados.find( e => reg.id_estado === e.id)).estado} </td>
                                        <td> {reg.importe} </td>
                                        <td> { reg.openNotificationAt?  new Date(reg.openNotificationAt).toLocaleString() : '' } </td>
                                        <td> {reg.id_compania} </td>
                                        <td> {reg.id_user} </td>
                                        <td> {reg.deletedAt ? new Date(reg.deletedAt).toLocaleString() : '-'} </td>
                                        <td> {reg.cbu} </td>
                                        <td> {reg.tarjeta} </td>
                                        <td> {reg.fecha} </td>
                                        <td> {reg.ramo} </td>
                                        <td> {reg.desc_rechazo} </td>                                            
                                        <td> {reg.n_cuota} </td>
                                        <td> {reg.saldo_total} </td>
                                    </tr>
                                ) )
                            : null
                        }
                    </tbody>
                </Table>
                </ScrollX>
            }
        </div>
        
        <br/>
        <Button
        onClick={()=>setShowHistorial(false)}
        backgroundColor={true}
        borderColor={false}
        textColor={false}
        className={"warning"}
        >
            
        Volver
        </Button>
    </DetailsMain>)
}


export default Historial;