import Modal from "../../../Modal";
import { Container } from "./styled";
import Titulo from "../../../Titulo";
import GridSection from "../../../GridSection";
import SpinnerLoading from "../../../SpinnerLoading";
// Constants
import { CLIENTE_LABELS, SERVICIO_LABELS, PAGO_LABELS, ARCHIVOS_AUTOGESTION_LABELS } from "../../../../constants/labels";
import GridSectionFile from "../../../GridSectionFile";

const ModalPago = ({ isModalOpen, setIsModalOpen, data }) => {

  return (
    <div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <Container>
          {data ? (
            <>
              <div>
                <span className="fecha-contratacion">Contratado el {data?.fecha}</span>
                <Titulo>Contratación ・ {data?.cliente.nombre}</Titulo>
              </div>

              <div>
                <GridSection data={data.cliente} labels={CLIENTE_LABELS} sectionTitle="Cliente" columns={4} />

                <hr />
                <GridSection data={data.servicio} labels={SERVICIO_LABELS} sectionTitle="Servicio contratado" columns={4} />

                {data.archivos.length > 0 ? (
                  <>
                    <hr />
                    <GridSectionFile data={data.archivos} labels={ARCHIVOS_AUTOGESTION_LABELS} sectionTitle="Archivos" columns={3} />
                  </>
                ) : null}

                <hr />
                <GridSection data={data.pago} labels={PAGO_LABELS} sectionTitle="Pago" columns={4} />
              </div>
            </>
          ) : (
            <SpinnerLoading text="Cargando contratación..." />
          )}
        </Container>
      </Modal>
    </div>
  );
};

export default ModalPago;
