import { useEffect, useState } from "react";
import { postSeguroData, updateSeguroData } from "../services/Emision";
import axiosRequest from "../utils/axiosConfig";

export const useSeguro = () => {

  const editarSeguroForm = async (seguro, id_cobertura_asegurada) => {
    try {
      let products = null
      // Request para buscar los nombres de la compania, producto y cobertura según el ID guardado en el step 2
      const companies = await axiosRequest.get(
        `/companias/${seguro.cia_cobertura_asegurada}`
      );
      if(seguro?.producto_cobertura_asegurada){
        products = await axiosRequest.get(
          `/productos/${seguro.producto_cobertura_asegurada}`
        );
      }
      
      // Nombres
      const companyName = companies.data.nombre_compania;
      const productName = products ? products.data.nombre_producto : null;

      const seguroData = {
        id_cobertura_asegurada: id_cobertura_asegurada,
        cia_cobertura_asegurada: companyName,
        producto_cobertura_asegurada: productName,
        cobertura_cobertura_asegurada: seguro?.cobertura_cobertura_asegurada,
        tipo_facturacion_cobertura_asegurada: seguro?.tipo_facturacion_cobertura_asegurada,
        cuotas_cobertura_asegurada: seguro?.cuotas_cobertura_asegurada,
        vigencia_cobertura_asegurada: seguro?.vigencia_cobertura_asegurada,
        valor_cobertura_asegurada: seguro?.valor_cobertura_asegurada,
      };

      const seguroForm = await updateSeguroData(seguroData, id_cobertura_asegurada)
      return seguroForm
    } catch (error) {
      console.log(error)
    }
  }

  const postearSeguroForm = async (seguro) => {
    try {
      let products = null
      // Request para buscar los nombres de la compania, producto y cobertura según el ID guardado en el step 2
      const companies = await axiosRequest.get(
        `/companias/${seguro.cia_cobertura_asegurada}`
      );
      if(seguro?.producto_cobertura_asegurada){
        products = await axiosRequest.get(
          `/productos/${seguro.producto_cobertura_asegurada}`
        );
      }

      // Nombres
      const companyName = companies.data.nombre_compania;
      const productName = products ? products.data.nombre_producto : null

      const seguroData = {
        cia_cobertura_asegurada: companyName,
        producto_cobertura_asegurada: productName,
        cobertura_cobertura_asegurada: seguro?.cobertura_cobertura_asegurada,
        tipo_facturacion_cobertura_asegurada: seguro?.tipo_facturacion_cobertura_asegurada,
        cuotas_cobertura_asegurada: seguro?.cuotas_cobertura_asegurada,
        vigencia_cobertura_asegurada: seguro?.vigencia_cobertura_asegurada,
        valor_cobertura_asegurada: seguro?.valor_cobertura_asegurada,
      };

      const seguroForm = await postSeguroData(seguroData)
      return seguroForm
    } catch (error) {
      console.log(error)
    }
  }

  return { 
    postearSeguroForm,
    editarSeguroForm
  }
};
