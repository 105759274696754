import { Container } from "./styled"

const Fichaje = ({ title, status, selected, onClick, disabled, time }) => {

    return (
        <Container onClick={onClick} selected={selected} done={status} disabled={disabled} >
            <div className="info-container">
                <h4 className={selected ? 'selected-title' : disabled ? 'disabled-title' : ''}>{title}</h4>
                {status && <span>Realizado a las {time}hs</span>}
            </div>
            {status && 
                <svg width="30" height="15" viewBox="0 0 47 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45.6195 0.678049C44.7155 -0.226016 43.2499 -0.226016 42.3457 0.678049L14.6121 28.4119L3.95178 17.7516C3.04781 16.8475 1.58221 16.8476 0.678049 17.7516C-0.226016 18.6556 -0.226016 20.1212 0.678049 21.0252L12.9753 33.3223C13.879 34.2262 15.3457 34.2256 16.249 33.3223L45.6195 3.95178C46.5235 3.04781 46.5234 1.58212 45.6195 0.678049Z" fill="#3D9F54" />
                </svg>
            }
        </Container>
    )
}

export default Fichaje