import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";


export const Container = styled.div`
  ${props => props.borderTop && css`
      & {
        //border-top: 1px solid ${leerColor(colores.gris)};
        border-top: 1px solid #ebebeb;
        padding-top: calc(40px - 1em);
        //margin-top: calc(40px + 1em);
        margin-top: ${props => (props.disabledMarginTop ? `0` : "calc(40px + 1em)")};
      } 
  `}
  font-family: 'DM Sans', sans-serif;

  .visible{
    padding-top:20px;
    background-color: blue;
  }
`;

export const TableHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => (props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(6, 1fr)')};
  //grid-template-columns: repeat(5, 1fr);
  color: ${leerColor(colores.celeste)};
  text-align: center;
  margin:${props => (props.borderTop ? `` : '24px 0 0 0')};
  font-size: 18px;
  font-weight: 600;
  //font-family: Raleway, sans-serif;
  font-family: 'DM Sans', sans-serif;
  position: sticky;
  top: 0;
  padding-top:20px;
  background-color: ${leerColor(colores.blanco)};
  z-index: 1;

  .header-arrow{
    width: 15px;
  }

  span {
    cursor: pointer
  }
`;

export const TableBody = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => (props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(6, 1fr)')};
  grid-template-rows:${props => (props.rows && `repeat(${props.rows}, 70px)`)};
  //grid-template-columns: repeat(5, 1fr);
  // 
  font-family: 'DM Sans', sans-serif;
  color: #525252;
  text-align: center;
  margin: 20px 0;
  align-items: center;

  .editButton{
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;

    .tooltip{
      display: none;
      position:absolute;
      background-color: rgb(30,30,30);
      color: #fff;
      top:-45px;
      padding:8px;
      font-size:14px;
      text-align: center;
      font-weight:500;
      border-radius:5px;
      width: 120px;

      ::after{
        content: '';
        display: block;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
        top:100%;
        left:50%;
        position: absolute;
      }
    }

    :hover{
      .tooltip{
        display:block;
      }
    }

  }

  .actionsContainer{
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    margin: 0 auto;

    img{
      width:24px;
      cursor: pointer;
      
      :hover{
        opacity:0.8;
      }
    }

    .delete{
      width: 22px;
    }
  }

  .positiveDifference{
    color: ${leerColor(colores.verde)};
    font-weight: 600;
  }

  .negativeDifference{
    color: ${leerColor(colores.rojo)};
    font-weight: 600;
  }
`;

export const NoHayBusquedas = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  svg{
    padding: 50px 0 20px;
  }
  h3{
     
    font-size: 18px;
    font-weight: 600;
    color: ${leerColor(colores.grisEstados)};
  }
`