import { useEffect } from "react";
import useHistorialNew from "../../hooks/useHistorialNew";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import NewButton from "../NewButton";
import MessagesHistory from "../MessagesHistoryNew";
import Cancel from "../../assets/iconos/cancel.svg";
import edit from "../../assets/iconos/edit.svg";
import CheckEmit from "../../assets/iconos/checkEmit.svg";
import Emisiones from "../../assets/iconos/emisiones.svg";
import Observacion from "../../assets/iconos/observacion.svg"
import Efectivo from "../../assets/iconos/dollar.png"
import { Container } from "./styled";
import { FiCheck, FiCoffee, FiEdit, FiMessageCircle, FiX } from "react-icons/fi";
import SpinnerLoading from "../SpinnerLoading";

const NewHistorial = ({ solicitud }) => {
  // Modales
  const {
    historial,
    handleModalHistorial,
    solicitudData,
  } = useHistorialNew();

  console.log(solicitud.solicitud)

  useEffect(() => {
    handleModalHistorial(solicitud.solicitud)
  }, [solicitud])

  return (
    <Container>
      <div className={"mensajesContainer"}>
        {historial.length > 0 ? 
        <>
        {historial &&
          historial.map((message, index) => {
            let previousDate =
              index !== 0
                ? dayjs(
                    new Date(historial[index - 1].fecha_solicitud_historial)
                  ).format("DD/MM") +
                  " - " +
                  dayjs(
                    new Date(historial[index - 1].fecha_solicitud_historial)
                  ).format("HH:mm")
                : "";
            let currentDate =
              dayjs(new Date(message.fecha_solicitud_historial)).format(
                "DD/MM"
              ) +
              " - " +
              dayjs(new Date(message.fecha_solicitud_historial)).format(
                "HH:mm"
              );
            let date;
            let messageClassName;
            let icon;

            const day = dayjs(new Date(message.fecha_solicitud_historial)).format('DD/MM')
            const previousDay = index !== 0 ? dayjs(new Date(historial[index - 1].fecha_solicitud_historial)).format('DD/MM') : null

            if (
              currentDate === previousDate &&
              !(
                index == 0 ||
                (index != 0 && message.id_area != historial[index - 1].id_area)
              )
            ) {
              date = "";
            } else {
              date = currentDate;
            }

            if (message.descripcion_solicitud_historial.includes("http")) {
              icon = Emisiones;
            }
            if (
              message.id_accion_historial === 4 &&
              !message.descripcion_solicitud_historial.startsWith("Solicitud")
            ) {
              icon = <FiMessageCircle />;
            }
            if (message.id_accion_historial === 8) {
              icon = Efectivo;
            }

            // Cambia la clase según el mensaje.
            switch (message.descripcion_solicitud_historial) {
              case "Solicitud de emisión enviada.":
                messageClassName = "enviada";
                break;
              case "Solicitud Rechazada.":
                messageClassName = "rechazado";
                icon = <FiX />;
                break;
              case "Solicitud Emitida.":
                messageClassName = "emitida";
                icon = <FiCheck />;
                break;
              case "Solicitud Pendiente Suscripción.":
                messageClassName = "suscripcion";
                break;
              case "Solicitud Emisión Provisoria.":
                messageClassName = "provisoria";
                break;
              default:
                messageClassName = "falta";
            }

            return (
              <>
              {day !== previousDay ?
                <>
                <div className="day-container">
                  <span>{dayjs(new Date(message.fecha_solicitud_historial)).format('DD/MM/YYYY')}</span>
                </div>
                <MessagesHistory 
                className={messageClassName} 
                icon={icon} 
                direction={message.id_area == '1' ? 'right' : 'left'} 
                persona={index == 0 || index != 0 && message.id_area != historial[index - 1].id_area ? `${message.id_area == '1' ? 'Operador' : 'Vendedor'} ${message.nombre_operador} ${message.apellido_operador}` : ''} 
                fechaHora={date} 
                mensaje={message.descripcion_solicitud_historial} 
                isLink={message.descripcion_solicitud_historial.includes('http') ? true : false} 
              />
                </>
              :
              <MessagesHistory 
                className={messageClassName} 
                icon={icon} 
                direction={message.id_area == '1' ? 'right' : 'left'} 
                persona={index == 0 || index != 0 && message.id_area != historial[index - 1].id_area ? `${message.id_area == '1' ? 'Operador' : 'Vendedor'} ${message.nombre_operador} ${message.apellido_operador}` : ''} 
                fechaHora={date} 
                mensaje={message.descripcion_solicitud_historial} 
                isLink={message.descripcion_solicitud_historial.includes('http') ? true : false} 
              />
              }
            </>
            );
        })}

        {solicitudData.estado === "Rechazado" ? 
          <div className="editar-solicitud-btn-container">
            <Link
              to={{
                pathname: `/EditarSolicitudEmision/${solicitudData.id}`,
                state: { data: solicitudData.id },
                search: `?tipoSolicitud=${solicitudData.id_tipo_solicitud}`,
              }}
            >
              <NewButton
                backgroundColor={false}
                borderColor={false}
                textColor={true}

              >
                <FiEdit />
                Editar solicitud
              </NewButton>
            </Link>
          </div>
        : null
            }
        </>
        : 
        <SpinnerLoading text="Cargando historial..." />
        }
      </div>
    </Container>
  );
};

export default NewHistorial;
