import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
    background-color: ${leerColor(colores.blanco)};
    padding: 50px 30px;
    text-align: center!important;
    color: ${leerColor(colores.grisEstados)};
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 6px;

  h3{
    margin: 0;
    //margin-top:20px;
    font-family: 'DM Sans',sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    color: ${leerColor(colores.negro)};
  }

  .icon {
    font-size: 60px;
    display: flex;
    color: ${leerColor(colores.rojo)};
  }
`;

