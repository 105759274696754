import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  InputSelect,
  ProgressBar,
  Menu,
  Wrapper,
  NewProgressBar,
  NavMenu,
  BurgerMenu,
  Modal,
  InputCalendar,
  PopUp,
  Informe,
  Seguimiento,
  Operaciones
} from "../components/Index";
import axiosRequest from "../utils/axiosConfig";
import { CalendarContainer, GridCalendarContainer, GridFirstDay, GridCalendarContainerHeader, BarColor, ModalEditarOperador, TabsContainer, Tab, GridCumplimientoContainerHeader, GridCumplimientoContainer, Error, ModalBorrarExcepcion } from './styled'


const Campanias = () => {

    const [excepciones, setExcepciones] = useState([])
    const user = JSON.parse(localStorage.getItem('userInfo'))
    const allowedRoles = [1, 2, 4, 6, 13, 14, 15];
    const [open, setOpen] = useState(false)

    const [selectedTab, setSelectedTab] = useState('resumen')

    const [selectToShow, setSelectToShow] = useState({
        selectProductos: [],
        selectCampanias: [],
        selectEstados:[],
        selectVendedores: []
    })

    const filtroInicial = {
        producto:'',
        estado:'',
        desde:'',
        hasta:'',
        campania: '',
        vendedor: ''
    }

    const [filtro, setFiltro] = useState(filtroInicial)
    const [productos, setProductos] = useState([])
    const [campanias, setCampanias] = useState([])
    const [estados, setEstados] = useState([])
    const [vendedores, setVendedores] = useState([])
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [btnLimpiar, setBtnLimpiar] = useState(false)
    const [error, setError] = useState({})
    

    const getProductos = async () => {
        try {
            const send = await axiosRequest.get(`/productos/`);
            setProductos(send.data)
        } catch (error) {
            setError({type: 'error', msg:error})
        }
    }

    const getCampanias = async () => {
        try {
            const send = await axiosRequest.get(`/campanias?desde=${filtro.desde}&hasta=${filtro.hasta}`);
            setCampanias(send.data)
            if(send.data.length!==0){
                setBtnDisabled(false)
                setBtnLimpiar(true)
            }
        } catch (error) {
            setError({type: 'error', msg:error})
        }
    }

    const getEstados = async () => {
        try {
            const send = await axiosRequest.get(`/campanias/estados/`);
            setEstados(send.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getVendedores = async () => {
        try {
            const send = await axiosRequest.get(`/vendedores/`);
            setVendedores(send.data)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(()=>{
        // trae las campañas por segun las fechas desde / hasta
        if(filtro.desde!=='' && filtro.hasta !=='' ) getCampanias()
    },[filtro.desde,filtro.hasta])

    useEffect(()=>{
        //formatea los vendedores para el select
        if(vendedores.length!== 0){
            const select = vendedores.map(v => {
                return({
                    'label': v.nombre,
                    'value': v.nombre,
                    'key': v.numero
                })
            })
            select.push({
                'label': 'Todos',
                'value': 'todos',
                'key':  'todos'
            })
            setSelectToShow({...selectToShow,selectVendedores:select})
        }
    },[vendedores])

    useEffect(()=>{
        //formatea los productos para el select
        if(productos.length!== 0){
            const select = productos.map(p => {
                return({
                    'label': p.nombre_producto,
                    'value': p.nombre_producto,
                    'key':  p.id_producto
                })
            })
            select.push({
                'label': 'Todos',
                'value': 'todos',
                'key':  'todos'
            })
            setSelectToShow({...selectToShow,selectProductos:select})
        }
    },[productos])

    useEffect(()=>{
        //formatea los estados para el select
        if(estados.length!== 0){
            const select = estados.map(e => {
                const label =e.descripcion.indexOf('-')!== -1? e.descripcion.slice(e.descripcion.indexOf('-')+1): e.descripcion
                return({
                    'label': label,
                    'value': e.descripcion,
                    'key':  e.numero
                })
            })
            select.push({
                'label': 'Todos',
                'value': 'todos',
                'key':  'todos'
            })
            setSelectToShow({...selectToShow,selectEstados:select})
        }
    },[estados])

    useEffect(()=>{
        //formatea las campañas para el select
        if(campanias.length!== 0){
            const select = campanias.map((c,index) => {
                return({
                    'label': c.campania,
                    'value': c.campania,
                    'key':  index
                })
            })
            select.push({
                'label': 'Todos',
                'value': 'todos',
                'key':  'todos'
            })
            setSelectToShow({...selectToShow,selectCampanias:select})
        }
    },[campanias])

    useEffect(()=>{
        getProductos()
        getVendedores()
        getEstados()
    },[])

    
    return(
        <div className="App">
            <BurgerMenu open={open} setOpen={setOpen}/>
            <NavMenu open={open} setOpen={setOpen} user={user} active={"campanias"} />

            <Wrapper>
                <Inner>
                <Grid colGap={21} rowGap={21}>
                    <Col desktop={12} spaced={true}>
                        <Titulo textColor={false}>Campañas</Titulo>
                    </Col>

                    <Col desktop={12}>
                        <TabsContainer>
                            <Tab selected={selectedTab === "resumen" ? true : false} onClick={()=> setSelectedTab('resumen')}>Resumen</Tab>
                            <Tab selected={selectedTab === "seguimiento" ? true : false} onClick={()=> setSelectedTab('seguimiento')}>Diario</Tab>
                            <Tab selected={selectedTab === "operaciones" ? true : false} onClick={()=> setSelectedTab('operaciones')}>Operaciones</Tab>
                        </TabsContainer>
                    </Col>


                {selectedTab === "resumen" ?
                    <Informe 
                        selectToShow={selectToShow}
                        setFiltro={setFiltro}
                        filtro={filtro}
                        error={error}
                        setError={setError}
                        btnDisabled={btnDisabled}
                        btnLimpiar={btnLimpiar}
                        setBtnDisabled={setBtnDisabled}
                        setBtnLimpiar={setBtnLimpiar}
                    />
                : null}

                {selectedTab === "seguimiento" ?
                    <Seguimiento
                        selectToShow={selectToShow}
                        setFiltro={setFiltro}
                        filtro={filtro}
                        error={error}
                        setError={setError}
                        btnDisabled={btnDisabled}
                        btnLimpiar={btnLimpiar}
                        setBtnDisabled={setBtnDisabled}
                        setBtnLimpiar={setBtnLimpiar}
                    />
                : null}

                {selectedTab === "operaciones"?
                    <Operaciones
                        selectToShow={selectToShow}
                        setFiltro={setFiltro}
                        filtro={filtro}
                        error={error}
                        setError={setError}
                        btnDisabled={btnDisabled}
                        btnLimpiar={btnLimpiar}
                        setBtnDisabled={setBtnDisabled}
                        setBtnLimpiar={setBtnLimpiar}
                    />
                : null}
                </Grid>
                </Inner>
            </Wrapper>

            
        </div>
    )
}



export default Campanias;

