import { useEffect, useState } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import { useLoading } from "../../../../hooks/useLoading";

export const useObjetivos = (mes) => {
  const [objetivos, setObjetivos] = useState({});
  const { loading, setLoading } = useLoading();

  // OBJETIVOS
  useEffect(() => {
    const getObjetivos = async () => {
      setLoading(true);
      if (mes) {
        try {
          setObjetivos([]);
          const objetivos = await axiosRequest.get(
            `/premios/objetivos/${parseInt(mes) + 1}`
          );
          setObjetivos(objetivos.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getObjetivos();
  }, [mes]);

  const refreshObjetivos = async () => {
    try {
      setLoading(true);
      setObjetivos([]);
      const objetivos = await axiosRequest.get(
        `/premios/objetivos/${parseInt(mes) + 1}`
      );
      setObjetivos(objetivos.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return {
    objetivos,
    refreshObjetivos,
    loading
  };
};
