import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Mensaje = styled.div`
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /*padding: 12px 26px;*/
  h6{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    color: ${leerColor(colores.negro)};
  }
  span{
    font-size: 12px;
    font-weight: 400;
    display: block;
    color: ${leerColor(colores.negro)};
  }
  p{
    font-size: 18px;
    font-weight: 600;
    margin: 12px 0;
    padding: 12px 20px;
    border-radius: 50px;
    width: fit-content;
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.gris)};
    color: ${leerColor(colores.grisEstados)};
    max-width: 500px;
    display: flex;
    align-items: center;
  }
  a{
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 4px 0;
    display: inline-block;
  }
  &.falta p{
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.gris)};
    color: ${leerColor(colores.grisEstados)};
  }
  &.rechazado p{
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.rojo)};
    color: ${leerColor(colores.rojo)};
  }
  &.emitida p{
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.verde)};
    color: ${leerColor(colores.verde)};
  }
  &.enviada p{
    background-color: #E5E5E5;
    border: 1px solid #E5E5E5;
    color: ${leerColor(colores.celeste)};
  }
  &.provisoria p{
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.celesteEstados)};
    color: ${leerColor(colores.celesteEstados)};
  }
  &.suscripcion p{
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.naranjaEstadosSuscripcion)};
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
  }

  .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  align-self: center;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${leerColor(colores.celeste)} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${(props) => props.direction === 'right' && css`
    align-self: flex-end;
    h6, span{
      text-align: right;
    }
  `}
  ${(props) => props.direction === 'left' && css`
    h6, span{
      text-align: left;
    }
  `}

  ${(props) => props.icon && css`
    p::before {
      content: url(${(props) => props.icon});
      padding-right: 16px;
      display: inline-block;
      //height: 20px;
      width: 20px;
    }
  `}
  
  ${(props) => props.reenviarMail === true && css`
    .reenviarMail{
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      color: ${leerColor(colores.negro)};
      .linksContainer{
        display: flex;
        width: 100%;
      }
      a{
        cursor: pointer;
        color: ${leerColor(colores.celeste)};
        font-size: 16px;
        margin:0 10px;
      }
      .active{
          color: ${leerColor(colores.celeste)};
        }
        .inactive{
          color: #7eb8d8;
        }
      a:hover{
        text-decoration: underline;
      }
      .mailAttachmentsVisible{
        width: 100%;
        display: flex;
        //justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        max-width:530px;
      }
      .inputsReenviarMailVisible{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 10px;
        height: fit-content;
        transition: height 0.25s ease-in;
        div{
          width: 100%;
          padding-bottom: 10px;
        }
        a{
          margin: 0;
          align-self: flex-end;
          text-decoration: none;
        }
      }
      .inputsReenviarMailInvisible{
        display: none;
        height: 0;
        transition: height 0.15s ease-out;
      }
      
    }
  `}
  ${(props) => props.reenviarMail === false && css `
    .reenviarMail{
      display: none;
    }
  `}
`;

export const Attachment = styled.img`
  width:70px;
  cursor: pointer;
  margin-top:10px;

  :hover{
    opacity:0.8;
  }
`
