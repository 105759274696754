import React, { useState, useEffect } from "react";
import {
  ThumbnailContainer,
  ThumbnailTextContainer,
  ArchivoCargado,
  ThumbnailImage,
  NombreArchivoCargado,
  Icon,
  IconContainer,
} from "./styled";
import deleteIcon from "../../assets/iconos/delete.svg";
import { FiTrash2 } from "react-icons/fi";
import useFiles from "../../hooks/useFiles";

const FileLoadedNew = ({
  file,
  filename,
  link,
  deleteFile,
  isLarger,
  inputName
}) => {

  const [thumbnail, setThumbnail] = useState(null)
  const [linkFile, setLinkFile] = useState(null)
  const { thumbnailImage, fileLink }= useFiles()

  useEffect(() => {
    if(file){
      const getThumbnail = async () => {
        const thumbnail = await thumbnailImage(file)
        const link = await fileLink(file) 
        setThumbnail(thumbnail)
        setLinkFile(link)
      }
      getThumbnail()
    }
  }, [file])

  function formatFileSize(bytes,decimalPoint) {
    if(bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <ThumbnailContainer isLarger={isLarger}>
      <div className="file-container">
        <div className="file">
          <a href={linkFile} target="_blank" rel="noreferrer">
            {typeof(thumbnail) === "string" ? <ThumbnailImage src={thumbnail} alt="" /> : <div className="icon">{thumbnail}</div>}
          </a>
        </div>
        <ThumbnailTextContainer isLarger={isLarger}>
          <ArchivoCargado key={`archivoInputFileRec`}>
            {filename}
          </ArchivoCargado>
          <NombreArchivoCargado isLarger={isLarger}>
            {typeof(file) === "string" ? "Link del archivo" : formatFileSize(file.image.size)}
          </NombreArchivoCargado>
        </ThumbnailTextContainer>
      </div>
      <IconContainer className="cross" isLarger={isLarger}>
        <span className="icon-borrar" onClick={() =>
            deleteFile(inputName)
          }>
          <FiTrash2 />
        </span>
        {/*
        <Icon
          onClick={() =>
            deleteFile(inputName)
          }
          src={deleteIcon}
          alt="icono_borrar_archivo"
        />
        */}
      </IconContainer>
    </ThumbnailContainer>
  );
};

export default FileLoadedNew;
