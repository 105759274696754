import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const Container = styled.div`
  .sin-solicitudes-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: ${({ disableBorder }) => disableBorder ? "" : "1px solid #eaeaea"};
    border-radius: 8px;
    box-sizing: border-box;
    padding: 60px 0;

    .icon-sin-solicitudes {
    font-size: 60px;
    color: ${leerColor(colores.azulRebranding)};
    display: flex;
  }


    h2 {
      color: ${leerColor(colores.negro)} !important;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: ${leerColor(colores.grisEstados)};
      font-size: 14px;
    }
  }
`