import { useState } from "react"

export default function useWizard () {

    // States
    const [stepSelected, setStepSelected] = useState(1)
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const previousStep = () => setStepSelected(prev => prev - 1)
    
    const nextStep = (e, validator) => {
        if(validator){
            const validateForm = validator(e)
            if(validateForm){
                setStepSelected(prev => prev + 1)
            }
        } else {
            setStepSelected(prev => prev + 1)
        }
    }

    const selectStep = (e, step, length, validator) => {
        const validateForm = validator(e)
        if((step !== length && validateForm) || (step !== length && step < stepSelected && !validateForm)) {
            setStepSelected(step)
        }
    }

    return { stepSelected, nextStep, previousStep, selectStep, submitDisabled, setSubmitDisabled }
    
}