import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  img {
    width: auto;
    max-width: 150px;
    width: auto;
    display: flex;
  }

  .logo-container {
    min-height: 35px;    
  }

  .limite-descripcion {
    color: ${leerColor(colores.grisEstados)};
    font-size: 14px;
  }

  .limite-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 6px;
    
    .divider {
      height: 1px;
      width: 100%;
      opacity: 0.2;
      margin: 0;
    }
  }


`;

