import { useOutsideClick } from "../../hooks/useClickOutside"
import { Container, AutocompleteSuggestion } from "./styled"

const Autocomplete = ({ autocomplete, autocompleteActive, setAutocomplete, cleanAutocomplete }) => {

    const ref = useOutsideClick(() => cleanAutocomplete([]))

    return (
        <Container ref={ref}>
            {autocomplete.map((value, index) => <AutocompleteSuggestion key={index} active={index === autocompleteActive} onClick={() => setAutocomplete(value)}>{value}</AutocompleteSuggestion>)}
        </Container>
    )
}

export default Autocomplete