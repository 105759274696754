import React from 'react';
import PropTypes from 'prop-types';

import { SubtituloNombre } from './styled';

function Subtitulo({ children, margin, className }) {
  return (
    <SubtituloNombre margin={margin} className={className} >
      {children}
    </SubtituloNombre>
  );
}

Subtitulo.propTypes = {
  children: PropTypes.any.isRequired
};

export default Subtitulo;
