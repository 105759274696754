import { useState } from "react"
import axiosRequest from "../utils/axiosConfig"
import useModal from "./useModal"
import { postMensajeHistorial } from "../services/Emision"
import dayjs from "dayjs"

export default function useHistorialNew(){
    const [solicitudData, setSolicitudData] = useState('')
    const [historial, setHistorial] = useState('')

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const { isModalOpen, handleModal } = useModal()

    const polizaEmail = async (data) => {
      if(data){
        try {
          const poliza = await axiosRequest.get(`/solicitud_poliza/${data.id}`)
          if(poliza){
            const id_mailgun = poliza.data.poliza_id_mail
            const estado_mail = await axiosRequest(`/mails_emision/${data.email}/${id_mailgun}`)
            if(poliza.data.estado_mail_poliza !== estado_mail.data.estado_email){
              switch(estado_mail.data.estado_email){
                case 1:
                  const entregado = {
                    id_solicitud_poliza: poliza.data.id_solicitud_poliza,
                    estado_email_poliza: estado_mail.data.estado_email
                  }
                  await axiosRequest.put(`/solicitudes_polizas/estado_email`, entregado)
                  const entregadoMensaje = {
                    id_solicitud: data.id,
                    id_area: 1,
                    id_operador: userInfo.id_operador,
                    id_accion_historial: 3,
                    fecha_solicitud_historial: dayjs(new Date()).format(
                      "DD/MM/YYYY HH:mm:ss"
                    ),
                    descripcion_solicitud_historial:
                      `El cliente recibió el email de la solicitud emitida.`,
                    eprevio_solicitud_historial: data.id_estado_previo,
                    estado_solicitud_historial: 1,
                    email_solicitudes_historial: 0,
                    id_historial_email: null,
                  };
                  postMensajeHistorial(entregadoMensaje);
                  break
                case 2:
                  const abierto = {
                    id_solicitud_poliza: poliza.data.id_solicitud_poliza,
                    estado_email_poliza: estado_mail.data.estado_email
                  }
                  await axiosRequest.put(`/solicitudes_polizas/estado_email`, abierto)
                  const abiertoMensaje = {
                    id_solicitud: data.id,
                    id_area: 1,
                    id_operador: userInfo.id_operador,
                    id_accion_historial: 3,
                    fecha_solicitud_historial: dayjs(new Date()).format(
                      "DD/MM/YYYY HH:mm:ss"
                    ),
                    descripcion_solicitud_historial:
                      `Email de póliza emitida abierto por el cliente.`,
                    eprevio_solicitud_historial: data.id_estado_previo,
                    estado_solicitud_historial: 1,
                    email_solicitudes_historial: 0,
                    id_historial_email: null,
                  };
                  postMensajeHistorial(abiertoMensaje);
                  break
                case 3:
                  const noEntregado = {
                    id_solicitud_poliza: poliza.data.id_solicitud_poliza,
                    estado_email_poliza: estado_mail.data.estado_email
                  }
                  await axiosRequest.put(`/solicitudes_polizas/estado_email`, noEntregado)
    
                  let mensaje = null
                  const NO_STORAGE_CODE = 452
                  const MAIL_DOESNT_EXIST_CODE = 550
                  if(estado_mail.data.codigo === NO_STORAGE_CODE){
                    mensaje = 'El cliente no pudo recibir el email con la póliza emitida. El correo electrónico indicado en la solicitud no tiene espacio disponible en su bandeja de entrada.'
                  } else if(estado_mail.data.codigo === MAIL_DOESNT_EXIST_CODE){
                    mensaje = 'El cliente no pudo recibir el email con la póliza emitida. El correo electrónico de la solicitud es inexistente.'
                  } else {
                    mensaje = 'El cliente no pudo recibir el email con la póliza emitida. Error al entregar el email.'
                  }
    
                  const noEntregadoMensaje = {
                    id_solicitud: data.id,
                    id_area: 1,
                    id_operador: userInfo.id_operador,
                    id_accion_historial: 3,
                    fecha_solicitud_historial: dayjs(new Date()).format(
                      "DD/MM/YYYY HH:mm:ss"
                    ),
                    descripcion_solicitud_historial: mensaje,
                    eprevio_solicitud_historial: data.id_estado_previo,
                    estado_solicitud_historial: 1,
                    email_solicitudes_historial: 0,
                    id_historial_email: null,
                  };
                  postMensajeHistorial(noEntregadoMensaje);
                  break
                default: 
                  break
              }
            }
          }
  
          /*
          if(!poliza?.data.poliza_enviada_mail){
            const statusDelivered = await axiosRequest(`/mails_entregados_emision/${data.email}`)
            const mailPolizaEntregado = statusDelivered.data.items.find(item => {
              const deliveryStatus = item["delivery-status"].code
              const successCode = 250
              const mensaje = item.message.headers
              return mensaje["message-id"] === id_mailgun && deliveryStatus === successCode
            })
            if(mailPolizaEntregado){
              const updatePoliza = {
                id_solicitud_poliza: poliza.data.id_solicitud_poliza,
                poliza_enviada_mail: 1
              }
              await axiosRequest.put(`/solicitudes_polizas/mail_entregado`, updatePoliza)
  
              const solicitudMessage = {
                id_solicitud: data.id,
                id_area: 1,
                id_operador: userInfo.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                descripcion_solicitud_historial:
                  `El cliente recibió el email de la solicitud emitida.`,
                eprevio_solicitud_historial: data.id_estado_previo,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null,
              };
              postMensajeHistorial(solicitudMessage);
            }
          } 
  
          if(!poliza?.data.poliza_abierta_mail){
            const statusOpened = await axiosRequest(`/mails_abiertos_emision/${data.email}/${id_mailgun}`)
            console.log(statusOpened)
            if(statusOpened.data?.items?.length > 0){
              const updatePoliza = {
                id_solicitud_poliza: poliza.data.id_solicitud_poliza,
                poliza_abierta_mail: 1
              }
              await axiosRequest.put(`/solicitudes_polizas/mail_abierto`, updatePoliza)
  
              const solicitudMessage = {
                id_solicitud: data.id,
                id_area: 1,
                id_operador: userInfo.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                descripcion_solicitud_historial:
                  `Email de póliza emitida abierto por el cliente.`,
                eprevio_solicitud_historial: data.id_estado_previo,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null,
              };
              postMensajeHistorial(solicitudMessage);
            }
          } 
            */
        } catch (error) {
          console.log(error)
        }
      }
    }

    const certificadoEmail = async (data) => {
      if(data){
        try {
          console.log("BUENAS")
          const certificado = await axiosRequest.get(`/solicitud_certificado/${data.id}`)
          console.log(certificado)
          console.log(certificado.data.estado_email_certificado)
          if(certificado){
            const id_mailgun = certificado.data.certificado_id_mail
            console.log(id_mailgun)
            console.log(data.email)
            const estado_mail = await axiosRequest(`/mails_emision/${data.email}/${id_mailgun}`)
            console.log(estado_mail.data.estado_email)
    
            if(certificado.data.estado_mail_certificado !== estado_mail.data.estado_email){
              switch(estado_mail.data.estado_email){
                case 1:
                  console.log(certificado.id_solicitud_certificado)
                  const entregado = {
                    id_solicitud_certificado: certificado.data.id_solicitud_certificado,
                    estado_email_certificado: estado_mail.data.estado_email
                  }
                  console.log("🍊🍊🍊🍊🍊 ENTREGADO ")
                  console.log(entregado)
                  await axiosRequest.put(`/solicitudes_certificados/estado_email`, entregado)
                  const entregadoMensaje = {
                    id_solicitud: data.id,
                    id_area: 1,
                    id_operador: userInfo.id_operador,
                    id_accion_historial: 3,
                    fecha_solicitud_historial: dayjs(new Date()).format(
                      "DD/MM/YYYY HH:mm:ss"
                    ),
                    descripcion_solicitud_historial:
                      `El cliente recibió el email con el certificado emitido.`,
                    eprevio_solicitud_historial: data.id_estado_previo,
                    estado_solicitud_historial: 1,
                    email_solicitudes_historial: 0,
                    id_historial_email: null,
                  };
                  postMensajeHistorial(entregadoMensaje);
                  break
                case 2:
                  const abierto = {
                    id_solicitud_certificado: certificado.data.id_solicitud_certificado,
                    estado_email_certificado: estado_mail.data.estado_email
                  }
                  await axiosRequest.put(`/solicitudes_certificados/estado_email`, abierto)
                  const abiertoMensaje = {
                    id_solicitud: data.id,
                    id_area: 1,
                    id_operador: userInfo.id_operador,
                    id_accion_historial: 3,
                    fecha_solicitud_historial: dayjs(new Date()).format(
                      "DD/MM/YYYY HH:mm:ss"
                    ),
                    descripcion_solicitud_historial:
                      `Email de certificado emitido abierto por el cliente.`,
                    eprevio_solicitud_historial: data.id_estado_previo,
                    estado_solicitud_historial: 1,
                    email_solicitudes_historial: 0,
                    id_historial_email: null,
                  };
                  postMensajeHistorial(abiertoMensaje);
                  break
                case 3:
                  const noEntregado = {
                    id_solicitud_certificado: certificado.data.id_solicitud_certificado,
                    estado_email_certificado: estado_mail.data.estado_email
                  }
                  await axiosRequest.put(`/solicitudes_certificados/estado_email`, noEntregado)
    
                  let mensaje = null
                  const NO_STORAGE_CODE = 452
                  const MAIL_DOESNT_EXIST_CODE = 550
                  if(estado_mail.data.codigo === NO_STORAGE_CODE){
                    mensaje = 'El cliente no pudo recibir el email con el certificado emitido. El correo electrónico indicado en la solicitud no tiene espacio disponible en su bandeja de entrada.'
                  } else if(estado_mail.data.codigo === MAIL_DOESNT_EXIST_CODE){
                    mensaje = 'El cliente no pudo recibir el email con el certificado emitido. El correo electrónico de la solicitud es inexistente.'
                  } else {
                    mensaje = 'El cliente no pudo recibir el email con el certificado emitido. Error al entregar el email.'
                  }
    
                  const noEntregadoMensaje = {
                    id_solicitud: data.id,
                    id_area: 1,
                    id_operador: userInfo.id_operador,
                    id_accion_historial: 3,
                    fecha_solicitud_historial: dayjs(new Date()).format(
                      "DD/MM/YYYY HH:mm:ss"
                    ),
                    descripcion_solicitud_historial: mensaje,
                    eprevio_solicitud_historial: data.id_estado_previo,
                    estado_solicitud_historial: 1,
                    email_solicitudes_historial: 0,
                    id_historial_email: null,
                  };
                  postMensajeHistorial(noEntregadoMensaje);
                  break
                default: 
                  break
              }
            }
          }

          /*
          if(!poliza?.data.poliza_enviada_mail){
            const statusDelivered = await axiosRequest(`/mails_entregados_emision/${data.email}`)
            const mailPolizaEntregado = statusDelivered.data.items.find(item => {
              const deliveryStatus = item["delivery-status"].code
              const successCode = 250
              const mensaje = item.message.headers
              return mensaje["message-id"] === id_mailgun && deliveryStatus === successCode
            })
            if(mailPolizaEntregado){
              const updatePoliza = {
                id_solicitud_poliza: poliza.data.id_solicitud_poliza,
                poliza_enviada_mail: 1
              }
              await axiosRequest.put(`/solicitudes_polizas/mail_entregado`, updatePoliza)
  
              const solicitudMessage = {
                id_solicitud: data.id,
                id_area: 1,
                id_operador: userInfo.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                descripcion_solicitud_historial:
                  `El cliente recibió el email de la solicitud emitida.`,
                eprevio_solicitud_historial: data.id_estado_previo,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null,
              };
              postMensajeHistorial(solicitudMessage);
            }
          } 
  
          if(!poliza?.data.poliza_abierta_mail){
            const statusOpened = await axiosRequest(`/mails_abiertos_emision/${data.email}/${id_mailgun}`)
            console.log(statusOpened)
            if(statusOpened.data?.items?.length > 0){
              const updatePoliza = {
                id_solicitud_poliza: poliza.data.id_solicitud_poliza,
                poliza_abierta_mail: 1
              }
              await axiosRequest.put(`/solicitudes_polizas/mail_abierto`, updatePoliza)
  
              const solicitudMessage = {
                id_solicitud: data.id,
                id_area: 1,
                id_operador: userInfo.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                descripcion_solicitud_historial:
                  `Email de póliza emitida abierto por el cliente.`,
                eprevio_solicitud_historial: data.id_estado_previo,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null,
              };
              postMensajeHistorial(solicitudMessage);
            }
          } 
            */
        } catch (error) {
          console.log(error)
        }
      }

    }
 
    const handleModalHistorial = async (data) => {
        handleModal()
        setSolicitudData('')
        try{
          console.log(data)
          if(data.id_estado === 2){
            await polizaEmail(data)
          }
          if(data.id_estado === 4){
            await certificadoEmail(data)
          }
          const historial = await axiosRequest.get(`/solicitudes_historial/${data.id}`)
          const orderHistorial = historial.data.sort(function(a, b){return a.id_solicitud_historial-b.id_solicitud_historial});
          setHistorial(orderHistorial)
          setSolicitudData(data)
        } catch (error){
          console.log(error)
        }
    }

    return {
        historial,
        isModalOpen,
        handleModal,
        handleModalHistorial,
        solicitudData,
    }
}