// Components
import Modal from "../../../Modal";
import { ModalRechazoContainer } from "../../../../pages/styled";
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import InputBasic from "../../../InputBasic";
import InputCalendar from "../../../InputCalendar";
import InputTextarea from "../../../InputTextarea";
import InputSelect from "../../../InputSelect";
import InputFileRec from "../../../InputFileRec";
import Button from "../../../Button";
import FileLoaded from "../../../FileLoaded";
import InputFileButton from "../../../InputFileButton";
import NewInputSelect from "../../../NewInputSelect"
import NewInputTextarea from "../../../NewInputTextarea"
// Hooks
import { useForm } from "../../../../hooks/useForm";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import useButtonMessage from "../../../../hooks/useButtonMessage";
import useFiles from "../../../../hooks/useFiles";
// Utils
import { modalRechazoReglas, objetivosPremiosReglas } from "../../../../utils/formRules";
import { modalRechazoForm, objetivosPremiosCsvForm } from "../../../../utils/formInitialValues";
// Services
import {
  getPolizaInformation,
  getProductoSiniestroId,
} from "../../../../services/Siniestros";
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";
import InputFile from "../../../InputFile";
import { useEffect, useState } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import { meses } from "../../../../constants/data";
import NewTabs from "../../../NewTabs";
import { useTabs } from "../../../../hooks/useTabs";
import { CSVLink } from "react-csv";
import { FiDownload, FiFile } from "react-icons/fi";

const ModalCargarObjetivosPremios = ({
  modal,
  refreshData
}) => {
  const exitoModal = useModal();
  const { isModalOpen, handleModal } = modal;
  const [exampleCSVFile, setExampleCSVFile] = useState([])

  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
  } = useForm(handleSubmit, objetivosPremiosReglas, objetivosPremiosCsvForm);
  const { loading, setLoading, loadingText, changeLoadingText } =
  useLoading(false);

  const headers = [
    { label: "Vendedor", key: "vendedor" },
    { label: "ID Vendedor", key: "id_vendedor" },
    { label: "Mes", key: "mes" },
    { label: "Operaciones", key: "operaciones" },
    { label: "Prima", key: "prima" }
  ];

  useEffect(() => {
    const getVendedores = async () => {
        try {
          const vendedores = await axiosRequest.get(
            `/premios/vendedores`
          );
          const formatVendedores = vendedores.data.map(item => {
            return {
              vendedor: item.nombre,
              id_vendedor: item.numero,
              mes: new Date().getMonth() + 1,
              operaciones: 0,
              prima: 0
            }
          })
          console.log(vendedores.data)
          setExampleCSVFile(formatVendedores)
        } catch (error) {
          console.log(error);
        }
      }
      getVendedores();
  }, [])

  async function handleSubmit() {
    setLoading(true);

    const file = inputValues.objetivos.image;
    const reader = new FileReader();

    reader.onload = async function(e) {
        const text = e.target.result;
        const content = text.slice(44, text.lastIndexOf(text.slice(-1))).split(/\r\n|\n/)
        console.log(content)

        const objetivos = content.map(obj => {
            const values = obj.split(",")
            console.log(values)

            return {
                id_vendedor: values[1],
                mes: values[2],
                operaciones: values[3],
                prima: values[4]
            }
        })

        if(objetivos.length > 0){
            const obj = await axiosRequest.post(`/premios/objetivos/csv`, objetivos)
            if(obj.status === 200){
              setLoading(false);
              handleModal()
              refreshData()
            }
        }
    }
    reader.readAsText(file);
  }

  return (
    <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
          <NewTitle>
            Cargar objetivos
          </NewTitle>
          </div>
          <hr />
          

          <div className="data-container">
          <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={12}>
                  <CSVLink data={exampleCSVFile} headers={headers} className="csv-link" filename="csv-objetivos">
                    <div className="csv-link-container">
                      <div className="csv-info-container">
                        <span className="icon-file"><FiFile /></span> 
                        <div className="info-container">
                          <h2 className="csv-link-title">Archivo CSV de objetivos</h2>
                          <span>Descargá el archivo para completar los objetivos</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="icon"><FiDownload /></span> 
                    </div>
                  </CSVLink>
                  </Col>
                  <Col desktop={12}>
                    <InputFile
                      name="objetivos"
                      text="Adjuntar archivo .csv de objetivos"
                      labelName="Objetivos del mes"
                      accept=".csv"
                      onChange={handleChangeExternal}
                      file={inputValues.objetivos}
                    />
                    {formErrors.objetivos && <ErrorInput>{formErrors.objetivos}</ErrorInput>}
                  </Col>
                  
                </Grid>
          </div>
          <hr />
          <div className={"actionButtonsCont"}>
            <div className={"buttonsCont"}>
              <NewButton
                onClick={handleModal}
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
              >
                Cancelar
              </NewButton>
              <NewButton
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={handleValidation}
              >
                {loading ? "Cargando objetivos..." : "Cargar objetivos"}
              </NewButton>
            </div>
          </div>
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalCargarObjetivosPremios;
