import axiosRequest from "../utils/axiosConfig";

// Usuarios
export const iniciarSesion = async (credenciales) => {
   return await axiosRequest.post('/login', credenciales)
}

// Notificaciones
export const obtenerNotificaciones = async (id_usuario) => {
   const notificaciones = await axiosRequest.get(`/notificaciones/${id_usuario}`)
   return notificaciones.data
}
