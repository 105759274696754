import styled from "styled-components";
import { breakpoints } from "../../constants";

export const WrapperContainer = styled.div`
  width: 1140px;
  margin: 75px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
`;
