import styled from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

const Container = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${(props) => props.colGap || 20}px;
  grid-row-gap: ${(props) => props.rowGap || 40}px;
  width: 100%;
  max-width: 100%;
  margin: ${(props) => (props.narrow ? "0 auto" : "")};
  justify-items: ${(props) => (props.centerItems ? "center" : "")};
`;

const Frame = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 200px;
  background-color: ${leerColor(colores.gris)};
  border: solid 1px #CCC;
  transition: border 0.5s;
  &:hover{
    border: solid 1px #999;
  }
  & img{
    max-width: 100%;
    max-height:100%;
  }
  & a{
    opacity:0;
    color: ${leerColor(colores.blanco)};
    border-radius: 20px;
    padding: 10px 20px;
    transition: opacity 0.5s, background-color 0.3s;;
  }
  & a:hover{
    background-color: ${leerColor(colores.celeste)};
  }
  & a:first-child:active{
    background-color: ${leerColor(colores.celesteEstados)};
  }
  &:hover a{
    opacity:1;
  }

  @keyframes changeback {
    from {
        background-color: ${leerColor(colores.grisNot)};
    }
  
    to {
        background-color: ${leerColor(colores.gris)};
    }
  }


  & div.loading{
    background-color: ${leerColor(colores.celeste)};
    position:absolute;
    width: 100%;
    height: 100%;
    text-align:center;
    animation-duration: 0.8s;
    animation-name: changeback;
    animation-iteration-count: infinite;
    animation-direction: ease-in-out;
  }
  display: ${(props) => (props.spaced ? "flex" : "")};
  align-items: ${(props) => (props.spaced ? "center" : "")};
  justify-content: ${(props) => (props.spaced ? "space-between" : "")};
  position: relative;
  .buttonsContainer{
    a{
      margin-left:10px;
    }
  }
`;

const BtnFrame = styled.div`
//display: inline-grid;

width: 100%;
height: 25px;
position: absolute;
top: 160px;
left: 7px;


`;

const DivDetailsImg = styled.div`
//display: inline-grid;
width: 100%;
height: 50%;

& .description{
  text-align: left;
  display: inline-block;
  margin-right: 15px;
  margin-top:30px;
  vertical-align:top;
}

& img{
    max-width: 40%;
    max-height:300px;
    box-shadow: #333 2px 2px 15px;
  }
}

`;

export { Container, Frame, BtnFrame, DivDetailsImg };

