import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow-y: scroll;
  max-height: 600px;
  padding-right: 30px;
  background-color: ${leerColor(colores.blanco)};
  padding: 40px 70px;
  width:100%;
  border-radius: 8px;
  overflow-x: hidden;
  gap: 20px;

  .fecha-contratacion {
    color: ${leerColor(colores.gris)}
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 40px 0 20px 0;
  }

  svg{
    width:40px;
    margin-bottom:10px;
    display: flex;
  }

  span{
    font-size:16px;
    font-weight: 500;
    //color: ${leerColor(colores.gris)};
    color: rgb(82, 82, 82);
  }

  .estadisticas-formato{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #ececec;
    padding:40px 0;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.3s;

    :hover{
      opacity: 0.7;
    }
  }

  .selected-formato{
    border: 1px solid ${leerColor(colores.celeste)} !important;
    
    span{
      font-weight: 800;
      color: ${leerColor(colores.celeste)};
    }
  }

  h2{
    text-align: center;
    font-weight: 600;
    //color: ${leerColor(colores.azul)};
  }

  .buttonsCont{
    display: flex;
    justify-content: center;
    width:100%;
  }

  .buttonsCont a {
    margin-left: 25px;
  }

  .excepcionData{
    display: flex;
    margin:10px 0 40px 0;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;

    p{
      margin:5px;
    }
  }

  .data{
    font-weight:600;
    color: ${leerColor(colores.celeste)};
  }

  h4{
    font-weight:600;
    color: ${leerColor(colores.azul)};
    margin:10px 0;
    font-family: 'DM Sans', sans-serif;

  }

  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid #eaeaea;
  }

  a {
    text-decoration: none;

    h4 {
      font-size: 14px;
    }
  }
`;

export const ArchivosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 60px;

  a {
    text-decoration: none;
  }

  .archivo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 10px;

    span {
      font-size: 12px;
    }

    :hover {
      opacity: 0.9;
    }
  }
`
