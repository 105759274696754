import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container } from "./styled";
import {
  FiArrowLeft,
  FiEdit3,
  FiFile,
  FiPlus,
  FiRefreshCcw,
  FiShare,
  FiUploadCloud,
  FiX,
} from "react-icons/fi";
import TitlePageSection from "../../../components/TitlePageSection";
import { useForm } from "../../../hooks/useForm";
import {
  cargarSiniestrosReglas,
  subirRechazoManualReglas,
} from "../../../utils/formRules";
import NewButton from "../../../components/NewButton";
import {
  cargarSiniestrosForm,
  subirRechazoManualForm,
} from "../../../utils/formInitialValues";
import useSiniestros from "../../../hooks/useSiniestros";
import {
  getPolizaInformation,
  getProductoSiniestroId,
} from "../../../services/Siniestros";
import { useLoading } from "../../../hooks/useLoading";
import BackButton from "../../../components/BackButton";
import CrearSiniestroForm from "../../../components/SiniestrosForms/CrearSiniestro";
import Divider from "../../../components/Divider";
import { useTabs } from "../../../hooks/useTabs.js";
import NewTabs from "../../../components/NewTabs/index.jsx";
import NewInputSelect from "../../../components/NewInputSelect/index.jsx";
import { useEffect, useState } from "react";
import {
  getCompanias,
  getEstados,
  getInforPolizas,
  sendData,
  updateMultipleRejects,
  sendMailManually
} from "../../../services/Rechazos.js";
import { Col, Grid } from "../../../components/Grid/index.js";
import Subtitle from "../../../components/Subtitle/index.jsx";
import InputFile from "../../../components/InputFile/index.js";
import formaterObject from "../../../functions/formaterJson.js";
import useModal from "../../../hooks/useModal.js";
import ModalCargarRechazos from "../../../components/Modals/Rechazos/ModalCargarRechazos/index.js";
import { useAuthContext } from "../../../context/AuthContext.js";
import NewInputBasic from "../../../components/NewInputBasic";
import * as XLSX from "xlsx";
import ModalCargarRechazos2 from "../../../components/Modals/Rechazos/ModalCargarRechazos2/index.js";
import ErrorInput from "../../../components/ErrorInput/index.js";
import { subirRechazoManualUpdateReglas } from "../../../utils/formRules";
import { subirRechazoManualUpdateForm } from "../../../utils/formInitialValues";

const GestionRechazos = () => {
  const { tabSelected, tabName, handleTab } = useTabs();
  const { authState } = useAuthContext();
  const { userInfo } = authState;
  const { loading, setLoading, loadingText, changeLoadingText } = useLoading();
  const modalCarga = useModal();

  const [companias, setCompanias] = useState([]); //metadatos
  const [estados, setEstados] = useState([]); //metadatos
  const [selection, setSelection] = useState([]); // rechazos cargados en con check
  const [sections, setSections] = useState([]); // el back envia la respuesta por secciones 'nuevos', 'no encontrados' etc
  const [idCompania, setIdCompania] = useState(null);
  const [idNuevoEstado, setIdNuevoEstado] = useState(null);
  const [medioContacto, setmedioContacto] = useState(null);
  const [idFormato, setIdFormato] = useState(1);
  const [lengthFile, setLengthFile] = useState(0);
  const [errorFormatingRows, setErrorsFormatingRows] = useState([]);
  const [modo, setModo] = useState(null);
  const [fileDataToSend, setFileDataToSend] = useState([]);
  const [errorGeneral, setErrorGeneral] = useState(null);
  const [success, setSuccess] = useState(false);

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleValidationAsync,
    formErrors,
    resetForm,
  } = useForm(handleSubmit, null, cargarSiniestrosForm);

  const formManual = useForm(
    handleSubmitRechazoManual,
    subirRechazoManualReglas,
    subirRechazoManualForm
  );

  let {
    inputValues: inputValuesUpdate, 
    handleChange: handleChangeUpdate, 
    handleValidation: handleValidationUpdate, 
    formErrors: formErrorsUpdate,
    resetForm: resetFormUpdate
  } = useForm(
    sendDataUpdateSelected,
    subirRechazoManualUpdateReglas,
    subirRechazoManualUpdateForm
  );


  async function handleSubmitRechazoManual() {
    setIdCompania(parseInt(formManual.inputValues.id_compania))
    precargarFormulario();
  }

  // no está usando el input del formulario
  async function handleSubmit() {
    console.error("procesando");
  }

  const formatos = [
    {
      label: "General",
      value: 1,
      key: 1,
    },
    //POR EL MOMENTO NO SON NECESARIAS LAS DEMAS OPCIONES.
    // {
    //   label: "FedPat-CBU",
    //   value: 2,
    //   key: 2,
    // },
    // {
    //   label: "FedPat-Efectivo",
    //   value: 3,
    //   key: 3,
    // },
    // {
    //   label: "Prudencia",
    //   value: 4,
    //   key: 4,
    // },
  ];

  const [inputsManual, setInputsManual] = useState({
    poliza: "",
    importe: "",
    motivo: "",
    comentario: "",
  });

  let tabs = [
    {
      name: "Por Archivo",
    },
    {
      name: "Manual",
    },
  ];

  ///////////////////////////////////////////////////////////// FUNCIONES

  // Maneja los imputs poliza e importe -> se puede cambiar al form completo
  const handleChangeManual = (event) => {
    const { name, value } = event.target;
    setInputsManual({
      ...inputsManual,
      [name]: value,
    });
  };

  // confierte un archivo en un array de objetos para ingrear al crm
  // { poliza,importe,motivo,comentario }
  const readFileToArray = (fileinput, formato) => {
    if (fileinput) {
      let fileInCSV = null;
      const reader = new FileReader();
      reader.onload = function (e) {
        if (formato !== "GENERICO") {
          // si se selecciona un formato -> pasar el xlsx primero a csv
          fileInCSV = fileinput;
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          // Selecciona la primera hoja
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          // Convierte la hoja a CSV
          fileInCSV = XLSX.utils.sheet_to_csv(worksheet, {
            raw: false,
            defval: null,
            FS: ";",
          });
        } else {
          fileInCSV = reader.result;
        }
        const { formated, errores, length, incorrectFormat } = formaterObject(
          fileInCSV,
          formato
        );

        setErrorGeneral(incorrectFormat);
        setErrorsFormatingRows(errores || []);
        setFileDataToSend(formated || []);
        setLengthFile(length);
      };

      // si es generico ya lo lee como csv
      formato === "GENERICO"
        ? reader.readAsText(fileinput)
        : reader.readAsArrayBuffer(fileinput);
    }
  };

  const preCargarCsv = async () => {
    changeLoadingText("Obteniendo datos...");
    setLoading(true);
    modalCarga.handleModal();
    setModo("create");
    try {
      // pide la data de las polizas
      await setNewArrayRechazos();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const preCargarCsvToUpdate = async () => {
    changeLoadingText("Obteniendo datos...");
    setLoading(true);
    modalCarga.handleModal();
    setModo("update");
    try {
      // pide la data de las polizas
      await setNewArrayRechazos({ toUpdate: true });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendDataSelected = async (tipo) => {
    setLoading(true);
    try {
      if (tipo === "cargaRechazos") {
        if (selection.length === 1) {
          const newRechazo = selection[0];
          const res = await sendData(
            [
              {
                ...newRechazo,
                motivo: inputsManual.motivo,
                comentario: inputsManual.comentario,
              },
            ],
            userInfo.id_usuario
          );
        } else {
          const res = await sendData(selection, userInfo.id_usuario);
        }
        // RENDERIZAR RESPUESTA
        //console.error('RESP CARGA FORM Y MASIVA', res)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setSelection([]);
      setFileDataToSend([]);
      setIdCompania(null);
      setInputsManual({ poliza: "", importe: "", comentario: "", motivo: "" });
      resetForm();
      setErrorGeneral(null);
      setSuccess(true);
      setmedioContacto("");
      //modalCarga.handleModal() // cierra y muestra el resumen
    }
  };

  async function sendDataUpdateSelected(){
    setLoading(true);
    try {
        if( inputValuesUpdate.idNuevoEstado == 11 && inputValuesUpdate.medioContacto==='email') {
          // si es notificacion por mail -> notifica masivamente
          let rechazos = selection.filter(rej => rej.checked)
          rechazos = rechazos.map(rej => {
            rej.id = rej.id_reject_prev;
            return rej;
          })
          // la notifacion masiva automaticamente cambia el estado
          const result = await sendMailManually( rechazos, userInfo.id_usuario)
        } else {
          const res = await updateMultipleRejects(
            {
              rejects: selection,
              id_compania: idCompania,
              new_state: inputValuesUpdate.idNuevoEstado,
              via_contacto: inputValuesUpdate.medioContacto,
            },
            userInfo.id_usuario
          );
          // RENDERIZAR RESPUESTA
          //console.error('RESP UPDATE',res)
        }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setSelection([]);
      setFileDataToSend([]);
      setIdCompania(null);
      resetForm();
      setErrorGeneral(null);
      setSuccess(true);
      setmedioContacto("");
      resetFormUpdate()
      //modalCarga.handleModal() // cierra y muestra el resumen
    }
  };

  const precargarFormulario = async () => {
    setIdCompania(parseInt(formManual.inputValues.id_compania))
    setSelection([]);
    changeLoadingText("Obteniendo datos...");
    setLoading(true);
    setModo("create");
    modalCarga.handleModal();
    try {
      await setNewArrayRechazos();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setNewArrayRechazos = async (toUpdate) => {
    // quita las polizas repetidas
    let polizas = [...new Set(fileDataToSend.map((d) => parseInt(d.poliza)))];
    try {
      setLoading(true);
      // trae los datos asociados a los numeros de polizas ingresados por csv
      const { data } = await getInforPolizas(polizas, idCompania, toUpdate);
      const { data: secciones } = data;

      setSections(secciones);

      for (const listas in secciones) {
        if (listas) {
          let formated = {};
          let temp = [];
          secciones[listas].data.forEach((row) => {
            const match = fileDataToSend.find(
              (d) => parseInt(d.poliza) === parseInt(row.poliza)
            );
            // const {nombre, poliza, mail, id_compania, id_seccion, respuesta,detalle, producto, telefono, dni, id, estado_anterior} = row;
            formated = {
              ...row,
              importe_nuevo: row.importe_anterior? match.importe : null,
              importe: row.importe_anterior? row.importe_anterior + match.importe : match.importe, // importe total si hay un acumulado
              medio_pago: row.medio_pago || "efectivo",
              id_reject: row.id || null,
              checked: secciones[listas].checkeable,
            };


            formated.importe = row.importe? row.importe : formated.importe;

            if (
              !match.importe ||
              match.importe === "" ||
              isNaN(match.importe) ||
              match.importe === 0
            ) {
              formated.checked = false;
              formated.respuesta = "el importe es un campo requerido";
              if (secciones[listas].data.length === 1) {
                secciones[listas].descripcion =
                  "El importe es un campo requerido";
              }
            }

            temp.push(formated);
            setSelection((prev) => [...prev, formated]);
          });
          secciones[listas].data = temp;
        }
      }

      // setInformeGeneralCarga(res.data) -> mostrar datos
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = (poliza) => {
    let selectAux = [...selection];
    for (const aux of selectAux) {
      if (aux.poliza === poliza) aux.checked = !aux.checked;
    }
    setSelection(selectAux);
  };

  const tableCheck = (e, data) => {
    let selectAux = [...selection];
    if (e.target.checked) {
      // si se selecciona
      for (const itemData of data) {
        for (const aux of selectAux) {
          if (aux.poliza === itemData.poliza) {
            aux.checked = true;
          }
        }
      }
    } else {
      // si se deselecciona
      for (const itemData of data) {
        for (const aux of selectAux) {
          if (aux.poliza === itemData.poliza) {
            aux.checked = false;
          }
        }
      }
    }
    setSelection(selectAux);
  };

  useEffect(() => {
    if (!modalCarga.isModalOpen) {
      setModo("");
    }
  }, [modalCarga]);

  const closeModal = () => {
    setModo("");
    modalCarga.setIsModalOpen(false);
    setSuccess(false);
  };


  /////////////////////////////////////////////////////////// EFECTOS

  // formulario manual para la carga de un rechazo
  // reemplazar por el hook de un formulario
  useEffect(() => {
    if (formManual.inputValues.poliza && formManual.inputValues.importe) {
      setFileDataToSend([
        {
          poliza: parseInt(formManual.inputValues.poliza),
          importe: parseFloat(formManual.inputValues.importe),
        },
      ]);
    }
  }, [formManual.inputValues.poliza, formManual.inputValues.importe]);


  // al cargar la vista trea las companias y estados
  useEffect(async () => {
    const companias = await getCompanias();
    const estados = await getEstados();
    setCompanias(companias);
    setEstados(estados);
  }, []);

  // cuando se selecciona un archivo y se cambia el formato en el select
  useEffect(() => {
    setErrorsFormatingRows([]);
    setFileDataToSend([]);
    setErrorGeneral(null);
    setSelection([]);
    if (inputValues?.file?.image) {
      if (parseInt(idFormato) === 1) {
        if (inputValues?.file?.image.name.includes(".csv")) {
          readFileToArray(inputValues?.file.image, "GENERICO");
        } else {
          setErrorGeneral("Formato incorrecto");
        }
      }
      // NO ES NECESARIO POR EL MOMENTO
      if (parseInt(idFormato) === 2) {
        readFileToArray(inputValues?.file.image, "FEDPAT");
      }
      if (parseInt(idFormato) === 3) {
        readFileToArray(inputValues?.file.image, "FEDPAT_EFECTIVO");
      }
      if (parseInt(idFormato) === 4) {
        readFileToArray(inputValues?.file.image, "PRUDENCIA");
      }
    }
  }, [inputValues, idFormato]);

  useEffect(() => {
    if (!modalCarga.isModalOpen) {
      // si se cierra el modal se quita la seleccion
      setSelection([]);
      setModo(null);
      resetFormUpdate()
    }
  }, [modalCarga.isModalOpen]);

  return (
    <div>
      <div>
        {modalCarga.isModalOpen ? (
          <ModalCargarRechazos2
            isModalOpen={modalCarga.isModalOpen}
            handleModal={modalCarga.handleModal}
            setIsModalOpen={modalCarga.setIsModalOpen}
            modal={modalCarga}
            loading={loading}
            selection={selection}
            setSelection={setSelection}
            loadingText={loadingText}
            sections={sections}
            tableCheck={tableCheck}
            handleCheck={handleCheck}
            sendData={sendDataSelected}
            modo={modo}
            estados={estados}
            setIdNuevoEstado={setIdNuevoEstado}
            setmedioContacto={setmedioContacto}
            success={success}
            closeModal={closeModal}
            medioContacto={medioContacto}
            idNuevoEstado={idNuevoEstado}
            sendDataUpdateSelected={sendDataUpdateSelected}
            handleChangeUpdate={handleChangeUpdate}
            handleValidationUpdate={handleValidationUpdate}
            formErrorsUpdate={formErrorsUpdate}
            inputValuesUpdate={inputValuesUpdate}
          />
        ) : null}
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
                <BackButton url={"/Rechazos2"} text="Cobranzas" />
                <TitlePageSection
                  title={`Gestionar Cobranzas`}
                  description={`Alta y actualización`}
                ></TitlePageSection>
              </div>
              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />
              {tabSelected === 0 && (
                <>
                  <Subtitle
                    icon={<FiFile />}
                    title={`Carga de archivo`}
                    description={`Cargue el archivo para continuar`}
                    disableDivider={true}
                  />
                  <Divider />
                  {!inputValues.file && (
                    <Grid colGap={21} rowGap={21} narrow={true}>
                      <Col desktop={12}>
                        <InputFile
                          type={"file"}
                          name={"file"}
                          text={"Seleccione un archivo para comenzar"}
                          labelName={""}
                          required={true}
                          multiple={false}
                          file={inputValues.file}
                          onChange={handleChangeExternal}
                        />
                      </Col>
                    </Grid>
                  )}
                  {inputValues.file && (
                    <>
                      <Subtitle
                        icon={<FiFile />}
                        title={inputValues.file.name}
                        description={`Seleccione la compañía con la que desea trabajar`}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>

                        <Col desktop={3}>
                          <NewInputSelect
                            type={"text"}
                            name={"id_compania"}
                            labelName={"Compañia"}
                            id={"compania"}
                            options={companias}
                            placeholder={"Compañia"}
                            onChange={(e) => setIdCompania(e.target.value)}
                          />
                        </Col>
                        <Col desktop={12}>
                          {errorGeneral && <p>{errorGeneral}</p>}
                          {lengthFile > 0 && (
                            <p>Se detectaron {lengthFile} registros</p>
                          )}
                          {errorFormatingRows &&
                            errorFormatingRows.length > 0 && (
                              <div className="errores-container">
                                <span className="errores-titulo">
                                  <span className="icon">
                                    <FiX></FiX>
                                  </span>
                                  {errorFormatingRows.length > 1
                                    ? "Se detectaron errores en el archivo subido"
                                    : "Se detecto un error en el archivo cargado"}
                                  :
                                </span>
                                <p>
                                  <strong>
                                    {errorFormatingRows[0].error}.
                                  </strong>
                                </p>
                                {/* <div>
                            {
                              errorFormatingRows.map( (e, index) => <p><span className="icon"><FiX></FiX></span>{e.error} </p>)
                            }
                          </div> */}
                              </div>
                            )}
                        </Col>
                      </Grid>
                      <Divider />
                      <Subtitle
                        icon={<FiShare />}
                        title={"Opciones"}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        {idCompania && fileDataToSend?.length > 0 && (
                          <>
                            <Col desktop={2}>
                              <NewButton
                                backgroundColor={true}
                                onClick={preCargarCsv}
                                disabled={loading}
                              >
                                <FiUploadCloud /> Dar de alta
                              </NewButton>
                            </Col>
                            <Col desktop={2}>
                              <NewButton
                                backgroundColor={true}
                                onClick={preCargarCsvToUpdate}
                                disabled={loading}
                              >
                                <FiEdit3 /> Actualizar
                              </NewButton>
                            </Col>
                          </>
                        )}
                        <Col desktop={3}>
                          <NewButton
                            backgroundColor={false}
                            textColor={true}
                            borderColor={true}
                            onClick={resetForm}
                            disabled={loading}
                          >
                            <FiRefreshCcw /> Cambiar de archivo
                          </NewButton>
                        </Col>
                      </Grid>
                    </>
                  )}
                </>
              )}
              {tabSelected === 1 && (
                <>
                  <Subtitle
                    icon={<FiFile />}
                    title={"Formulario"}
                    description={`Ingresar un nuevo rechazo de forma manual`}
                    disableDivider={true}
                  />
                  <Grid colGap={21} rowGap={21} narrow={true}>
                    <Col desktop={3}>
                      <NewInputBasic
                        type={"text"}
                        name={"poliza"}
                        labelName={"Póliza"}
                        placeholder={"1234"}
                        id={"poliza"}
                        onChange={formManual.handleChange}
                        value={formManual.inputValues.poliza}
                        required
                      />
                      {formManual.formErrors.poliza ? (
                        <ErrorInput>{formManual.formErrors.poliza}</ErrorInput>
                      ) : null}
                    </Col>
                    <Col desktop={3}>
                      <NewInputBasic
                        type={"number"}
                        name={"importe"}
                        labelName={"Importe"}
                        placeholder={"1234"}
                        id={"importe"}
                        onChange={formManual.handleChange}
                        value={formManual.inputValues.importe}
                      />
                      {formManual.formErrors.importe ? (
                        <ErrorInput>{formManual.formErrors.importe}</ErrorInput>
                      ) : null}
                    </Col>
                    <Col desktop={3}>
                      <NewInputBasic
                        type={"text"}
                        name={"motivo"}
                        labelName={"Motivo"}
                        placeholder={"Falta de pago"}
                        id={"motivo"}
                        onChange={formManual.handleChange}
                        value={formManual.inputValues.motivo}
                        required
                      />
                      {formManual.formErrors.motivo ? (
                        <ErrorInput>{formManual.formErrors.motivo}</ErrorInput>
                      ) : null}
                    </Col>
                    <Col desktop={3}>
                      <NewInputBasic
                        type={"text"}
                        name={"comentario"}
                        labelName={"Comentario"}
                        placeholder={"Informado por el banco"}
                        id={"comentario"}
                        onChange={formManual.handleChange}
                        value={formManual.inputValues.comentario}
                      />
                      {formManual.formErrors.comentario ? (
                        <ErrorInput>
                          {formManual.formErrors.comentario}
                        </ErrorInput>
                      ) : null}
                    </Col>
                    <Col desktop={3}>
                      <NewInputSelect
                        type={"number"}
                        name={"id_compania"}
                        labelName={"Compañia"}
                        id={"id_compania"}
                        options={companias}
                        placeholder={"Compañia"}
                        onChange={formManual.handleChange}
                      />
                      {formManual.formErrors.id_compania ? (
                        <ErrorInput>
                          {formManual.formErrors.id_compania}
                        </ErrorInput>
                      ) : null}
                    </Col>
                  </Grid>
                  <Grid colGap={21} rowGap={21} narrow={true}>
                    <Col desktop={3}>
                      <NewButton
                        backgroundColor={true}
                        onClick={formManual.handleValidation}
                        disabled={loading}
                      >
                        <FiEdit3 /> Cargar Rechazo
                      </NewButton>
                    </Col>
                  </Grid>
                </>
              )}
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default GestionRechazos;
