import { WrapperContainer } from './styled';

function NewWrapper({ children }) {
  return (
    <WrapperContainer>
      {children}
    </WrapperContainer>
  );
}

export default NewWrapper;
