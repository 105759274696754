import { useEffect, useState } from "react";
import axiosRequest from "../../../../utils/axiosConfig";

export const useCrearGrupo = (handleChangeExternal) => {

  const [vendedores, setVendedores] = useState([]);
  const [listaVendedores, setListaVendedores] = useState([]);
  const [vendedoresSeleccionados, setVendedoresSeleccionados] = useState([]); 

  useEffect(() => {
    const getData = async () => {
      try {
        const vendedores = await axiosRequest.get("/premios/vendedores");
        setVendedores(vendedores.data);
        setListaVendedores(vendedores.data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const seleccionarVendedor = (vendedor) => {
    const seleccionado = vendedoresSeleccionados.find(
      (ven) => ven === vendedor
    );
    if (seleccionado) {
      const removerVendedor = vendedoresSeleccionados.filter(
        (ven) => ven !== vendedor
      );
      handleChangeExternal("integrantes", removerVendedor);
      setVendedoresSeleccionados(removerVendedor);
    } else {
      handleChangeExternal("integrantes", [
        ...vendedoresSeleccionados,
        vendedor,
      ]);
      setVendedoresSeleccionados((prev) => [...prev, vendedor]);
    }
  };

  const handleChangeVendedor = (e) => {
    const { value } = e.target;
    if (value) {
      const filtrarVendedores = listaVendedores.filter((vendedor) =>
        vendedor.nombre.toLowerCase().startsWith(value.toLowerCase())
      );
      setVendedores(filtrarVendedores);
    } else {
      setVendedores(listaVendedores);
    }
  };

  return {
    handleChangeVendedor, 
    seleccionarVendedor,
    vendedores,
    vendedoresSeleccionados,
    setVendedoresSeleccionados
  };
};
