import { useEffect, useState } from 'react'
import TitlePageSection from '../../../components/TitlePageSection'
import NewTitlePageSection from '../../../components/NewTitlePageSection'
import { Col, Grid } from '../../../components/Grid'
import NewButton from '../../../components/NewButton'
import Subtitle from '../../../components/Subtitle'
import CardNumber from '../../../components/CardNumber'
import BarChart from '../../../components/BarChart'
import Wrapper from '../../../components/Wrapper'
import NewWrapper from '../../../components/NewWrapper'
import NewInner from '../../../components/NewInner'
import Inner from '../../../components/Inner'
import Sidebar from '../../../components/Sidebar'
import Divider from '../../../components/Divider'
import { Container, Day, Profile } from './styled'
import { FiBarChart, FiCalendar, FiCheckCircle, FiClock, FiCoffee, FiGrid, FiLogIn, FiLogOut, FiSun, FiThumbsUp, FiTrendingUp, FiUser } from 'react-icons/fi'
import NewInputSelect from '../../../components/NewInputSelect'
import dayjs from "dayjs"
import { meses } from '../../../constants/data'
import Layout from "../../../components/Layout"
import ModalFichajes from '../../../components/Modals/Fichajes/ModalFichajes'
import useModal from '../../../hooks/useModal'
import axiosRequest from '../../../utils/axiosConfig'
import formatSelectData from '../../../utils/formatSelectData'
import orderAlphabetically from '../../../utils/alphabeticalOrder'
import { useLoading } from '../../../hooks/useLoading'
import SpinnerLoading from '../../../components/SpinnerLoading'
import CardSinResultados from '../../../components/CardSinResultados'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(isBetween);

const FichajesRefactor = () => {

  const currentDay = new Date()
  const [mes, setMes] = useState(new Date().getMonth())
  const [dia, setDia] = useState(new Date())
  const [usuarios, setUsuarios] = useState([])
  const [excepciones, setExcepciones] = useState([])
  const [fichajes, setFichajes] = useState({})
  const loadingFichajes = useLoading()
  const daysInMonth = new Date(2024, mes + 1, 0).getDate() 
  const firstDay = new Date(2024, mes, 1).getDay()
  const [estadisticasMensuales, setEstadisticasMensuales] = useState({})
  const [estadisticasAnuales, setEstadisticasAnuales] = useState({})
  const [fichajesDiaActual, setFichajesDiaActual] = useState([])
  const [calendario, setCalendario] = useState([])
  const [usuario, setUsuario] = useState(JSON.parse(localStorage.getItem("userInfo"))?.id_usuario)
  const id_rol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol
  const rolesPermitidosExcepciones = [1, 16]

  const [mesEstadisticasMensuales, setMesEstadisticasMensuales] = useState(new Date().getMonth())

  const fichajesModal = useModal()

  useEffect(() => {
    const getCalendarDays = async () => {
      const daysInMonth = new Date(2024, mes + 1, 0).getDate() 
      const mesFormatted = dayjs(new Date(2024, mes, 1)).format("MM")
      const excepciones = await axiosRequest.get(`/excepciones_mes/${usuario}/${mesFormatted}`)
      const calendarDays = [...Array(daysInMonth)].map((_, index) => {
        const excepcion = excepciones.data.filter(item => {
          if(item.periodo_excepcion === "Fecha"){
            if(index + 1 === parseInt(dayjs(item.periodo_fecha_excepcion).format("DD"))){
              return item
            }
          }
          if(item.periodo_excepcion === "Desde / Hasta"){
            const currentDate = dayjs(new Date('2024', parseInt(mes), index + 1)).format("YYYY-MM-DD")
            const desdeDate = dayjs(item.periodo_fecha_desde_excepcion).format("YYYY-MM-DD")
            const hastaDate = dayjs(item.periodo_fecha_hasta_excepcion).format("YYYY-MM-DD")
            const isInBetween = dayjs(currentDate).isBetween(desdeDate, hastaDate, "day", "[]");
            if(isInBetween) return item
          }
        })
        if(excepcion.length > 0){
          return { dia: index + 1, excepcion: excepcion }
        }
        return { dia: index + 1, excepcion: [] }
      })
      setCalendario(calendarDays)
    }
    getCalendarDays()
  }, [mes, usuario])

  useEffect(() => {
    const getUsuarios = async () => {
      try {
        const usuarios = await axiosRequest.get('/operadores') 
        const filterUsuarios = usuarios.data.filter(usuario => !usuario.nombre_operador.toLowerCase().includes("prueba"))
        const formatUsuarios = formatSelectData(filterUsuarios, 'id_usuario', ['nombre_operador', 'apellido_operador'], 'id_usuario')
        setUsuarios(formatUsuarios.sort(orderAlphabetically))
      } catch (error) {
        console.log(error)
      }
    }
    getUsuarios()
  }, [])

  const getFichajeDiaActual = async () => {
    try {
      const fichajes = await axiosRequest.get(`/fichajes_usuario/${usuario}/${dayjs(dia).format("YYYYMMDD")}`)
      setFichajesDiaActual(fichajes.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getFichajeDiaActual()
  }, [])

  const handleDiaFichaje = async (daySelected) => {
    if(usuario && calendario){
      const diaSeleccionado = daySelected ? new Date(2024, mes, daySelected) : dia
      if(daySelected){
        setDia(new Date(2024, mes, daySelected))
      }
      loadingFichajes.setLoading(true)
      const fecha = dayjs(diaSeleccionado).format("YYYYMMDD")
      try {
        const fichajes = await axiosRequest.get(`/fichajes_usuario/${usuario}/${fecha}`)
        const findDayCalendar = calendario.find(item => item.dia === parseInt(dayjs(fecha).format("DD")))      
        setFichajes( { fichajes: fichajes.data, excepcion: findDayCalendar.excepcion })
        loadingFichajes.setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    handleDiaFichaje()
  }, [usuario, calendario])

  useEffect(() => {
    const getEstadisticasUsuarioMes = async () => {
      setEstadisticasMensuales({})
      try {
        const estadisticas = await axiosRequest.get(`/fichajes_usuario_estadisticas/${usuario}/${mesEstadisticasMensuales}`)
        setEstadisticasMensuales(estadisticas.data)
      } catch (error) {
        console.log(error)
      }
    }
    getEstadisticasUsuarioMes()
  }, [mesEstadisticasMensuales, usuario])

  useEffect(() => {
    const getEstadisticasUsuarioAnual = async () => {
      try {
        const estadisticas = await axiosRequest.get(`/fichajes_usuario_anual/${usuario}`)
        const data = {
          labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
          datasets: [
            {
              label: "Horas esperadas",
              data: estadisticas.data.map(item => item.horas_esperadas),
              backgroundColor: "#c4d4ff",
            },
            {
              label: "Horas cumplidas",
              data: estadisticas.data.map(item => item.horas_cumplidas),
              backgroundColor: "#1A56FF",
            },
          ],
        };
      
        setEstadisticasAnuales(data)
      } catch (error) {
        console.log(error)
      }
    }
    getEstadisticasUsuarioAnual()
  }, [usuario])

  useEffect(() => {
    const getExcepciones = async () => {
      try {
        const excepciones = await axiosRequest.get(`/excepciones/${usuario}/2024`)
        setExcepciones(excepciones.data)
      } catch (error) {
        console.log(error)
      }
    }
    getExcepciones()
  }, [usuario])

  const refreshFichajes = () => {
    getFichajeDiaActual()
    handleDiaFichaje()
  }

  const FICHAJES_ICONS = {
    ingreso: <FiLogIn />,
    reingreso_especial: <FiLogIn />,
    inicio_almuerzo: <FiCoffee />,
    fin_almuerzo: <FiCoffee />,
    fin_jornada: <FiLogOut />,
    salida_especial: <FiLogOut />
  }

  const TIPOS_EXCEPCIONES_ICONS = {
    vacaciones: <FiSun />,
    acuerdo: <FiThumbsUp />,
    licencia: <FiCalendar />
  }
  
  return (
    <>
        <ModalFichajes modal={fichajesModal} fichajes={fichajesDiaActual} refreshFichajes={refreshFichajes} usuarios={usuarios} />
        {/* <Sidebar /> */}
        <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={12}>
                <NewTitlePageSection title="Fichajes" description="Lista y estadísticas de tu actividad">
                  {rolesPermitidosExcepciones.find(id => id === id_rol) ? <Link to="/Excepcion"><NewButton backgroundColor={false} textColor={true} borderColor={true} ><FiCalendar /> Crear Excepción</NewButton></Link> : null}
                  <NewButton backgroundColor={true} onClick={fichajesModal.handleModal}><FiCalendar /> Fichar</NewButton>
                </NewTitlePageSection>
              </Col>
            </Grid>

            {rolesPermitidosExcepciones.find(id => id === id_rol) ?
            <>
            <Divider />

            <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={9}>
                <Subtitle 
                  title="Usuario"
                  description="Elegí un usuario para ver sus fichajes y estadísticas"
                  icon={<FiUser />}
                  disableDivider={true}
                />
              </Col> 
              <Col desktop={3}>
                <NewInputSelect
                  name={"usuario"}
                  onChange={(e) => setUsuario(e.target.value)}
                  placeholder={"Usuario"}
                  options={usuarios}
                  value={usuario}
                />
              </Col>
            </Grid>
            </>
            : null}

            <Divider />

            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={8}>
                <Subtitle 
                  title={`Calendario de ${usuarios.find(user => user.value == usuario)?.label}`}
                  image={true}
                  description="Tus fichajes a lo largo del mes"
                  icon={<FiCalendar />}
                  disableDivider={true}
                />
              </Col>
              <Col desktop={2}>
                <NewInputSelect
                  name={"mes"}
                  onChange={(e) => setMes(e.target.value)}
                  placeholder={"Mes"}
                  options={meses}
                  value={mes}
                />
              </Col>
              <Col desktop={2}>
                <NewInputSelect
                  placeholder="2024"
                  options={[]}
                />
              </Col>
              
              <Col desktop={6}>
                <div className='calendar-container'>
                  <div className='calendario-header'>
                    <div className='calendario-header-titulo'>
                      <p>Mes seleccionado</p>
                      <h4 className="subtitulo-historial">
                        {meses.find(item => mes == item.value)?.label}
                      </h4>
                    </div>
                    <div className='calendario-header-referencias'>
                      <div className='referencia-container'>
                        <div className='referencia'></div><span>Excepciones</span>
                      </div>
                    </div>
                  </div>
                  <div className='grid-calendar-header'>
                    <span>Dom</span>
                    <span>Lun</span>
                    <span>Mar</span>
                    <span>Mie</span>
                    <span>Jue</span>
                    <span>Vie</span>
                    <span>Sab</span>
                  </div>
                  <div className='grid-calendar'>
                    {calendario.map((item, index) => {
                      if(index === 0){
                        return (
                          <Day startPosition={firstDay} onClick={() => handleDiaFichaje(item.dia)}>
                            {item.dia}
                          </Day>
                        )
                      }
                      return (
                        <Day onClick={() => handleDiaFichaje(item.dia)} className={`${dayjs(dia).format("D") == (item.dia) && 'selected'} ${item.excepcion.length > 0 ? 'excepcion' : ''}`}>
                          {item.dia}
                        </Day>
                      )
                    })}
                  </div>
                </div>
              </Col>
              <Col desktop={6}>
                <div className='fichajes-container'>
                  <div className='fichajes-header'>
                    <p>Fichajes del día</p>
                    <h4 className="subtitulo-historial">
                      {dayjs(dia).format("dddd D MMMM, YYYY")}
                    </h4>
                  </div>
                  <div className='fichajes-contenido'>
                    {fichajes?.fichajes?.length > 0 && fichajes?.fichajes.map(item => {
                      return (
                        <div className='fichaje'>
                          <span className='icon'>{FICHAJES_ICONS[item.descripcion_fichaje.toLowerCase().replaceAll(" ", "_")]}</span><strong>{item.descripcion_fichaje}</strong> fichado a las {dayjs(item.dia_fichaje).format("HH:mm")}hs
                        </div>
                      )
                    })}
                    {fichajes?.excepcion?.length > 0 && fichajes?.excepcion.map(excepcion => {
                      return (
                        <div
                        className={`excepcion-container`}
                      >
                        {excepcion.id_usuario_excepcion && excepcion.tipo_excepcion && excepcion.periodo_excepcion && excepcion.hora_excepcion ?
                        <>
                          <span className="icon">
                              {TIPOS_EXCEPCIONES_ICONS[excepcion.tipo_excepcion.toLowerCase()]}
                          </span>
                          <span>
                              {excepcion.id_usuario_excepcion && excepcion.tipo_excepcion ? <><strong>{usuarios.find(usuario => usuario?.value == excepcion?.id_usuario_excepcion)?.label}</strong> tendrá una excepción del tipo <strong>{excepcion.tipo_excepcion}</strong></> : null}
                                  
                              {excepcion.periodo_excepcion === "Desde / Hasta" && excepcion.periodo_fecha_desde_excepcion && excepcion.periodo_fecha_hasta_excepcion ?  <> desde el <strong>{dayjs(excepcion.periodo_fecha_desde_excepcion).format("DD/MM/YYYY")}</strong> hasta el <strong>{dayjs(excepcion.periodo_fecha_hasta_excepcion).format("DD/MM/YYYY")}</strong></> : null}
      
                              {excepcion.periodo_excepcion === "Fecha" && excepcion.periodo_fecha_excepcion ? <> el día <strong>{dayjs(excepcion.periodo_fecha_excepcion).format("DD/MM/YYYY")}</strong></> : null}
      
                              {excepcion.hora_desde_excepcion && excepcion.hora_hasta_excepcion ? <> desde las <strong>{excepcion.hora_desde_excepcion}hs</strong> hasta las <strong>{excepcion.hora_hasta_excepcion}hs</strong></> : null}
                          </span>
                          </>
                          :
                          <span>Completá todos los datos necesarios para ver la excepción acá</span>
                          }
                      </div>
                      )
                    })}
                    {loadingFichajes.loading && fichajes?.fichajes?.length === 0 ? <SpinnerLoading /> : null}
                    {!loadingFichajes.loading && fichajes?.fichajes?.length === 0 && fichajes?.excepcion?.length === 0 ? <CardSinResultados icon={<FiCalendar />} title="No hay fichajes" description="No encontramos ningún fichaje para el día seleccionado" disableBorder={true} /> : null}
                  </div>
                </div>
              </Col>
              </Grid>

              <Divider />

              <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={8}>
                <Subtitle 
                  title={`Estadísticas del mes de ${usuarios.find(user => user.value == usuario)?.label}`}
                  image={true}
                  description="Estadísticas sobre tus fichajes a lo largo del mes"
                  icon={<FiBarChart />}
                  disableDivider={true}
                />
              </Col>
              <Col desktop={2}>
                <NewInputSelect
                  name={"mes"}
                  onChange={(e) => setMesEstadisticasMensuales(e.target.value)}
                  placeholder={"Mes"}
                  options={meses}
                  value={mesEstadisticasMensuales}
                />
              </Col>
              <Col desktop={2}>
                <NewInputSelect
                  placeholder="2024"
                  options={[]}
                />
              </Col>

              {Object.keys(estadisticasMensuales).length > 0 ?
                <>
                  <Col desktop={3}>
                    <CardNumber title="Mes" value={meses.find(item => mesEstadisticasMensuales == item.value)?.label} icon={<FiCalendar />}/>
                  </Col>
                  <Col desktop={3}>
                    <CardNumber title="Horas esperadas" value={`${estadisticasMensuales?.horas_esperadas}hs`} icon={<FiClock />}/>
                  </Col>
                  <Col desktop={3}>
                    <CardNumber title="Horas cumplidas" value={`${estadisticasMensuales?.horas_cumplidas}hs`} icon={<FiCheckCircle /> } />
                  </Col>
                  <Col desktop={3}>
                    <CardNumber title="Porcentaje" value={`${estadisticasMensuales?.porcentaje}%`} icon={<FiTrendingUp /> }/>
                  </Col>
                </>
              : <Col desktop={12}><SpinnerLoading text={"Cargando estadísticas mensuales..."}/></Col>}
              </Grid>

              <Divider />

              <Grid colGap={21} rowGap={21} narrow={false}>

              <Col desktop={10}>
                <Subtitle 
                  title={`Cumplimiento anual de ${usuarios.find(user => user.value == usuario)?.label}`}
                  image={true}
                  description="Estadísticas sobre tus fichajes a lo largo del año"
                  icon={<FiGrid />}
                  disableDivider={true}
                />
              </Col>
              <Col desktop={2}>
                <NewInputSelect
                  placeholder="2024"
                  options={[]}
                />
              </Col>

              {Object.keys(estadisticasAnuales).length > 0 ?
              <Col desktop={12}>
                <div className='section-data'>
                <BarChart data={estadisticasAnuales} />
                </div>
              </Col>
              : null}

              </Grid>
              
              <Divider />

              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={10}>
                  <Subtitle 
                    title="Excepciones"
                    description="Lista de tus excepciones cargadas por RRHH"
                    icon={<FiSun />}
                    disableDivider={true}
                  />
                </Col> 
                <Col desktop={2}>
                <NewInputSelect
                  placeholder="2024"
                  options={[]}
                />
               </Col>
                {excepciones.length > 0 ? excepciones.map(excepcion => {
                  return (
                    <Col desktop={12}>
                    <div
                      className={`excepcion-container`}
                    >
                      {excepcion.id_usuario_excepcion && excepcion.tipo_excepcion && excepcion.periodo_excepcion && excepcion.hora_excepcion ?
                      <>
                        <span className="icon">
                            {TIPOS_EXCEPCIONES_ICONS[excepcion.tipo_excepcion.toLowerCase()]}
                        </span>
                        <span>
                            {excepcion.id_usuario_excepcion && excepcion.tipo_excepcion ? <><strong>{usuarios.find(usuario => usuario?.value == excepcion?.id_usuario_excepcion)?.label}</strong> tiene una excepción del tipo <strong>{excepcion.tipo_excepcion}</strong></> : null}
                                
                            {excepcion.periodo_excepcion === "Desde / Hasta" && excepcion.periodo_fecha_desde_excepcion && excepcion.periodo_fecha_hasta_excepcion ?  <> desde el <strong>{dayjs(excepcion.periodo_fecha_desde_excepcion).format("DD/MM/YYYY")}</strong> hasta el <strong>{dayjs(excepcion.periodo_fecha_hasta_excepcion).format("DD/MM/YYYY")}</strong></> : null}
    
                            {excepcion.periodo_excepcion === "Fecha" && excepcion.periodo_fecha_excepcion ? <> el día <strong>{dayjs(excepcion.periodo_fecha_excepcion).format("DD/MM/YYYY")}</strong></> : null}
    
                            {excepcion.hora_desde_excepcion && excepcion.hora_hasta_excepcion ? <> desde las <strong>{dayjs(excepcion.hora_desde_excepcion).format("HH:mm")}hs</strong> hasta las <strong>{dayjs(excepcion.hora_hasta_excepcion).format("HH:mm")}hs</strong></> : null}
                        </span>
                        </>
                        :
                        <span>Completá todos los datos necesarios para ver la excepción acá</span>
                        }
                    </div>
                  </Col>

                  )
                })
                :
                <Col desktop={12}>
                  <CardSinResultados icon={<FiSun />} title={"No hay excepciones"} description={"No se encontraron excepciones cargadas hasta el momento"}/>
                </Col>
                }

              </Grid>
            </Container>
          </NewInner>
        </NewWrapper>
        </Layout>
    </>
  )
}

export default FichajesRefactor