import { Grid, Col } from "../Grid";
import { EmisionData } from "./styled";
import Subtitle from "../Subtitle";
import { RiWhatsappLine } from "react-icons/ri";
import { FiCopy } from "react-icons/fi";
import IconButton from "../IconButton";
import { useState } from "react";

const MediosContactoSeccion = ({ data, title, label, icon, speech }) => {

  const [copied, setCopied] = useState(false)

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
    setCopied(true)
    setTimeout(() => {
        setCopied(false)
    }, 2000)
  }

  return (
    <Grid colGap={21} rowGap={21} narrow={true}>
      <Col desktop={12} spaced={true}>
        <Subtitle title={title} icon={icon} />
      </Col>
      {Object.keys(data).length > 0 &&
        Object.entries(data).map((item, index) => {
          if (label[item[0]] && item[1]) {
            const isPhone = item[0].includes("telefono");
            const isEmail = item[1].includes("@");
            return (
              <Col desktop={6} key={index}>
                <EmisionData copied={copied}>
                  <h6>{label[item[0]]}</h6>
                  {isPhone ? 
                    <p className="whatsapp-telefono">
                      <span><RiWhatsappLine /></span>
                      <a
                        id="boton-wpp"
                        href={`https://api.whatsapp.com/send?phone=${item[1]}${speech}`}
                        target="_blank"
                        rel="noreferrer"
                        className="whatsapp-telefono"
                      >
                        {" "}
                        {item[1]}
                      </a>
                    </p>
                  : 
                    null
                  }
                  {isEmail ? 
                  <p>{item[1]} <IconButton icon={<FiCopy />} tooltipText="Copiar mail" className="clipboard-icono" action={() => copyToClipboard(item[1])}></IconButton></p>
                  : null}
                </EmisionData>
              </Col>
            );
          }
        })}
    </Grid>
  );
};

export default MediosContactoSeccion;
