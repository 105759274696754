import { TituloNombre } from './styled';

const NewTitle = ({ children, textColor, margin }) => {

  return (
    <TituloNombre data-textcolor={textColor} margin={margin} >
      {children}
    </TituloNombre>
  );
}

export default NewTitle;
