import { Container } from './styled'

const NotificacionPagina = ({ mensaje, resetNotificaciones }) => {
  return (
    <Container>
        <div className="notificacion-container">
        <div className="blob"></div>
        <span className="notificacion" onClick={resetNotificaciones}>
            {mensaje}
        </span>
        </div>
    </Container>
  );
};

export default NotificacionPagina;
