import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AnimacionExito from "../AnimacionExito";
import { Col, Grid } from "../Grid";
import NewTitle from "../NewTitle";
import NewButton from "../NewButton";
import { SuccessContainer, SuccessTitle } from "./styled";

const Success = ({ title, description, page, link, handleModal, disableMargin }) => {

  return (
    <Grid colGap={21} rowGap={21} narrow={true} centerItems={true}>
      <Col desktop={12}>
        <SuccessContainer disableMargin={disableMargin}>
          <Col desktop={12}>
            <AnimacionExito />
          </Col>
          <Col desktop={12}>
            <SuccessTitle>
              <NewTitle>{title}</NewTitle>
              <p>
                {description}
              </p>
              {link ?
              <Link
                to={{
                  pathname: link,
                }}
              >
                <NewButton
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                >
                  Volver a {page}
                </NewButton>
              </Link>
              : null}
              {handleModal ? 
                <NewButton
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={handleModal}
                >
                  Cerrar 
                </NewButton>
              : 
              null}
            </SuccessTitle>
          </Col>
        </SuccessContainer>
      </Col>
    </Grid>
  );
};

export default Success;
