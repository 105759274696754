import React from "react";
import { useState } from "react";
import NewInputBasic from "../NewInputBasic";
import { Container } from "./styled";

const GoalConverter = () => {
  const formatter = new Intl.NumberFormat("es-ES", {});

  const [objetivo, setObjetivo] = useState(null);
  const [objetivoPrimaConversor, setObjetivoPrimaConversor] = useState(0);

  const handleChangeObjetivo = (value) => {
    if (value) {
      const primeraParte = (value * 55) / 100;
      const segundaParte = (value * 45) / 100 / 4;
      const objetivo = primeraParte + segundaParte;
      setObjetivoPrimaConversor(objetivo);
    } else {
      setObjetivoPrimaConversor(0);
    }
  };

  return (
    <Container>
      <div className="conversor-prima-container">
        <div className="conversor-container">
          <NewInputBasic
            type="number"
            name={"objetivo"}
            onChange={(e) => handleChangeObjetivo(e.target.value)}
            placeholder={"2500500"}
            value={objetivo}
          />
          <span className="igual-conversor">=</span>
          <span className="prima-convertida">
            ${formatter.format(objetivoPrimaConversor)}
          </span>
        </div>
        <span className="aclaracion-conversor">
          Cálculo: se ingresa un número de prima y el 55% de esa prima queda
          igual, el 45% de la misma se toma la cuarta parte.
        </span>
      </div>
    </Container>
  );
};

export default GoalConverter;
