import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const DivCarousel = styled.div`
  /* ${props => props.showCarousel === 'true' & css`
    &{
      display: flex;
    }
  `}
  ${props => props.showCarousel === 'false' & css`
    &{
      display: none;
    }
  `} */
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 20px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
  .carousel{
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  &.closeCarousel {
    display: none;
  }
  .slick-slider{
    width: 70%;
    height: auto;
  }
  .slick-thumb{
    bottom: -100px;
    display: flex!important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .slick-thumb li {
    width: 100px;
    height: fit-content;
    align-self: center;
  }
  .slick-thumb li img {
    filter: grayscale(100%);
  }
  img{
    max-width: 50%;
    //margin: 0 0 1.45rem;
    padding: 0;
    margin: 10px auto;
  }
  .slick-thumb li.slick-active img {
    filter: grayscale(0);
  }
  .slick-dots{
    position: initial;
    //margin-top:25px;
  }

  .slick-prev{
    left: -40px;
    bottom: 0;
    top: 78%;
  }
  .slick-next{
    right: -40px;
    bottom: 0;
    top: 78%;
  }
  .slick-track{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .slick-prev::before {
    content: url('');
  }
  .slick-next::before {
    content: url('');
  }
  #react-doc-viewer{
    height: 500px;
    width:100%!important;
  }
  #proxy-renderer, #header-bar{
    width:100%!important;
  }

  #pdf-renderer {
    //overflow: hidden;
    //overflow-y: initial;
    overflow-y: scroll;
  }

  #pdf-controls {
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid #eaeaea;
    box-shadow: none;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
  }

  #pdf-controls a, #pdf-controls button {
    border: 1px solid #eaeaea;
    box-shadow: none;
  }

  #pdf-controls a svg path, 
  #pdf-controls a svg polygon,
  #pdf-controls button svg g g path,
  #pdf-controls button svg path {
    fill: ${leerColor(colores.celeste)};
  }

  video {
    display: flex;
    margin: 0 auto;
  }

  // CHEQUEAR ESTO

  /*
  .slick-slide div div{
    display:flex !important;
    margin: 0 auto;
    justify-content: center !important;
    align-items: center !important;
  }
  */

  /*
  #pdf-renderer {
    background-color: red;
    position: relative;
    display: flex;
  }
  #pdf-controls {
    background-color: blue;
    position: absolute;
    margin: 0 auto;
    left: -100px;
    bottom: 0;
  }
  */
`

export const Video = styled.video`  
  width: auto;
  height:50vh;
  margin: 0 auto;
`