import { Container, TableHeader, TableBody, NoHayBusquedas } from './new-styled'
import InfoBoardRow from "../InfoBoardRow";
import SkeletonLoading from "../SkeletonLoading";
import ActionsButtonRefactor from "../ActionsButtonRefactor"
import replaceAccents from "../../utils/replaceAccents";
// Iconos
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

function InfoBoard({ borderTop, disabledMarginTop, headers, data, loading, page, buttonsStatus, setButtonsStatus, handleModalHistorial, handleSusurroModal, selectRegister, checkboxesStatus, handleModalEnviarEmail, setLoadingModalMessage, setIsModalOpenEnviarEmail, children, orderParams, setOrderParams }) {

  const orderHeader = (header) => {
    let orden_tipo = orderParams.includes("orden_tipo=ASC") ? "DESC" : "ASC"
    let filters = `orden=${replaceAccents(header.toLowerCase().replace(/\s+/g, '').replace('.', '_'))}&orden_tipo=${orden_tipo}`
    setOrderParams(filters)
  }

  return (
    <>               
        <Container borderTop={borderTop} disabledMarginTop={disabledMarginTop}>
            <TableHeader columns={headers.length} borderTop={borderTop} >
                {headers.map(header => { return ( <div onClick={(page === "Solicitud Emisión" || page === "Emisiones" || page === "Historial" || page === "Siniestros") && header !== "Acciones" ? () => orderHeader(header) : undefined}><span>
                  {header}
                  {orderParams ? 
                    <>
                    {orderParams.includes(replaceAccents(header.toLowerCase().replace(/\s+/g, '').replace('.', '_'))) && orderParams.includes("orden_tipo=ASC") && <AiOutlineArrowUp className="header-arrow" />}
                    {orderParams.includes(replaceAccents(header.toLowerCase().replace(/\s+/g, '').replace('.', '_'))) && orderParams.includes("orden_tipo=DESC") && <AiOutlineArrowDown className="header-arrow" />}
                    </>
                  : null} 
                </span></div> )})}
            </TableHeader>

            {loading && data.length == 0 ? <SkeletonLoading rows={headers.length} /> : null }

            {!loading && data.length == 0 && page === "Estadísticas Producto Canal" ? 
                <NoHayBusquedas>
                  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.9593 0C9.85123 0 0.000976562 9.85025 0.000976562 21.9583C0.000976562 34.0663 9.85123 43.9166 21.9593 43.9166C34.0673 43.9166 43.9176 34.0663 43.9176 21.9583C43.9176 9.85025 34.0674 0 21.9593 0ZM21.9593 41.3333C11.2746 41.3333 2.58427 32.643 2.58427 21.9583C2.58427 11.2736 11.2746 2.58329 21.9593 2.58329C32.644 2.58329 41.3343 11.2736 41.3343 21.9583C41.3343 32.643 32.644 41.3333 21.9593 41.3333Z" fill="#787878"/>
                    <path d="M61.6211 59.7962L37.485 35.66C36.9813 35.1563 36.1623 35.1563 35.6586 35.66C35.1548 36.1638 35.1548 36.9827 35.6586 37.4865L59.7946 61.6225C60.0478 61.8731 60.3784 61.9997 60.7091 61.9997C61.0398 61.9997 61.3704 61.8732 61.6211 61.6225C62.1248 61.1188 62.1248 60.3 61.6211 59.7962Z" fill="#787878"/>
                    <path d="M31.1667 23H12.8333C12.3733 23 12 22.552 12 22C12 21.448 12.3733 21 12.8333 21H31.1667C31.6267 21 32 21.448 32 22C32 22.552 31.6267 23 31.1667 23Z" fill="#787878"/>
                  </svg>
                  <h3>Selecciona un año, mes y producto para ver sus estadísticas</h3>
                </NoHayBusquedas>
              : 
                null
            }

            {!loading && data.length == 0 && page !== "Estadísticas Producto Canal" ? 
                <NoHayBusquedas>
                  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.9593 0C9.85123 0 0.000976562 9.85025 0.000976562 21.9583C0.000976562 34.0663 9.85123 43.9166 21.9593 43.9166C34.0673 43.9166 43.9176 34.0663 43.9176 21.9583C43.9176 9.85025 34.0674 0 21.9593 0ZM21.9593 41.3333C11.2746 41.3333 2.58427 32.643 2.58427 21.9583C2.58427 11.2736 11.2746 2.58329 21.9593 2.58329C32.644 2.58329 41.3343 11.2736 41.3343 21.9583C41.3343 32.643 32.644 41.3333 21.9593 41.3333Z" fill="#787878"/>
                    <path d="M61.6211 59.7962L37.485 35.66C36.9813 35.1563 36.1623 35.1563 35.6586 35.66C35.1548 36.1638 35.1548 36.9827 35.6586 37.4865L59.7946 61.6225C60.0478 61.8731 60.3784 61.9997 60.7091 61.9997C61.0398 61.9997 61.3704 61.8732 61.6211 61.6225C62.1248 61.1188 62.1248 60.3 61.6211 59.7962Z" fill="#787878"/>
                    <path d="M31.1667 23H12.8333C12.3733 23 12 22.552 12 22C12 21.448 12.3733 21 12.8333 21H31.1667C31.6267 21 32 21.448 32 22C32 22.552 31.6267 23 31.1667 23Z" fill="#787878"/>
                  </svg>
                  <h3>No se encontraron resultados</h3>
                </NoHayBusquedas>
              : 
                null
            }

            <TableBody columns={headers.length} rows={data.length}>
                {data && data.map((row, index) => {
                  const headersFormatted = headers.map(header => { return replaceAccents(header.toLowerCase().replace(/\s+/g, '').replace(/[-._]/g, '')) })
                  const values = Object.entries(row)
                  const rowData = []
                  values.map(value => {
                    if(headersFormatted.includes(value[0].replace(/\s+/g, '').replace(/[-._]/g, ''))){
                      const column = value[0]
                      const valueData = value[1]
                      if(!value[0].includes("acciones")){
                        rowData.push(<InfoBoardRow column={column} value={valueData} allValues={row} page={page} selectRegister={selectRegister} checkboxesStatus={checkboxesStatus} item={row} />)
                      } else if(value[0] === "acciones" && (page === "Rechazadas" || page === "Historial" || page === "Siniestros" || page === "Fichajes" || page === "Landings" || page === "Autogestion")){
                        rowData.push(value[1])
                      } else {
                        rowData.push(<ActionsButtonRefactor data={row} index={index} handleModalHistorial={handleModalHistorial} buttonsStatus={buttonsStatus} setButtonsStatus={setButtonsStatus} handleModalEnviarEmail={handleModalEnviarEmail} setLoadingModalMessage={setLoadingModalMessage} setIsModalOpenEnviarEmail={setIsModalOpenEnviarEmail} handleSusurroModal={handleSusurroModal} />)
                      }
                    }
                  })
                  return rowData
                })}
            </TableBody>

            {children}
        </Container>
        </>
  );
}

export default InfoBoard;

