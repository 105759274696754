import { FiActivity, FiFilter } from "react-icons/fi";
import SpinnerLoading from "../../../SpinnerLoading";
import { ModificacionHistorial } from "../../../../pages/new/Premios/styled";
import { meses } from "../../../../constants/data";
import NewFiltersSection from "../../../NewFiltersSection";
import { Col, Grid } from "../../../Grid";
import NewButton from "../../../NewButton";
import Subtitle from "../../../Subtitle";
import useFiltersNew from "../../../../hooks/useFiltersNew";
import { filterHistorialPremiosInitialValues, filtersHistoriaPremios } from "../../../../utils/filtersPages";
import formatSelectData from "../../../../utils/formatSelectData";
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import localeData from "dayjs/plugin/localeData";
import CardSinResultados from "../../../CardSinResultados";
import Divider from "../../../Divider";
import ViewMore from "../../../ViewMore";
import { useViewMore } from "../../../../hooks/useViewMore";
import { useHistorialPremios } from "./hooks/useHistorialPremios";
import { PREMIOS_HISTORIAL_MODIFICACIONES } from "../../../../constants/icons";
import { useSingleOpen } from "../../../../hooks/useSingleOpen";

dayjs.locale("es");
dayjs.extend(localeData);

const PremiosHistorialTab = ({ data }) => {

  const { vendedores } = data
  const { inputValues, params, handleSearch, handleChange, clearSearch } =
  useFiltersNew(filterHistorialPremiosInitialValues);
  const { isOpen: isFiltersOpen, handleOpen: handleFiltersOpen } = useSingleOpen()
  const { itemsToView, handleItemsToView } = useViewMore(5)
  const { historial, loadingHistorial, tiposModificacionesHistorial } = useHistorialPremios(params)

  const filters = filtersHistoriaPremios({
    meses,
    vendedores: formatSelectData(
      vendedores,
      "id_vendedor",
      "vendedor",
      "id_vendedor"
    ),
    tipos: tiposModificacionesHistorial
  });

  return (
    <>
      <Subtitle
        icon={<FiActivity />}
        title={`Historial de modificaciones`}
        description={
          "Lista de actividades realizadas que se vinculan con la página de Premios"
        }
        disableDivider={true}
      />
      <NewButton
        backgroundColor={false}
        textColor={true}
        borderColor={true}
        onClick={() => handleFiltersOpen(!isFiltersOpen)}
      >
        <FiFilter /> Filtros
        {params?.split("=").length > 1 ? (
          <span className="filtros-aplicados">
            {params?.split("=").length - 1}
          </span>
        ) : null}
      </NewButton>

      {isFiltersOpen ? (
        <Grid colGap={21} rowGap={21} narrow={true}>
          <Col desktop={12}>
            <NewFiltersSection
              section="solicitudes"
              inputs={filters}
              values={inputValues}
              params={params}
              handleChange={handleChange}
              handleSearch={handleSearch}
              clearSearch={clearSearch}
            />
          </Col>
        </Grid>
      ) : null}

      <Divider />
      {historial.length > 0 ? (
        <>
          {historial
            .filter((_, index) => index < itemsToView)
            .map((item, index) => {
              let msg = item.mensaje;
              Object.keys(item).forEach((key) => {
                msg = msg.replace(`{${key}}`, () =>
                  typeof item[key] === "string"
                    ? item[key].replace(".", " ")
                    : item[key]
                );
              });
              const calculo = msg.split(".")[1];

              const mesModificacion = dayjs(item.dia).format("M");
              const mesModificacionAnterior =
                index > 0 ? dayjs(historial[index - 1].dia).format("M") : "";

              const diaModificacion = dayjs(item.dia).format("DD");
              const diaModificacionAnterior =
                index > 0 ? dayjs(historial[index - 1].dia).format("DD") : "";

              msg = msg.replace(item.usuario, "");
              msg = msg.replace(item.vendedor, "");

              return (
                <>
                  {mesModificacion !== mesModificacionAnterior ? (
                    <h4 className="subtitulo-historial">
                      {
                        meses.find(
                          (mes) => mes.value === parseInt(mesModificacion - 1)
                        )?.label
                      }
                    </h4>
                  ) : null}
                  {diaModificacion !== diaModificacionAnterior ? (
                    <h4 className="subtitulo-historial">
                      {dayjs(item.dia).format("dddd D MMMM, YYYY")}
                    </h4>
                  ) : null}
                  <ModificacionHistorial>
                    <div className="icono">
                      <span>
                        {PREMIOS_HISTORIAL_MODIFICACIONES[item.tipo.toLowerCase()]}
                      </span>
                    </div>
                    <div className="modificacion">
                      <div className="modificacion-titulo">
                        <span>
                          <strong>{item.usuario}</strong>
                          {msg.split(".")[0]}
                          <strong>{item.vendedor}</strong>
                        </span>
                      </div>
                      <div className="calculo-container">
                        <span>{calculo}</span>
                      </div>
                      <div>
                        <span className="modificacion-hora">
                          Modificación realizada a las{" "}
                          {dayjs(item.dia).format("HH:mm")}hs
                        </span>
                      </div>
                    </div>
                  </ModificacionHistorial>
                  <Divider />
                </>
              );
            })}
          {itemsToView < historial.length ? (
            <ViewMore action={() => handleItemsToView(5)} />
          ) : null}
        </>
      ) : null}
      {loadingHistorial.loading ? <SpinnerLoading /> : null}
      {!loadingHistorial.loading && historial.length === 0 ? (
        <CardSinResultados icon={<FiActivity />} title={"No hay mensajes"} description={"No se encontraron mensajes en el historial"}/>
      ) : null}
    </>
  );
};

export default PremiosHistorialTab;
