import { useEffect, useState } from "react";
import { postMensajeHistorial } from "../services/Emision";
import dayjs from "dayjs"

export const useObservacion = () => {

  const editarObservacionForm = async (observaciones, solicitud, id_operador) => {
    const mensajeSolicitudModificada = {
        id_solicitud: solicitud.id,
        id_area: 12,
        id_operador: id_operador,
        id_accion_historial: 4,
        fecha_solicitud_historial: dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
        descripcion_solicitud_historial: 'Solicitud corregida.',
        eprevio_solicitud_historial: solicitud.id_estado,
        estado_solicitud_historial: 1,
        email_solicitudes_historial: 0,
        id_historial_email: null
    }

    try{
        await postMensajeHistorial(mensajeSolicitudModificada)
        if(observaciones.observaciones){
            const mensajeObservacion = { ...mensajeSolicitudModificada, descripcion_solicitud_historial: observaciones.observaciones}
            await postMensajeHistorial(mensajeObservacion)
        }
    } catch (error){
        console.log(error)
    }
  }

  const postearObservacionForm = async (observaciones, solicitudID, id_operador, tipoSolicitud) => {
    let mensaje = null
    switch(tipoSolicitud){
      case "1": 
        mensaje = 'Nueva solicitud de emisión enviada.'
        break
      case "2": 
        mensaje = 'Solicitud de anulación por reemplazo enviada.'
        break
      case "3": 
        mensaje = 'Solicitud de endoso enviada.'
        break
      default: 
        return
    }
    const mensajeSolicitudEnviada = {
        id_solicitud: solicitudID,
        id_area: 12,
        id_operador: id_operador,
        id_accion_historial: 4,
        fecha_solicitud_historial: dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
        descripcion_solicitud_historial: mensaje,
        eprevio_solicitud_historial: 1,
        estado_solicitud_historial: 1,
        email_solicitudes_historial: 0,
        id_historial_email: null
    }

    try{
        await postMensajeHistorial(mensajeSolicitudEnviada)
        if(observaciones.observaciones){
            const mensajeObservacion = { ...mensajeSolicitudEnviada, descripcion_solicitud_historial: observaciones.observaciones}
            await postMensajeHistorial(mensajeObservacion)
        }
    } catch (error){
        console.log(error)
    }
  }

  return { 
    postearObservacionForm,
    editarObservacionForm
  }
};
