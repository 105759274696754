import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Mensaje, Attachment, Tab } from "./styled";
import { InputBasic, Button } from "../Index";
import { motion } from "framer-motion"
import url from '../../utils/url'
import docVideo from '../../assets/images/docVideo.png'
import docImage from '../../assets/images/doc_cuadrado.png'
import axios from "axios";
import { FiLink } from "react-icons/fi";

function MessagesHistory({ icon, className, direction, persona, fechaHora, mensaje, isLink }) {

  const [link, setLink] = useState('')
  const [linkText, setLinkText] = useState('Cargando archivo...')

  useEffect(() => {
    const getLink = async () => {
        if(isLink){
            let lastValueText = mensaje.indexOf(':')
            let startValueLink = mensaje.indexOf('http')
            //const newLink = await getFile(mensaje.substring(startValueLink))
            const url = mensaje.substring(startValueLink)
            //setLink(mensaje.substring(startValueLink))
            setLinkText(mensaje.substring(0, lastValueText))
            const link = await getFile(url)

            setLink(link)
          }
    }
    getLink()
  }, [])

  const getFile = async (url) => {
    const config = {
        headers: { 'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69' },
        responseType: 'blob',
    }
    try {
        console.log(url)
        // obtiene el documento como un blob
        const file = await axios.get(url, config)

        let src;
        var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
        if ( isChrome ){
            src = window.webkitURL.createObjectURL(file.data);
        } else {
            src = window.URL.createObjectURL(file.data);
        }

        return src;

    } catch (error) {
        console.log(error)
    }
  }

  return (
    <Mensaje
      className={className}
      direction={direction}
    >
      <div className="mensaje-container">
        {persona && <h6>{persona}</h6>}
        <span className="fecha-hora">{fechaHora}</span>
        {isLink 
          ? <div>
            <p><FiLink />
              <a href={link} target="_blank" rel="noreferrer">{linkText}.</a></p>
            </div>
          : <p>{icon} {mensaje}</p>
        }
      </div>
    </Mensaje>
  );
}

export default MessagesHistory;
