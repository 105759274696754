import { useState } from "react";
import useModal from "./useModal";
import dayjs from "dayjs";
import axiosRequest from "../utils/axiosConfig";
import { useLoading } from "./useLoading";
import { useSocket } from "../context/SocketProvider";

export const useAsignacion = (refreshData) => {

  const socket = useSocket()
  const [solicitudesSeleccionadas, setSolucitudesSeleccionadas] = useState([])
  const [solicitudesAsignadas, setSolicitudesAsignadas] = useState(false)
  const [operadorId, setOperadorId] = useState(null)
  const { isModalOpen, handleModal } = useModal()
  const { loading, setLoading } = useLoading()

  const handleChangeOperador = (value) => {
    setOperadorId(value)
  }

  const seleccionarSolicitud = (event, item) => {
    const { checked } = event.target
    if(checked){
      setSolucitudesSeleccionadas(prev => [...prev, item])
    } else {
      setSolucitudesSeleccionadas(prev => prev.filter(solicitud => solicitud.id !== item.id))
    }
  }

  const removerSolicitud = (id) => {
    setSolucitudesSeleccionadas(prev => prev.filter(solicitud => solicitud.id !== id))
  }

  const asignarSolicitud = async (exitoModal) => {
      setLoading(true)

      const operadores = await axiosRequest.get('/operadores')

      const assing = solicitudesSeleccionadas.map(async (item) => {
        const solicitud = await axiosRequest.get(`/solicitudes/${item.id}`)
        const findSolicitud = solicitud.data.solicitud
        findSolicitud.operador_solicitud = parseInt(operadorId.value)
        findSolicitud.id_estado_previo = findSolicitud.id_estado
        findSolicitud.ingreso_solicitud = dayjs(findSolicitud.ingreso_solicitud).format('DD/MM/YYYY hh:mm:ss')
        if(findSolicitud.rechazo_solicitud !== null){
            findSolicitud.rechazo_solicitud = dayjs(findSolicitud.rechazo_solicitud).format('DD/MM/YYYY hh:mm:ss') 
        }
        await axiosRequest.put(`/solicitudes/${item.id}`, findSolicitud)

        const operador = operadores.data.find(operador => operador.id_operador === parseInt(operadorId.value))
        const tipoSolicitudMensaje = {
          1: "una nueva solicitud",
          2: "una anulación por reemplazo",
          3: "un endoso"
        }

        const notificacion = {
          url_pagina: `/Emision/${item.id}`,
          nombre_icono: "emision",
          nombre_pagina: "Solicitud Emisión",
          notificacion: `Te asignaron ${tipoSolicitudMensaje[item.id_tipo_solicitud]}: ID: ${item.id} - Fecha de ingreso: ${dayjs(item.f_ingreso).format("DD/MM/YYYY")} - Asegurado: ${item.cliente} - DNI: ${item.dni} - Vendedor: ${item.vendedor} - Compañia: ${item.compania}`,
          time: dayjs(new Date()).format("hh:mm"),
          id_usuario: operador.id_usuario,
        }
        socket.emit('notificacion-crm-emision', notificacion)
        await axiosRequest.post('/notificacion/crear', notificacion)
      })

      Promise.all(assing).then(() => {
        handleModal()
        exitoModal.setIsModalOpen(true)
        exitoModal.handleModalMessage("¡Solicitudes asignadas!")
        setSolucitudesSeleccionadas([])
        setLoading(false)
        setSolicitudesAsignadas(true)
        setOperadorId(null)
        refreshData()

        setTimeout(() => {
            exitoModal.setIsModalOpen(false)
            setSolicitudesAsignadas(false)
        }, 2000);
      });
  }

  return {
    modal: {
        isModalOpen,
        handleModal,
    },
    seleccionarSolicitud,
    solicitudesSeleccionadas,
    removerSolicitud,
    asignarSolicitud,
    handleChangeOperador,
    loading,
    solicitudesAsignadas,
    operadorId
  };
}