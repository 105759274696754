import React, { forwardRef, useState } from "react";
import { Table } from "./styled";
import MenuActions from "../../assets/iconos/menuActions.svg";
import PaginationNew from "../PaginationNew";
// Iconos
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

const TableStyled = (
  {
    companias,
    rechazosTabla,
    setShowDetails,
    setRowSelected,
    setOrder,
    pagination,
    setPage,
    btnOrder,
    estados,
  },
  ref
) => {
  const [load, setLoad] = useState(false);

  const showDetailsforId = (e) => {
    setRowSelected(e.target.value);
    setShowDetails(true);
    //pasar el id para app
  };

  const setNewPage = (value) => {
    setPage(value);
  };


  const RowIconUp = ({filtro} ) => {
    return (
      btnOrder? <a href="#" onClick={() => setOrder(`filtro=${filtro}&order=ASC`)}>
        <AiOutlineArrowUp ></AiOutlineArrowUp>
      </a>
      : null
    )
  }

  const RowIconDown = ({filtro} ) => {
    return (
      btnOrder ? <a href="#" onClick={() => setOrder(`filtro=${filtro}&order=DESC`)}>
        <AiOutlineArrowDown ></AiOutlineArrowDown>
      </a>
      : null
    )
  }
  return (
    <Table ref={ref}>
      <thead>
        <tr>
          <th className="colum-id">
            Id 
            <RowIconUp filtro={"id"} />
            <RowIconDown filtro={"id"} />
          </th>
          <th>
            Nombre
            <RowIconUp filtro={"nombre"} />
            <RowIconDown filtro={"nombre"} />
          </th>
          <th>
            Estado
            <RowIconUp filtro={"id_estado"} />
            <RowIconDown filtro={"id_estado"} />
          </th>
          <th>
            Veces Notificado
          </th>
          <th>
            Póliza
            <RowIconUp filtro={"poliza"} />
            <RowIconDown filtro={"poliza"} />
          </th>
          <th>
            Compañía
            <RowIconUp filtro={"compania"} />
            <RowIconDown filtro={"compania"} />
          </th>
          <th className="colum-detalles">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {rechazosTabla.map((row, index) => (
          <tr key={index} className={row.deletedAt ? "deleted" : ""}>
            <td className="colum-id"> { row.id_cobranza ||  row.id}</td>
            <td> {row.nombre}</td>
            <td> 
            {" "}
              {load
                ? "cargando ..."
                : estados.length !== 0
                  ? estados.filter((e) => e.id === row.id_estado)
                    .length !== 0
                    ? estados.filter((e) => e.id === row.id_estado)[0]
                      .estado
                    : row.id_estado + " ! "
                  : +" ! "}
            </td>
            <td> {row.veces_notificado  || '-' }</td>
            <td> {row.poliza}</td>
            <td>
              {" "}
              {load
                ? "cargando ..."
                : companias.length !== 0
                  ? companias.filter((cia) => cia.numero === row.id_compania)
                    .length !== 0
                    ? companias.filter((cia) => cia.numero === row.id_compania)[0]
                      .nombre
                    : row.id_compania + " ! "
                  : +" ! "}
            </td>
            <td className="colum-detalles">
              <button
                value={row.id}
                onClick={showDetailsforId}
                target="_self"
                data-backgroundcolor={false}
                data-bordercolor={true}
                data-textcolor={true}
                icon={MenuActions}
              >
                Detalles
              </button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={10} id="td-botones">
            {pagination ? (
              <PaginationNew currentPage={pagination.page} lastPage={pagination.lastPage} previousPage={() => setNewPage(pagination.previousPage)} nextPage={() => setNewPage(pagination.nextPage)} dataLength={pagination.count} selectPage={setNewPage} />
            ) : null}
          </td>
        </tr>
      </tfoot>
  
    </Table>
  );
};

export default forwardRef(TableStyled);
