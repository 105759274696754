import { Container, Page, ResultsAmount } from './styled';
import Button from "../Button"
import NewButton from '../NewButton';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

const NewPagination = ({ currentPage, lastPage, previousPage, nextPage, dataLength, selectPage }) => {

    const totalPages = Array.from(Array(lastPage).keys())
    
    return (
        <>
            <Container>
                <NewButton backgroundColor={false} textColor={true} borderColor={true} direction={"before"} onClick={previousPage} disabled={currentPage === 1}><FiArrowLeft />Anterior</NewButton>
                <Page active={currentPage === 1} onClick={() => selectPage(1)}>1</Page>
                {totalPages.map(page => {
                    if(page + 1 !== 1 && page + 1 !== lastPage && ((currentPage - (page + 1)) >= -1 && (currentPage - (page + 1)) <= 1)){
                        return (
                            <Page active={currentPage === page + 1} onClick={() => selectPage(page + 1)}>{page + 1}</Page>   
                        )
                    }
                })}
                <Page active={currentPage === lastPage} onClick={() => selectPage(lastPage)}>{lastPage}</Page>
                <NewButton backgroundColor={false} borderColor={true} textColor={true} direction={"after"} onClick={nextPage} disabled={currentPage === lastPage}>Siguiente <FiArrowRight /></NewButton>
            </Container>
            <ResultsAmount>Cantidad de resultados: {dataLength}</ResultsAmount>
        </>
    )
}

export default NewPagination