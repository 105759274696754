import { useEffect, useState, useRef } from "react";
// Custom Hooks
import { useLoading } from "./useLoading";
import usePagination from "./usePagination";
import { borrarObjetivo, editarObjetivo, obtenerCanales, obtenerObjetivosLeads, obtenerProductos, postObjetivo } from "../services/ObjetivosLeads";
import { meses, anios } from "../constants/data";
import useModal from "./useModal";
import { useForm } from "./useForm"
import { datosObjetivoLeadReglas } from "../utils/formRules";
import { objetivoLeadForm } from "../utils/formInitialValues";

export const useObjetivosLeads = (filterParams, orderParams) => {

    const [objetivos, setObjetivos] = useState([])
    const [objetivosLength, setObjetivosLength] = useState(null)
    const [productos, setProductos] = useState([])
    const [canales, setCanales] = useState([])
    const [objetivoSeleccionado, setObjetivoSeleccionado] = useState(null)

    const [csvFile, setCsvFile] = useState(null)

    // Form
    const form = useForm(editObjetivo, datosObjetivoLeadReglas, objetivoLeadForm)

    // Loading
    const { loading, setLoading } = useLoading(true)
    const loadingCargarObjetivosLeadsModal = useLoading()

    // Modal
    const cargarObjetivosLeadsModal = useModal()
    const editarObjetivoLeadsModal = useModal()
    const borrarObjetivoLeadsModal = useModal()

    // Paginación
    const pagination = usePagination(objetivosLength)

    // Filter ref
    const filtrosRef = useRef(null)

    const refreshObjetivos = async () => {
        setLoading(true)
        setObjetivos([])
        let paginacion = filtrosRef.current !== filterParams ? 1 : pagination.currentPage
        if(filtrosRef.current !== filterParams){
          filtrosRef.current = filterParams
          pagination.selectPage(1)
        }
        try {
          const objetivos = await obtenerObjetivosLeads(filterParams, orderParams, paginacion)
          const objetivosLength = await obtenerObjetivosLeads(filterParams, orderParams, null)
          setObjetivos(objetivos)
          setObjetivosLength(objetivosLength.length)
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
    }

    useEffect(() => {
        refreshObjetivos()
    }, [filterParams, orderParams, pagination.currentPage])

    useEffect(() => {
    const getInitialData = async () => {
        setObjetivos([])
        try {
          const objetivos = await obtenerObjetivosLeads(null, null, 1)
          const objetivosLength = await obtenerObjetivosLeads(null, null, null)
          const productos = await obtenerProductos()
          const canales = await obtenerCanales()
          setObjetivos(objetivos)
          setObjetivosLength(objetivosLength.length)
          setProductos(productos)
          setCanales(canales)
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
    }
    getInitialData()
  }, [])

  const cargarObjetivos = async () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function(e) {
        const text = e.target.result;
        const headers = text.slice(0, text.indexOf('\n')).split(",")
        headers.splice(headers.indexOf("canal"), 1)
        headers.splice(headers.indexOf("producto"), 1)
        const content = text.slice(63, text.lastIndexOf(text.slice(-1))).split('\n')

        const objetivos = content.map(obj => {
            const values = obj.split(";")
            values.splice(1, 1)
            values.splice(2, 1)

            return {
                canal_obj_lead: values[0],
                prod_obj_lead: values[1],
                lead_obj_lead: values[4],
                op_obj_lead: values[5].slice(0, -1),
                mes_obj_lead: values[2],
                anio_obj_lead: values[3],
            }
        })
        if(objetivos.length > 0){
            objetivos.forEach(async (obj, index) => {
                loadingCargarObjetivosLeadsModal.setLoading(true)
                try {
                    await postObjetivo(obj)
                    if(index == objetivos.length - 1){
                        loadingCargarObjetivosLeadsModal.setLoading(false)
                        refreshObjetivos()
                        cargarObjetivosLeadsModal.setIsModalOpen(false) 
                        /*         
                        setPopUpMessage('¡Objetivos cargados!')
                        setIsSuccessPopUpOpen(true);
                        setTimeout(() => {
                          setIsSuccessPopUpOpen(false)
                        }, 1200);
                        */
                    }
                } catch (error) {
                    console.log(error)
                }
            })
        }
    }
    reader.readAsText(file);
  }

  const openEditModal = (objetivo) => {
    const monthNumber = meses.find(mes => mes.value + 1 === objetivo.mes)
    form.completeForm({
        id: objetivo.id,
        canal: objetivo.id_canal, 
        producto: objetivo.id_producto,
        mes: monthNumber.value,
        anio: objetivo.anio,
        leads: objetivo.leads,
        operaciones: objetivo.operaciones
    })
    editarObjetivoLeadsModal.setIsModalOpen(true)
}

  async function editObjetivo() {
      try {
        const data = {
            canal_obj_lead: form.inputValues.canal,
            prod_obj_lead: form.inputValues.producto,
            lead_obj_lead: form.inputValues.leads,
            op_obj_lead: form.inputValues.operaciones,
            mes_obj_lead: parseInt(form.inputValues.mes) + 1,
            anio_obj_lead: form.inputValues.anio,
        }        
        const objetivo = await editarObjetivo(form.inputValues.id, data)
        if(objetivo.status === 200){   
            refreshObjetivos()
            editarObjetivoLeadsModal.setIsModalOpen(false) 
            /*         
            setPopUpMessage('!Objetivo editado!')
            setIsSuccessPopUpOpen(true);
            setTimeout(() => {
              setIsSuccessPopUpOpen(false)
            }, 1200);
            */
        }

      } catch (error) {
          console.log(error)
          console.log(error.response)
          console.log(error.response.data)
      } 
  }

  const openDeleteModal = (objetivo) => {
    setObjetivoSeleccionado(objetivo)
    borrarObjetivoLeadsModal.setIsModalOpen(true)
}

  const deleteObjetivo = async () => {
    try {
        const borrarObjetivoLead = await borrarObjetivo(objetivoSeleccionado.id)
        if(borrarObjetivoLead.status === 200){ 
            refreshObjetivos()    
            borrarObjetivoLeadsModal.setIsModalOpen(false) 
            /*         
            setPopUpMessage('¡Objetivo eliminado!')
            setIsSuccessPopUpOpen(true);
            setTimeout(() => {
              setIsSuccessPopUpOpen(false)
            }, 1200);
            */
        }
    } catch (error) {
        console.log(error)
    }
  }

  return { 
    data: {
        objetivos: objetivos,
        productos: productos,
        canales: canales,
        meses: meses,
        anios: anios
    },
    loading,
    pagination,
    cargaObjetivosLeads: {
      csvFile: csvFile,
      setCsvFile: setCsvFile,
      cargarObjetivos: cargarObjetivos,
      loading: loadingCargarObjetivosLeadsModal.loading,
      isModalOpen: cargarObjetivosLeadsModal.isModalOpen,
      setIsModalOpen: cargarObjetivosLeadsModal.setIsModalOpen,
    },
    editarObjetivoLead: {
      form: form,
      isModalOpen: editarObjetivoLeadsModal.isModalOpen,
      setIsModalOpen: editarObjetivoLeadsModal.setIsModalOpen,
      openEditModal: openEditModal
    },
    borrarObjetivoLead: {
      deleteObjetivo: deleteObjetivo,
      isModalOpen: borrarObjetivoLeadsModal.isModalOpen,
      setIsModalOpen: borrarObjetivoLeadsModal.setIsModalOpen,
      openDeleteModal: openDeleteModal,
      objetivo: objetivoSeleccionado
    }
  }
};
