import { useState, useEffect } from "react";
import { useLoading } from "../../../../hooks/useLoading";
import { obtenerPlanes } from "../../../../services/Landings";
import axiosRequest from "../../../../utils/axiosConfig";

export const usePlanes = (landing) => {
  const [planes, setPlanes] = useState([]);
  const [productos, setProductos] = useState([])
  const [productoSeleccionado, setProductoSeleccionado] = useState(1)
  const [pruductoSeleccionadoLimites, setProductoSeleccionadoLimites] = useState(1)
  const [limites, setLimites] = useState([])
  const { loading, loadingText, changeLoadingText, setLoading } = useLoading(true);

  useEffect(() => {
    const getPlanes = async () => {
      setPlanes([])
      setLoading(true)
      changeLoadingText(`Cargando información de ${landing.name}`);
      try {
        const planes = await obtenerPlanes(landing.name, landing.initialProduct);
        setPlanes(planes);
        if(landing.name === "Seguro Bici"){
          const productos = await axiosRequest.get(`/productos_landing/${landing.name}`);
          setProductos(productos.data)
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getPlanes();
  }, [landing]);

  const handlePlanes = (planes) => setPlanes(planes)
  const handleProducto = (producto) => setProductoSeleccionado(producto)
  const handleProductoLimite = (producto) => setProductoSeleccionadoLimites(producto)

  const getPlanes = async () => {
    setPlanes([])
    setLoading(true)
    changeLoadingText(`Cargando planes`);
    try {
      const planes = await obtenerPlanes(landing.name, productoSeleccionado);
      setPlanes(planes);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const getLimites = async () => {
      setLimites([])
      try {
        const limites = await axiosRequest.get(`limites_landings/${landing.id_landing}/${pruductoSeleccionadoLimites}`)
        setLimites(limites.data)
      } catch (error){
        console.log(error)
      }
    }
    getLimites()
  }, [landing, pruductoSeleccionadoLimites])

  useEffect(() => {
    if(productoSeleccionado){
      getPlanes()
    }
  }, [productoSeleccionado])

  return {
    planes,
    handlePlanes,
    productos,
    limites,
    handleProductoLimite,
    productoSeleccionado,
    pruductoSeleccionadoLimites,
    handleProducto,
    loading,
    loadingText,
  };
};
