import { useState } from "react"

export default function useModal () {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    
    const handleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    const handleModalMessage = (message) => {
        setModalMessage(message)
    }

    return {
        isModalOpen,
        handleModal, 
        setIsModalOpen,
        modalMessage,
        handleModalMessage
    }
}
