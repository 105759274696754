import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-content: center;

  .mensajesContainer{
    display: flex;
    flex-direction: column;
    align-content: center;
    overflow-y: scroll;
    max-height: 500px;
    box-sizing: border-box;
    padding: 30px 40px;
    border: 1px solid #eaeaea;
    border-radius: 8px;



    /* ScrollBar */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)}; 
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }
  }
  .day-container {
    background-color: #f7f9ff;
    border-radius: 100px;
    color: ${leerColor(colores.grisEstados)};
    //border: 1px solid ${leerColor(colores.grisEstados)};
    width: fit-content;
    display: flex;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 6px 14px;
    font-size: 14px;
  }
  h1{ 
    margin-top:0;
    font-size: 28px;
  }
  h3{
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 22px;
  }

  .loadingContainer{
    display:flex;
    align-items:center;
    justify-content:center;
  }
 
  .editar-solicitud-btn-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 12px 0;

    a {
      text-decoration: none;
    }
  }
  .emitido{
    color: ${leerColor(colores.verde)};
    font-weight: 500;
  }
  .pendiente{
    color: ${leerColor(colores.naranjaEstados)};
    font-weight: 500;
  }
  .rechazado{
    color: ${leerColor(colores.rojo)};
    font-weight: 500;
  }
  .provisoria{
    color: ${leerColor(colores.celesteEstados)};
    font-weight: 500;
  }
  .suscripción{
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 500;
  }
  // Siniestros //
  .siniestroPreNotif{
    background-color: ${leerColor(colores.celestePasos)};
    font-weight: 500;
  }
  .menuRechazadas{
    display: flex;
    flex-direction: row;
  }
`;