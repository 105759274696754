import Modal from "../../../Modal"
import SpinnerLoading from "../../../SpinnerLoading"
import { Container } from "./styled"

const ModalLoading = ({ isModalOpen, setIsModalOpen, message }) => {
    return(
        <>
            {isModalOpen ?
                <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} hideCross={true}>
                    <Container>
                        <SpinnerLoading />
                        <h3>{message}</h3>
                        <h3>Por favor aguarde un momento</h3>
                    </Container>
                </Modal>
                : 
                null
            }
        </>
    )
}

export default ModalLoading