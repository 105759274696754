import React, { useState, forwardRef, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Div, InputDiv, Input, ModalRecordatorio } from "./styled";
import Upload from '../../assets/iconos/upload.svg';
import { Modal, Button } from "../Index";

function InputFileRec({ type, name, required, labelName, files, setFiles, valuesStepTwo, setValuesStepTwo, valuesStepThree, setValuesStepThree, setModalToShow, onClick, text, multiple, aclaracion, accept }, ref) {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const inputRef = useRef(null)

  // Remueve el value del input para que se puede volver a subir el mismo archivo en caso de que se borre
  useEffect(() => {
    document.getElementById(name).value = ''
  }, [valuesStepThree, files])
  
  function onFileUpload(event) {
    event.preventDefault();
    if(event.target.files[0]){
      const { name } = event.target;
      setFiles(prev => ({...prev, [name]: {image: event.target.files[0], name: event.target.files[0].name }}))
      if(setValuesStepTwo){
        setValuesStepTwo(prev => ({...prev, [name]: event.target.files[0], }))
      }
      if(setValuesStepThree){
        setValuesStepThree(prev => ({...prev, [name]: event.target.files[0], }))
      }
     // 
    }

    // Si se pueden subir varios archivos
    if(multiple){
      if(event.target.files.length > 1){
        const { name } = event.target;
        const filesArr = Array.from(event.target.files)
        if(setValuesStepTwo){
          let files = valuesStepTwo.adjuntos_cobertura_abierta ? valuesStepTwo.adjuntos_cobertura_abierta : []
          filesArr.forEach(file =>{
            files.push({image: file, name: file.name })
          })
          let newArray = [...files]
          setValuesStepTwo(prev => ({...prev, [name]: newArray }))
          setFiles(prev => ({...prev, [name]: newArray }))
        } else {
          let files = []
          filesArr.forEach(file =>{
            files.push({image: file, name: file.name })
          })
          setFiles(prev => ({...prev, [name]: files }))
        }
      } else if(event.target.files[0]){
        if(setValuesStepTwo){
          let files = valuesStepTwo.adjuntos_cobertura_abierta ? valuesStepTwo.adjuntos_cobertura_abierta : []
          files.push({image: event.target.files[0], name: event.target.files[0].name })
          let newArray = [...files]
          setValuesStepTwo(prev => ({...prev, [name]: newArray }))
          setFiles(prev => ({...prev, [name]: newArray }))
        } else {
          setFiles(prev => ({...prev, [name]: [{image: event.target.files[0], name: event.target.files[0].name }]}))
        }
        
      }
      //event.target.value = ''
    }
  }

  // MODAL 

  // Frena la ejecución del evento que dispara el explorador y abre el modal
  function handleChangeFileInput(event){
    if(!isModalOpen && aclaracion && !modalOpened){
      event.preventDefault();
      setIsModalOpen(true)
      setModalOpened(true)
    }
  }

  // Abre el explorador de archivos del input correspondiente después de hacer click en el botón "aceptar"
  function handleFileExplorer(){
    let inputToOpen = inputRef.current
    inputToOpen.click()
    setIsModalOpen(false)
  }

  //  onChange={onFileUpload}

  return (
    <>
    {isModalOpen && aclaracion ? 
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} >
        <ModalRecordatorio>
          <p>{aclaracion.descripcion_aclaracion}</p>
          <div className={'buttonCont'}>
            <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={handleFileExplorer}> Aceptar </Button>
          </div>
        </ModalRecordatorio>  
      </Modal>
    : 
      null
    }
    <Div>
      <label htmlFor={name}>{labelName}</label>
      <InputDiv>
        <label>
          <Input
            type={type}
            name={name}
            id={name}
            multiple={multiple}
            required={required}
            accept={accept ? accept : ".png, .jpg, .jpeg, image/jpeg, image/png, .svg, .mp4, .pdf, .docx"}
            onChange={onFileUpload}
            onClick={handleChangeFileInput}
            ref={inputRef}
          />
          <span>
            <img src={Upload} />
            {text ? text : 'Adjuntar archivo'}
          </span>
        </label>
      </InputDiv>
    </Div>
    </>
  );
}

export default forwardRef(InputFileRec);


