import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    margin: 40px auto 0 auto;

    .disabled{
        color: ${leerColor(colores.gris)} !important;
        background: none;
    }
`;

export const Page = styled.span`
    // 
    font-family: 'DM Sans', sans-serif;
    color: ${props => (props.active ? `${leerColor(colores.celeste)}` : `${leerColor(colores.gris)}`)};
    border: ${props => (props.active ? `1px solid ${leerColor(colores.celeste)}` : `1px solid ${leerColor(colores.gris)}`)};
    padding: 10px 16px;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s ;
`;

export const ResultsAmount = styled.span`
    text-align: center;
    color: hsl(0,1.4%,72.5%);
    font-size: 0.9rem;
    display: block;
    margin: 10px 0;
    font-family: 'DM Sans', sans-serif;

`;