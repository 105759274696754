import {Container} from './styled'

const Switch = ({ checked, name, onChange }) => {

  return (
    <Container>
        <label class="switch">
            <input type="checkbox" checked={checked} onChange={onChange} name={name} />
            <span class="slider round"></span>
        </label>
    </Container>
  )
}

export default Switch