import React, { useEffect, useState } from "react";
import axios from "axios";
import SpinnerLoading from '../SpinnerLoading'
import { Container, Icon, IconsContainer, Video } from "./styled";
import { FiDownload, FiExternalLink } from "react-icons/fi";

const VideoGys = ({ url, descarga }) => {
    const [imgTag, setImgTag] = useState(null)

    const getFile = async (url) => {

        const config = {
            headers: { 'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69' },
            responseType: 'blob',
        }

        try {
            const id = Date.now()
            const file = await axios.get(url, config)
            let src;
            var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
            if ( isChrome ){
                src = window.webkitURL.createObjectURL(file.data);
            } else {
                src = window.URL.createObjectURL(file.data);
            }
            
            const img = <Video controls>
                            <source id={id} src={src} type="video/mp4" />
                        </Video>

            return src;
        } catch (error) {
            console.log(error)
            return <img id={'error'} src={'errorurl'} />
        }
    }

    // obtiene el video de la api
    useEffect(() => {
        getFile(url).then(res => setImgTag(res)).catch(e => console.log(e))
    }, [url])

    return (
        <Container>
            {descarga && imgTag ? 
                <IconsContainer>
                    <a href={imgTag} download><Icon><FiDownload /></Icon></a>
                    <a href={imgTag} target="_blank" rel="noreferrer"><Icon><FiExternalLink /></Icon></a>
                </IconsContainer>
            : 
                null
            }
            {imgTag ? 
                <Video controls>
                    <source src={imgTag} type="video/mp4" />
                </Video>
            : 
                <SpinnerLoading />
            }
        </Container>
    )
}

export default VideoGys;