import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const CargaSolicitud = styled.div`
  .stepByStep {
     
    font-weight: 600;
    font-size: 18px;
    color: ${leerColor(colores.celeste)};
  }
  .stepByStep:first-child::before {
    content: none;
  }
  ul li::before {
    content: "";
    position: absolute;
    top: 50%;
    height: 6px;
    width: 100%;
    right: 50%;
    transform: translateY(-50%);
    z-index: -1;
    background: linear-gradient(
      to left,
      ${leerColor(colores.celestePasos)} 50%,
      ${leerColor(colores.verde)} 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 200ms ease-in-out;
  }
  ._y9pkY._3cMiU ._1umoQ {
    color: white;
    background-color: #bb392d !important;
    animation: _3QhsZ 350ms ease-in-out forwards;
  }
  ._2Jtxm ._2kL0S, ._2Jtxm ._2JvrO { 
    // Steps todos //
    background-color: #e7eaf3;
    height: 40px;
    width: 40px;
  }

  ._y9pkY._4bt1T ._1umoQ {
    // Step completado //
    background-color: ${leerColor(colores.verde)};
    border-color: ${leerColor(colores.verde)};
    color: white;
    animation: _3SDnc 400ms ease-in-out forwards;
  }
  ._y9pkY._1-LPA ._3CEPH {
    // Step actual //
    background-color: ${leerColor(colores.celeste)};
    color: white;
    animation: _JAh3L 400ms ease forwards;
  }
  ._y9pkY ._3CEPH, ._y9pkY ._1umoQ {
    // Step faltantes //
    background-color: ${leerColor(colores.blanco)};
    border: 2px solid ${leerColor(colores.celeste)};
  }

  ._2_g61 {   
    //Step container // 
    min-height: 60vh; 
  }

  .progressClass {
    background-color: ${leerColor(colores.blanco)}!important;
  }
  /* ul li span{
    border: 1px solid ${leerColor(colores.celeste)}!important;
    background-color: ${leerColor(colores.blanco)}!important;
  } */
  .btn-prev {
     
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px 26px;
    border-radius: 5px;
    background-color: ${leerColor(colores.blanco)}!important;
    border: 1px solid ${leerColor(colores.celeste)};
    color: ${leerColor(colores.celeste)}!important;
  }
  .btn-next {
     
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px 26px;
    border-radius: 5px;
    background-color: ${leerColor(colores.celeste)}!important;
    color: ${leerColor(colores.blanco)} !important;
  }
  .btn-prev:hover, .btn-next:hover{
    box-shadow: none;
  }

  /* LOADING SPINNER */

  .spinner{
    display: flex;
    flex-direction: revert;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    align-self: center;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${leerColor(colores.celeste)} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonsCont = styled.div`
  width: 100%;
  display: flex;
  place-content: space-between;
  padding-top: 65px;
`;

export const Titulo = styled.h2`
   
  color: ${leerColor(colores.celeste)};
`;

export const LoadingContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
