import React from 'react'
import { Col, Grid } from '../Grid'
import NewTitle from '../NewTitle'
import { Container } from './styled'

const NewTitlePageSection = ({ title, description, tabs, children }) => {
  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={12}>
        <Container>
          <div className="container-section">
            <div className="title-container">
              <NewTitle textColor={false}>{title}</NewTitle>
              {description ? <p className="descripcion">{description}</p> : null}
            </div>
            <div className="container-acciones">
              {children}
            </div>
          </div>
          {tabs}
        </Container>
      </Col>
    </Grid>
  )
}

export default NewTitlePageSection