// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import FilterSection from "../../../components/FiltersSection";
import TableInfo from "../../../components/TableInfo";
import ModalDescargaCSV from "../../../components/Modals/General/ModalDescargaCSV";
import TitlePageSection from "../../../components/TitlePageSection";
import Button from "../../../components/Button";
import Subtitulo from "../../../components/Subtitulo";
import { Container, Icon } from "./styled";
// Custom Hooks
import useFiltersNew from "../../../hooks/useFiltersNew";
import useProductoCanal from "../../../hooks/useProductoCanal"
import { useTable } from "../../../hooks/useTable";
import useModal from "../../../hooks/useModal";
// Filters
import { filtersProductoCanal } from "../../../utils/filtersPages";
import { filterProductoCanalInitialValues } from "../../../utils/filtersPages";
// Table
import { productoCanalTable } from "../../../utils/tableData";
// Icons
import { MdFileDownload } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5"
import { BsPieChart } from "react-icons/bs"
import SpinnerLoading from "../../../components/SpinnerLoading";

const EstadisticasProductoCanal = () => {

    const { order, handleTableOrder } = useTable()
    const { isModalOpen, handleModal } = useModal()

    const { data, getEstadisticas, loading, descarga } = useProductoCanal()
    const { inputValues, params, handleSearch, handleChange, clearSearch } = useFiltersNew(filterProductoCanalInitialValues, getEstadisticas)

    const filters = filtersProductoCanal(data)

    const tableData = productoCanalTable(data.mesAnterior)

    return (
        <div>

            <ModalDescargaCSV isModalOpen={isModalOpen} setIsModalOpen={handleModal} descarga={descarga} />

            <Sidebar active={"estadisticas-producto-canal"}/>
            <Wrapper>
                <Inner>
                <Grid colGap={21} rowGap={21} narrow={true}>
                    
                    <Col desktop={12}>
                    <TitlePageSection title="Estadísticas Producto Canal">
                        <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} disabled={Object.values(data.total).length === 0} onClick={handleModal}>
                            <span><MdFileDownload></MdFileDownload></span>Descargar estadísticas
                        </Button>
                    </TitlePageSection>
                    </Col>

                    <Col desktop={12}>
                    <FilterSection section="estadísticas" inputs={filters} values={inputValues} params={params} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} required={true} />
                    </Col>
                </Grid>


                {data.marketing.length === 0 ? 
                    <Container>
                        {loading ?
                            <SpinnerLoading text={"Cargando estadísticas"} />
                        :
                            <>
                                <Icon><BsPieChart /></Icon>
                                <Subtitulo>No hay filtros aplicados</Subtitulo>
                                <p>Usá los filtros <strong>desde, hasta y producto</strong> para obtener estadísticas de todos los canales y productos.</p>
                            </>
                        }
                    </Container>
                :
                    <>
                        <TableInfo columns={tableData} data={data.marketing} loading={loading} order={handleTableOrder} orderParams={order} title="Marketing" total={data.subtotalMarketing} />

                        <TableInfo columns={tableData} data={data.comercial} loading={loading} order={handleTableOrder} orderParams={order} title="Comercial" total={data.subtotalComercial} />

                        <TableInfo columns={tableData} data={data.otrosCanales} loading={loading} order={handleTableOrder} orderParams={order} title="Otros Canales" total={data.subtotalOtrosCanales} />

                        <TableInfo columns={tableData} data={[data.total]} loading={loading} order={handleTableOrder} orderParams={order} title="Total" />
                    </>
                }
                </Inner>
            </Wrapper>        
        </div>
    )
}

export default EstadisticasProductoCanal
