import styled from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const SubtituloNombre = styled.h2`
  // 
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  //color: ${leerColor(colores.negro)};
  color: ${leerColor(colores.celeste)};
  //margin: ${(props) => (props.margin ? "60px 0 20px 0" : "")};
  margin: ${(props) => (props.margin ? "40px 0 20px 0" : "")};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  span {
    margin: 0px 7px 0px 0px;
    font-size: 20px;
  }

  @media (min-width: ${breakpoints.mobileGrande}) {
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.laptop}) {
  }
  @media (min-width: ${breakpoints.laptopL}) {
  }
  @media (min-width: ${breakpoints.notebook}) {
  }
`;
