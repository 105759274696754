import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  position: relative;

  #proxy-renderer, #header-bar{
    width:100%!important;
  }

  #pdf-renderer {
    //overflow: hidden;
    //overflow-y: initial;
    overflow-y: scroll;
  }

  #pdf-controls {
    background-color: ${leerColor(colores.blanco)};
    border-bottom: 1px solid #eaeaea;
    box-shadow: none;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
  }

  #pdf-controls a, #pdf-controls button {
    border: 1px solid #eaeaea;
    box-shadow: none;
  }

  #pdf-controls a svg path, 
  #pdf-controls a svg polygon,
  #pdf-controls button svg g g path,
  #pdf-controls button svg path {
    fill: ${leerColor(colores.azulRebranding)};
  }
`;

