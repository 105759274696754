import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const InputDiv = styled.div`
  width: 100%;
  position: relative;
  margin: 5px;
  display: flex;
  align-items: flex-start;

  label {
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    color: ${leerColor(colores.negro)};
    margin-left: 5px;
    font-weight: 500;
  }

  input[type="checkbox"] {
    border:2px solid red;
}
`