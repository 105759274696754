import styled from "styled-components";
import { leerColor, colores } from "../../constants";

export const Container = styled.div`
  width: 100%;
  margin-top: 6px;
  border-radius: 6px;
  position: absolute;
  background-color: #fff;
  overflow-y: scroll;
  border: 1px solid #f2f2f2;
  z-index: 2000;
  max-height: 210px;
  top: 70px;
`

export const AutocompleteSuggestion = styled.div`
    display: block;
    padding: 8px 14px;
    margin: 0;
    border-bottom: 1px solid #f2f2f2;
    font-size: 0.8em;
    cursor: pointer;
    background-color: ${(props) => (props.active ? `#f3f6ff` : ``)};
    color: ${(props) => (props.active ? `${leerColor(colores.azulRebranding)}` : `#868686`)};
    font-weight: ${(props) => (props.active ? `600` : `400`)};
    position: relative;
    z-index: 2000;

    :last-child {
      border: none;
    }

    :hover {
      background-color: #f2f2f2;
    }
`