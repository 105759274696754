import { useEffect, useState } from "react";
import { deleteReject, sendMailManually, updateReject } from "../../functions/requestHttp";
import {Button} from "../Index";
import { ConfirmationMain } from "./styled";

function Confirmation ({details, confirmation,setConfirmation, setSuccess, newstate }){
    const [buttonMsg, setButtonMsg] = useState('');
    const [disabled, setDisabled] = useState(false);

    const DeleteRejectSelect =  async(id)=>{
        const result = await deleteReject(id);
        if(result.type ==='success'){
            setSuccess('Registro eliminado correctamente');
        }
        else{
            setSuccess('Error al eliminar el rechazo')
        }
    }

    const sendMail = async ()=>{
        setSuccess('Enviando correo nuevamente...')
        const result = await sendMailManually([details]);
        if(result){
            setSuccess('Correo reenviado correctamente')
        }else{
            setSuccess('Error al reenviar el correo')
        }
    }

    // NUEVA FUNCIÓN
    const handledUpdateReject = async (newState)=>{
        try {
            const {msg} = await updateReject(details.id, { ...details, id_estado: newState})
            setSuccess(msg)
        } catch (error) {
            console.log(error)
        }
    }

    const confirmAction = async ()=>{
        setDisabled(true);
        if(confirmation==='delete'){
            await DeleteRejectSelect(details.id);
        }
        else if(confirmation==='sendMail'){
            await sendMail(details.id);
        }
        else{
            // si es una actulizacion solo se envia el id del estado a actualizar
            await handledUpdateReject(newstate)
        }
    }

    useEffect(()=>{
        switch (confirmation) {
            case 'delete':
                setButtonMsg('Confirmar y Borrar Rechazo')
                break;
            case 'sendMail':
                setButtonMsg('Reenviar notificación por correo')
                    break;
            default:
                const msg = {
                    4 : "contactado",
                    5 : "resuelto positivo",
                    6 : "resuelto negativo"
                }
                setButtonMsg(`Cambiar a ${msg[confirmation]}`)
                break;
        }
    },[confirmation]);
    return (
        <ConfirmationMain>
            <h4>Confirme si desea realizar los cambios</h4>
            <br/>
            <Button
                onClick={()=>setConfirmation('')}
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                className={"warning"}
                disabled={disabled}
            >     
                Volver
            </Button>
            <Button
                onClick={confirmAction}
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                className={"warning"}
                disabled={disabled}
            >     
                {buttonMsg}
            </Button>
        </ConfirmationMain>
    )
}

export default Confirmation;