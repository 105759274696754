import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Error = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:8px;
`;

export const ArchivoCargado = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.negro)};
  font-weight: 500;
  font-size: 14px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NombreArchivoCargado = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.grisEstados)};
  width: ${props => (props.isLarger ? "170px" : '170px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const InputCheckbox = styled.div`
  >div{ flex-direction: row!important };
`;

export const ModalRecordatorio = styled.div`
    width: 620px;
    align-items: center;
    padding: 42px 50px 36px;
    
    div {align-items: center; }
  p {
    margin: 0;
    font-family: 'DM Sans',sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 34px;
    text-align: center;
    color: ${leerColor(colores.grisModales)};
  }
  .buttonCont {
    display: flex;
    place-content: flex-end;
  }
`;

export const ThumbnailContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  color: hsl(0,0%,32.2%);
  width: ${props => (props.isLarger ? "400px" : '')};
  margin: ${props => (props.isLarger ? "15px 0 10px 0" : '15px 0 0 0')};
  border: 1px solid #eaeaea;
  border-radius: 6px;
  &:first-of-type{
    margin: ${props => (props.isLarger ? "10px 0 0 0" : '15px 0 0 0')};
  }
  padding: 16px;
  overflow: hidden;

  .file-container{
    display: flex;
    align-items: center;
  }

  .file{
    display:flex;
    padding: ${props => (props.isLarger ? "0" : '0')};

    a:visited {
      color: ${leerColor(colores.azulRebranding)};
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    cursor:pointer;
    border: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  .icon:hover {
    opacity: 0.7;
  }
`;

export const ThumbnailTextContainer = styled.div`
  display:flex; 
  flex-direction:column;
  align-items: flex-start;
  padding: ${props => (props.isLarger ? "0px 0px" : '0px 0px 0px 0px ')};
  margin-left:15px;
  gap: 4px;
`;

export const ThumbnailImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 6px;
  cursor:pointer;
  border: 1px solid #eaeaea;
  
  :hover{
    opacity: 0.7;
  }
`;

export const PreviewFile = styled.img`
  width:500px;
`;

export const Video = styled.video`  
  width: auto;
  height:50vh;
  margin: 0 auto;
`

export const Icon = styled.img`
  width: 22px;
`;

export const IconContainer = styled.div`
  display:flex;
  cursor:pointer;
  //border-left: 1px solid #f1f1f1;

  .icon-borrar {
    color: ${leerColor(colores.negro)};
    display: flex;
    font-size: 20px;
  }

  :hover{
    opacity:0.7;
  }

  span {
    display: flex;
    color: ${leerColor(colores.rojo)};
    font-size: 26px;
  }
`;