import Modal from "../../../Modal"
import Button from "../../../Button"
import { ModalRecordatorio } from "./styled"
import Subtitulo from "../../../Subtitulo"
import { FiAlertCircle } from "react-icons/fi"
import NewTitle from "../../../NewTitle"
import NewButton from "../../../NewButton"

const ModalAclaracion = ({ isModalOpen, handleModal, inputName, aclaracion, handleFileExplorer }) => {
    return(
        <>
            {isModalOpen ? 
            <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal} >
                <ModalRecordatorio>
                    <div className="header-container">
                    <NewTitle>Aclaración {inputName}</NewTitle>
                    <p>{aclaracion.descripcion_aclaracion}</p>
                    </div>
                    <hr />
                    {/*
                    <div className="data-container">
                    <p>{aclaracion.descripcion_aclaracion}</p>
                    </div>
      
                    <hr />
                          */}
                    <div className={"actionButtonsCont"}>
                        <div className={'buttonsCont'}>
                            <NewButton backgroundColor={false} borderColor={true} textColor={true} onClick={handleModal}>Cancelar</NewButton>
                            <NewButton backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={handleFileExplorer}>Subir archivo</NewButton>
                        </div>
                    </div>
                </ModalRecordatorio>  
            </Modal>
            : 
            null
            }
        </>
    )
}

export default ModalAclaracion