import React from "react";
import PropTypes from "prop-types";
//import { InputDiv, Input, Icon, Autocomplete, AutocompleteSuggestion } from "./styled";
import { InputDiv, Input, Icon } from "./styled";
import { MdInfoOutline } from "react-icons/md";
// Components
import Autocomplete from "../Autocomplete"

function InputBasic({ labelName, type, name, placeholder, onChange, value, disabled, maxLength, color, checked, pattern, onBlur, showIcon, iconImage, iconCallback, labelBackground, autocomplete, autocompleteActive, setAutocomplete, onKeyDown, cleanAutocomplete }) {

  return (
    <InputDiv labelBackground={labelBackground}>
    <input 
      type={type}
      name={name}
      color={color}
      id={name}
      placeholder={placeholder}
      checked={checked}
      onChange={onChange}
      value={value}
      disabled={disabled}
      maxLength={maxLength}
      onBlur={onBlur}
      onWheel={() => document.activeElement.blur()}
      onKeyDown={onKeyDown}
      pattern={pattern}
    />
    <div className="label-container">
      <label
        className={
          value == null || value == ""
            ? "label-down"
            : "label-top"
        }
        htmlFor={name}
        color={color}
      >
        {labelName}
      </label>
      {iconImage && 
        <Icon>
          {typeof(icon) === "string" ?
              <img className="tooltip" src={iconImage} alt="icono" />
            :
              <span className="size" onClick={iconCallback}>{iconImage}</span>
          }
        </Icon>
      }
    </div>
    {autocomplete?.length ?
      <Autocomplete autocomplete={autocomplete} autocompleteActive={autocompleteActive} setAutocomplete={setAutocomplete} name={name} cleanAutocomplete={cleanAutocomplete} />
    : null}
  </InputDiv>
  )
}

export default InputBasic;
