import { Grid, Col } from "../../../components/Grid"
import Inner from "../../../components/Inner"
import Sidebar from "../../../components/Sidebar"
import TableInfo from "../../../components/TableInfo"
import Wrapper from "../../../components/Wrapper"
import TitlePageSection from "../../../components/TitlePageSection"
import FilterSection from "../../../components/FiltersSection"
import { Container } from "./styled"
import NotificacionPagina from "../../../components/NotificacionPagina"
// Custom Hooks
import useFiltersNew from "../../../hooks/useFiltersNew";
import { useTable } from "../../../hooks/useTable"
import { autogestionTable } from "../../../utils/tableData"
import { useAutogestion } from "../../../hooks/useAutogestion"
import { useSocket } from "../../../context/SocketProvider"
// Filters
import { filtersAutogestion } from "../../../utils/filtersPages";
import { filterAutogestionInitialValues } from "../../../utils/filtersPages";
import useModal from "../../../hooks/useModal"
import ModalPago from "../../../components/Modals/Autogestion/ModalPago"
import { useNotificacionesPage } from "../../../hooks/useNotificaciones"
import NewTable from "../../../components/NewTable"
import Divider from "../../../components/Divider"
import NewButton from "../../../components/NewButton"
import { FiFilter } from "react-icons/fi"
import { useSingleOpen } from "../../../hooks/useSingleOpen"
import NewFiltersSection from "../../../components/NewFiltersSection"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const AutogestionRefactor = () => {

    const pagoModal = useModal()
    const location = useLocation()

    const { order, handleTableOrder } = useTable()

    // Filtros
    const { inputValues, params, handleSearch, handleChange, clearSearch } = useFiltersNew(filterAutogestionInitialValues)

    // Autogestion
    const { data, loading, pagination, modalLoading } = useAutogestion(params, order, pagoModal.handleModal)

    // Notificaciones
    const { notificaciones, resetNotificaciones, mensaje } = useNotificacionesPage(['autogestión'], data.refreshContrataciones)

    const filters = filtersAutogestion()

    const tableData = autogestionTable(location)

    const { isOpen: isFiltersOpen, handleOpen: handleFiltersOpen } = useSingleOpen()

    return (
        <>

        <ModalPago isModalOpen={pagoModal.isModalOpen} setIsModalOpen={pagoModal.handleModal} data={data.pago} loading={modalLoading} />

        <Sidebar active="autogestion" />
          
        <Wrapper>
          <Inner>
            <Container>
            <Grid colGap={21} rowGap={21} narrow={true}>
              
              <Col desktop={12}>
              <TitlePageSection
                  title="Autogestión - Emisión"
                  description={"Lista de contrataciones provenientes de las landings"}
                >
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={() => handleFiltersOpen(!isFiltersOpen)}
                  >
                    <FiFilter /> Filtros
                    {params?.split("=").length > 1 ? (
                      <span className="filtros-aplicados">
                        {params?.split("=").length - 1}
                      </span>
                    ) : null}
                  </NewButton>
                </TitlePageSection>
              </Col>
            </Grid>

            {isFiltersOpen ? (
              <>
              <Divider />
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                  <NewFiltersSection
                    section="contrataciones hechas a través de las landings"
                    inputs={filters}
                    values={inputValues}
                    params={params}
                    handleChange={handleChange}
                    handleSearch={handleSearch}
                    clearSearch={clearSearch}
                  />
                </Col>
              </Grid>
              </>
            ) : null}
            
            <Divider />
            <NewTable
              columns={tableData}
              data={data.contrataciones}
              loading={loading}
              order={handleTableOrder}
              orderParams={order}
              pagination={pagination}
            />
            </Container>
          </Inner>
        </Wrapper>
      </>
    )
}

export default AutogestionRefactor