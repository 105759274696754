import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const MenuDiv = styled.div`
  ${props => props.active === 'solicitudEmision' && css`
    .solicitudEmision {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'emisiones' && css`
    .emisiones {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'clientes' && css`
    .clientes {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'estadisticas' && css`
    .estadisticas {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'estadisticas-producto-canal' && css`
    .estadisticas-producto-canal {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'objetivos' && css`
    .objetivos {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'consultas' && css`
    .consultas {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'siniestros' && css`
    .siniestros {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'fichajes' && css`
    .fichajes {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'historialSolicitudes' && css`
    .historialSolicitudes {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'objetivos-cobranzas' && css`
    .objetivos-cobranzas {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'estadisticas-cobranzas' && css`
    .estadisticas-cobranzas {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'autogestion' && css`
    .autogestion {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'premios-vendedores' && css`
    .premios-vendedores {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'premios' && css`
    .premios {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
  ${props => props.active === 'objetivos-por-vendedor' && css`
    .objetivos-por-vendedor {
      background-color: rgba(50, 137, 185, 30%);
      transition: 0.2s;
    }
  `}
`;

export const StyledMenu = styled.div`
  
  position: fixed;
  width: 340px;
  // 
  font-family: 'DM Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${leerColor(colores.azul)};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  text-align: left;
  z-index: 99;
  top: 0;
  left: 0;
  
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  -ms-transform: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out; 
  
  h2 {
    font-size: 36px;
    color: ${leerColor(colores.blanco)};
    margin-bottom: 42px;
    margin-top: 120px;
    padding: 0 2rem;
    font-family: 'DM Sans', sans-serif;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  nav {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: -webkit-fill-available;
    overflow: scroll;
  }

  nav::-webkit-scrollbar {
    display: none;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: unset;
    height: 100%;
  }
  ul li {
    transition: 0.2s;
  }
  ul li:hover {
    background-color: rgba(50, 137, 185, 30%);
    transition: 0.2s;
  }
  ul li:last-of-type {
    margin: auto 0 10px;
  }
  ul li a {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 12px 2rem;
    font-weight: 600;
    color: ${leerColor(colores.blanco)};
    text-decoration: none;
    transition: color 0.3s linear;
    font-family: 'DM Sans', sans-serif;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  ul li a figure {
    margin: 0;
    width: 15%;
    text-align: center;
  }
  ul li a span {
    padding-left: 20px;
  }

  .icon {
    font-size: 30px;
    color: ${leerColor(colores.celeste)};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
`;
