import React, { useState, useEffect } from "react";
import { Frame, BtnFrame } from '../Gallery/styled';
import axios from 'axios';


const FrameLoad = ({ img, setOpenModalDetalles, setDetalles }) => {
    const [imgTag, setImgTag] = useState(null);
    const [copiado, setCopiado] = useState(false);
    const getImg = async () => {
        const copiarEnlace = () => {
            setCopiado(true);
            navigator.clipboard.writeText(img.url);

        }
        const verDetalles = async (src) => {
            setOpenModalDetalles(true)
            try {
                const {data} = await axios.get(`${img.url}?fulldata=true`)
                setDetalles({...data,src})
            } catch (error) {
                console.log(error)
            }
        }
        try {
            const id = Date.now()
            const res = await axios.get(img.url, { responseType: 'blob' })
            let src;
            var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
            if ( isChrome ){
                src = window.webkitURL.createObjectURL(res.data);
            } else {
                src = window.URL.createObjectURL(res.data);
            }
            const tag = <>
                <img id={id} src={src} alt={"img"} />
                <BtnFrame>
                    <a onClick={copiarEnlace}>{copiado ? 'Copiado' : 'Copiar URL'}</a>
                    <a onClick={()=> verDetalles(src)}>Detalles</a>
                </BtnFrame>
            </>
            return (tag);
        } catch (error) {
            console.log(error)
            return (
                <div>
                    <img id={'error'} src={'errorurl'} alt={"Error al cargar"} />
                </div>)
        }
    }
    useEffect(() => {
        getImg().then(res => setImgTag(res))
    }, [])

    return (
        <Frame>
            {imgTag || <div className="loading"></div>}
        </Frame>
    )
}

export default React.memo(FrameLoad); 