import { useState, useEffect } from "react"
import axiosRequest from "../utils/axiosConfig";
import { useParams, useLocation } from "react-router-dom";
import { getCoberturas, getCompanias, getProductos, getSolicitud, getVigencias } from "../services/Emision";

export default function useEditarSolicitud () {

    const [solicitud, setSolicitud] = useState({
      asegurado: {},
      seguro: {},
      bienAsegurado: [],
      pago: {}
    })
    const [selectsData, setSelectsData] = useState({
      productos: [],
      coberturas: [],
      vigencias: []
    })

    const location = useLocation()
    const solicitudId = useParams();
    const id = solicitudId.id
    const tipoSolicitud = new URLSearchParams(location.search).get("tipoSolicitud");

    useEffect(() => {
        const getData = async () => {
          if(id){
            try{
              const solicitud = await getSolicitud(id)
              console.log(solicitud)
              setSolicitud(solicitud)

              if(tipoSolicitud !== 3){
                const companias = await getCompanias()
                const productos = await getProductos(solicitud.seguro.cia_cobertura_asegurada)
                const coberturas = await getCoberturas(solicitud.seguro.cia_cobertura_asegurada, solicitud.seguro.producto_cobertura_asegurada)
                const vigencias = await getVigencias(solicitud.seguro.cia_cobertura_asegurada, solicitud.seguro.producto_cobertura_asegurada, solicitud.seguro.cobertura_cobertura_asegurada)
                setSelectsData({ companias: companias, productos: productos, coberturas: coberturas, vigencias: vigencias })
              }
            } catch (error){
              console.log(error)
            }
          }
        }
        getData();
      }, [id]);

    return {
        solicitud,
        selectsData
    }
}
