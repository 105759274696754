import axios from 'axios';
import axiosRequest from './axiosConfig'
  
  // Sube archivos al server
const sendFiles = async (file) => {
    console.log("SUBIR ARCHIVO")
    console.log(file)
    let bodyFormData = new FormData();
    try {
      bodyFormData.append('fotos', file.image, file.name);
      const createDoc = await axiosRequest.post('/upload_files', bodyFormData);
      let path = createDoc.data[0].path
      return path
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
}

export default sendFiles
