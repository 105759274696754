import React, { useState, useEffect } from "react";
import {
  Subtitulo,
  Col,
  InputSelect,
  Button,
} from "../../../Index";
//import axios from "axios";
import axiosRequest from '../../../../utils/axiosConfig'
import { Table } from "../../../Table/styled";


const RenovacionesPAS = ({mesesSelect, productoresSelect}) => {

    // estructura del filtro
    const filtroStruct = {
        productor: '',
        mes: ''
    }
    // cargando
    const [loading, setLoading] = useState(false)

    // estructura tabla
    const [datosTabla, setDatosTabla] = useState([]);

    const [filtro, setFiltro] = useState({...filtroStruct})
    const [productor, setProductor] = useState('')

    // desactiva el boton
    const [btnDisabled, setBtnDisabled] = useState(false)

    const filtrarRenovaciones = (e)=>{
        const {name, value} = e.target;
        setFiltro({...filtro,[name]: value})
    }

    const downloadExcel = async (productorId,compania,mes) => {
        const nombre = productoresSelect.find(p => (p.value === productorId))
        const send = await axiosRequest({
            method:'GET',
            url: `/productores/renovaciones?download=true&compania=${compania}&productor=${productorId}&mes=${mes}`,      
            responseType: 'blob', 
        });
        if(send.status === 200){
            let url;
            var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
            if ( isChrome ){
                url = window.webkitURL.createObjectURL(new Blob([send.data]));
            } else {
                url = window.URL.createObjectURL(new Blob([send.data]));
            }
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `reno_pas_${nombre.label||''}_${Date.now()}.xlsx`);
            document.body.appendChild(link)
            link.click();
        }
        setBtnDisabled(false)
    }

    const startDownload = () => {
        setBtnDisabled(true)
        downloadExcel(parseInt(filtro.productor),5,parseInt(filtro.mes))
    }

    const getRenovaciones = async (id_productor, compania, mes)=>{
        setLoading(true)
        try {
            const send = await axiosRequest.get(`/productores/renovaciones?compania=${compania}&productor=${id_productor}=&mes=${mes}`);
            console.log(send.data)
            setDatosTabla(send.data.msg[0].productos)
            setProductor(send.data.msg[0].id_productor)
            setLoading(false)  
        } catch (error) {
            console.log(error)
        }
        
    }


    // obtiene las renovaciones por los filtros
    useEffect(()=>{
        if(filtro.mes!=='' && filtro.productor!==''){
            // fedpat => compania = 5
            getRenovaciones(parseInt(filtro.productor),5,parseInt(filtro.mes))
        }
    },[filtro]);

    return (
            <>                 
    
                        <Col desktop={12} spaced={true}>
                            <Subtitulo>Filtrar</Subtitulo>
                            {
                                datosTabla.length!==0?
                                <Button backgroundColor={true} disabled={btnDisabled} borderColor={false} textColor={false} icon={false} onClick={startDownload}>
                                    {btnDisabled? 'Descargando...' :'Descargar'}
                                </Button>
                                :
                                null
                            }
                        </Col>
                        <Col desktop={6}>
                                    <InputSelect
                                        name={"productor"}
                                        labelName={"Productor"}
                                        id={"productor"}
                                        options={productoresSelect}
                                        onChange={filtrarRenovaciones}
                                        value={filtro.productor}
                                    />
                        </Col>
                        <Col desktop={6}>
                                    <InputSelect
                                        name={"mes"}
                                        labelName={"Mes a Comparar"}
                                        id={"mes"}
                                        options={mesesSelect}
                                        onChange={filtrarRenovaciones}
                                        value={filtro.mes}
                                    />
                        </Col>
                        {
                            !loading?
                            <Col desktop={12}>
                            {
                                datosTabla.length!==0?
                                <div>
                                    {
                                        datosTabla.map(info => {
                                            return(
                                            <div key={info.id_producto}>
                                            <Subtitulo>{info.descrip }</Subtitulo>
                                            <Table >
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Marzo</th>
                                                    <th>Mes Actual</th>
                                                    <th>Diferencia</th>
                                                    {productor===99999?
                                                    null :
                                                    <th>{info.id_producto===9999?'Comisión Real':'Comisión'}</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Renovaciones</td>
                                                    <td>{info.efectivasMarzo} de {info.cantidadMarzo}</td>
                                                    <td>{info.efectivasMesActual} de {info.cantidadMesActual}</td>
                                                    <td className={info.efectivasDiferencia > 0? 'verde':'rojo'}>{((info.efectivasDiferencia)) || 0}</td>
                                                    {productor===99999?
                                                    null :
                                                    <td className={info.comision > 0? 'verde':'rojo'}>{info.comision}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>Prima</td>
                                                    <td>{Math.floor(info.primaMarzo)}</td>
                                                    <td>{Math.floor(info.primaMesActual)}</td>
                                                    <td className={Math.floor(info.primaDiferencia) > 0? 'verde':'rojo'}>{Math.floor(info.primaDiferencia)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Efectividad</td>
                                                    <td> {info.efectividadMarzo} % </td>
                                                    <td> {info.efectividadMesActual} %  </td>
                                                    <td className={info.efectividadDiferencia > 0? 'verde':'rojo'}> {info.efectividadDiferencia} % </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        </div>
                                        )})
                                    }
                                    
                            </div>
                            : 
                            'Seleccione un productor y un mes'
                            }
                            
                        </Col>
                        
                        :
                        
                        <Col desktop={12}><h4>Obteniendo datos...</h4></Col>
                        }   

            </>);
};

export default RenovacionesPAS;