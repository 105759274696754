import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Error = styled.span`
   
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:8px;
`;

export const ArchivoCargado = styled.span`
   
  color: ${leerColor(colores.negro)};
`;

export const NombreArchivoCargado = styled.span`
   
  color: ${leerColor(colores.negro)};
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputCheckbox = styled.div`
    div{ 
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    order: 1;

    label {
      padding:0;
    }

    input{
      margin-right:15px;
    }
  };
`;

export const ModalRecordatorio = styled.div`
    width: 620px;
    align-items: center;
    padding: 42px 50px 36px;
    
    div {align-items: center; }
  p {
    margin: 0;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 34px;
    text-align: center;
    color: ${leerColor(colores.grisModales)};
  }
  .buttonCont {
    display: flex;
    place-content: flex-end;
  }
`;

/*
export const ThumbnailContainer = styled.div`
  display:flex;
  padding:10px 0;
  align-items: center;
  justify-content: space-between;
`;
*/

export const ThumbnailContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-around;
  background-color: hsl(0,0%,100%);
    -webkit-box-shadow: 12px 12px 30px 0px rgb(177 178 180 / 20%);
  box-shadow: 12px 12px 30px 0px rgb(177 178 180 / 20%);
  color: hsl(0,0%,32.2%);
  //padding: 20px 0;
  //min-width: 450px;
  margin-top: 15px;
  border: 1px solid #f5f5f5;

  .cont{
    display:flex;
    align-items: center;
  }

  .cross{
    display:flex;
    //padding: 0 25px;
    padding-right: 11px;
    cursor:pointer;

    :hover{
      opacity:0.7;
    }
  }

  .file{
    display:flex;
    padding: 0;
  }
`;

/*
export const ThumbnailTextContainer = styled.div`
  display:flex; 
  flex-direction:column;
  align-items: flex-start;
`;
*/

export const ThumbnailTextContainer = styled.div`
  display:flex; 
  flex-direction:column;
  align-items: flex-start;
  //padding: 20px 25px;
  padding: 15px 0px 15px 10px;
  border-left: 1px solid #f1f1f1;
`;

export const ThumbnailImage = styled.img`
  width:40px;
  cursor:pointer;
  
  :hover{
    opacity: 0.7;
  }
`;

export const PreviewFile = styled.img`
  width:500px;
`;

export const Video = styled.video`  
  width: auto;
  height:50vh;
  margin: 0 auto;
`

export const Icon = styled.img`
  width: 25px;
`;

export const CheckboxContainer = styled.div`
  background: blue;
  display: flex;
  width: 100%;
  margin:40px 0;
`;

export const Container = styled.div`
  display: flex;
  margin:20px 0 40px 0;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const StepContainer = styled.div`
  .resetSelect{
    position: absolute;
    font-family: sans-serif;
    right:473px;
    font-size:12px;
    top: 610px;
    cursor: pointer;
    color: ${leerColor(colores.celeste)};
    font-weight: 500;

    :hover{
      opacity: 0.8;
    }
  }

  .resetSelect2{
    position: absolute;
    font-family: sans-serif;
    right:473px;
    font-size:12px;
    top: 513px;
    cursor: pointer;
    color: ${leerColor(colores.celeste)};
    font-weight: 500;

    :hover{
      opacity: 0.8;
    }
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 40px 0;
  }
`;