import Modal from "../../../Modal";
import Titulo from "../../../Titulo";
import Subtitulo from "../../../Subtitulo";
import IconButton from "../../../IconButton";
import DeleteIcon from "../../../../assets/iconos/delete.svg";
import InputSelect from "../../../InputSelect";
import Button from "../../../Button";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import { FiFile, FiTrash2, FiUser } from "react-icons/fi";
import { Grid, Col } from "../../../Grid";
import { Container, OperadorItem, RechazoContainer } from "./styled";
import NewTitle from "../../../NewTitle";
import NewButton from "../../../NewButton";
import { LOGOS_COMPANIAS } from "../../../../constants/logos-companias";
import NewTabs from "../../../NewTabs"
import { useTabs } from "../../../../hooks/useTabs";
import Divider from "../../../Divider";
import { useEffect } from "react";
import Success from "../../../Success";

const ModalNotificacionMasivaRechazos = ({
  modal,
  asignacion
}) => {

  const { isModalOpen, setIsModalOpen, handleModal } = modal
  const { rechazosSeleccionados, seleccionarRechazo, removerRechazo, notificarRechazos, loading, success } = asignacion

  return (
    <>
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <RechazoContainer>
          {
            success ?
            <Success title="¡Gestión realizada!" description="Rechazos notificados correctamente" handleModal={handleModal} />
            :
            <>
            {rechazosSeleccionados.length === 0 && (
            <>
              <div className="header-container">
              <NewTitle>
                  Notificaciones masivas
              </NewTitle>
              <p>Notificá múltiples rechazos al mismo tiempo</p>
            </div>
            <hr />
            <div className="sin-solicitudes-container">
              <span className="icon-sin-solicitudes">
                <FiFile />
              </span>
              <Subtitulo>No hay rechazos seleccionados</Subtitulo>
              <span className="description">
                Seleccioná un rechazo haciendo click en el{" "}
                <strong>recuadro antes del ID</strong>.
              </span>
            </div>
            </>
          )}

          {rechazosSeleccionados.length > 0 && (
        //   {rechazosSeleccionados.length > 0 && !loading && !solicitudesAsignadas && (
            <>
              <div className="header-container">
                <NewTitle>
                    Notificaciones masivas
                </NewTitle>
                <p>Notificá múltiples rechazos al mismo tiempo</p>
              </div>
              <hr />

              <div className="data-container">
                <div className="solicitudes-container">
                {rechazosSeleccionados.map((item, index) => {
                  const logo = LOGOS_COMPANIAS.find(logo => logo.nombre.toLocaleLowerCase() === item.compania.toLowerCase())?.url
                  return (
                    <>
                    <OperadorItem>
                      <>
                        <div className="solicitud">
                          <div className="logo-container">
                            <img src={logo} alt="Logo de compañia" />
                          </div>
                          <div className="solicitud-data-container">
                            <div className="header-solicitud-data">
                              <span>
                                ID: {item.id} - Póliza: {item.poliza}
                              </span>
                              <span className="asegurado">
                                {item.nombre} - {item.compania}
                              </span>
                            </div>  
                            <div className="operador-solicitud-data">
                              <span>
                                Veces notificado: {item.veces_notificado}
                              </span>
                            </div>
                          </div>
                        </div>
                        <IconButton
                          action={() => removerRechazo(item.id)}
                          icon={<FiTrash2 />}
                          tooltipText="Quitar de la lista"
                          size="small"
                        />
                      </>
                    </OperadorItem>
                    {index + 1 !== rechazosSeleccionados.length ? <Divider /> : null}
                    </>
                  );
                })}
                </div>
              </div>

              <hr />
              
              <div className="actionButtonsCont">
                <div className="buttonsCont">
                  <NewButton
                    backgroundColor={false}
                    borderColor={true}
                    textColor={true}
                    onClick={() => setIsModalOpen(false)}
                  >
                    Cancelar
                  </NewButton>
                  <NewButton
                    backgroundColor={true}
                    borderColor={false}
                    textColor={false}
                    onClick={notificarRechazos}
                    disabled={loading}
                  >
                    Notificar masivamente
                  </NewButton>
                </div>
              </div>
            </>
          )}
            </>
          }

          
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalNotificacionMasivaRechazos;
