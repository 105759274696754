import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const Container = styled.div` 
    border-radius:16px;
    padding:20px 20px;
    transition: 0.3s;
    cursor: ${props => (props.done ? `` : 'pointer')};
    pointer-events: ${props => (props.done || props.disabled ? `none` : '')};
    font-family: "DM Sans", sans-serif;
    font-weight:500;
    display: flex;
    align-items: center;
    justify-content:space-between;
    border: ${props => (props.selected ? `1px solid ${leerColor(colores.celeste)}` : '1px solid rgb(240,240,240)')};
    border: ${props => (props.done ? `1px solid ${leerColor(colores.verde)}` : '')};
    min-height:48px;
    color: ${props => (props.next ? `${leerColor(colores.negro)}` : `rgb(245,245,245)`)};

    :hover{
        opacity: ${props => (props.done ? `` : '0.8')};
        border: 1px solid ${leerColor(colores.grisModales)};
    }

    .info-container{
        display:flex;
        flex-direction:column;
    }

    h4{
        margin:0 0 4px 0;
        color: ${leerColor(colores.negro)};
    }

    .selected-title{
        color: ${props => (props.selected ? `${leerColor(colores.celeste)}` : `${leerColor(colores.negro)}`)};
    }

    .disabled-title{
        color: ${props => (props.disabled ? `${leerColor(colores.gris)}` : `${leerColor(colores.negro)}`)};
    }

    span{
        font-size:14px;
        color: ${leerColor(colores.negro)}
    }
`;
