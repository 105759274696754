import { Container } from "./styled"
import { FiAlertTriangle } from "react-icons/fi"

const Estado = ({ estado, operador, previo }) => {

    const classNameEstado = operador === 66 && estado ? `${estado?.replaceAll(" ", "-").toLowerCase()}-sin-asignar` : estado?.replaceAll(" ", "-").toLowerCase().replace(/\s/g,'')

    return (
        <Container >
            <span className={classNameEstado}>
                {estado}
                {previo === 3 && <span className="estado_previo_rechazado"><FiAlertTriangle /></span>}
            </span> 
        </Container>
    )
}

export default Estado