import { useEffect, useState } from "react";
import { getAseguradoByDNI, postAseguradoData, updateAseguradoAdicionalData, updateAseguradoData } from "../services/Emision";
import dayjs from "dayjs";
import sendFiles from "../utils/uploadFile";

export const useAsegurado = (dni, completeForm, handleModal, idSolicitud) => {

  const [dniAsegurado, setDniAsegurado] = useState("")
  const [dniEncontrado, setDniEncontrado] = useState(dni ? true : false)

  const editarAseguradoForm = async (data, id_asegurado, id_asegurado_adicional, asegurado) => {
    try {
      if(data?.archivo_estatuto_empresa_asegurado !== asegurado?.archivo_estatuto_empresa_asegurado){
        const file = await sendFiles(data.archivo_estatuto_empresa_asegurado)
        data.archivo_estatuto_empresa_asegurado = file
      }
      if(id_asegurado_adicional){
        const aseguradoAdicionalForm = await updateAseguradoAdicionalData(data, id_asegurado_adicional)
        return aseguradoAdicionalForm
      } else {
        const dataAsegurado = {...data}
        if(dataAsegurado.nacimiento_asegurado === "Invalid Date"){
          dataAsegurado.nacimiento_asegurado = null
        }
        const aseguradoForm = await updateAseguradoData(dataAsegurado, id_asegurado)
        return aseguradoForm
      }
    } catch (error) {
      console.log(error)
    }
  }

  const postearAseguradoForm = async (data) => {
    try {
      if(data.archivo_estatuto_empresa_asegurado){
        const file = await sendFiles(data.archivo_estatuto_empresa_asegurado)
        data.archivo_estatuto_empresa_asegurado = file
      }
      const aseguradoForm = await postAseguradoData({...data, estado_asegurado: 1})
      return aseguradoForm
    } catch (error) {
      console.log(error)
    }
  }

  const removerDatosAsegurado = (resetForm) => {
    resetForm()
    setDniEncontrado(false)
    setDniAsegurado("")
  }

  const handleChangeDniAsegurado = (e) => {
    const { value } = e.target
    setDniAsegurado(value)
  }
  
  const buscarAsegurado = async (dni, completeForm) => {
    try {
        const asegurado = await getAseguradoByDNI(dni)
        if(asegurado){
            if(!dniAsegurado && !dniEncontrado) handleModal()
            asegurado.nacimiento_asegurado = dayjs(asegurado.nacimiento_asegurado).format("YYYY-MM-DD")

            setDniEncontrado(true)
            setDniAsegurado("")
            completeForm(asegurado)
        }
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    if(dni?.length === 8 && !dniEncontrado && !idSolicitud){
      buscarAsegurado(dni, completeForm)
    }
  }, [dni])

  return {
    buscarAsegurado,
    removerDatosAsegurado,
    handleChangeDniAsegurado,
    dniEncontrado,
    dniAsegurado,
    postearAseguradoForm,
    editarAseguradoForm,
  };
};
