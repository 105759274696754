import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const Video = styled.video`  
    width: auto;
    height:50vh;
    margin: 0 auto;
`

export const Container = styled.div`
  position: relative;
`;

export const Icon = styled.span`
  background-color: ${leerColor(colores.blanco)};
  padding: 6px;
  border-radius: 100%;
  //border: 1px solid ${leerColor(colores.celeste)};
  border: 1px solid #eaeaea;
  font-size: 20px;
  color: ${leerColor(colores.celeste)};
  display: flex;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

export const IconsContainer = styled.span`
  display: flex;
  position: absolute;
  top: 10px;
  right: 20%;
  gap: 10px;
  padding: 10px;
  z-index: 500;
`;
