import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import { useForm } from "../../../hooks/useForm";
import { crearGrupoReglas } from "../../../utils/formRules";
import { crearGrupoForm } from "../../../utils/formInitialValues";
import { useLoading } from "../../../hooks/useLoading";
import BackButton from "../../../components/BackButton";
import CrearGrupoForm from "../../../components/PremiosForm/CrearGrupo";
import { useCrearGrupo } from "./hooks/useCrearGrupo";
import Divider from "../../../components/Divider";

const PremiosCrearGrupo = () => {
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const { loading, setLoading } = useLoading();
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
    resetForm,
  } = useForm(crearGrupo, crearGrupoReglas, crearGrupoForm);
  const { vendedores, handleChangeVendedor, seleccionarVendedor, vendedoresSeleccionados, setVendedoresSeleccionados } = useCrearGrupo(handleChangeExternal)

  async function crearGrupo() {
    setLoading(true);
    try {
      const data = {
        integrantes: vendedoresSeleccionados,
        nombre: inputValues.nombre,
      };
      const integrantes = vendedoresSeleccionados.map((vendedor) => {
        const findVendedor = vendedores.find((ven) => ven.numero === vendedor);
        return findVendedor.nombre;
      });

      const grupo = await axiosRequest.post("/premios/crear_grupo", data);
      if (grupo.status === 200) {
        const historial = {
          mensaje: `${
            user.nombre_operador
          } creó un nuevo grupo. Integrantes de ${
            inputValues.nombre
          }: ${integrantes.join(", ")}`,
          dia: new Date(),
          id_vendedor: null,
          id_premio: null,
          id_usuario: user.id_operador,
          id_tipo_modificacion: 3,
        };
        console.log(historial);

        const historialModificaciones = await axiosRequest.post(
          `/premios/historial_modificaciones`,
          historial
        );

        setLoading(false);
        setVendedoresSeleccionados([]);
        resetForm();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div>
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
                <BackButton url={"/Premios"} text="Premios" />
                <TitlePageSection
                  title="Crear Grupo"
                  description="Armá grupos de vendedores para asignar premios"
                ></TitlePageSection>
              </div>
              <Divider />
              <CrearGrupoForm form={{ inputValues, handleChange, formErrors, handleValidation }} data={{ handleChangeVendedor, vendedores, vendedoresSeleccionados, seleccionarVendedor, loading }} />
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default PremiosCrearGrupo;
