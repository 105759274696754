import { useState } from "react";

export const useTable = () => {

  const [order, setOrder] = useState("ASC")

  const handleTableOrder = (header) => {
    const tipo = order.includes("ASC") ? "DESC" : "ASC"
    const orderParams = `orden=${header}&orden_tipo=${tipo}`
    setOrder(orderParams)
  }

  return {
    order,
    handleTableOrder
  };
}