import { Col } from "../Grid"
import Subtitulo from "../Subtitulo";
import { Section } from "./styled";

const SectionLandings = ({ columns, icon, title, description, children, divider, noMargin }) => {
  
  return (
    <Col desktop={columns}>
      {divider && <hr />}
      <Section noMargin={noMargin}>
        <div className="section-title">
          <Subtitulo>
            <span className="icon">
              {icon}
            </span>
            {title}
          </Subtitulo>
          <p>{description}</p>
        </div>
        {children}
      </Section>
    </Col>
  );
};

export default SectionLandings;
