export const NACIONALIDADES = [
  { "codigo": "093", "descripcion": "AFGANISTAN" },
  { "codigo": "355", "descripcion": "ALBANIA" },
  { "codigo": "049", "descripcion": "ALEMANIA" },
  { "codigo": "376", "descripcion": "ANDORRA" },
  { "codigo": "244", "descripcion": "ANGOLA" },
  { "codigo": "102", "descripcion": "ANGUILA" },
  { "codigo": "541", "descripcion": "ANTARTIDA" },
  { "codigo": "103", "descripcion": "ANTIGUA Y BARBUDA\t" },
  { "codigo": "599", "descripcion": "ANTILLAS HOLANDESAS" },
  { "codigo": "213", "descripcion": "ARGELIA" },
  { "codigo": "054", "descripcion": "ARGENTINA" },
  { "codigo": "374", "descripcion": "ARMENIA" },
  { "codigo": "297", "descripcion": "ARUBA" },
  { "codigo": "061", "descripcion": "AUSTRALIA" },
  { "codigo": "043", "descripcion": "AUSTRIA" },
  { "codigo": "994", "descripcion": "AZERBAIYAN" },
  { "codigo": "099", "descripcion": "BAHAMAS" },
  { "codigo": "973", "descripcion": "BAHREIN" },
  { "codigo": "880", "descripcion": "BANGLADES" },
  { "codigo": "101", "descripcion": "BARBADOS" },
  { "codigo": "032", "descripcion": "BELGICA" },
  { "codigo": "501", "descripcion": "BELICE" },
  { "codigo": "229", "descripcion": "BENIN" },
  { "codigo": "141", "descripcion": "BERMUDAS" },
  { "codigo": "375", "descripcion": "BIELORRUSIA" },
  { "codigo": "095", "descripcion": "MYANMAR" },
  { "codigo": "245", "descripcion": "GUINEA BISSAU" },
  { "codigo": "591", "descripcion": "BOLIVIA" },
  { "codigo": "387", "descripcion": "BOSNIA Y HERZEGOVINA" },
  { "codigo": "267", "descripcion": "BOTSUANA" },
  { "codigo": "055", "descripcion": "BRASIL" },
  { "codigo": "673", "descripcion": "BRUNEI" },
  { "codigo": "359", "descripcion": "BULGARIA" },
  { "codigo": "226", "descripcion": "BURKINA FASO" },
  { "codigo": "257", "descripcion": "BURUNDI" },
  { "codigo": "975", "descripcion": "BUTAN" },
  { "codigo": "238", "descripcion": "CABO VERDE" },
  { "codigo": "345", "descripcion": "ISLAS CAIMAN" },
  { "codigo": "855", "descripcion": "CAMBOYA" },
  { "codigo": "237", "descripcion": "CAMERUN" },
  { "codigo": "002", "descripcion": "CANADA" },
  { "codigo": "974", "descripcion": "CATAR" },
  { "codigo": "236", "descripcion": "REPUBLICA CENTROAFRICANA" },
  { "codigo": "235", "descripcion": "CHAD" },
  { "codigo": "246", "descripcion": "TERR. BRITANICO DEL OC. INDICO" },
  { "codigo": "978", "descripcion": "REPUBLICA CHECA" },
  { "codigo": "056", "descripcion": "CHILE" },
  { "codigo": "086", "descripcion": "CHINA" },
  { "codigo": "357", "descripcion": "CHIPRE" },
  { "codigo": "613", "descripcion": "ISLAS COCOS" },
  { "codigo": "057", "descripcion": "COLOMBIA" },
  { "codigo": "269", "descripcion": "COMORAS" },
  { "codigo": "242", "descripcion": "CONGO" },
  { "codigo": "682", "descripcion": "ISLAS COOK" },
  { "codigo": "506", "descripcion": "COSTA RICA" },
  { "codigo": "385", "descripcion": "CROACIA" },
  { "codigo": "053", "descripcion": "CUBA" },
  { "codigo": "116", "descripcion": "CURAZAO" },
  { "codigo": "045", "descripcion": "DINAMARCA" },
  { "codigo": "809", "descripcion": "REPUBLICA DOMINICANA" },
  { "codigo": "767", "descripcion": "DOMINICA" },
  { "codigo": "240", "descripcion": "GUINEA ECUATORIAL" },
  { "codigo": "593", "descripcion": "ECUADOR" },
  { "codigo": "020", "descripcion": "EGIPTO" },
  { "codigo": "971", "descripcion": "EMIRATOS ARABES UNIDOS" },
  { "codigo": "291", "descripcion": "ERITREA" },
  { "codigo": "999", "descripcion": "ESCOCIA" },
  { "codigo": "421", "descripcion": "ESLOVAQUIA" },
  { "codigo": "386", "descripcion": "REPUBLICA DE ESLOVENIA" },
  { "codigo": "034", "descripcion": "ESPAÑA" },
  { "codigo": "094", "descripcion": "SRI LANKA" },
  { "codigo": "001", "descripcion": "ESTADOS UNIDOS DE AMERICA" },
  { "codigo": "372", "descripcion": "ESTONIA" },
  { "codigo": "251", "descripcion": "ETIOPIA" },
  { "codigo": "298", "descripcion": "ISLAS FEROE" },
  { "codigo": "063", "descripcion": "REPUBLICA DE FILIPINAS" },
  { "codigo": "358", "descripcion": "FINLANDIA" },
  { "codigo": "679", "descripcion": "FIYI" },
  { "codigo": "033", "descripcion": "FRANCIA" },
  { "codigo": "689", "descripcion": "POLINESIA FRANCESA" },
  { "codigo": "241", "descripcion": "GABON" },
  { "codigo": "442", "descripcion": "GALES" },
  { "codigo": "220", "descripcion": "GAMBIA" },
  { "codigo": "995", "descripcion": "GEORGIA" },
  { "codigo": "233", "descripcion": "GHANA" },
  { "codigo": "350", "descripcion": "GIBRALTAR" },
  { "codigo": "473", "descripcion": "GRANADA" },
  { "codigo": "030", "descripcion": "GRECIA" },
  { "codigo": "299", "descripcion": "GROENLANDIA" },
  { "codigo": "590", "descripcion": "GUADALUPE" },
  { "codigo": "111", "descripcion": "GUAM" },
  { "codigo": "502", "descripcion": "GUATEMALA" },
  { "codigo": "594", "descripcion": "GUAYANA FRANCESA" },
  { "codigo": "105", "descripcion": "GUERNSEY" },
  { "codigo": "224", "descripcion": "GUINEA" },
  { "codigo": "592", "descripcion": "GUYANA" },
  { "codigo": "509", "descripcion": "HAITI" },
  { "codigo": "808", "descripcion": "HAWAI" },
  { "codigo": "031", "descripcion": "PAISES BAJOS" },
  { "codigo": "504", "descripcion": "HONDURAS" },
  { "codigo": "852", "descripcion": "HONG KONG" },
  { "codigo": "036", "descripcion": "HUNGRIA" },
  { "codigo": "091", "descripcion": "REPUBLICA DE LA INDIA" },
  { "codigo": "062", "descripcion": "REPUBLICA DE INDONESIA" },
  { "codigo": "044", "descripcion": "INGLATERRA" },
  { "codigo": "098", "descripcion": "IRAN" },
  { "codigo": "964", "descripcion": "IRAK" },
  { "codigo": "353", "descripcion": "REPUBLICA DE IRLANDA" },
  { "codigo": "354", "descripcion": "ISLANDIA" },
  { "codigo": "972", "descripcion": "ISRAEL" },
  { "codigo": "039", "descripcion": "ITALIA" },
  { "codigo": "876", "descripcion": "JAMAICA" },
  { "codigo": "081", "descripcion": "JAPON" },
  { "codigo": "106", "descripcion": "JERSEY" },
  { "codigo": "962", "descripcion": "JORDANIA" },
  { "codigo": "077", "descripcion": "KAZAJISTAN" },
  { "codigo": "254", "descripcion": "KENIA" },
  { "codigo": "996", "descripcion": "KIRGUISA" },
  { "codigo": "686", "descripcion": "KIRIBATI" },
  { "codigo": "965", "descripcion": "KUWAIT" },
  { "codigo": "856", "descripcion": "LAOS" },
  { "codigo": "266", "descripcion": "LESOTO" },
  { "codigo": "371", "descripcion": "LETONIA" },
  { "codigo": "961", "descripcion": "LIBANO" },
  { "codigo": "231", "descripcion": "LIBERIA" },
  { "codigo": "218", "descripcion": "LIBIA" },
  { "codigo": "423", "descripcion": "LIECHTENSTEIN" },
  { "codigo": "370", "descripcion": "LITUANIA" },
  { "codigo": "352", "descripcion": "LUXEMBURGO" },
  { "codigo": "853", "descripcion": "MACAO" },
  { "codigo": "389", "descripcion": "MACEDONIA" },
  { "codigo": "060", "descripcion": "MALASIA" },
  { "codigo": "265", "descripcion": "MALAUI" },
  { "codigo": "960", "descripcion": "MALDIVAS" },
  { "codigo": "261", "descripcion": "MADAGASCAR" },
  { "codigo": "223", "descripcion": "MALI" },
  { "codigo": "356", "descripcion": "MALTA" },
  { "codigo": "500", "descripcion": "ISLAS MALVINAS" },
  { "codigo": "107", "descripcion": "ISLA DE MAN" },
  { "codigo": "225", "descripcion": "COSTA DE MARFIL" },
  { "codigo": "212", "descripcion": "REINO DE MARRUECOS" },
  { "codigo": "692", "descripcion": "ISLAS MARSHALL" },
  { "codigo": "596", "descripcion": "MARTINICA" },
  { "codigo": "230", "descripcion": "MAURICIO" },
  { "codigo": "222", "descripcion": "MAURITANIA" },
  { "codigo": "262", "descripcion": "MAYOTTE" },
  { "codigo": "052", "descripcion": "MEXICO" },
  { "codigo": "691", "descripcion": "EDOS. FEDERADOS DE MICRONESIA" },
  { "codigo": "373", "descripcion": "MOLDAVIA" },
  { "codigo": "377", "descripcion": "MONACO" },
  { "codigo": "976", "descripcion": "MONGOLIA" },
  { "codigo": "382", "descripcion": "MONTENEGRO" },
  { "codigo": "109", "descripcion": "MONTSERRAT" },
  { "codigo": "258", "descripcion": "MOZAMBIQUE" },
  { "codigo": "264", "descripcion": "NAMIBIA" },
  { "codigo": "674", "descripcion": "NAURU" },
  { "codigo": "687", "descripcion": "NUEVA CALEDONIA" },
  { "codigo": "064", "descripcion": "NUEVA ZELANDA" },
  { "codigo": "977", "descripcion": "NEPAL" },
  { "codigo": "505", "descripcion": "NICARAGUA" },
  { "codigo": "234", "descripcion": "NIGERIA" },
  { "codigo": "227", "descripcion": "NIGER" },
  { "codigo": "683", "descripcion": "NIUE" },
  { "codigo": "850", "descripcion": "COREA DEL NORTE" },
  { "codigo": "672", "descripcion": "ISLA NORFOLK" },
  { "codigo": "440", "descripcion": "IRLANDA DEL NORTE" },
  { "codigo": "110", "descripcion": "MARIANAS DEL NORTE" },
  { "codigo": "047", "descripcion": "NORUEGA" },
  { "codigo": "968", "descripcion": "OMAN" },
  { "codigo": "092", "descripcion": "PAKISTAN" },
  { "codigo": "680", "descripcion": "PALAOS" },
  { "codigo": "970", "descripcion": "PALESTINA" },
  { "codigo": "507", "descripcion": "REPUBLICA DE PANAMA" },
  { "codigo": "675", "descripcion": "PAPUA NUEVA GUINEA" },
  { "codigo": "595", "descripcion": "PARAGUAY" },
  { "codigo": "051", "descripcion": "PERU" },
  { "codigo": "641", "descripcion": "ISLA PITCAIRN" },
  { "codigo": "048", "descripcion": "POLONIA" },
  { "codigo": "351", "descripcion": "PORTUGAL" },
  { "codigo": "787", "descripcion": "PUERTO RICO" },
  { "codigo": "114", "descripcion": "REUNION" },
  { "codigo": "250", "descripcion": "RUANDA" },
  { "codigo": "040", "descripcion": "RUMANIA" },
  { "codigo": "007", "descripcion": "RUSIA" },
  { "codigo": "113", "descripcion": "SAHARA OCCIDENTAL" },
  { "codigo": "677", "descripcion": "ISLAS SALOMON" },
  { "codigo": "503", "descripcion": "EL SALVADOR" },
  { "codigo": "685", "descripcion": "SAMOA" },
  { "codigo": "684", "descripcion": "SAMOA AMERICANA" },
  { "codigo": "869", "descripcion": "SAN CRISTOBAL Y NIEVES" },
  { "codigo": "378", "descripcion": "SAN MARINO" },
  { "codigo": "508", "descripcion": "SAN PEDRO Y MIQUELON" },
  { "codigo": "247", "descripcion": "ISLA ASCENSION" },
  { "codigo": "758", "descripcion": "SANTA LUCIA" },
  { "codigo": "115", "descripcion": "SANTA ELENA" },
  { "codigo": "239", "descripcion": "SANTO TOME Y PRINCIPE" },
  { "codigo": "784", "descripcion": "SAN VICENTE Y LAS GRANADINAS" },
  { "codigo": "966", "descripcion": "ARABIA SAUDITA" },
  { "codigo": "221", "descripcion": "SENEGAL" },
  { "codigo": "381", "descripcion": "SERBIA" },
  { "codigo": "284", "descripcion": "SEYCHELLES" },
  { "codigo": "232", "descripcion": "SIERRA LEONA" },
  { "codigo": "065", "descripcion": "SINGAPUR" },
  { "codigo": "963", "descripcion": "SIRIA" },
  { "codigo": "252", "descripcion": "SOMALIA" },
  { "codigo": "268", "descripcion": "SUAZILANDIA" },
  { "codigo": "027", "descripcion": "REPUBLICA DE SUDAFRICA" },
  { "codigo": "249", "descripcion": "SUDAN" },
  { "codigo": "046", "descripcion": "SUECIA" },
  { "codigo": "041", "descripcion": "SUIZA" },
  { "codigo": "082", "descripcion": "REPUBLICA DE COREA" },
  { "codigo": "597", "descripcion": "SURINAM" },
  { "codigo": "211", "descripcion": "SUDAN DEL SUR" },
  { "codigo": "066", "descripcion": "TAILANDIA" },
  { "codigo": "886", "descripcion": "TAIWAN" },
  { "codigo": "255", "descripcion": "TANZANIA" },
  { "codigo": "992", "descripcion": "TAYIKISTAN" },
  { "codigo": "861", "descripcion": "TIBET" },
  { "codigo": "670", "descripcion": "TIMOR ORIENTAL" },
  { "codigo": "228", "descripcion": "TOGO" },
  { "codigo": "690", "descripcion": "TOKELAU" },
  { "codigo": "676", "descripcion": "TONGA" },
  { "codigo": "868", "descripcion": "TRINIDAD Y TOBAGO" },
  { "codigo": "216", "descripcion": "TUNEZ" },
  { "codigo": "090", "descripcion": "TURQUIA" },
  { "codigo": "993", "descripcion": "TURKMENISTAN" },
  { "codigo": "688", "descripcion": "TUVALU" },
  { "codigo": "380", "descripcion": "UCRANIA" },
  { "codigo": "256", "descripcion": "UGANDA" },
  { "codigo": "598", "descripcion": "REPUBLICA ORIENTAL DEL URUGUAY" },
  { "codigo": "998", "descripcion": "UZBEKISTAN" },
  { "codigo": "678", "descripcion": "VANUATU" },
  { "codigo": "379", "descripcion": "VATICANO" },
  { "codigo": "058", "descripcion": "REP. BOLIVARIANA DE VENEZUELA" },
  { "codigo": "084", "descripcion": "VIETNAM" },
  { "codigo": "104", "descripcion": "ISLAS VIRGENES BRITANICAS" },
  { "codigo": "112", "descripcion": "ISLAS VIRGENES AMERICANAS" },
  { "codigo": "681", "descripcion": "WALLIS Y FUTUNA" },
  { "codigo": "967", "descripcion": "YEMEN" },
  { "codigo": "253", "descripcion": "YIBUTI" },
  { "codigo": "260", "descripcion": "ZAMBIA" },
  { "codigo": "263", "descripcion": "ZIMBAWE" },
  { "codigo": "243", "descripcion": "REP. DEMOCRATICA DEL CONGO" },
  { "codigo": "471", "descripcion": "ISLA BOUVET" },
  { "codigo": "472", "descripcion": "ISLAS SVALBARD Y JAN MAYEN" },
  { "codigo": "611", "descripcion": "ISLA NAVIDAD" },
  { "codigo": "612", "descripcion": "ISLAS HEARD Y MCDONALD" },
  { "codigo": "100", "descripcion": "IS. GEORGIA Y SANDWICH DEL SUR" },
  { "codigo": "108", "descripcion": "ISLAS TURCAS Y CAICOS" }
]

export const PROFESIONES = [
  { "codigo": "626", "descripcion": "PRODUCTOR MUSICAL" },
  { "codigo": "628", "descripcion": "EXTRACCION DE PIEDRA, ARENA Y ARCILLAS" },
  { "codigo": "627", "descripcion": "DESMOTADORA DE ALGODON" },
  { "codigo": "630", "descripcion": "ACOMPAÑANTE TERAPEUTICO" },
  { "codigo": "635", "descripcion": "FABRICACION DE HILADOS DE LANA" },
  { "codigo": "633", "descripcion": "TECNICO AERONAUTICO" },
  { "codigo": "634", "descripcion": "INGENIO" },
  { "codigo": "631", "descripcion": "CONCEJAL" },
  { "codigo": "632", "descripcion": "SERVICIOS DE ASOCIACIONES" },
  { "codigo": "629", "descripcion": "POCERO" },
  { "codigo": "637", "descripcion": "GENDARMERIA NACIONAL" },
  { "codigo": "636", "descripcion": "LICENCIADO EN CRIMINALISTICA" },
  { "codigo": "638", "descripcion": "SERV.DE ELAB.DE DATOS Y COMPUTACION" },
  { "codigo": "639", "descripcion": "LICENCIADO EN OCEANOGRAFIA" },
  { "codigo": "642", "descripcion": "FRACCIONAMIENTO DE ALCOHOLES" },
  { "codigo": "640", "descripcion": "SERVICIOS DE SANEAMIENTO PUBLICO" },
  { "codigo": "641", "descripcion": "INGENIERO NUCLEAR" },
  { "codigo": "643", "descripcion": "OBRA SOCIAL" },
  { "codigo": "644", "descripcion": "MENTALISTA" },
  { "codigo": "645", "descripcion": "MUSICOTERAPIA" },
  { "codigo": "647", "descripcion": "SIN ACTIVIDAD - MENOR DE EDAD" },
  { "codigo": "648", "descripcion": "AGRUPACION" },
  { "codigo": "649", "descripcion": "EMPLEADO PODER JUDICIAL" },
  {
    "codigo": "672",
    "descripcion": "FABRIC.DE PARTES,PIEZAS Y ACC.P/VEHÍCULO"
  },
  { "codigo": "646", "descripcion": "CUIDADO DE ANCIANOS" },
  { "codigo": "651", "descripcion": "LIC.EN SEGURIDAD Y SALUD OCUPACIONAL" },
  { "codigo": "656", "descripcion": "JUZGADO" },
  { "codigo": "653", "descripcion": "LABORATORISTA VIAL" },
  { "codigo": "652", "descripcion": "SERV. DE PREPARACIÓN DE COMIDAS" },
  { "codigo": "655", "descripcion": "GENERACION DE ENERGIA HIDRAULICA" },
  { "codigo": "664", "descripcion": "FABRICACIÓN DE LADRILLOS" },
  { "codigo": "654", "descripcion": "EDITORIAL" },
  { "codigo": "662", "descripcion": "SERVICIOS PARA LA NAVEGACIÓN" },
  { "codigo": "658", "descripcion": "VACUNADOR DE HACIENDA" },
  { "codigo": "657", "descripcion": "SERVICIOS CULTURALES" },
  { "codigo": "659", "descripcion": "SERVICIOS INMOBILIARIOS" },
  { "codigo": "661", "descripcion": "MEDIADOR/A" },
  {
    "codigo": "665",
    "descripcion": "FABRIC. DE MAQ. Y EQUIPO AGROP. Y FOREST"
  },
  { "codigo": "663", "descripcion": "SERVICIOS DE EXPLOTACIÓN PÚBLICA" },
  { "codigo": "660", "descripcion": "VENTA AL POR MAYOR DE CEREALES" },
  { "codigo": "667", "descripcion": "MOLIENDA" },
  { "codigo": "666", "descripcion": "INDUSTRIA MANUFACTURERA" },
  { "codigo": "668", "descripcion": "VENTA AL POR MENOR DE ELECTRODOMESTICOS" },
  { "codigo": "681", "descripcion": "DIPUTADO NACIONAL" },
  {
    "codigo": "669",
    "descripcion": "VENTA AL POR MAYOR DE FRUTAS, LEG. Y CER"
  },
  { "codigo": "716", "descripcion": "PREFECTO" },
  {
    "codigo": "676",
    "descripcion": "INSTAL.Y MANTENIM. DE INSTAL. ELÉCTRICAS"
  },
  {
    "codigo": "680",
    "descripcion": "FABRICACIÓN DE PAPEL Y CARTÓN EXC.ENVASE"
  },
  { "codigo": "670", "descripcion": "SERV. DE ALMACENAMIENTO Y DEP. EN SILOS" },
  {
    "codigo": "671",
    "descripcion": "SERV. DE ASESORAM. DIRECCIÓN Y GEST. EMP"
  },
  {
    "codigo": "674",
    "descripcion": "CAPTACIÓN,DEPURACIÓN Y DISTR.DE AGUA SUB"
  },
  { "codigo": "677", "descripcion": "CONSTRUCCION Y REPARACION DE BUQUES" },
  { "codigo": "673", "descripcion": "PREPARACIÓN DE ARROZ" },
  { "codigo": "678", "descripcion": "NEONATOLOGIA" },
  { "codigo": "684", "descripcion": "ALQ. Y/O VTA. DE MAQ. Y EQUIPOS NCP" },
  { "codigo": "679", "descripcion": "SERV.APOYO P/EXTRACCIÓN DE PETR. Y GAS" },
  { "codigo": "683", "descripcion": "FABRIC. DE PROD. MINERALES NO METALIC." },
  { "codigo": "675", "descripcion": "DECANO" },
  { "codigo": "682", "descripcion": "SERVICIOS PARA LA AERONAVEGACIÓN" },
  { "codigo": "685", "descripcion": "SINDICO" },
  { "codigo": "686", "descripcion": "TECNICO EN ALIMENTACION" },
  {
    "codigo": "687",
    "descripcion": "RECOL.TRANSP.TRAT. Y DISP.RESID.NO PELIG"
  },
  { "codigo": "688", "descripcion": "ELABORACIÓN DE YERBA MATE" },
  { "codigo": "690", "descripcion": "ELAB.DE LECHES Y PROD.LACTEOS" },
  { "codigo": "691", "descripcion": "SERVICIOS DE JUSTICIA" },
  { "codigo": "689", "descripcion": "SERVICIOS DE BOLSAS DE COMERCIO" },
  { "codigo": "692", "descripcion": "SERV.AUX. P/SERV.GRALES DE LA ADM.PUB" },
  { "codigo": "693", "descripcion": "ADMINISTRACION PUBLICA" },
  { "codigo": "694", "descripcion": "SERV.DE CASAS Y AGENCIAS DE CAMBIO" },
  { "codigo": "695", "descripcion": "EXPLOTACIÓN DE JUEGOS DE AZAR" },
  { "codigo": "696", "descripcion": "LICENCIADO/A BIBLIOTECOLOGÍA" },
  { "codigo": "585", "descripcion": "SERVICIOS EMPRESARIALES" },
  { "codigo": "187", "descripcion": "SERVICIOS DE EJECUCION DE OBRAS" },
  { "codigo": "188", "descripcion": "GUARDAVIDAS" },
  { "codigo": "253", "descripcion": "GREMIALISTA" },
  { "codigo": "254", "descripcion": "SINDICALISTA" },
  { "codigo": "255", "descripcion": "DIRIGENTE GREMIAL" },
  { "codigo": "203", "descripcion": "GOMERIA" },
  { "codigo": "204", "descripcion": "LAVADERO DE ROPA" },
  { "codigo": "205", "descripcion": "ESTACION DE SERVICIO" },
  { "codigo": "206", "descripcion": "SALON DE VENTAS" },
  { "codigo": "207", "descripcion": "HELADERIA" },
  { "codigo": "208", "descripcion": "VENTA DE FIAMBRES" },
  { "codigo": "209", "descripcion": "VENTA DE ABERTURAS" },
  { "codigo": "210", "descripcion": "INDUSTRIA ALIMENTICIA" },
  { "codigo": "216", "descripcion": "ALQUILER DE VEHICULOS" },
  { "codigo": "218", "descripcion": "SERVICIOS DE FUMIGACION" },
  { "codigo": "176", "descripcion": "CHOFER" },
  { "codigo": "251", "descripcion": "SERVICIOS DE PINTURA" },
  { "codigo": "171", "descripcion": "REPOSTERO/A" },
  { "codigo": "177", "descripcion": "VENDEDOR/A" },
  { "codigo": "178", "descripcion": "MECANICO INDUSTRIAL" },
  { "codigo": "179", "descripcion": "PEON RURAL" },
  { "codigo": "180", "descripcion": "ACOPIADOR" },
  { "codigo": "233", "descripcion": "CAPITAN DE BARCO" },
  { "codigo": "172", "descripcion": "AMA DE CASA" },
  { "codigo": "230", "descripcion": "REPARTIDOR" },
  { "codigo": "231", "descripcion": "PROVISION DE AGUA" },
  { "codigo": "174", "descripcion": "AGRICULTOR" },
  { "codigo": "175", "descripcion": "ESTUDIANTE" },
  { "codigo": "221", "descripcion": "MARINO MERCANTE" },
  { "codigo": "222", "descripcion": "FABRICACION Y/O VENTA DE CALZADO" },
  { "codigo": "223", "descripcion": "PESCA" },
  { "codigo": "167", "descripcion": "EMPRESARIO/A" },
  { "codigo": "168", "descripcion": "SERVICIOS DE FLETE" },
  { "codigo": "169", "descripcion": "INTERMEDIARIO" },
  { "codigo": "170", "descripcion": "JUBILADO/A" },
  { "codigo": "182", "descripcion": "COCINERO/A" },
  { "codigo": "224", "descripcion": "SERVICIO DE ALOJAMIENTO" },
  { "codigo": "226", "descripcion": "FABRICA Y VENTA DE MUEBLES" },
  { "codigo": "227", "descripcion": "FABRICA DE ABERTURAS" },
  { "codigo": "228", "descripcion": "ELABORACION DE ALIMENTOS" },
  { "codigo": "229", "descripcion": "REFINERIA DE GRASA" },
  { "codigo": "183", "descripcion": "DISEÑADOR GRAFICO" },
  { "codigo": "184", "descripcion": "DISEÑADOR PUBLICITARIO" },
  { "codigo": "186", "descripcion": "FRIGORIFICO" },
  { "codigo": "185", "descripcion": "BIOQUIMICO/A" },
  { "codigo": "238", "descripcion": "MADERERA" },
  { "codigo": "240", "descripcion": "FABRICA DE ACEITES" },
  { "codigo": "239", "descripcion": "TECNICO EN SEGURIDAD INDUSTRIAL" },
  { "codigo": "252", "descripcion": "FABRICACION Y/O DISTRIBUCION DE BEBIDAS" },
  { "codigo": "301", "descripcion": "PLANTA DE TRATAM.RESIDUOS ESPECIALES" },
  { "codigo": "327", "descripcion": "LAVADERO" },
  { "codigo": "337", "descripcion": "INDUSTRIA METALURGICA" },
  { "codigo": "263", "descripcion": "VENTA DE VEHICULOS" },
  { "codigo": "264", "descripcion": "ADMINISTRACION" },
  { "codigo": "265", "descripcion": "FRUTICULTURA" },
  { "codigo": "285", "descripcion": "MANDATARIO/A" },
  { "codigo": "311", "descripcion": "VENTA DE DIARIOS Y/O REVISTAS" },
  { "codigo": "276", "descripcion": "INSTRUCTOR DE MANEJO" },
  { "codigo": "277", "descripcion": "SERVICIOS DE MONTAJE" },
  { "codigo": "262", "descripcion": "ASERRADERO" },
  { "codigo": "319", "descripcion": "REPARACION DE CALZADO" },
  { "codigo": "318", "descripcion": "NIÑERA" },
  { "codigo": "321", "descripcion": "SERVICIOS PORTUARIOS" },
  { "codigo": "322", "descripcion": "BICICLETERO" },
  { "codigo": "303", "descripcion": "GUARDERIA NAUTICA" },
  { "codigo": "266", "descripcion": "GERENTE/A" },
  { "codigo": "313", "descripcion": "DIARIO" },
  { "codigo": "256", "descripcion": "VIAJANTE" },
  { "codigo": "275", "descripcion": "JORNALERO" },
  { "codigo": "333", "descripcion": "SOLDADOR" },
  { "codigo": "272", "descripcion": "VENTA DE MAQUINARIAS AGRICOLAS" },
  { "codigo": "273", "descripcion": "GASISTA" },
  { "codigo": "294", "descripcion": "INDUSTRIAS VARIAS" },
  { "codigo": "298", "descripcion": "INSTALACIONES EN GENERAL" },
  { "codigo": "299", "descripcion": "FERRETERIA" },
  {
    "codigo": "300",
    "descripcion": "VENTA DE PRODUCTOS AGRICOL. Y/O FERTILI."
  },
  { "codigo": "258", "descripcion": "SERVICIOS DE HOTELERIA" },
  { "codigo": "259", "descripcion": "SERVICIOS NO CLASIFICADOS EN OTRA PARTE" },
  { "codigo": "274", "descripcion": "GANADERIA" },
  { "codigo": "287", "descripcion": "EMBALAJES" },
  { "codigo": "288", "descripcion": "DEPORTISTA" },
  { "codigo": "331", "descripcion": "GUIA TURISTICO" },
  { "codigo": "284", "descripcion": "FABRICACION Y MONTAJES" },
  { "codigo": "328", "descripcion": "CLINICA" },
  { "codigo": "291", "descripcion": "COMISIONISTA" },
  { "codigo": "289", "descripcion": "VENTA DE INSUMOS INFORMATICOS" },
  { "codigo": "290", "descripcion": "ESTETICISTA" },
  { "codigo": "267", "descripcion": "BIBLIOTECA" },
  { "codigo": "268", "descripcion": "SOCIEDAD RURAL" },
  { "codigo": "269", "descripcion": "BOMBEROS VOLUNTARIOS" },
  { "codigo": "270", "descripcion": "VITIVINICOLA" },
  { "codigo": "315", "descripcion": "PUBLICIDAD" },
  { "codigo": "314", "descripcion": "CUIDADOR DE AUTOMOVILES" },
  { "codigo": "286", "descripcion": "COBRANZAS" },
  { "codigo": "306", "descripcion": "IMPRENTA" },
  { "codigo": "307", "descripcion": "HORTICULTOR" },
  { "codigo": "304", "descripcion": "CERRAJERO" },
  { "codigo": "305", "descripcion": "SERVICIO TECNICO Y PROFES." },
  { "codigo": "302", "descripcion": "CARTERO" },
  { "codigo": "312", "descripcion": "MARMOLERIA" },
  { "codigo": "335", "descripcion": "PREFECTURA" },
  { "codigo": "317", "descripcion": "RADIODIFUSION" },
  { "codigo": "271", "descripcion": "TERAPEUTA" },
  { "codigo": "296", "descripcion": "CUIDADOR/A DE ANIMALES" },
  { "codigo": "297", "descripcion": "ASESOR/A" },
  { "codigo": "336", "descripcion": "MUSICO" },
  { "codigo": "260", "descripcion": "PORTERO/A" },
  { "codigo": "283", "descripcion": "CORRALON" },
  { "codigo": "316", "descripcion": "RENTISTA" },
  { "codigo": "278", "descripcion": "VIGILADOR PRIVADO/SERV.DE VIGILANCIA" },
  { "codigo": "279", "descripcion": "SERVICIO DE TRANSPORTE" },
  { "codigo": "280", "descripcion": "SERVICIOS DE SEPELIOS" },
  { "codigo": "261", "descripcion": "SUPERVISOR/ENCARGADO" },
  { "codigo": "338", "descripcion": "MUTUAL" },
  { "codigo": "282", "descripcion": "PSICOPEDAGOGIA" },
  { "codigo": "320", "descripcion": "CULTIVOS INDUSTRIALES" },
  { "codigo": "292", "descripcion": "VERDULERIA" },
  { "codigo": "293", "descripcion": "FABRICA" },
  { "codigo": "281", "descripcion": "METEOROLOGO/A" },
  { "codigo": "257", "descripcion": "PRESIDENTE DE COMUNA" },
  { "codigo": "324", "descripcion": "PROYECTISTA" },
  { "codigo": "325", "descripcion": "PAPEL Y AFINES" },
  { "codigo": "310", "descripcion": "INSTITUCION EDUCATIVA" },
  { "codigo": "295", "descripcion": "LIBRERIA Y/O FOTOCOPIADORA" },
  { "codigo": "308", "descripcion": "MODELO" },
  { "codigo": "309", "descripcion": "INSTRUCTOR/GUIA" },
  { "codigo": "326", "descripcion": "EXPLOTACION FORESTAL" },
  { "codigo": "329", "descripcion": "ALAMBRADOR" },
  { "codigo": "330", "descripcion": "DIRECTOR TECNICO" },
  { "codigo": "332", "descripcion": "OFTALMOLOGO/A" },
  { "codigo": "323", "descripcion": "ENSAYOS Y ANALISIS TECNICOS" },
  { "codigo": "334", "descripcion": "PASTOR EVANGELICO" },
  { "codigo": "362", "descripcion": "AEROCLUB" },
  { "codigo": "369", "descripcion": "RESTAURADOR" },
  { "codigo": "346", "descripcion": "INDUSTRIA TEXTIL" },
  { "codigo": "347", "descripcion": "VENTA DE REPUESTOS PARA AUTOMOTOR" },
  { "codigo": "348", "descripcion": "VENTA DE PRODUCTOS DE DECORACION" },
  { "codigo": "370", "descripcion": "MINERIA" },
  { "codigo": "352", "descripcion": "VENTA DE PRODUCTOS TEXTILES" },
  { "codigo": "341", "descripcion": "IGLESIA" },
  { "codigo": "342", "descripcion": "REPRESENTANTE LEGAL" },
  { "codigo": "377", "descripcion": "PRODUCTOR PRIMARIO" },
  { "codigo": "378", "descripcion": "CURTIEMBRES" },
  { "codigo": "351", "descripcion": "ALQUILER DE VIVIENDAS" },
  { "codigo": "372", "descripcion": "SINDICATO" },
  { "codigo": "363", "descripcion": "AGENCIA DE LOTERIA" },
  { "codigo": "361", "descripcion": "INSTITUTO DE REHABILITACION" },
  { "codigo": "360", "descripcion": "AGENCIA MARITIMA" },
  { "codigo": "355", "descripcion": "SUPERMERCADO" },
  { "codigo": "374", "descripcion": "COOPERADORA" },
  { "codigo": "340", "descripcion": "COMPLEJO DEPORTIVO" },
  { "codigo": "345", "descripcion": "POLITICO" },
  { "codigo": "358", "descripcion": "DESPACHANTE DE ADUANA" },
  { "codigo": "350", "descripcion": "COMUNICACIONES" },
  { "codigo": "365", "descripcion": "PRESTAMISTA" },
  {
    "codigo": "366",
    "descripcion": "FABRICACION Y/O VENTA DE ARTI.CON MADERA"
  },
  { "codigo": "339", "descripcion": "YESERO" },
  { "codigo": "349", "descripcion": "SECRETARIA/RECEPCION" },
  { "codigo": "373", "descripcion": "DEPOSITO Y ALMACENAMIENTO" },
  { "codigo": "359", "descripcion": "LICENCIADO EN BIOTECNOLOGIA" },
  { "codigo": "356", "descripcion": "VISITADOR MEDICO" },
  { "codigo": "357", "descripcion": "ROTISERIA" },
  { "codigo": "364", "descripcion": "PRESIDENTE DE LA FIRMA" },
  { "codigo": "367", "descripcion": "LICENCIADO EN COMERCIO EXTERIOR" },
  { "codigo": "368", "descripcion": "FABRICACION DE ENVASES" },
  { "codigo": "371", "descripcion": "SEGURIDAD" },
  { "codigo": "343", "descripcion": "BODEGA" },
  { "codigo": "344", "descripcion": "ESTIBADOR" },
  { "codigo": "376", "descripcion": "TRABAJADOR AUTONOMO" },
  { "codigo": "375", "descripcion": "SERVICIOS DE INGENIERIA Y ARQUITECTURA" },
  { "codigo": "354", "descripcion": "TECNICO EN SEGURIDAD E HIGIENE" },
  { "codigo": "353", "descripcion": "CONSULTORA" },
  { "codigo": "423", "descripcion": "BAILE/DANZAS" },
  { "codigo": "430", "descripcion": "FABRICANTE DE PILETAS" },
  { "codigo": "431", "descripcion": "PRODUCTOR AGRICOLA" },
  { "codigo": "412", "descripcion": "REPARACIONES EN GENERAL" },
  { "codigo": "389", "descripcion": "SALA DE JUEGOS" },
  { "codigo": "399", "descripcion": "FISICO" },
  { "codigo": "383", "descripcion": "RESTAURANTE" },
  { "codigo": "405", "descripcion": "MANT., REPARACION Y/O VENTA DE MAQUINAS" },
  { "codigo": "406", "descripcion": "AGENTE DE BOLSA" },
  { "codigo": "391", "descripcion": "SERVICIO DE COSECHA MECANICA" },
  { "codigo": "379", "descripcion": "PERFUMERIA" },
  { "codigo": "426", "descripcion": "CORREO / MENSAJERIA" },
  { "codigo": "380", "descripcion": "HOSPITAL" },
  { "codigo": "417", "descripcion": "VENTA Y/O DISTRIBUCION DE BEBIDAS" },
  { "codigo": "418", "descripcion": "SASTRE/MODISTA" },
  { "codigo": "407", "descripcion": "ARTISTA" },
  { "codigo": "393", "descripcion": "EMBARCADO" },
  { "codigo": "395", "descripcion": "DESMONTE" },
  { "codigo": "400", "descripcion": "FABRICACION DE PASTAS" },
  { "codigo": "392", "descripcion": "SERVICIOS MEDICOS" },
  { "codigo": "419", "descripcion": "INVERSIONISTA" },
  { "codigo": "381", "descripcion": "GESTORIA" },
  { "codigo": "382", "descripcion": "ASEGURADORA RIESGOS DE TRABAJO" },
  { "codigo": "394", "descripcion": "LABORATORIO" },
  {
    "codigo": "414",
    "descripcion": "VENTA DE MATERIALES PARA LA CONSTRUCCION"
  },
  { "codigo": "384", "descripcion": "LICENCIADO EN RELACIONES LABORALES" },
  { "codigo": "424", "descripcion": "ORGANIZADOR DE EVENTOS" },
  { "codigo": "408", "descripcion": "ESCRITOR" },
  { "codigo": "425", "descripcion": "NUTRICIONISTA" },
  { "codigo": "427", "descripcion": "SERVICIOS DE LOGISTICA" },
  { "codigo": "413", "descripcion": "VERIFICACION TEC. VEHICULAR" },
  { "codigo": "432", "descripcion": "ALQUILER DE CONTENEDORES" },
  { "codigo": "433", "descripcion": "FILANTROPIA" },
  { "codigo": "398", "descripcion": "SERVICIO DE CABLE" },
  { "codigo": "404", "descripcion": "SERENO" },
  { "codigo": "421", "descripcion": "MOLINERO" },
  { "codigo": "422", "descripcion": "JOYERIA" },
  { "codigo": "434", "descripcion": "CORREDOR PUBLICO" },
  { "codigo": "390", "descripcion": "LOCUTORIO" },
  { "codigo": "411", "descripcion": "TALLER MECANICO" },
  { "codigo": "420", "descripcion": "APLICACIONES TERRESTRES" },
  { "codigo": "396", "descripcion": "CLUB" },
  { "codigo": "402", "descripcion": "INSPECTOR" },
  { "codigo": "435", "descripcion": "CAMAROGRAFO" },
  { "codigo": "429", "descripcion": "ASISTENTE" },
  { "codigo": "428", "descripcion": "DIRECTOR" },
  { "codigo": "401", "descripcion": "CENTRO DE INVESTIGACIONES" },
  { "codigo": "415", "descripcion": "ARTESANO" },
  { "codigo": "416", "descripcion": "ACTOR/ACTRIZ" },
  { "codigo": "403", "descripcion": "MOZO/A" },
  { "codigo": "438", "descripcion": "DECORADOR/A" },
  { "codigo": "439", "descripcion": "SERVICIOS DE MANTENIMIENTO" },
  { "codigo": "440", "descripcion": "SEPARACION DE RESIDUOS DOMICILIARIOS" },
  { "codigo": "397", "descripcion": "ASOCIACION SIN FINES DE LUCRO" },
  { "codigo": "385", "descripcion": "COMUNA" },
  { "codigo": "386", "descripcion": "TAPICERO" },
  { "codigo": "387", "descripcion": "OBSERVADOR DE ABORDO" },
  { "codigo": "388", "descripcion": "HOSTEL" },
  { "codigo": "409", "descripcion": "SOCORRISTA" },
  { "codigo": "410", "descripcion": "TURISTA" },
  { "codigo": "436", "descripcion": "VENTA DE APARATOS ELECTRONICOS" },
  { "codigo": "437", "descripcion": "CINE/TEATRO" },
  { "codigo": "441", "descripcion": "EMBOTELLADO DE BEBIDAS" },
  { "codigo": "496", "descripcion": "FLORICULTOR" },
  { "codigo": "448", "descripcion": "AGRONOMIA" },
  { "codigo": "502", "descripcion": "TOPOGRAFO" },
  { "codigo": "498", "descripcion": "ZINGUERIA" },
  { "codigo": "477", "descripcion": "EMPRESA DE RECICLAJE" },
  { "codigo": "485", "descripcion": "PERITO TASADOR" },
  { "codigo": "495", "descripcion": "PODOLOGO/A" },
  { "codigo": "488", "descripcion": "ALQUILER DE EQUIPOS" },
  { "codigo": "457", "descripcion": "SERVICIOS FINANCIEROS" },
  { "codigo": "483", "descripcion": "SERVICIO DE ADIESTRAMIENTO CANINO" },
  {
    "codigo": "442",
    "descripcion": "VENTA DE INSUMOS HOSPITALARIOS/FARMACEU."
  },
  {
    "codigo": "479",
    "descripcion": "REPOBLACION/CONSERV. DE ZONAS FORESTADAS"
  },
  { "codigo": "482", "descripcion": "CONSIGNATARIO/A" },
  { "codigo": "487", "descripcion": "ENTRENADOR DE ANIMALES" },
  { "codigo": "467", "descripcion": "COORDINADOR/A DE EVENTOS" },
  { "codigo": "472", "descripcion": "PRODUCCION DE TABACO" },
  { "codigo": "455", "descripcion": "VIVERO" },
  { "codigo": "456", "descripcion": "PUESTERO" },
  { "codigo": "461", "descripcion": "SEGUROS" },
  { "codigo": "480", "descripcion": "AGENTE MARITIMO" },
  { "codigo": "458", "descripcion": "USINA" },
  { "codigo": "471", "descripcion": "SERVICIO DE PULVERIZACION" },
  { "codigo": "497", "descripcion": "VENTA DE ANIMALES" },
  { "codigo": "462", "descripcion": "PLAYA DE ESTACIONAMIENTO/COCHERA" },
  { "codigo": "463", "descripcion": "REPOSITOR" },
  { "codigo": "453", "descripcion": "ANALISTA CONTABLE" },
  { "codigo": "454", "descripcion": "CANTERA" },
  { "codigo": "445", "descripcion": "FABRICACION DE PRODUCTOS QUIMICOS" },
  { "codigo": "503", "descripcion": "SALON DE BAILE/DISCOTECA" },
  { "codigo": "504", "descripcion": "AUXILIO MECANICO" },
  { "codigo": "466", "descripcion": "REFINACION DE MATERIA PRIMA" },
  { "codigo": "449", "descripcion": "ARQUEOLOGO" },
  { "codigo": "469", "descripcion": "PENSIONADO" },
  { "codigo": "505", "descripcion": "FUNDACION" },
  { "codigo": "460", "descripcion": "TALLER DE REPARACIONES NAVALES" },
  { "codigo": "478", "descripcion": "HOGAR DE ANCIANOS" },
  { "codigo": "500", "descripcion": "RELACIONES PUBLICAS" },
  { "codigo": "501", "descripcion": "COSMETOLOGA" },
  { "codigo": "450", "descripcion": "SONIDISTA" },
  { "codigo": "447", "descripcion": "EDITOR" },
  { "codigo": "494", "descripcion": "SEGURIDAD VIAL" },
  { "codigo": "489", "descripcion": "VENTA DE COLCHONES" },
  { "codigo": "490", "descripcion": "SERVICIO DE DIAGNOSTICO" },
  { "codigo": "493", "descripcion": "AGENTE SANITARIO" },
  { "codigo": "475", "descripcion": "ORGANISMOS ESTATALES" },
  { "codigo": "481", "descripcion": "GUARDABOSQUES" },
  { "codigo": "452", "descripcion": "ESTUDIO JURIDICO" },
  { "codigo": "465", "descripcion": "ESPECTACULOS" },
  { "codigo": "446", "descripcion": "PEDICURO" },
  { "codigo": "464", "descripcion": "HOGAR PARA DISCAPACITADOS" },
  { "codigo": "470", "descripcion": "ARRENDATARIO" },
  { "codigo": "492", "descripcion": "TINTORERIA" },
  { "codigo": "486", "descripcion": "GIMNASIO" },
  { "codigo": "451", "descripcion": "MERCADO DE VALORES" },
  { "codigo": "459", "descripcion": "MANICURA" },
  { "codigo": "476", "descripcion": "SERVICIOS PETROLIFEROS" },
  { "codigo": "473", "descripcion": "FIDEICOMISO" },
  { "codigo": "474", "descripcion": "MASAJISTA" },
  { "codigo": "484", "descripcion": "FUNCIONARIO PUBLICO" },
  { "codigo": "499", "descripcion": "VENTA DE TELEFONIA CELULAR" },
  { "codigo": "443", "descripcion": "PREVENTISTA" },
  { "codigo": "491", "descripcion": "TECNICO SUP.EN PROTESIS DENTAL" },
  { "codigo": "444", "descripcion": "PROMOTOR/A" },
  { "codigo": "468", "descripcion": "ARBITRO" },
  { "codigo": "525", "descripcion": "UNIVERSIDAD" },
  { "codigo": "533", "descripcion": "SOCIOLOGO/A" },
  { "codigo": "515", "descripcion": "BANCO" },
  { "codigo": "517", "descripcion": "ELABORACION DE MATERIALES DE CONSTRUCC." },
  { "codigo": "518", "descripcion": "METROLOGIA LEGAL" },
  { "codigo": "519", "descripcion": "BARRIO PRIVADO" },
  { "codigo": "524", "descripcion": "CRIADOR DE ANIMALES" },
  { "codigo": "523", "descripcion": "GEOFISICO" },
  { "codigo": "506", "descripcion": "FABRICANTE DE EMBARCACIONES" },
  { "codigo": "535", "descripcion": "AUDITOR/A" },
  { "codigo": "516", "descripcion": "CRIADORES DE CABALLOS" },
  { "codigo": "507", "descripcion": "MECANICO HIDRAULICO" },
  { "codigo": "513", "descripcion": "INTENDENTE" },
  { "codigo": "528", "descripcion": "AUTODROMO" },
  { "codigo": "530", "descripcion": "LUSTRADOR DE MUEBLES" },
  { "codigo": "511", "descripcion": "SANATORIO" },
  { "codigo": "534", "descripcion": "LOCADOR" },
  { "codigo": "529", "descripcion": "PILOTO DE AUTOS" },
  { "codigo": "508", "descripcion": "TECNICO EN INST.QUIRURGICA" },
  { "codigo": "509", "descripcion": "DISEÑADOR INDUSTRIAL" },
  { "codigo": "520", "descripcion": "O.N.G." },
  { "codigo": "510", "descripcion": "HIPODROMO" },
  { "codigo": "521", "descripcion": "MUSEO" },
  { "codigo": "527", "descripcion": "ENOLOGO/A" },
  { "codigo": "532", "descripcion": "ESQUILADOR/ES" },
  { "codigo": "512", "descripcion": "LICENCIADO EN COMERCIALIZACION" },
  { "codigo": "514", "descripcion": "CENTRO DE RECREACION" },
  { "codigo": "526", "descripcion": "ORIENTADOR ESCOLAR" },
  { "codigo": "522", "descripcion": "DISC JOCKEY" },
  { "codigo": "531", "descripcion": "PROVEEDOR DE SERVICIOS INFORMATICOS" },
  { "codigo": "538", "descripcion": "COMISARIA" },
  { "codigo": "540", "descripcion": "PARAPSICOLOGO/A" },
  { "codigo": "541", "descripcion": "CENTRO DE JUBILADOS Y PENSIONADOS" },
  { "codigo": "546", "descripcion": "ALQUILER DE PREDIOS DEPORTIVOS" },
  { "codigo": "543", "descripcion": "BALNEARIO" },
  { "codigo": "544", "descripcion": "VENTA DE ART.DE LIBRERIA" },
  { "codigo": "537", "descripcion": "SERVICIOS DE LABRANZA" },
  { "codigo": "542", "descripcion": "FERROVIARIO" },
  { "codigo": "545", "descripcion": "GALERIA DE COMPRAS" },
  { "codigo": "539", "descripcion": "LICENCIADO EN LETRAS" },
  { "codigo": "536", "descripcion": "MARROQUINERO Y/O TALABARTERO" },
  { "codigo": "573", "descripcion": "INSTRUCTOR DE TENIS" },
  { "codigo": "577", "descripcion": "PRECEPTOR/CELADOR" },
  { "codigo": "554", "descripcion": "INSTRUCTOR DE BUCEO" },
  { "codigo": "555", "descripcion": "ALQUILER DE CABAÑAS" },
  { "codigo": "556", "descripcion": "CHOCOLATERA" },
  { "codigo": "557", "descripcion": "EXCURSIONES" },
  { "codigo": "558", "descripcion": "INSTRUCTOR DE ESQUI" },
  { "codigo": "559", "descripcion": "INSTRUCTOR DE SNOWBOARD" },
  { "codigo": "560", "descripcion": "LIMPIEZA DE VIDRIOS" },
  { "codigo": "561", "descripcion": "PRODUCTOR/A DE CINE" },
  { "codigo": "562", "descripcion": "VINOTECA" },
  { "codigo": "593", "descripcion": "VENTA DE BICICLETAS" },
  { "codigo": "580", "descripcion": "CONSTRUCC., REFORMA Y REPARAC.DE REDES" },
  { "codigo": "569", "descripcion": "REPARTIDOR DE COMIDAS" },
  { "codigo": "586", "descripcion": "VENTA DE ARTICULOS DEPORTIVOS" },
  { "codigo": "572", "descripcion": "PSICOMOTRISTA" },
  {
    "codigo": "590",
    "descripcion": "ADMINISTRACION DE SERVICIOS ATMOSFERICOS"
  },
  { "codigo": "575", "descripcion": "TECNICO EN PETROLEO" },
  { "codigo": "592", "descripcion": "SERVICIO DE RECOLECCION DE FRUTAS/VERD." },
  { "codigo": "550", "descripcion": "SERV.DE ESPARCIMIENTO CON JUEGOS" },
  { "codigo": "548", "descripcion": "ZAPATERIA" },
  { "codigo": "568", "descripcion": "FABRICACION DE PRODUCTOS METALICOS" },
  { "codigo": "571", "descripcion": "CHATARRERO" },
  { "codigo": "584", "descripcion": "PLANETARIO" },
  { "codigo": "587", "descripcion": "HOGAR DE NIÑOS" },
  { "codigo": "588", "descripcion": "TECNICO/A EN HEMOTERAPIA" },
  { "codigo": "602", "descripcion": "LICENCIADO EN CS.DE LA EDUCACION" },
  { "codigo": "597", "descripcion": "SALA DE ENSAYOS Y/O GRABACIÓN" },
  { "codigo": "600", "descripcion": "CORRETAJE DE CEREALES" },
  { "codigo": "563", "descripcion": "OPERADOR TURISTICO" },
  { "codigo": "564", "descripcion": "PATRON DE EMBARCACIONES" },
  { "codigo": "565", "descripcion": "TECHISTA" },
  { "codigo": "566", "descripcion": "TIMONEL" },
  { "codigo": "567", "descripcion": "SISTEMAS DE RIEGO" },
  { "codigo": "601", "descripcion": "VENTA DE PRODUCTOS ALIMENTICIOS" },
  { "codigo": "603", "descripcion": "PAISAJISTA" },
  { "codigo": "591", "descripcion": "INGENIERO EN HIGIENE Y SEGURIDAD" },
  { "codigo": "553", "descripcion": "ARENERA" },
  { "codigo": "598", "descripcion": "BROMATOLOGO/A" },
  { "codigo": "594", "descripcion": "BUZO TACTICO" },
  { "codigo": "595", "descripcion": "EXPLOSIVISTA" },
  { "codigo": "578", "descripcion": "EMPRESA EXPORTADORA" },
  { "codigo": "579", "descripcion": "DIST.DE ENERGIA ELECTRICA" },
  { "codigo": "582", "descripcion": "INGENIERO NAVAL" },
  {
    "codigo": "589",
    "descripcion": "COMERCIO DE LUBRICANTES, FILTROS Y ACCE."
  },
  { "codigo": "549", "descripcion": "VENTA DE GAS ENVASADO" },
  { "codigo": "552", "descripcion": "TERAPISTA OCUPACIONAL" },
  { "codigo": "574", "descripcion": "BAR/PUB" },
  { "codigo": "570", "descripcion": "GUARDAPARQUES" },
  { "codigo": "604", "descripcion": "PANTOGRAFIA" },
  { "codigo": "551", "descripcion": "FLORERIA" },
  { "codigo": "599", "descripcion": "VENTA DE ALIMENTO PARA ANIMALES" },
  { "codigo": "596", "descripcion": "CASINO" },
  { "codigo": "583", "descripcion": "INSTRUCTOR DE GIMNASIA" },
  { "codigo": "581", "descripcion": "VENTA Y/O REPARACION DE NEUMÁTICOS" },
  { "codigo": "547", "descripcion": "PRODUCTOR DE MEDIOS DE COMUNICACION" },
  { "codigo": "606", "descripcion": "SERVICIO DE TELECOMUNICACIONES" },
  { "codigo": "607", "descripcion": "BUCEO" },
  { "codigo": "608", "descripcion": "DIRECTOR DE ESCUELA" },
  { "codigo": "605", "descripcion": "AZAFATA" },
  { "codigo": "612", "descripcion": "PISCICULTOR/A" },
  { "codigo": "614", "descripcion": "GRAFOLOGO/A" },
  { "codigo": "613", "descripcion": "TATUADOR" },
  { "codigo": "610", "descripcion": "SALON DE BELLEZA" },
  { "codigo": "609", "descripcion": "FABRICACION DE PRODUCTOS PLASTICOS" },
  { "codigo": "617", "descripcion": "SERVICIOS RELACIONADOS CON LA SALUD" },
  { "codigo": "611", "descripcion": "DIPLOMATICO" },
  { "codigo": "615", "descripcion": "ORGANISMO NO GUBERNAMENTAL" },
  { "codigo": "616", "descripcion": "TEC.SUP.EN PSICOMOTRICIDAD" },
  { "codigo": "623", "descripcion": "CONSULADO" },
  { "codigo": "621", "descripcion": "MUSEOLOGO/A" },
  { "codigo": "619", "descripcion": "DISEÑADOR DE MODA" },
  { "codigo": "622", "descripcion": "HERBORISTERIA" },
  { "codigo": "620", "descripcion": "MATANZA DE GANADO" },
  { "codigo": "625", "descripcion": "SERVICIO PENITENCIARIO" },
  { "codigo": "624", "descripcion": "CAPACITACION" },
  { "codigo": "166", "descripcion": "AGENTE DE SEGUROS" },
  { "codigo": "165", "descripcion": "PANADERIA / CONFITERIA" },
  { "codigo": "181", "descripcion": "CADETE" },
  { "codigo": "001", "descripcion": "ABOGADO/A" },
  { "codigo": "139", "descripcion": "PARAMEDICO/A" },
  { "codigo": "140", "descripcion": "TECNICO SANITARIO" },
  { "codigo": "141", "descripcion": "QUIMICO INDUSTRIAL" },
  { "codigo": "142", "descripcion": "PROFESOR CIENCIAS ECONOMICAS" },
  { "codigo": "143", "descripcion": "LICENCIADO EN ESTADISTICA" },
  { "codigo": "144", "descripcion": "LICENCIADO ANALISIS DE SISTEMAS" },
  { "codigo": "145", "descripcion": "OPERARIO" },
  { "codigo": "146", "descripcion": "CIENCIAS DE LA COMPUTACION" },
  { "codigo": "147", "descripcion": "LICENCIADO EN ODONTOLOGIA" },
  { "codigo": "148", "descripcion": "PROFESOR/A EN LETRAS" },
  { "codigo": "149", "descripcion": "LICENCIADO/A EN FINANZAS" },
  { "codigo": "150", "descripcion": "TRADUCTOR/A" },
  { "codigo": "151", "descripcion": "TECNICO SUPERIOR EN MARKETING" },
  { "codigo": "152", "descripcion": "ADMINISTRACION AGRARIA" },
  { "codigo": "153", "descripcion": "PROFESOR/A FILOSOFIA" },
  { "codigo": "154", "descripcion": "LICENCIADO EN SERVICIOS SOCIALES" },
  { "codigo": "155", "descripcion": "TECNICO DE LABORATORIO" },
  { "codigo": "156", "descripcion": "RETIRADO" },
  { "codigo": "157", "descripcion": "LICENCIADO EN HISTORIA" },
  { "codigo": "158", "descripcion": "TECNICO TORNERO" },
  { "codigo": "159", "descripcion": "LICENCIADO ADMINISTRACION" },
  { "codigo": "160", "descripcion": "ELECTRONICA" },
  { "codigo": "161", "descripcion": "INGENIERO CONSTRUCTOR" },
  { "codigo": "162", "descripcion": "ESTILISTA" },
  { "codigo": "163", "descripcion": "METALURGICO/A" },
  { "codigo": "164", "descripcion": "JUEZ/A" },
  { "codigo": "094", "descripcion": "CURA PARROCO" },
  { "codigo": "095", "descripcion": "CARPINTERO" },
  { "codigo": "096", "descripcion": "TAMBERO" },
  { "codigo": "097", "descripcion": "LICENCIADO EN COMUNICACION SOCIAL" },
  { "codigo": "098", "descripcion": "LICENCIADO EN ECONOMIA AGROPECUARIA" },
  { "codigo": "099", "descripcion": "PILOTO PROFESIONAL DE AVIONES" },
  { "codigo": "100", "descripcion": "SUPERVISOR TECNICO" },
  { "codigo": "101", "descripcion": "TECNICO BANCARIO" },
  { "codigo": "102", "descripcion": "DOCTOR/A EN QUIMICA" },
  { "codigo": "103", "descripcion": "TECNICO AUTOMOTORES" },
  { "codigo": "104", "descripcion": "LICENCIADO EN TEOLOGIA" },
  { "codigo": "106", "descripcion": "TECNICO RADIOLOGO" },
  { "codigo": "107", "descripcion": "INGENIERO PETROQUIMICO" },
  { "codigo": "108", "descripcion": "PERITO TECNICO BANCARIO" },
  { "codigo": "109", "descripcion": "MILITAR" },
  { "codigo": "110", "descripcion": "INGENIERO HIDRAULICO" },
  { "codigo": "111", "descripcion": "LICENCIADO EN CIENCIA INFORMATICA" },
  { "codigo": "112", "descripcion": "LICENCIADO EN BELLAS ARTES" },
  { "codigo": "113", "descripcion": "LICENCIADO EN CIENCIAS ECONOMICAS" },
  { "codigo": "114", "descripcion": "INGENIERO MECANICO ELECTRICISTA" },
  { "codigo": "115", "descripcion": "ORTOPEDISTA" },
  { "codigo": "116", "descripcion": "ESTADISTICO" },
  { "codigo": "117", "descripcion": "LOCUTOR/A" },
  { "codigo": "118", "descripcion": "LICENCIADO/A EN SISTEMAS" },
  { "codigo": "119", "descripcion": "BANCARIO" },
  { "codigo": "120", "descripcion": "MAESTRO MAYOR EN OBRAS" },
  { "codigo": "121", "descripcion": "INGENIERO EN ALIMENTACION" },
  { "codigo": "122", "descripcion": "TECNICO ELECTROMECANICO NAVAL" },
  { "codigo": "123", "descripcion": "CONSTRUCCIÓN" },
  { "codigo": "124", "descripcion": "CALIGRAFIA" },
  { "codigo": "125", "descripcion": "LICENCIADO EN TURISMO" },
  { "codigo": "126", "descripcion": "NOTARIO" },
  { "codigo": "127", "descripcion": "CIRUJANO/A" },
  { "codigo": "128", "descripcion": "LICENCIADO ADMINISTRACION DE PERSONAL" },
  { "codigo": "129", "descripcion": "TECNICO ADMINISTRATIVO CONTABLE" },
  { "codigo": "130", "descripcion": "TRABAJADOR/A SOCIAL" },
  { "codigo": "131", "descripcion": "RADIOLOGO/A" },
  { "codigo": "132", "descripcion": "LICENCIADO EN MARKETING" },
  { "codigo": "133", "descripcion": "TECNICO EN COMPUTACION" },
  { "codigo": "134", "descripcion": "PERITO AGRONOMO" },
  { "codigo": "135", "descripcion": "LICENCIADO EN FISICA" },
  { "codigo": "136", "descripcion": "LICENCIADO EN MECANICA AGRICOLA" },
  { "codigo": "137", "descripcion": "LICENCIADO FISIOTERAPEUTA" },
  { "codigo": "138", "descripcion": "BIOLOGO/A" },
  { "codigo": "049", "descripcion": "INGENIERO/A FORESTAL" },
  { "codigo": "050", "descripcion": "INGENIERO QUIMICO" },
  { "codigo": "051", "descripcion": "TECNICO EN REFRIGERACION" },
  { "codigo": "052", "descripcion": "TECNICO EN ADMINISTRACION DE EMPRESAS" },
  { "codigo": "053", "descripcion": "MARTILLERO PUBLICO" },
  { "codigo": "054", "descripcion": "PERITO CLASIFICADOR DE GRANOS" },
  { "codigo": "055", "descripcion": "INGENIERO ELECTRONICO" },
  { "codigo": "056", "descripcion": "ASISTENCIA SOCIAL" },
  { "codigo": "057", "descripcion": "FONOAUDIOLOGO/A" },
  { "codigo": "058", "descripcion": "INGENIERO INDUSTRIA ALIMENTACION" },
  { "codigo": "059", "descripcion": "LICENCIADO EN ADMINISTRACION DEL AGRO" },
  { "codigo": "060", "descripcion": "ALBAÑIL" },
  { "codigo": "061", "descripcion": "PERITO OPTICO" },
  { "codigo": "062", "descripcion": "LICENCIADO EN COOPERATIVAS" },
  { "codigo": "063", "descripcion": "LICENCIADO EN CIENCIAS POLITICAS" },
  { "codigo": "064", "descripcion": "PROGRAMADOR" },
  { "codigo": "065", "descripcion": "LICENCIADO PROPULSION NAVAL" },
  { "codigo": "066", "descripcion": "OPTICO" },
  { "codigo": "067", "descripcion": "LICENCIADO EN ANTROPOLOGIA" },
  { "codigo": "068", "descripcion": "ENFERMERO/A" },
  { "codigo": "069", "descripcion": "CONTRATISTA" },
  { "codigo": "070", "descripcion": "TOCOGINECOLOGO/A" },
  { "codigo": "071", "descripcion": "PSICOLOGO/A" },
  { "codigo": "072", "descripcion": "AGENCIA DE VIAJES Y TURISMO" },
  { "codigo": "073", "descripcion": "LICENCIADO/A EN MATEMATICA" },
  { "codigo": "074", "descripcion": "POLICIA" },
  { "codigo": "075", "descripcion": "PELUQUERO/A" },
  { "codigo": "076", "descripcion": "ACTIVIDAD RELIGIOSA" },
  { "codigo": "077", "descripcion": "EMPLEADO/A" },
  { "codigo": "078", "descripcion": "LICENCIADO EN FILOSOFIA" },
  { "codigo": "079", "descripcion": "MARKETING" },
  { "codigo": "080", "descripcion": "INGENIERO/A INDUSTRIAL" },
  { "codigo": "081", "descripcion": "TECNICO EN ADMINISTRACION HOSPITALARIA" },
  { "codigo": "082", "descripcion": "INGENIERO EN SISTEMAS" },
  { "codigo": "083", "descripcion": "INSTRUMENTISTA" },
  { "codigo": "084", "descripcion": "FISIOTERAPEUTA" },
  { "codigo": "085", "descripcion": "PERSONAL AUXILIAR" },
  { "codigo": "086", "descripcion": "DOCTOR EN CIENCIAS HUMANAS DE EMPRESAS" },
  { "codigo": "087", "descripcion": "PROCURADOR" },
  { "codigo": "088", "descripcion": "PSIQUIATRA" },
  { "codigo": "089", "descripcion": "ELECTROMECANICO" },
  { "codigo": "090", "descripcion": "GEOLOGO/A" },
  { "codigo": "091", "descripcion": "DOCTOR EN FISICOQUIMICA" },
  { "codigo": "092", "descripcion": "PERITO AGROTECNICO" },
  { "codigo": "093", "descripcion": "LICENCIADO EN ECONOMIA" },
  { "codigo": "002", "descripcion": "CONTADOR PUBLICO NACIONAL" },
  { "codigo": "003", "descripcion": "MEDICO/A" },
  { "codigo": "004", "descripcion": "NO ESPECIFICA" },
  { "codigo": "005", "descripcion": "INGENIERO AGRONOMO" },
  { "codigo": "006", "descripcion": "CONTRATISTA RURAL" },
  { "codigo": "007", "descripcion": "ARQUITECTO/A" },
  { "codigo": "008", "descripcion": "TECNICO MECANICO" },
  { "codigo": "009", "descripcion": "ADMINISTRACION DE EMPRESA" },
  { "codigo": "010", "descripcion": "BACHILLER" },
  { "codigo": "011", "descripcion": "ELECTRICISTA" },
  { "codigo": "012", "descripcion": "PERIODISTA" },
  { "codigo": "013", "descripcion": "LICENCIADO EN QUIMICA" },
  { "codigo": "014", "descripcion": "FARMACEUTICO/A" },
  { "codigo": "015", "descripcion": "INGENIERO/A CIVIL" },
  { "codigo": "016", "descripcion": "MAESTRO/PROFESOR" },
  { "codigo": "017", "descripcion": "INGENIERO MECANICO" },
  { "codigo": "018", "descripcion": "INGENIERO EN PETROLEO" },
  { "codigo": "019", "descripcion": "PERITO MERCANTIL" },
  { "codigo": "020", "descripcion": "COMERCIANTE" },
  { "codigo": "021", "descripcion": "DOCENTE" },
  { "codigo": "022", "descripcion": "AGRIMENSOR" },
  { "codigo": "023", "descripcion": "ANALISTA DE SISTEMAS" },
  { "codigo": "024", "descripcion": "AGROPECUARIO" },
  { "codigo": "025", "descripcion": "ESCRIBANO/A" },
  { "codigo": "026", "descripcion": "KINESIOLOGO/A" },
  { "codigo": "027", "descripcion": "TRANSPORTISTA" },
  { "codigo": "028", "descripcion": "VETERINARIO/A" },
  { "codigo": "029", "descripcion": "LIC. ECLESIASTICA" },
  { "codigo": "030", "descripcion": "TECNICO DIGITAL" },
  { "codigo": "031", "descripcion": "TECNICO ELECTRONICO" },
  { "codigo": "032", "descripcion": "TECNICO CONSTRUCTOR" },
  { "codigo": "033", "descripcion": "TECNICO ELECTROMECANICO" },
  { "codigo": "034", "descripcion": "TECNICO QUIMICO" },
  { "codigo": "035", "descripcion": "MAESTRO TALLER" },
  { "codigo": "036", "descripcion": "LIC. ADMINISTRACION DE EMPRESAS" },
  { "codigo": "037", "descripcion": "LIC. TRABAJO SOCIAL" },
  { "codigo": "038", "descripcion": "ANTROPOLOGO/A" },
  { "codigo": "039", "descripcion": "COMPUTADOR CIENTIFICO" },
  { "codigo": "040", "descripcion": "INGENIERO/A ELECTRICISTA" },
  { "codigo": "041", "descripcion": "DIBUJO TECNICO" },
  { "codigo": "042", "descripcion": "FUTBOLISTA" },
  { "codigo": "043", "descripcion": "ODONTOLOGO/A" },
  { "codigo": "044", "descripcion": "MAQUINISTA" },
  { "codigo": "045", "descripcion": "TECNICO/A AGRARIO" },
  { "codigo": "046", "descripcion": "DESEMPLEADO/A" },
  { "codigo": "047", "descripcion": "INGENIERO/A" },
  { "codigo": "048", "descripcion": "MECANICO DENTAL" },
  { "codigo": "220", "descripcion": "INMOBILIARIA" },
  { "codigo": "576", "descripcion": "INGENIERO ELECTROMECANICO" },
  { "codigo": "232", "descripcion": "COOPERATIVA" },
  { "codigo": "234", "descripcion": "OBSERVADOR PERIMETRAL" },
  { "codigo": "235", "descripcion": "SALON PARA EVENTOS" },
  { "codigo": "236", "descripcion": "VENTA DE COMBUSTIBLES" },
  { "codigo": "237", "descripcion": "HERRERIA" },
  { "codigo": "241", "descripcion": "EMPRESA DE SERVICIOS" },
  { "codigo": "173", "descripcion": "SERVICIO DE LIMPIEZA" },
  { "codigo": "242", "descripcion": "APICULTOR/A" },
  { "codigo": "243", "descripcion": "FABRICA DE SODA" },
  { "codigo": "244", "descripcion": "PINTOR DE OBRA" },
  { "codigo": "245", "descripcion": "MUNICIPALIDAD" },
  { "codigo": "246", "descripcion": "REPARTICIONES GUBERNAMENTALES" },
  { "codigo": "247", "descripcion": "SERVICIOS GASTRONOMICOS" },
  { "codigo": "248", "descripcion": "GESTOR IMPOSITIVO" },
  { "codigo": "249", "descripcion": "FOTOGRAFO/A" },
  { "codigo": "250", "descripcion": "SERVICIOS DE MAQUINARIA Y AGROPECUARIOS" },
  { "codigo": "219", "descripcion": "DISTRIBUIDOR" },
  { "codigo": "189", "descripcion": "SERVICIOS DE JARDINERIA" },
  { "codigo": "198", "descripcion": "PINTURERIA" },
  { "codigo": "199", "descripcion": "GERIATRICO" },
  { "codigo": "200", "descripcion": "CHAPISTA" },
  { "codigo": "201", "descripcion": "JARDIN DE INFANTES Y GUARDERIA" },
  { "codigo": "202", "descripcion": "ESCUELA" },
  { "codigo": "211", "descripcion": "AVICULTURA" },
  { "codigo": "212", "descripcion": "PERFORACIONES" },
  { "codigo": "213", "descripcion": "CONCESIONARIA DE AUTOS Y/O MOTOS" },
  { "codigo": "214", "descripcion": "PRODUCCION DE ARANDANOS" },
  { "codigo": "215", "descripcion": "TAXI / REMISSE" },
  { "codigo": "217", "descripcion": "ENTRENADOR DE ARTES MARCIALES" },
  { "codigo": "190", "descripcion": "CONSORCIO" },
  { "codigo": "191", "descripcion": "CARNICERO" },
  { "codigo": "192", "descripcion": "SERVICIOS FUNEBRES" },
  { "codigo": "193", "descripcion": "DIBUJANTE" },
  { "codigo": "194", "descripcion": "SERVICIO DE AMBULANCIAS Y EMERG.MEDICAS" },
  { "codigo": "195", "descripcion": "EMPLEADA DOMESTICA" },
  { "codigo": "196", "descripcion": "ALQUILER DE GRUA" },
  { "codigo": "197", "descripcion": "VIDRIERO" },
  { "codigo": "225", "descripcion": "PLOMERO" },
  { "codigo": "618", "descripcion": "TESORERO/A" },
  { "codigo": "650", "descripcion": "SERVICIOS DE MUDANZAS" },
  { "codigo": "697", "descripcion": "SERV.GRALES.PARA LA ADM PUB" },
  { "codigo": "703", "descripcion": "EMBAJADOR/CONSUL" },
  { "codigo": "700", "descripcion": "SERV.INMOBILIARIOS POR CUENTA PROPIA" },
  { "codigo": "701", "descripcion": "SERVICIOS DE GESTIÓN ADUANERA" },
  {
    "codigo": "704",
    "descripcion": "MOV.DE SUELOS Y PREP.DE TERRENOS P/ OBRA"
  },
  { "codigo": "698", "descripcion": "SERVICIOS JURÍDICOS" },
  { "codigo": "699", "descripcion": "ASTROLOGÍA" },
  { "codigo": "708", "descripcion": "LICENCIADO/A EN OBSTETRICIA" },
  { "codigo": "707", "descripcion": "SERV. DE TRANSP. AUTOMOTOR DE PASAJEROS" },
  { "codigo": "702", "descripcion": "FISCAL" },
  { "codigo": "706", "descripcion": "SERV COMPLEM. PARA EL TRANSP. MARÍTIMO" },
  { "codigo": "705", "descripcion": "SERV MINORISTAS DE AG DE VIAJES" },
  { "codigo": "724", "descripcion": "FIDUCIARIO" },
  { "codigo": "723", "descripcion": "SERV.DE APOYO A LA EDUCACION" },
  { "codigo": "725", "descripcion": "GENDARME" },
  { "codigo": "726", "descripcion": "MECANICO INTEGRAL" },
  { "codigo": "722", "descripcion": "CULTIVO PLANTAS ORNAMENTALES" },
  { "codigo": "715", "descripcion": "ELAB.PESC.DE MAR, CRUSTÁCEOS, PROD.MAR." },
  {
    "codigo": "713",
    "descripcion": "VTA POR MAY.COMISIÓN ALIMENT., BEB.,TAB."
  },
  { "codigo": "717", "descripcion": "SERVICIOS PERSONALES" },
  { "codigo": "719", "descripcion": "TRANSPORTADORAS DE CAUDALES" },
  { "codigo": "718", "descripcion": "FUNDICIÓN DE METALES NO FERROSOS" },
  { "codigo": "709", "descripcion": "DIPUTADO PROVINCIAL" },
  {
    "codigo": "710",
    "descripcion": "CRIA DE GANADO EQUINO REALIZADA EN HARAS"
  },
  { "codigo": "711", "descripcion": "ENS.UNIV.EXC FORMACION DE POSGRADO" },
  { "codigo": "714", "descripcion": "FAB.CARROCERIAS P/VEHIC, FAB.REMOLQUES" },
  { "codigo": "720", "descripcion": "AGRICULTURA AGROECOLOGICA" },
  { "codigo": "721", "descripcion": "AGRICULTURA HIDROPÓNICA" },
  { "codigo": "712", "descripcion": "FABRICACIÓN VENTI., EXT.AIRE Y SIMILARES" }
]

export const ESTADO_CIVIL = [
  { codigo: "7", descripcion: "CONVIVIENTE" },
  { codigo: "1", descripcion: "SOLTERO/A" },
  { codigo: "2", descripcion: "CASADO/A" },
  { codigo: "3", descripcion: "VIUDO/A" },
  { codigo: "4", descripcion: "DIVORCIADO/A" },
  { codigo: "5", descripcion: "SEPARADO/A" },
];