import { descargarZip } from "../services/Descarga";
import { getSolicitud } from "../services/Emision";
import createExcelFile from "../utils/createExcelFile";
import useModal from "./useModal";
import url from "../utils/url";

export const useDescarga = () => {

  const { isModalOpen, handleModal, modalMessage, handleModalMessage, setIsModalOpen } = useModal()

  const descargarSolicitud = async (id) => {  
    handleModal();  
    handleModalMessage("Preparando archivos")  
    try{
      const solicitud = await getSolicitud(id)

      /*
      let document; 
      if(solicitud.seguro.cia_cobertura_asegurada === 19){
        const excelFilePath = await createExcelFile(solicitud)
        document = excelFilePath;
      }
      */

      //let solicitudFiles = { solicitud: document }
      let solicitudFiles = {}
      for (const key in solicitud.bienAsegurado) {
        if(key.startsWith('archivo')){
          solicitudFiles[key] = solicitud.bienAsegurado[key]
        }
      }

      const zip_data = {
          files: solicitudFiles, 
          name: `${solicitud.asegurado.nombre_asegurado}-${solicitud.asegurado.apellido_asegurado}`, 
          id_solicitud: solicitud.solicitud.id,
          id_asegurado: solicitud.solicitud.id_asegurado
      }

      const zip = await descargarZip(zip_data)
      if(zip.status === 200){
        setIsModalOpen(false);
        let zipPath = zip.data
        window.location.href = `${url}${zipPath}`
      }
    } catch (error){
      console.log(error)
    }
  }

  return {
    modal: {
        isModalOpen,
        handleModal,
        modalMessage
    },
    descargarSolicitud,
  };
};
