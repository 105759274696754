import { Grid, Col } from "../../Grid";
import { InputBasic, ErrorInput } from "../../../components/Index";
import { Container } from "./styled";

const NuevoLimiteForm = ({ form, data }) => {

  const { inputValues, handleChange, formErrors } = form;
  const { companiaLogo, productos } = data
  const producto = productos.find(producto => producto.value === inputValues.producto)?.label

  return (
    <Container>
        <Grid colGap={21} rowGap={10} narrow={true}>
            <Col desktop={3}>
                <div className="limite-container">
                <div className="logo-container">
                    <img src={companiaLogo.logo} alt={`Logo de ${companiaLogo.nombre}`} />
                </div>
                <hr className="divider" />
                <span className="limite-descripcion">
                    <strong>Empresa: </strong> {companiaLogo.nombre}
                </span>
                <span className="limite-descripcion">
                    <strong>Producto:</strong> {producto}
                </span>
                <hr className="divider" />
                <InputBasic
                    labelName={`Limite de ${companiaLogo.nombre}`}
                    placeholder={`Limite de ${companiaLogo.nombre}`}
                    name="limite"
                    value={inputValues.limite}
                    onChange={handleChange}
                />
                {formErrors.limite && (
                    <ErrorInput>{formErrors.limite}</ErrorInput>
                )}
                </div>
            </Col>
        </Grid>
    </Container>
  );
};

export default NuevoLimiteForm;
