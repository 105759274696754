import Modal from "../../../Modal"
import { ModalEditarOperador } from "../../../../pages/styled"
import Titulo from "../../../Titulo"
import { Grid, Col } from "../../../Grid"
import Button from "../../../Button"
import InputBasic from "../../../InputBasic"
import { ContainerBotones, Usuario, Icon } from "./styled"
import ErrorInput from "../../../ErrorInput"
import { InputFileRec2 } from "../../../Index"
import FileLoadedNew from "../../../FileLoadedNew"
import SpinnerLoading from "../../../SpinnerLoading"
// Icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { VscLock } from 'react-icons/vsc'
import { FaUserCircle } from 'react-icons/fa'
import { BsCreditCard2Back, BsCart3, BsPersonCircle } from 'react-icons/bs'
import docImage from '../../../../assets/images/doc_cuadrado.png'
// React
import { useState } from "react"
import Subtitulo from "../../../Subtitulo"

const ModalCargarObjetivosLeads = ({ isModalOpen, setIsModalOpen, data }) => {

    const { loading, cargarObjetivos, csvFile, setCsvFile } = data

    return (
      <div>
            <Modal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                >
                <ModalEditarOperador>
                    <Titulo margin={true}>
                        Cargar Objetivos
                    </Titulo>
                    <Grid colGap={21} rowGap={21}>
                        {!loading ? 
                            <>
                            <Col desktop={12}>
                                <InputFileRec2
                                    type={"file"}
                                    name={"objetivos"}
                                    onChange={(e) => { 
                                        setCsvFile(e.target.files[0])
                                        e.target.value = "";
                                    }}
                                    accept={".csv"}
                                    text={"Cargar archivo .csv"}
                                />
                                {csvFile ? 
                                    <FileLoadedNew filename={csvFile.name} file={docImage} link={URL.createObjectURL(csvFile)} deleteFile={() => setCsvFile('')} />
                                : 
                                    null 
                                }
                            </Col>
                            
                            <Col desktop={12}>
                                <div className="buttonsContSiniestro">
                                    <Button
                                    backgroundColor={false}
                                    borderColor={true}
                                    textColor={true}
                                    icon={false}
                                    onClick={() => {
                                        setCsvFile('')
                                        setIsModalOpen(false)
                                    }}
                                    >
                                    Cancelar
                                    </Button>
                                    <Button
                                        backgroundColor={true}
                                        borderColor={false}
                                        textColor={false}
                                        icon={false}
                                        onClick={cargarObjetivos}
                                        disabled={csvFile ? false : true}
                                    >
                                        Cargar Objetivos
                                    </Button>
                                </div>
                            </Col>
                            </>
                        : 
                            <Col desktop={12}>
                                <SpinnerLoading text="Cargando objetivos..." />
                            </Col>
                        }
                    </Grid>
                </ModalEditarOperador>
            </Modal>
      </div>
    );
}

export default ModalCargarObjetivosLeads
