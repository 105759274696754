import React, { useEffect, useState } from "react";
import Subtitle from "../../../Subtitle";
import {
  FiAlertCircle,
  FiArrowUpRight,
  FiBarChart,
  FiStar,
  FiTarget,
  FiUser,
} from "react-icons/fi";
import { meses } from "../../../../constants/data";
import { Col, Grid } from "../../../Grid";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SpinnerLoading from "../../../SpinnerLoading";
import Subtitulo from "../../../Subtitulo";
import NewButton from "../../../NewButton";
import PieChart from "../../../PieChart";
import axios from "axios";
import axiosRequest from "../../../../utils/axiosConfig";
import CardNumber from "../../../CardNumber";
import CardSinResultados from "../../../CardSinResultados";

const VendedorDetalleTab = ({ detalle }) => {
  const {
    mes,
    vendedor,
    premios,
    vendedorNombre,
    loading,
    objetivos,
    objetivosModal,
    data,
    location,
    previousUrl,
  } = detalle;
  const formatter = new Intl.NumberFormat("es-ES", {});
  const userRol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol;
  const [formaPago, setFormaPago] = useState({});
  const [diasHabiles, setDiasHabiles] = useState(null);
  const [premiosMes, setPremiosMes] = useState(
    location.state?.data ? location.state.data : []
  );
  const [grupo, setGrupo] = useState([]);
  const [grupoData, setGrupoData] = useState(null);
  const [limites, setLimites] = useState(null);
  const [porcentajeObjetivoOperaciones, setPorcentajeObjetivoOperaciones] = useState(null)
  const isComercial = userRol === 10 || userRol === 18 || userRol === 22

  console.log(mes)

  const getLimites = async () => {
    try {
      const limites = await axiosRequest.get(`/premios/limites`);
      const porcentaje_objetivo_operaciones = await axiosRequest.get(
        `/premios/porcentaje_objetivo_operaciones`
      );
      setLimites(limites.data);
      setPorcentajeObjetivoOperaciones(porcentaje_objetivo_operaciones.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLimites();
  }, []);

  useEffect(() => {
    const getVendedor = async () => {
      if (vendedor) {
        try {
          setGrupo([]);
          const grupo = await axiosRequest.get(
            `/premios/grupo/${vendedor.id}/${parseInt(mes) + 1}`
          );
          const grupos = await axiosRequest.get(
            `/premios/grupos/${parseInt(mes) + 1}`
          );
          if (grupo.data) {
            const grupoData = grupos.data.find(
              (item) => item.id_vendedores_grupo == grupo.data.id_grupo
            );
            setGrupoData(grupoData);
            setGrupo(grupo.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, [vendedor, mes]);

  useEffect(() => {
    const getVendedor = async () => {
      //if (!location.state) {
      try {
        const data = await axiosRequest.get(
          `/premios/historial/vendedor/${vendedor.id}/${mes}`
        );
        setPremiosMes(data.data);
      } catch (error) {
        console.log(error);
      }
      // }
    };
    getVendedor();
  }, [mes]);

  useEffect(() => {
    const getFormaPago = async () => {
      try {
        const formaPago = await axiosRequest.get(
          `/premios/vendedor_forma_pago/${mes}/${vendedor.id}`
        );
        const dataPie = {
          labels: formaPago.data.forma_pago.map((item) => {
            const porcentaje = (
              (item.operaciones * 100) /
              data?.operaciones
            ).toFixed(2);
            return `${item.forma_pago} (${porcentaje}%)`;
          }),
          datasets: [
            {
              label: "Operaciones emitidas: ",
              data: formaPago.data.forma_pago.map((item) => item.operaciones),
              backgroundColor: [
                "rgb(26, 86, 255)",
                "rgb(116, 255, 227)",
                "rgb(125, 188, 255)",
              ],
              hoverOffset: 4,
            },
          ],
        };
        setFormaPago(dataPie);
      } catch (error) {
        console.log(error);
      }
    };
    getFormaPago();
  }, [userRol, mes, data]);

  useEffect(() => {
    const getDiasHabiles = async () => {
      try {
        setDiasHabiles(null);
        const month =
          parseInt(mes) + 1 > 9 ? parseInt(mes) + 1 : `0${parseInt(mes) + 1}`;
        console.log(month);
        const feriados = await axios.get(
          "https://api.argentinadatos.com/v1/feriados/2024"
        );
        const feriadosMes = feriados.data.filter((item) => {
          const day = new Date(
            "2024",
            parseInt(mes),
            parseInt(item.fecha.split("-")[2])
          ).getDay();
          if (
            item.fecha.split("-")[1].includes(month) &&
            day != 0 &&
            day != 6
          ) {
            return item;
          }
        });

        let diasHabiles = 0;
        let diasHabilesTranscurridos = 0;

        const diasMes = new Date("2024", parseInt(mes) + 1, 0).getDate();
        const feriadosDias =
          feriadosMes.length > 0
            ? feriadosMes.map((item) => item.fecha.split("-")[1])
            : [];
        for (let i = 1; i <= diasMes; i++) {
          let day = new Date("2024", parseInt(mes), i).getDay();
          let today = new Date().getDate();
          if (day != 0 && day != 6) {
            diasHabiles++;

            if (today >= i && !feriadosDias.includes(i.toString())) {
              diasHabilesTranscurridos++;
            }
          }
        }

        const actualMonth = new Date().getMonth();

        if (actualMonth != mes) {
          diasHabilesTranscurridos = diasHabiles;
        }
        diasHabiles -= feriadosMes.length;
        setDiasHabiles({ diasHabiles, diasHabilesTranscurridos });
      } catch (error) {
        console.log(error);
      }
    };
    getDiasHabiles();
  }, [mes]);

  const porcentajeOperaciones = Math.round((100 * data?.operaciones) / objetivos?.operaciones)
  console.log(porcentajeOperaciones)

  return (
    <>
      <hr />
      <Subtitle
        icon={<FiStar />}
        title={`Premios del mes de ${meses
          .find((m) => m.value == mes)
          ?.label.toLowerCase()}`}
        description={
          "Valores obtenidos para todos los premios. Hacé click en cada uno para conocer más detalles."
        }
        disableDivider={true}
      />

      <div className="aclaraciones-premios-container">
        {limites ? (
          <div className="tope-premios">
            <p>
              El tope de la suma de premios es{" "}
              <strong>${formatter.format(limites?.limite_general)}</strong>. El tope de la suma de premios para quienes cumplan sus objetivos en operaciones y prima es{" "}
              <strong>${formatter.format(limites?.limite_objetivos)}</strong>.
            </p>
          </div>
        ) : null}
        {porcentajeObjetivoOperaciones && parseInt(mes) >= 10 ?
        <Grid colGap={10} rowGap={21} narrow={false}>
          <Col desktop={6}>
            <div className="tope-premios">
              <p>
                <strong>No se cobran premios</strong> si no se llega al{" "}
                <strong>{porcentajeObjetivoOperaciones.operaciones_porcentaje_objetivo}% del objetivo de operaciones.</strong>
              </p>
            </div>
          </Col>
          <Col desktop={6}>
            <div className="tope-premios">
              <p>
                <strong>No se cobran comisiones</strong> si no se hace un{" "}
                <strong>mínimo</strong> de <strong>35 operaciones.</strong>
              </p>
            </div>
          </Col>
        </Grid>
        : null}
      </div>

      {premiosMes?.premios?.length > 0 && porcentajeObjetivoOperaciones && ((porcentajeOperaciones >= parseInt(porcentajeObjetivoOperaciones?.operaciones_porcentaje_objetivo) && isComercial) || !isComercial || parseInt(mes) < 10) ? (
        <Grid colGap={21} rowGap={21} narrow={false}>
          {premiosMes.premios.map((premio) => {
            if (premio !== "vendedor" && premio !== "id_vendedor") {
              return (
                <Col desktop={3}>
                  <Link
                    to={{
                      pathname: `/Premio/Vendedor/${vendedor.id}/${premio.id_premio}`,
                      state: {
                        previousUrl: previousUrl,
                        premio: {
                          nombre: premio.nombre,
                          valor: formatter.format(premio.premio),
                        },
                        data: premios.find(
                          (prem) => prem.id_vendedor_premio == premio.id_premio
                        ),
                        mes: mes,
                        vendedor: vendedorNombre,
                      },
                    }}
                  >
                    <CardNumber
                      title={premio.nombre}
                      value={`$${formatter.format(premio.premio)}`}
                      background={premio === "total"}
                    />
                  </Link>
                </Col>
              );
            }
          })}
          <Col desktop={3}>
            <div className={`premio-container total`}>
              <span>Total</span>
              <h2>${formatter.format(premiosMes.total)}</h2>
            </div>
          </Col>
        </Grid>
      ) : null}
      {loading && ((porcentajeOperaciones >= parseInt(porcentajeObjetivoOperaciones?.operaciones_porcentaje_objetivo) && isComercial) || !isComercial || parseInt(mes) < 10) ? <SpinnerLoading /> : null}
      {!loading && premiosMes.premios?.length === 0 ? (
        <CardSinResultados
          icon={<FiStar />}
          title={"No hay premios"}
          description={"¡Los premios de este mes se publicarán proximamente!"}
        />
      ) : null}

      {grupo?.integrantes?.length > 0 ? (
        <>
          <hr />
          <Subtitle
            icon={<FiUser />}
            title={"Grupo"}
            description={"Prima de los integrantes del grupo"}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={false}>
            {grupo.integrantes.length > 0 ? (
              <>
                {grupo.integrantes.map((item, index) => {
                  console.log(item);
                  return (
                    <Col desktop={3}>
                      <div
                        className={`vendedor-container ${
                          item.id_vendedor != vendedor.id && "no-seleccionado"
                        }`}
                      >
                        <h2>#{index + 1}</h2>
                        <span className="vendedor-nombre">{item.nombre}</span>
                        <span>${formatter.format(Math.round(item.prima))}</span>
                      </div>
                    </Col>
                  );
                })}
                {userRol !== 10 &&
                userRol !== 18 &&
                userRol !== 22 &&
                grupoData ? (
                  <Col desktop={12}>
                    <Link
                      to={{
                        pathname: `/Premios/Vendedores/Grupos/${grupo.id_grupo}`,
                        state: grupoData,
                      }}
                    >
                      <NewButton backgroundColor={true}>
                        Ver grupo <FiArrowUpRight />
                      </NewButton>
                    </Link>
                  </Col>
                ) : null}
              </>
            ) : (
              <SpinnerLoading />
            )}
          </Grid>
        </>
      ) : null}

      <hr />
      <Subtitle
        icon={<FiTarget />}
        title={`Objetivos del mes de ${meses
          .find((m) => m.value == mes)
          ?.label.toLowerCase()}`}
        description={"Operaciones y prima a realizar"}
        disableDivider={true}
      />
      {objetivos && (
        <Grid colGap={21} rowGap={21} narrow={false}>
          <Col desktop={3}>
            <CardNumber
              title="Objetivo operaciones"
              value={objetivos.operaciones}
            />
          </Col>
          <Col desktop={3}>
            <CardNumber
              title="Objetivo prima"
              value={`$${formatter.format(objetivos.prima)}`}
            />
          </Col>
          {userRol !== 10 && userRol !== 18 && userRol !== 22 ? (
            <Col desktop={12}>
              <NewButton
                backgroundColor={true}
                onClick={() => objetivosModal.setIsModalOpen(true)}
              >
                Actualizar objetivos
              </NewButton>
            </Col>
          ) : null}
        </Grid>
      )}
      {!objetivos && loading && <SpinnerLoading />}
      {!objetivos && !loading && (
        <div className="sin-solicitudes-container">
          <span className="icon-sin-solicitudes">
            <FiTarget />
          </span>
          <Subtitulo>No hay objetivos cargados</Subtitulo>
          <span className="description">
            No se encontraron objetivos establecidos para el mes de{" "}
            {meses.find((m) => m.value == mes)?.label.toLowerCase()}
          </span>
          {userRol !== 10 && userRol !== 18 && userRol !== 22 ? (
            <NewButton
              backgroundColor={true}
              onClick={() => objetivosModal.setIsModalOpen(true)}
            >
              Crear objetivos
            </NewButton>
          ) : null}
        </div>
      )}

      <hr />
      <Subtitle
        icon={<FiBarChart />}
        title={`Estadísticas del mes de ${meses
          .find((m) => m.value == mes)
          ?.label.toLocaleLowerCase()}`}
        description={`Datos sobre el desempeño de ${vendedorNombre}`}
        disableDivider={true}
      />
      {data && diasHabiles ? (
        <>
          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={3}>
              <CardNumber
                title="Operaciones realizadas"
                value={data?.operaciones}
              />
            </Col>
            {objetivos?.operaciones ? (
              <Col desktop={3}>
                <CardNumber
                  title="Porcentaje operaciones"
                  value={`${Math.round(
                    (100 * data?.operaciones) / objetivos.operaciones
                  )}%`}
                />
              </Col>
            ) : null}
            <Col desktop={3}>
              <CardNumber
                title="Prima obtenida"
                value={`$${formatter.format(Math.round(data?.totalPrima))}`}
              />
            </Col>
            {objetivos?.prima ? (
              <Col desktop={3}>
                <CardNumber
                  title="Porcentaje prima"
                  value={`${Math.round(
                    (100 * data?.totalPrima) / objetivos.prima
                  )}%`}
                />
              </Col>
            ) : null}
            <Col desktop={3}>
              <CardNumber
                title="Dias hábiles"
                value={diasHabiles.diasHabiles}
              />
            </Col>
            <Col desktop={3}>
              <CardNumber
                title="Dias hábiles transcurridos"
                value={diasHabiles.diasHabilesTranscurridos}
              />
            </Col>
          </Grid>
          {Object.keys(formaPago)?.length > 0 ? (
            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={12}>
                <div className={`total-container pie`}>
                  <PieChart data={formaPago} />
                </div>
              </Col>
            </Grid>
          ) : null}
        </>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );
};

export default VendedorDetalleTab;