import { useEffect, useState } from "react";
import axiosRequest from "../../../../utils/axiosConfig";

export const useEditarGrupo = (location, handleChangeExternal) => {
  const [integrantes, setIntegrantes] = useState(
    location.state?.integrantes ? location.state.integrantes : []
  );
  const [vendedores, setVendedores] = useState([]);
  const [listaVendedores, setListaVendedores] = useState([]);
  const [integrantesEliminados, setIntegrantesEliminados] = useState([]);
  const [vendedoresSeleccionados, setVendedoresSeleccionados] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const vendedores = await axiosRequest.get("/premios/vendedores");
        const filterVendedores = vendedores.data.filter((item) => {
          return !integrantes.find(
            (integrante) => integrante.id_vendedor == item.numero
          );
        });
        handleChangeExternal("integrantes", integrantes);
        setVendedores(filterVendedores);
        setListaVendedores(filterVendedores);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const seleccionarVendedor = (vendedor) => {
    const seleccionado = vendedoresSeleccionados.find(
      (ven) => ven === vendedor
    );
    if (seleccionado) {
      const removerVendedor = vendedoresSeleccionados.filter(
        (ven) => ven !== vendedor
      );
      setVendedoresSeleccionados(removerVendedor);
    } else {
      setVendedoresSeleccionados((prev) => [...prev, vendedor]);
    }
  };

  const handleChangeVendedor = (e) => {
    const { value } = e.target;
    if (value) {
      const filtrarVendedores = listaVendedores.filter((vendedor) =>
        vendedor.nombre.toLowerCase().startsWith(value.toLowerCase())
      );
      setVendedores(filtrarVendedores);
    } else {
      setVendedores(listaVendedores);
    }
  };

  const eliminarVendedor = (id) => {
    const vendedores = integrantes.filter((item) => item.id_vendedor != id);
    setIntegrantes(vendedores);
    setIntegrantesEliminados([...integrantesEliminados, id]);
  };

  return {
    eliminarVendedor,
    handleChangeVendedor,
    seleccionarVendedor,
    vendedores,
    integrantes,
    vendedoresSeleccionados,
    listaVendedores,
    integrantesEliminados,
    setVendedoresSeleccionados,
  };
};
