import Skeleton from 'react-loading-skeleton';
import { RowsContainer } from './styled'

const SkeletonLoading = ({rows}) => {

    const generateSkeletons = () => {
        const arraySkeleton = [];
        const skeletons = []
        for (let i = 0; i < rows; i++){
          skeletons.push(<Skeleton width={"80%"} />)
        }
        for (let index = 0; index < 5; index++) {
            arraySkeleton.push(
              <RowsContainer rows={rows}>
                {skeletons}
              </RowsContainer>
            )
        }
        return arraySkeleton;
    }

    return(
      generateSkeletons()
    )
}

export default SkeletonLoading