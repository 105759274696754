import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import { useForm } from "../../../hooks/useForm";
import { editarGrupoReglas } from "../../../utils/formRules";
import { editarGrupoForm } from "../../../utils/formInitialValues";
import { useLoading } from "../../../hooks/useLoading";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import EditarGrupoForm from "../../../components/PremiosForm/EditarGrupo";
import BackButton from "../../../components/BackButton";
import { useEditarGrupo } from "./hooks/useEditarGrupo";
import Divider from "../../../components/Divider";

const PremiosEditarGrupo = () => {
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const history = useHistory(); // Hook de router
  const grupo = useParams();
  const location = useLocation();

  const { handleChangeExternal, formErrors, handleValidation, resetForm } =
    useForm(editarGrupo, editarGrupoReglas, editarGrupoForm);
  const { eliminarVendedor, handleChangeVendedor, seleccionarVendedor, vendedores, integrantes, vendedoresSeleccionados, listaVendedores,integrantesEliminados, setVendedoresSeleccionados } = useEditarGrupo(location, handleChangeExternal);
  const { loading, setLoading } = useLoading();

  async function editarGrupo() {
    setLoading(true);
    try {
      const data = {
        agregar: vendedoresSeleccionados,
        eliminar: integrantesEliminados,
      };
      const editar = await axiosRequest.put(`/premios/actualizar_grupo/${grupo.id}`, data);
      if (editar.status === 200) {
        const agregados = vendedoresSeleccionados.map((vendedor) => {
          const findVendedor = listaVendedores.find((ven) => ven.id_vendedor === vendedor);
          return findVendedor.nombre;
        });
        const eliminados = integrantesEliminados.map((vendedor) => {
          const findVendedor = location.state.integrantes.find((ven) => ven.id_vendedor === vendedor);
          return findVendedor.nombre;
        });

        const agregadosMensaje =
          agregados.length > 0
            ? `Integrantes agregados al grupo: ${agregados.join(", ")}.`
            : "";
        const eliminadosMensaje =
          eliminados.length > 0
            ? `Integrantes eliminados del grupo: ${eliminados.join(", ")}`
            : "";

        const historial = {
          mensaje: `${user.nombre_operador} editó el grupo ${location.state.nombre}. ${agregadosMensaje} ${eliminadosMensaje}`,
          dia: new Date(),
          id_vendedor: null,
          id_premio: null,
          id_usuario: user.id_operador,
          id_tipo_modificacion: 3,
        };
        console.log(historial);

        const historialModificaciones = await axiosRequest.post(
          `/premios/historial_modificaciones`,
          historial
        );

        setLoading(false);
        setVendedoresSeleccionados([]);
        resetForm();
        history.push("/Premios");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div>
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
                <BackButton url="/Premios" text="Premios" />
                <TitlePageSection
                  title="Editar Grupo"
                  description="Modificá la lista de integrantes del grupo"
                ></TitlePageSection>
              </div>
              <Divider />
              <EditarGrupoForm
                data={{ integrantes, eliminarVendedor, handleChangeVendedor, vendedores, vendedoresSeleccionados, seleccionarVendedor, loading }}
                form={{ formErrors, handleValidation }}
              />
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default PremiosEditarGrupo;
