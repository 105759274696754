import { useState, useEffect } from 'react'
import { BarColor, GridFirstDay } from '../../pages/styled'
import dayjs from 'dayjs'

const CalendarDay = ({day, month, firstDay}) => {
    const [fichajeInfo, setFichajeInfo] = useState('')
    const [barColorState, setBarColorState] = useState([])

    let dayColorState = []

    useEffect(() => {
      if(day.excepciones.length){
        if(day.dayNumber != 0 && day.dayNumber != 6){
            dayColorState.push('excepcion')
            setBarColorState(dayColorState)
        }
      }
    }, [day])

    useEffect(() => {
        const getData = async () => {              
            if(day.fichajes.length){
                const data = day.fichajes.map(fichaje => {
                    let hora = dayjs(fichaje.dia_fichaje).format('HH:mm')
                    if(day.fichajes.length >= 4){
                        let horaIngreso = "09:00"
                        let horaSalida = "18:00"
                        if(fichaje.descripcion_fichaje === "Ingreso"){
                            // Chequea si existe una excepción para la hora de ingreso
                            if(day.excepciones.length){
                                if(day.excepciones[0].hora_excepcion === "Hora"){
                                    horaIngreso = dayjs(day.excepciones[0].hora_desde_excepcion).format("HH:mm")
                                }
                            } 
                            const date1 = dayjs(fichaje.dia_fichaje)
                            const date2 = dayjs(new Date(`${day.year}-${parseInt(month) < 10 ? `0${parseInt(month) + 1}` : parseInt(month) + 1}-${day.date} ${horaIngreso}`))
                            const result = date1.diff(date2, 'minutes', true)
                            if(result > 0){
                                dayColorState.push('incumplimiento')
                            } else if(result <= -60){
                                // Si llegó una o hace más de una hora antes de la hora de ingreso
                                dayColorState.push('extra')
                            }
                        }

                        if(fichaje.descripcion_fichaje === "Fin jornada"){
                            // Chequea si existe una excepción para la hora de salida
                            if(day.excepciones.length){
                                if(day.excepciones[0].hora_excepcion === "Hora"){
                                    horaSalida = dayjs(day.excepciones[0].hora_hasta_excepcion).format("HH:mm")
                                }
                            } 
                            const date1 = dayjs(fichaje.dia_fichaje)
                            const date2 = dayjs(new Date(`${day.year}-${parseInt(month) < 10 ? `0${parseInt(month) + 1}` : parseInt(month) + 1}-${day.date} ${horaSalida}`))
                            const result = date1.diff(date2, 'minutes', true)
                            if(result > 0){
                                dayColorState.push('extra')
                            } else if(result <= -60){
                                // Si se fue una o hace más de una hora antes de la hora de salida
                                dayColorState.push('incumplimiento')
                            }
                        }
                    }

                    return {
                        descripcion_fichaje: fichaje.descripcion_fichaje,
                        hora_fichaje: hora + "hs"
                    }
                })
                setBarColorState(dayColorState)
                setFichajeInfo(data)
            }
        }
        getData()
    }, [day])

    useEffect(() => {
        if(day.dayNumber === 0 || day.dayNumber === 6){
            dayColorState.push('no laboral')
            setBarColorState(dayColorState)
        }
    }, [day])

    return(
        <>
        {day.date !== 1 ? 
            <div className="day" >
                <div className="dayContainer">
                    <span className="dayNumber">{day.date}</span>
                    {/*{barColorState === "excepcion" && <span className="openExcepcion">+</span>}*/}
                    <div>
                    {fichajeInfo && fichajeInfo.map((fichaje, index) => <p key={index}>{fichaje.descripcion_fichaje}: <b>{fichaje.hora_fichaje}</b></p>)}
                    </div>
                </div>
                {barColorState.length > 0 ?
                    <div className="barContainer">
                    {barColorState.map((colorState, index) => {
                        return (
                            <BarColor state={colorState} quantity={barColorState.length} key={index}/>
                        )
                    })}
                    </div>
                : null}
            </div>
        : 
            <>
                <GridFirstDay start={firstDay}>
                <div className="dayContainer">
                    <span className="dayNumber">1</span>
                    <div>
                        {fichajeInfo && fichajeInfo.map((fichaje, index) => <p key={index}>{fichaje.descripcion_fichaje}: <b>{fichaje.hora_fichaje}</b></p>)}
                    </div>
                </div>
                {barColorState.length > 0 ?
                    <div className="barContainer">
                    {barColorState.map((colorState, index) => {
                        return (
                            <BarColor state={colorState} quantity={barColorState.length} key={index}/>
                        )
                    })}
                    </div>
                : null}
                </GridFirstDay>
            </>
        }
        </>
    )
}

export default CalendarDay