import React, { useState, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import { Div, InputDiv, Input, ModalRecordatorio } from "./styled";
import Upload from '../../assets/iconos/upload.svg';

function InputFileRec({disabled, type, name, required, labelName, setFiles, valuesStepThree, setValuesStepThree, setModalToShow, onClick, text, onChange, display }) {

  return (
    <>
    <Div>
      <label htmlFor={name}>{labelName}</label>
      <InputDiv>
        <label>
          <Input
            disabled={disabled}
            type={type}
            name={name}
            id={name}
            required={required}
            accept={".csv"}
            onChange={onChange}
            display={display}
          />
          <span>
            <img src={Upload} />
          </span>
          {text ? text : 'Cargar archivo '}
        </label>
      </InputDiv>
    </Div>
    </>
  );
}

// InputFile.propTypes = {
//   children: PropTypes.string.isRequired
// };

export default InputFileRec;
