import ErrorInput from "../../ErrorInput";
import { Landing } from "./styled";
import { landingsTabs } from "../../../constants/tabs";

const LandingsForm = ({ form }) => {

  const { inputValues, handleChangeExternal, error } = form
  return (
    <div>
      <div className="landings-container">
        {landingsTabs.map((landing) => {
          return (
            <Landing
              className="landing"
              onClick={() => handleChangeExternal("landing", landing.name)}
              selected={landing.name === inputValues.landing}
            >
              <img src={landing.image} alt={`Logo de ${landing.name}`} />
            </Landing>
          );
        })}
      </div>
      {error && <ErrorInput>{error}</ErrorInput>}
    </div>
  );
};

export default LandingsForm;
