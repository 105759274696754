import { Grid, Col } from "../Grid";
import Subtitulo from "../Subtitulo";

const GridSection = ({ data, labels, sectionTitle, columns }) => {

  return (
    <>
    <Subtitulo>{sectionTitle}</Subtitulo>
    <Grid colGap={21} rowGap={21} narrow={true}>
      {Object.entries(data).map((item, index) => {
        if (item[1]) {
          const esValorMonetario = typeof(item[1]) === "number" && (labels[item[0]].includes("Suma") || labels[item[0]].includes("Valor"))
          return (
            <Col desktop={columns} key={index}>
              <h4>{labels[item[0]]}</h4>
              <span>
                {esValorMonetario ? "$" : null}
                {item[1]}
              </span>
            </Col>
          );
        }
      })}
    </Grid>
    </>
  );
};

export default GridSection;
