import Modal from "../Modal"
import { motion, AnimatePresence } from "framer-motion"
import Warning from "../../assets/iconos/warning.svg";
import PopUp from "../Popup";
import Button from "../Button";
import { BiError } from "react-icons/bi"

const ModalError = ({isSuccessPopUpOpen, setIsSuccessPopUpOpen, popUpMessage}) => {
    return(
          <Modal isModalOpen={isSuccessPopUpOpen} setIsModalOpen={setIsSuccessPopUpOpen}>
            <PopUp
              icono={Warning}
              backgroundColor={false}
              mensaje={popUpMessage}
              closeModal={setIsSuccessPopUpOpen}
            />
          </Modal>
    )
}

export default ModalError