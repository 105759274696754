import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const ContainerBotones = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;

export const Aclaracion = styled.p`
    margin: 0;
    background-color: #f3fbff;
    color: ${leerColor(colores.celeste)};
    padding: 20px;
    margin-bottom: 15px;
    font-weight: 500;

    span {
      color: ${leerColor(colores.celeste)};
      margin-right: 10px;
      font-weight: 800;
      font-size: 18px;
    }

    code {
        font-weight: 600;
        font-size: 14px;
        background-color: ${leerColor(colores.celeste)};
        color: #f3fbff;
        padding: 2px 4px;
        border-radius: 2px;
    }
`;

export const TableRechazos = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    margin-bottom: 40px;
  }

  table th {
    text-align: left;
    border-collapse: collapse;
    font-size: 14px;
    color: #6d6d6d;
    font-weight: 400;
    padding: 20px 0;
    border-top: 1px solid #eaeaea;
    font-weight: 500;
    background-color: #fdfdfd;
    position: sticky;
    top: 0px;
    border-bottom: 1px solid #eaeaea;
    z-index: 10;
    cursor: pointer;
  }

  table tr {
    border-collapse: collapse;
    border-bottom: 1px solid #eaeaea;
  }

  table td {
    font-size: 14px;
    color: #6d6d6d;
    font-weight: 400;
    padding: 20px 0;
    border-collapse: collapse;
  }

  .selected {
    background-color: #fafafa;
  }

  table td img {
    width: 85px;
  }

  .san_cristobal, .prudencia {
    width: 100px;
  }

  .atm, .hdi {
    width: 60px;
  }

  .experta {
    width: 75px;
  }

  .cliente, .vendedor, .operador, .cobertura, .producto, .estado {
    padding-right: 12px;
    box-sizing: border-box;
  }

  .cliente a {
    text-decoration: none;
    color: ${leerColor(colores.grisEstados)};
  }

  .userIcon {
    display: flex;
    border: 1px solid #eaeaea;
    font-size: 20px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 100px;
    color: #1a56ff;
}

.asegurado {
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    gap: 10px;
}

.aseguradoInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.aseguradoAdditionalInfo {
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 4px;
}

  .pendiente, .pendiente-emisión {
    display: flex !important;
    font-weight: 600 !important;
    background-color: rgb(246, 246, 246) !important;
    color: rgb(120, 120, 120) !important;
    border-radius: 20px !important;
    padding: 6px 12px !important;
    position: relative !important;
    width: fit-content !important;
    align-items: center !important;
  }

  .estado-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    position: relative;
    width: fit-content;
  }

  .prioridad {
    background-color: ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    width: fit-content;
    border-radius: 8px;
    padding: 4px 10px;
    box-sizing: border-box;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 10.5px;
    position: absolute;
    right: -5px;
    top: -17px;
  }

    // Estados //
    .emisión-sin-curso, .emitido---cambio-pas, .endoso-enviado-a-compañia {
      font-weight: 600;
      background-color: #e1f5ff;
      color: ${leerColor(colores.celeste)};
      border-radius: 20px;
      padding: 6px 12px;
      display: block;
    }

  // Estados //
  .emitido,
  .cerradopositivo,
  .cerradopositivo-conformeparcial,
  .cerradopositivo-conforme,
  .cerradopositivo-disconforme,
  .resueltopositivo,
  .emitido-certificado {
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
    display: block;
  }
  .pendiente-emisión-sin-asignar {
    /*
    font-weight: 600;
    background-color: #fff7ed;
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
    //border:1px solid ${leerColor(colores.naranjaEstados)};
    width: min-content;
    */

    display: flex !important;
    font-weight: 600 !important;
    background-color: #fff7ed;
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px !important;
    padding: 6px 12px !important;
    position: relative !important;
    width: fit-content !important;
    align-items: center !important;
  }
  .pendiente,
  .ingresado {
    font-weight: 600;
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
  }
  .rechazado,
  .rechazado-sin-asignar,
  .resueltonegativo,
  .cerrado-negativo,
  .negativo-cerrado,
  .cerradonegativo {
    font-weight: 600;
    background-color: #fff6f6;
    color: ${leerColor(colores.rojo)};
    border-radius: 20px;
    padding: 6px 12px;
    //border:1px solid ${leerColor(colores.rojo)};
    width: min-content;
    display: block;
  }

  .emisiónprovisoria, .pendientededocumentación, .pendientedepago, .pendientederesolución {
    font-weight: 600;
    background-color: #f0faff;
    color: ${leerColor(colores.celeste)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
    display: block;
  }
  .pendientesuscripción {
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 700;
    display: block;
  }
  .pendienteaprobación, .ofrecimiento, .notificado{
    font-weight: 600;
    background-color: #ecf0ff;
    color: ${leerColor(colores.azul)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
    display: block;
  }
  
  .promesa-de-pago {
    font-weight: 600;
    background-color: ${leerColor(colores.naranjaNot)};
    color: ${leerColor(colores.naranja)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
    display: block;
  }
  .estado_previo_rechazado {
    position: absolute;
    top: -15px;
    right: 0;
    //background: #fff7ed;
    background: ${leerColor(colores.blanco)};
    border-radius: 100px;
    color: hsl(36.10000000000002,100%,66.1%);
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    border: 1px solid hsl(36.1deg 100% 82.49%);
  }

  .actionsContainer {
    display: flex;
    gap: 16px;
    box-sizing: border-box;
     
    a { 
      text-decoration: none;
    }
  }

  .actions-center {
    justify-content: center;
  }

  .container-flex {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .activo{
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .inactivo{
    font-weight: 600;
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
    border-radius: 20px;
    padding: 6px 12px;
  }
`;

export const SinResultadosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  svg {
    padding: 50px 0 20px;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${leerColor(colores.grisEstados)};
  }
`;

export const RechazoContainer = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  //min-width: fit-content;
  width: 1200px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  .motivos-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: #202020;
      font-weight: 500;
    }
    .motivo {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        border: 1px solid #eaeaea;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        cursor: pointer;
        //transition: 0.2s;
    }

    .motivo-selected {
        color: ${leerColor(colores.azulRebranding)};
        border: 1px solid ${leerColor(colores.azulRebranding)};
        font-weight: 500;
    }

    .motivo:hover {
        opacity: 0.8s;
    }
  }
  .header-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }
  textarea {
    margin: 0 !important;
  }
  p {
    margin: 0;
    color: ${leerColor(colores.grisEstados)};
  }
  .data-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .buttonsCont {
    display: flex;
    width: 100%;
    padding: 0 30px;
    gap: 10px;
  }
  a {
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  /*
  .buttonsCont a {
    margin-left: 32px;
  }
  */
  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
    //margin-top: 43px;
  }
  .selectCont {
    width: 50%;
  }

  .title_section {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .icon_section {
    color: #FFF;
    font-size: 24px;
    max-height: 24px;
    padding 8px;
    border-radius: 20px;
  }

  .invalid {
      background-color: ${leerColor(colores.rojo)};
  }
  
  .valid {
      background-color: ${leerColor(colores.verde)};
  }

  .warning {
      background-color: ${leerColor(colores.celeste)};
  }

`;