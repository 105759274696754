import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Col,
  Inner,
  InputBasic,
  InputSelect,
} from "../../../Index";
import axiosRequest from "../../../../utils/axiosConfig";
import { Table } from "../../../Table/styled";

const Seguimiento = ({selectToShow, filtro, setFiltro, error, setError, btnLimpiar, btnDisabled, setBtnDisabled,setBtnLimpiar}) => {
    const filtroInicial = {
        producto:'',
        estado:'',
        desde:'',
        hasta:'',
        campania: ''
    }

    const [datosTabla, setDatosTabla] = useState([])
    
    const addField = (e)=>{
        const {name, value} = e.target;
        setFiltro({...filtro,[name]: value})
    }

    const buscarInformePorDia = async () => {
        setBtnDisabled(true)
        try {
            const send = await axiosRequest.get(`/campanias/informe_diario?producto=${filtro.producto}&estado=${filtro.estado}&campania=${filtro.campania}&desde=${filtro.desde}&hasta=${filtro.hasta}`);
            setDatosTabla(send.data)
            if(send.data.length === 0){
                setError({type: 'warning', msg:'No se encontraron coincidencias'})
            }
        } catch (error) {
            setError({type: 'error', msg:error})
        }
        finally{
            setBtnDisabled(false)
        }
    }

    const limpiarForm = ()=>{
        setFiltro(filtroInicial)
        setDatosTabla([])
        setBtnLimpiar(false)
        setBtnDisabled(true)
    }
    return(<> 
                <Col desktop={12}>
                    <Subtitulo>Seguimiento de campaña Email Marketing</Subtitulo>
                </Col>
                <Col desktop={3}>
                    <InputBasic
                        type={"date"}
                        name={"desde"}
                        labelName={"Desde"}
                        value={filtro.desde}
                        onChange={addField}
                    />
                </Col>
                <Col desktop={3}>
                    <InputBasic
                        type={"date"}
                        name={"hasta"}
                        labelName={"Hasta"}
                        value={filtro.hasta}
                        onChange={addField}
                    />
                </Col>
                <Col desktop={3}>
                    <InputSelect
                        name={"campania"}
                        labelName={"Campaña"}
                        options={selectToShow.selectCampanias}
                        value={filtro.campania}
                        onChange={addField}
                        disabled={selectToShow.selectCampanias.length===0}
                    />
                </Col>
                <Col desktop={3}>
                    <InputSelect
                        name={"producto"}
                        labelName={"Producto"}
                        options={selectToShow.selectProductos}
                        value={filtro.producto}
                        onChange={addField}
                    />
                </Col>
                <Col desktop={12}>
                    <Button
                         backgroundColor={true}
                         borderColor={false}
                         textColor={false}
                         icon={false}
                         onClick={buscarInformePorDia}
                         disabled={btnDisabled}
                    >
                        Buscar
                    </Button>
                    {
                        btnLimpiar?
                        <Button
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={limpiarForm}
                        >
                            Limpiar
                        </Button>
                        : null
                    }
                </Col>
                <Col desktop={12}>
                    {
                        datosTabla.length!==0?
                        <Table border={true}>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>Fecha</th>
                                    <th rowSpan={2}>Campaña</th>
                                    <th rowSpan={2}>Producto</th>
                                    <th colSpan={14}>Estado</th>
                                </tr>
                                <tr>
                                    <th>Sin Gestion</th>
                                    <th>NC - 1</th>
                                    <th>NC - 2</th>
                                    <th>NC - 3</th>
                                    <th>Se Coordina Llamado</th>
                                    <th>Interesado</th>
                                    <th>Pendiente de Cierre</th>
                                    <th>Contratado</th>
                                    <th>Baja</th>
                                    <th>Anulada</th>
                                    <th>Costo enviado</th>
                                    <th>Reasignado</th>
                                    <th>Cotizaciones</th>
                                    <th>Venta</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                             {datosTabla.map(d => {
                                 const fecha = new Date(d.fecha).toLocaleString().split(',')
                                 return (
                                     <>
                                                <tr>
                                                    <td>{fecha[0]}</td>
                                                    <td>{d.campania}</td>
                                                    <td>{d.producto}</td>
                                                    <td>{d['Sin Gestion']}</td>
                                                    <td>{d['NC - 1']}</td>
                                                    <td>{d['NC - 2']}</td>
                                                    <td>{d['NC - 3']}</td>
                                                    <td>{d['Se Coordina Llamado']}</td>
                                                    <td>{d['Interesado']}</td>
                                                    <td>{d['Pendiente de Cierre']}</td>
                                                    <td>{d['Contratado']}</td>
                                                    <td>{d['Baja']}</td>
                                                    <td>{d['Anulada']}</td>
                                                    <td>{d['Costo enviado']}</td>
                                                    <td>{d['Reasignado']}</td>
                                                    <td>{d['cotizaciones']}</td>
                                                    <td>{d['venta']}</td>
                                                </tr>
                                     </>
                                 )
                             })}
                             </tbody>
                        </Table>
                        : 
                        error.msg !== '' ? error.msg : null
                    }
                        
                </Col>
    </>)
}

export default Seguimiento;