import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const ClearFilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  .icon {
    width: 22px;
    margin-right: 10px;
  }
`;
