import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const ChatInfo = styled.div`
   
  padding-top: 40px;
  textarea{
    height: auto;
  }
  div.buttons{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0;
  }
  div.buttons .btnAdjQueEnviar{
    display: flex;
    flex-direction: row;
  }
`;
export const Info = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
`;
export const Subtitulo = styled.h4`
   
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: ${leerColor(colores.negro)};
  &:last-of-type{
    justify-self: end;
  }
  &.pendiente{
    &::after{
      content: url(${(props) => props.icon});
      padding-left: 10px;
      display: inline-block;
      height: 20px;
      width: 20px;
    }
  }
`;
export const Perfil = styled.a`
  justify-self: end;
`;
export const Conversacion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const MensajeRecibido = styled.div`
   
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  span{
    font-size: 12px;
    font-weight: 400;
  }
  p{
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
    padding: 12px 20px;
    border-radius: 50px;
    width: fit-content;
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.gris)};
    color: ${leerColor(colores.grisEstados)};
  }
`;
export const MensajeEnviado = styled.div`
   
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  span{
    font-size: 12px;
    font-weight: 400;
  }
  p{
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
    padding: 12px 20px;
    border-radius: 50px;
    width: fit-content;
    background-color: ${leerColor(colores.blanco)};
    border: 1px solid ${leerColor(colores.gris)};
    color: ${leerColor(colores.grisEstados)};
  }
`;
export const BtnAdjQue = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0 10px;
  &:last-of-type{
    margin-right: 20px;
  }
`;