import axios from 'axios';
  
// Borra un archivo en el server
const downloadFile = async (url) => {

    console.log(url)

    const config = { 
        headers: {'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69'},
        responseType: 'blob', 
     }
     
    try {
        const id = Date.now()
        const file = await axios.get( url , config )
        let src;
        var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
        if ( isChrome ){
            src = window.webkitURL.createObjectURL(file.data);
        } else {
            src = window.URL.createObjectURL(file.data);
        }

        return src;
    } catch (error) {
        console.log(error)
    }
}

export default downloadFile