// Components
import Modal from "../../../Modal";
import { ModalEditarOperador } from "../../../../pages/styled";
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import Button from "../../../Button";
import FileLoaded from "../../../FileLoaded";
import { InputFileRec2 } from "../../../Index";
import SpinnerLoading from "../../../SpinnerLoading";
// Iconos
import { ImWarning } from "react-icons/im";
import docImage from '../../../../assets/images/doc_cuadrado.png'

const ModalCargarSiniestroCSV = ({ isModalOpen, handleModal, postSiniestrosLoading, csvFile, setCsvFile, cargarCSV }) => {

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={handleModal}
    >
      <ModalEditarOperador>
        <Titulo margin={true}>Cargar Siniestros con CSV</Titulo>
        <Grid colGap={21} rowGap={21}>
          {!postSiniestrosLoading ? (
            <>
              <Col desktop={12}>
                <p className="fedpat-warning">
                  <span>
                    <ImWarning></ImWarning>
                  </span>
                  IMPORTANTE: APTO SÓLO PARA FEDERACIÓN PATRONAL
                </p>

                <InputFileRec2
                  type={"file"}
                  name={"siniestros_csv"}
                  onChange={(e) => {
                    setCsvFile(e.target.files[0]);
                    e.target.value = "";
                  }}
                  accept={".csv"}
                  text={"Cargar siniestros de Federación Patronal .csv"}
                />
                {csvFile ? (
                  <FileLoaded
                    filename={csvFile.name}
                    file={docImage}
                    link={URL.createObjectURL(csvFile)}
                    deleteFile={() => setCsvFile("")}
                  />
                ) : null}
              </Col>

              <Col desktop={12}>
                <div className="buttonsContSiniestro">
                  <Button
                    backgroundColor={false}
                    borderColor={true}
                    textColor={true}
                    icon={false}
                    onClick={() => {
                      setCsvFile("");
                      handleModal();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    backgroundColor={true}
                    borderColor={false}
                    textColor={false}
                    icon={false}
                    onClick={cargarCSV}
                    disabled={csvFile ? false : true}
                  >
                    Cargar CSV
                  </Button>
                </div>
              </Col>
            </>
          ) : (
            <Col desktop={12}>
              <SpinnerLoading text="Cargando siniestros..." />
            </Col>
          )}
        </Grid>
      </ModalEditarOperador>
    </Modal>
  );
};

export default ModalCargarSiniestroCSV;
