import { useState, useEffect, useRef} from "react"
import { getRechazoById , getCompanias , updateReject, sendMailManually, getRechazoHistorial, getMedioPago, getMediosPago } from "../services/Rechazos"
import { useLoading } from "./useLoading"
import { useAuthContext } from "../context/AuthContext"
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import axiosRequest from "../utils/axiosConfig";

export default function useRechazoV2() {
  // User Data
  const { authState } = useAuthContext()
  const { userInfo } = authState
  const userParams = userInfo.id_rol === 6 ? `user=${userInfo.nombre_operador}` : ``
  let idRechazo = useParams();
  const [ rechazo, setRechazo] =  useState(null);
  const [ historial, setHistorial ] = useState(null)
  const [ rechazoFormated, setRechazoFormated] = useState(null)
  const [ companias, setCompanias] = useState([])
  const { loading, setLoading } = useLoading(true)
  const [ mediosFormated, setMediosFormated ] = useState([])
  const [success, setSuccess] = useState(false)

  const getRechazo = async () => {
    setLoading(true)

    try {
      // obtener rechazos
      const data = await getRechazoById(idRechazo.id)
      setRechazo(data);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const format = (rechazo) => {
    if(rechazo){
      rechazo.createdAt = dayjs(rechazo.createdAt).format('DD/MM/YYYY')
      rechazo.updatedAt = dayjs(rechazo.updatedAt).format('DD/MM/YYYY')
      rechazo.seccion = rechazo.sw_seccione.seccion;
      const compania = companias.find(c => c.key === rechazo.id_compania)
      rechazo.compania = compania.label;
    }

    return rechazo;
  }

  const setResueltoNegativo = async () => {
    setLoading(true)
    try {
      const response = await updateReject(idRechazo.id, {id_estado: 6}, userInfo.id_usuario)
    } catch (error) {
      console.log(error)
    } finally {
      getRechazo()
      setLoading(false)
    }
  }

  const setResueltoPositivo = async () => {
    setLoading(true)
    try {
      const response = await updateReject(idRechazo.id, {id_estado: 5}, userInfo.id_usuario)
    } catch (error) {
      console.log(error)
    } finally {
      getRechazo()
      setLoading(false)
    }
  }

  const sendOneMail = async () => {
    setLoading(true)
    try {
      const res = await sendMailManually([rechazo], userInfo.id_usuario)
    } catch (error) {
      console.log(error)
    } finally {
      setSuccess(true)
      getRechazo()
      getHistorial()
      setLoading(false)
    }
  }

  const handleSuccess = () => setSuccess(false)

  const getHistorial = async () => {
    setLoading(true)
    try {
      // obtener rechazos
      const data = await getRechazoHistorial(idRechazo.id)
      const operadores = await axiosRequest.get('operadores')
      const historial = data.map(item => {
        const operador = operadores.data.find(operador => operador.id_usuario === item.id_usuario)
        return { ...item, nombre_usuario: `${operador.nombre_operador} ${operador.apellido_operador}`}
      })
      setHistorial(historial);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(async () => {
    const companias = await getCompanias();
    setCompanias(companias)
    getRechazo()
    getHistorial()
    getMedioPago()
  }, [])

  const getMedioPago = async () => {
    try {
      const medio = await getMediosPago()
      setMediosFormated(medio)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    if(rechazo !== null ){
      const medio = getMedioPago()
      const rechazoFormated = format(rechazo)
      const medioFiltered = mediosFormated.find(m => parseInt(m.value) === rechazo.id_medio_pago)
      rechazoFormated.medio_pago =  medioFiltered?.label || 'efectivo';
      setRechazoFormated(rechazoFormated)
    }
  },[rechazo])

  return {  
    rechazo: rechazoFormated,
    historial,
    loading,
    setResueltoNegativo,
    setResueltoPositivo,
    sendOneMail,
    getHistorial,
    getRechazo,
    success,
    handleSuccess
  }

}
