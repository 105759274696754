import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Container } from "./Premios/styled";
// Components
import Wrapper from "../../components/Wrapper";
import Inner from "../../components/Inner";
import TitlePageSection from "../../components/TitlePageSection";
import Sidebar from "../../components/Sidebar";
import NewButton from "../../components/NewButton";
import NewTabs from "../../components/NewTabs";
import ModalCargarObjetivosPremios from "../../components/Modals/Premios/ModalCargarObjetivos";
import PremiosVendedoresTab from "../../components/Tabs/Premios/Vendedores";
import PremiosGruposTab from "../../components/Tabs/Premios/Grupos";
import PremiosEstadisticasTab from "../../components/Tabs/Premios/Estadisticas";
import PremiosHistorialTab from "../../components/Tabs/Premios/Historial";
import PremiosObjetivosTab from "../../components/Tabs/Premios/Objetivos";
// Custom Hooks
import { useTabs } from "../../hooks/useTabs";
import { useObjetivos } from "./Premios/hooks/useObjetivos";
import { usePremios } from "./Premios/hooks/usePremios";
import useModal from "../../hooks/useModal";
// Constants
import { PREMIOS_TABS } from "../../constants/tabs";
import Layout from "../../components/Layout";
import NewWrapper from "../../components/NewWrapper";
import NewInner from "../../components/NewInner";
import NewTitlePageSection from "../../components/NewTitlePageSection";


const NewPremios = () => {
  const [mes, setMes] = useState(new Date().getMonth());

  const {
    objetivos,
    loading: loadingObjetivos,
    refreshObjetivos,
  } = useObjetivos(mes);
  const { vendedoresData, grupos, loading, setLoading } = usePremios(mes);
  const { vendedores, totalPremios, setVendedores } = vendedoresData;
  const { handleTab, tabSelected } = useTabs();
  const objetivosModal = useModal();

  const TABS_TO_RENDER = [
    <PremiosVendedoresTab
      data={{
        mes,
        setMes,
        loading,
        totalPremios,
        vendedores,
        grupos,
        setVendedores,
        setLoading,
      }}
    />,
    <PremiosGruposTab data={{ mes, vendedores, grupos }} />,
    <PremiosEstadisticasTab data={{ mes, setMes }} />,
    <PremiosObjetivosTab data={{ mes, setMes, objetivos, loadingObjetivos: loadingObjetivos }} />,
    <PremiosHistorialTab data={{ vendedores }} />
  ];

  return (
    <div>
      {objetivosModal.isModalOpen ? (
        <ModalCargarObjetivosPremios
          modal={objetivosModal}
          refreshData={refreshObjetivos}
        />
      ) : null}
      <Layout>
      <NewWrapper>
        <NewInner>
          <Container>
            <NewTitlePageSection
              title="Premios"
              description="Lista de todos los vendedores junto a sus premios"
              tabs={
                <NewTabs
                  tabs={PREMIOS_TABS}
                  tabSelected={tabSelected}
                  handleTab={handleTab}
                />}
            >
              <NewButton
                backgroundColor={true}
                onClick={() => objetivosModal.setIsModalOpen(true)}
              >
                Cargar objetivos
              </NewButton>
              <Link to="/CrearGrupo">
                <NewButton
                  backgroundColor={false}
                  textColor={true}
                  borderColor={true}
                >
                  Crear grupo
                </NewButton>
              </Link>
            </NewTitlePageSection>
            {TABS_TO_RENDER[tabSelected]}
          </Container>
        </NewInner>
      </NewWrapper>
      </Layout>
    </div>
  );
};

export default NewPremios;
