import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosRequest from "../utils/axiosConfig";
import { useLoading } from "./useLoading";

export const useEmision = () => {

  const { loading, setLoading } = useLoading()
  let solicitudId = useParams();
  const [solicitud, setSolicitud] = useState({
    asegurado: {},
    seguro: {},
    bienAsegurado: {},
    bienAseguradoInfo: [],
    pago: {},
    solicitud: {},
    seguroInfo: {}
  })

  const medios = {
    1: "Tarjeta de Crédito",
    2: "CBU",
    3: "CVU",
    4: "Efectivo",
    5: "AFIP",
    6: "VISA Debito"
  }

  const getSolicitud = async () => {
    try {
      setLoading(true)
      // ID de la solicitud que llego para poder conseguir la información de los demás campos  
      const solicitud = await axiosRequest.get(`/solicitudes/${solicitudId.id}`);
      if(solicitud.data.solicitud.id_tipo_solicitud !== 3) solicitud.data.pago.medio = medios[solicitud.data.pago.medio]
      console.log(solicitud.data)
      setSolicitud(solicitud.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

    // Trae la información de la Emisión
  useEffect(() => {
    getSolicitud()
  }, [solicitudId]);

  return {
    solicitudEmision: solicitud,
    loading,
    getSolicitud
  };
};
