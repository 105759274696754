import { Grid, Col } from "../../Grid";
import InputBasic from "../../InputBasic";
import { useForm } from "../../../hooks/useForm";
import { useState, useEffect } from "react";
import useButtonMessage from "../../../hooks/useButtonMessage";
import { limitesReglas } from "../../../utils/formRules";
import ErrorInput from "../../ErrorInput";
import Button from "../../Button";
import SpinnerLoading from "../../SpinnerLoading";
import axiosRequest from "../../../utils/axiosConfig";
import { Container } from "./styled";
import { formatNumber } from "../../../utils/formatter";

const LimitesForm = ({ limites, productoSeleccionado, productos }) => {
    const [initialValues, setInitialValues] = useState({});
    const { inputValues, handleChange, handleValidation, formErrors } = useForm(
      handleSubmit,
      limitesReglas,
      initialValues
    );
    const { buttonText, handleButtonText } =
      useButtonMessage("Actualizar limites");

    const producto = productos.find(producto => productoSeleccionado === producto.numero)?.descripcion
    useEffect(() => {
      const initialValues = limites.reduce((total, item) => {
        const key = `${item.nombre.toLowerCase().replace(" ", "_")}_${item.id_landing_limite_producto_compania}`
        return {
          ...total,
          [key]:
            item.limite,
        };
      }, {});
      setInitialValues(initialValues);
    }, [limites]);
  
    async function handleSubmit() {
      handleButtonText("Actualizando...");
      try {
        const actualizar = await axiosRequest.put("/limites_producto_compania", inputValues);
        if (actualizar) {
            handleButtonText("Limites actualizados correctamente!");
            setTimeout(() => {
              handleButtonText("Actualizar límites");
            }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }

  return (
    <Container>
      <div>
        <Grid colGap={21} rowGap={40} narrow={true}>
          {limites.length > 0 ? (
            <>
              {limites.map((limite) => {
                const key = `${limite.nombre
                    .toLowerCase()
                    .replace(" ", "_")}_${limite.id_landing_limite_producto_compania}`
                return (
                  <Col desktop={3} key={limite.id}>
                    <div className="limite-container">
                    <div className="logo-container">
                      <img src={limite.logo} />
                    </div>
                    <hr className="divider"/>
                    <span><strong>Empresa:</strong> {limite.nombre}</span>
                    <span><strong>Producto:</strong> {producto}</span>
                    <span><strong>Límite actual:</strong> ${formatNumber.format(limite.limite)}</span>
                    <hr className="divider"/>
                    <InputBasic
                      labelName={limite.nombre}
                      placeholder={`Limite de ${limite.nombre}`}
                      name={key}
                      value={
                        inputValues[key]
                      }
                      onChange={handleChange}
                    />
                    {formErrors[
                      inputValues[key]
                    ] && (
                      <ErrorInput>
                        {
                          formErrors[
                            inputValues[key]
                          ]
                        }
                      </ErrorInput>
                    )}
                    </div>
                  </Col>
                );
              })}
              <Col desktop={12}>
                <Button backgroundColor={true} onClick={handleValidation}>
                  {buttonText}
                </Button>
              </Col>
            </>
          ) : (
            <Col desktop={12}>
              <SpinnerLoading text="Cargando limites" />
            </Col>
          )}
        </Grid>
      </div>
    </Container>
  );
};

export default LimitesForm;
