import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  InputSelect,
  ProgressBar,
  Menu,
  Wrapper,
  NewProgressBar,
  NavMenu,
  BurgerMenu,
  Modal,
  InputCalendar,
  PopUp,
  ExternosViejos,
  ExternosNuevos,
  Internos,
} from "../components/Index";
import axiosRequest from "../utils/axiosConfig";
import {
  CalendarContainer,
  GridCalendarContainer,
  GridFirstDay,
  GridCalendarContainerHeader,
  BarColor,
  ModalEditarOperador,
  TabsContainer,
  Tab,
  GridCumplimientoContainerHeader,
  GridCumplimientoContainer,
  Error,
  ModalBorrarExcepcion,
} from "./styled";
import { anios, meses } from "../constants/data";
import url from "../utils/url";
import { ModalDescargarEstadisticas } from "./styled";

const PremiosVendedores = () => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [open, setOpen] = useState(false);

  const [selectedTab, setSelectedTab] = useState("externos_v");

  const [selectToShow, setSelectToShow] = useState({
    vendedores: [],
    anios,
    meses,
  });

  const filtroInicial = {
    anio: "",
    mes: "",
    vendedor: "",
  };

  const [filtro, setFiltro] = useState(filtroInicial);
  const [vendedores, setVendedores] = useState([]);
  const [datosTabla, setDatosTabla] = useState([]);

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [btnLimpiar, setBtnLimpiar] = useState(false);
  const [error, setError] = useState({});

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [format, setFormat] = useState("");

  const getVendedoresExtViejos = async () => {
    try {
      const vendedores = await axiosRequest.get(
        `/premios_vendedores/vendedoresExtViejos`
      );
      setVendedores(vendedores.data);
    } catch (error) {
      setError({ type: "error", msg: error });
    }
  };

  const getVendedoresExtNuevos = async () => {
    try {
      const vendedores = await axiosRequest.get(
        `/premios_vendedores/vendedoresExtNuevos`
      );
      setVendedores(vendedores.data);
    } catch (error) {
      setError({ type: "error", msg: error });
    }
  };

  const getVendedoresInternos = async () => {
    try {
      const vendedores = await axiosRequest.get(
        `/premios_vendedores/vendedoresInternos`
      );
      setVendedores(vendedores.data);
    } catch (error) {
      setError({ type: "error", msg: error });
    }
  };

  const buscarInforme = async () => {
    try {
      setBtnDisabled(true);

      if (selectedTab === "externos_v") {
        const send = await axiosRequest.get(
          `/premios_vendedores/premiosExtViejos?anio=${filtro.anio}&mes=${
            parseInt(filtro.mes) + 1
          }&vendedor=${filtro.vendedor}`
        );
        console.log(send.data)
        setDatosTabla(send.data);
      } else if (selectedTab === "externos_n") {
        const send = await axiosRequest.get(
          `/premios_vendedores/premiosExtNuevos?anio=${filtro.anio}&mes=${
            parseInt(filtro.mes) + 1
          }&vendedor=${filtro.vendedor}`
        );
        setDatosTabla(send.data);
      } else if (selectedTab === "internos") {
        const send = await axiosRequest.get(
          `/premios_vendedores/premiosInternos?anio=${filtro.anio}&mes=${
            parseInt(filtro.mes) + 1
          }&vendedor=${filtro.vendedor}`
        );
        setDatosTabla(send.data);
      } else if (datosTabla.length === 0) {
        setError({ type: "warning", msg: "No se encontraron coincidencias" });
      }
    } catch (error) {
      setError({ type: "error", msg: error });
    } finally {
      setBtnDisabled(false);
    }
  };
  
  const getPremiosPorVendedor = async () => {
    try {
      if (filtro.anio !== "" && filtro.mes !== "" && filtro.vendedor !== "") {
        setBtnDisabled(false);
        setBtnLimpiar(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (filtro.anio !== "" && filtro.mes !== "" && filtro.vendedor !== "") {
      getPremiosPorVendedor();
    }
  }, [filtro.anio, filtro.mes, filtro.vendedor]);

  useEffect(() => {
    //formatea los vendedores para el select
    if (vendedores.length !== 0) {
      const selectVendedores = vendedores.map((v) => {
        return {
          label: v.nombre,
          value: v.numero,
          key: v.numero,
        };
      });
      selectVendedores.push({
        label: "Todos",
        value: "todos",
        key: "todos",
      });

      setSelectToShow({
        ...selectToShow,
        vendedores: selectVendedores,
      });
    }
  }, [vendedores]);

  useEffect(() => {
    if (selectedTab === "externos_v") {
      getVendedoresExtViejos();
    }
    if (selectedTab === "externos_n") {
      getVendedoresExtNuevos();
    }
    if (selectedTab === "internos") {
      getVendedoresInternos();
    }
  }, [selectedTab]);

  let headers;

  if (selectedTab === "externos_v") {
    headers = [
      { header: "VENDEDOR", key: "vendedor", width: 20 },
      { header: "AUTO", key: "autoValidador", width: 15 },
      { header: "BICI", key: "biciValidador", width: 15 },
      { header: "PRIMA", key: "primaValidador", width: 15 },
      { header: "QUINCENA", key: "quincena", width: 15 },
      { header: "BICI", key: "bici", width: 15 },
      { header: "AUTO", key: "auto", width: 15 },
      { header: "MOTO", key: "moto", width: 15 },
      { header: "HOGAR", key: "hogar", width: 15 },
      { header: "PRIMA", key: "prima", width: 15 },
      { header: "REF Y CROSS", key: "refYcross", width: 15 },
      { header: "OPERACIONES", key: "operaciones", width: 15 },
      { header: "GRUPAL", key: "grupal", width: 15 },
      { header: "TOTAL", key: "total", width: 15 },
    ];
  } else if (selectedTab === "externos_n") {
    headers = [
      { header: "VENDEDOR", key: "vendedor", width: 20 },
      { header: "QUINCENA", key: "quincena", width: 15 },
      { header: "BICI", key: "bici", width: 15 },
      { header: "AUTO", key: "auto", width: 15 },
      { header: "MOTO", key: "moto", width: 15 },
      { header: "HOGAR", key: "hogar", width: 15 },
      { header: "PRIMA", key: "prima", width: 15 },
      { header: "REF Y CROSS", key: "refYcross", width: 15 },
      { header: "OPERACIONES", key: "operaciones", width: 15 },
      { header: "TOTAL", key: "total", width: 15 },
    ];
  } else if (selectedTab === "internos") {
    headers = [
      { header: "VENDEDOR", key: "vendedor", width: 20 },
      { header: "QUINCENA", key: "quincena", width: 15 },
      { header: "BICI", key: "bici", width: 15 },
      { header: "AUTO", key: "auto", width: 15 },
      { header: "HOGAR", key: "hogar", width: 15 },
      { header: "OPERACIONES", key: "operaciones", width: 15 },
      { header: "OPS. PRIMA ALTA", key: "opsPrimaAlta", width: 15 },
      { header: "GRUPAL", key: "grupal", width: 15 },
      { header: "TOTAL", key: "total", width: 15 },
    ];
  }

  const downloadCSV = async () => {
    try {
      console.log(vendedores)
      console.log(filtro)

      const csvData = {
        selectedTab: selectedTab,
        headers,
        data: datosTabla,
        formato: format,
        vendedor: vendedores.find((v) => v.numero == filtro.vendedor),
        anio: filtro.anio,
        mes: parseInt(filtro.mes),
      };

      const csv = await axiosRequest.post(
        "/premios_vendedores/descargar_archivo",
        csvData
      );
      if (csv.status === 200) {
        window.location.href = `${url}${csv.data.path}`;
        setTimeout(async () => {
          await axiosRequest.delete("/delete_file", {
            data: { path: "./app/public/" + csv.data.path },
          });
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const limpiarForm = () => {
    setFiltro(filtroInicial);
    setDatosTabla([]);
    setBtnLimpiar(false);
    setBtnDisabled(true);
  };
  const precioFormato = function (number) {
    return new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const changeTable = (tab) => {
    setDatosTabla([]);
    setFiltro(filtroInicial);
    setSelectedTab(tab);
  };

  return (
    <div className="App">
      <BurgerMenu open={open} setOpen={setOpen} />
      <NavMenu
        open={open}
        setOpen={setOpen}
        user={user}
        active={"premios-vendedores"}
      />
      {isDownloadModalOpen ? (
        <Modal
          isModalOpen={isDownloadModalOpen}
          setIsModalOpen={setIsDownloadModalOpen}
        >
          <ModalDescargarEstadisticas>
            <h2>Elegir formato para descargar la información</h2>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={6}>
                <div
                  className={
                    format === "excel"
                      ? "estadisticas-formato selected-formato"
                      : "estadisticas-formato"
                  }
                  onClick={() => setFormat("excel")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      fill={format === "excel" ? "#3289b9" : "#bab8b8"}
                      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z"
                    />
                  </svg>
                  <span>XLSX (Excel)</span>
                </div>
              </Col>
              <Col desktop={6}>
                <div
                  className={
                    format === "csv"
                      ? "estadisticas-formato selected-formato"
                      : "estadisticas-formato"
                  }
                  onClick={() => setFormat("csv")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      fill={format === "csv" ? "#3289b9" : "#bab8b8"}
                      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 224H96c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8H96c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40V264c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16H198.4c-7.9 0-14.4 6.4-14.4 14.4c0 5.2 2.8 9.9 7.2 12.5l25.4 14.5c14.4 8.3 23.4 23.6 23.4 40.3c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4c0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5C160.9 302.4 152 287 152 270.4zM280 240v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                    />
                  </svg>
                  <span>CSV</span>
                </div>
              </Col>
            </Grid>
            <div className={"buttonsCont"}>
              <Button
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
                onClick={() => setIsDownloadModalOpen(false)}
              >
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={downloadCSV}
              >
                {format
                  ? `Descargar como ${
                      format === "excel" ? ".xlsx (Excel)" : ".csv"
                    }`
                  : "Descargar"}
              </Button>
            </div>
          </ModalDescargarEstadisticas>
        </Modal>
      ) : null}
      <Wrapper>
        <Inner>
          <Grid colGap={21} rowGap={21}>
            <Col desktop={12} spaced={true}>
              <Titulo textColor={false}>Premios Vendedores</Titulo>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={() => setIsDownloadModalOpen(true)}
              >
                Descargar Datos
              </Button>
            </Col>

            <Col desktop={12}>
              <TabsContainer>
                <Tab
                  selected={selectedTab === "externos_v" ? true : false}
                  onClick={() => changeTable("externos_v")}
                >
                  Externos Viejos
                </Tab>
                <Tab
                  selected={selectedTab === "externos_n" ? true : false}
                  onClick={() => changeTable("externos_n")}
                >
                  Externos Nuevos
                </Tab>
                <Tab
                  selected={selectedTab === "internos" ? true : false}
                  onClick={() => changeTable("internos")}
                >
                  Internos
                </Tab>
              </TabsContainer>
            </Col>

            {selectedTab === "externos_v" ? (
              <ExternosViejos
                selectToShow={selectToShow}
                setFiltro={setFiltro}
                filtro={filtro}
                error={error}
                btnLimpiar={btnLimpiar}
                btnDisabled={btnDisabled}
                datosTabla={datosTabla}
                limpiarForm={limpiarForm}
                buscarInforme={buscarInforme}
                precioFormato={precioFormato}
              />
            ) : null}

            {selectedTab === "externos_n" ? (
              <ExternosNuevos
                selectToShow={selectToShow}
                setFiltro={setFiltro}
                filtro={filtro}
                error={error}
                btnLimpiar={btnLimpiar}
                btnDisabled={btnDisabled}
                datosTabla={datosTabla}
                limpiarForm={limpiarForm}
                buscarInforme={buscarInforme}
                precioFormato={precioFormato}
              />
            ) : null}

            {selectedTab === "internos" ? (
              <Internos
                selectToShow={selectToShow}
                setFiltro={setFiltro}
                filtro={filtro}
                error={error}
                btnLimpiar={btnLimpiar}
                btnDisabled={btnDisabled}
                datosTabla={datosTabla}
                limpiarForm={limpiarForm}
                buscarInforme={buscarInforme}
                precioFormato={precioFormato}
              />
            ) : null}
          </Grid>
        </Inner>
      </Wrapper>
    </div>
  );
};

export default PremiosVendedores;
