import React, { useState, useEffect } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  Grid,
  Col,
  Inner,
  InputBasic,
  ProgressBar,
  Menu,
  Wrapper,
  NewProgressBar,
  InfoBoard,
  InputCheckbox
} from "../components/Index";
import CreditCardNew from "../components/CreditCardNew";
import SpinnerLoading from "../components/SpinnerLoading";
import SkeletonLoading from "../components/SkeletonLoading"
import IconButton from "../components/IconButton";
import ActionsButton from "../components/ActionsButton";
import edit from "../assets/iconos/edit.svg";
import see from "../assets/iconos/see.svg";
import deleteIcon from "../assets/iconos/delete.svg";
import MenuActions from "../assets/iconos/menuActions.svg";

const Estadisticas = () => {

  const [titular, setTitular] = useState('')
  const [numero, setNumero] = useState('')
  const [vencimiento, setVencimiento] = useState('')

  const [loading, setLoading] = useState(true)

  const headers = ["#Id", "Operacion", "F.Ingreso", "Vendedor", "Cliente", "Estado", "Producto", "Compañia", "Cobertura", "Acciones"]
  const data = [
    {
      id: 1,
      operacion: 2,
      fecha_ingreso: 3,
      vendedor: "Emma",
      cliente: "Noel Gallagher",
      estado: "Pendiente",
      producto: "Celular",
      compania: "Samsung",
      cobertura: "A",
      accion: <div><ActionsButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /></div>
    },
    {
      id: 1,
      operacion: 2,
      fecha_ingreso: 3,
      vendedor: "Emma",
      cliente: "Noel Gallagher",
      estado: "Pendiente",
      producto: "Celular",
      compania: "Samsung",
      cobertura: "A",
      accion: <div><ActionsButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /></div>
    },
    {
      id: 1,
      operacion: 2,
      fecha_ingreso: 3,
      vendedor: "Emma",
      cliente: "Noel Gallagher",
      estado: "Pendiente",
      producto: "Celular",
      compania: "Samsung",
      cobertura: "A",
      accion: <div><ActionsButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /></div>
    }
  ]

  const headersCumplimiento = ["Mes", "Objetivos Horas", "Objetivos Personales", "Horas Cumplidas", "Diferencia", "Porcentaje"]
  const dataCumplimiento = [
    
  ]

  // RECHAZADAS DATA
  const rechazadasHeaders = ["#Id", "F. Ingreso", "F. Rechazo", "Cliente", "Producto", "Motivo", "Acciones"]
  const dataRechazadas = [
    {
      id: 1,
      fecha_ingreso: "14/04/2022",
      fecha_rechazo: "16/04/2022",
      cliente: "Charlotte de Witte",
      producto: "Celular",
      motivo: "Falta documentación",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /></div>
    },
    {
      id: 2,
      fecha_ingreso: "14/04/2022",
      fecha_rechazo: "16/04/2022",
      cliente: "Ben Bohmer",
      producto: "Celular",
      motivo: "Falta documentación",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /></div>
    },
    {
      id: 3,
      fecha_ingreso: "14/04/2022",
      fecha_rechazo: "16/04/2022",
      cliente: "Amelie Lens",
      producto: "Celular",
      motivo: "Falta documentación",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /></div>
    }
  ]

  // HISTORIAL SOLICITUDES DATA
  const historialHeaders = ["#Id", "Operación", "F. Ingreso", "Cliente", "Estado", "Producto", "Compañia", "Cobertura", "Acciones"]
  const dataHistorial = [
    {
      id: 1,
      operacion: 2,
      fecha_ingreso: 3,
      cliente: "Noel Gallagher",
      estado: "Pendiente",
      producto: "Celular",
      compania: "Samsung",
      cobertura: "A",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /></div>
    },
    {
      id: 2,
      operacion: 2,
      fecha_ingreso: 3,
      cliente: "Boris Brejcha",
      estado: "Pendiente",
      producto: "Celular",
      compania: "Samsung",
      cobertura: "A",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /></div>
    },
    {
      id: 3,
      operacion: 2,
      fecha_ingreso: 3,
      cliente: "Yotto",
      estado: "Pendiente",
      producto: "Celular",
      compania: "Samsung",
      cobertura: "A",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /></div>
    }
  ]

  // EXCEPCIONES DATA
  const excepcionesHeaders = ["Id", "Tipo", "Periodo", "Dias", "Fecha", "F. Desde", "F. Hasta", "Hora", "H. Desde", "H. Hasta", "Acciones"]
  const dataExcepciones = [
    {
      id: 51,
      tipo: "Acuerdo",
      periodo: "Fecha",
      dias: "",
      fecha: "05/04/2022",
      fecha_desde: "",
      fecha_hasta: "",
      hora: "Hora",
      hora_desde: "",
      hora_hasta: "",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={deleteIcon} /></div>
    },
    {
      id: 52,
      tipo: "Acuerdo",
      periodo: "Fecha",
      dias: "",
      fecha: "05/04/2022",
      fecha_desde: "",
      fecha_hasta: "",
      hora: "Hora",
      hora_desde: "",
      hora_hasta: "",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={deleteIcon} /></div>
    },
    {
      id: 53,
      tipo: "Acuerdo",
      periodo: "Fecha",
      dias: "",
      fecha: "05/04/2022",
      fecha_desde: "",
      fecha_hasta: "",
      hora: "Hora",
      hora_desde: "",
      hora_hasta: "",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} /><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={deleteIcon} /></div>
    },
  ]

  const siniestrosHeaders = ["Id", "Num. Siniestro", "Fecha", "Tipo", "Poliza", "Asegurado", "Compañia", "Estado", "Prioridad", "Acciones"]
  const dataSiniestros = [
    {
      id: 51,
      num_siniestro: "RA32",
      fecha: "10/10/2022",
      tipo: "Robo Total",
      poliza: "1991693",
      asegurado: "Ilan Bluestone",
      compania: "La Second",
      estado: "Cerrado Positivo",
      prioridad: "Baja",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /></div>
    },
    {
      id: 68,
      num_siniestro: "RA32",
      fecha: "10/10/2022",
      tipo: "Robo Total",
      poliza: "1991693",
      asegurado: "Tale Of Us",
      compania: "La Second",
      estado: "Cerrado Positivo",
      prioridad: "Baja",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /></div>
    },
    {
      id: 90,
      num_siniestro: "RA32",
      fecha: "10/10/2022",
      tipo: "Robo Total",
      poliza: "1991693",
      asegurado: "Marsh",
      compania: "La Second",
      estado: "Cerrado Positivo",
      prioridad: "Baja",
      accion: <div><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} /></div>
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  return (
    <div className="App">
      <InputCheckbox labelName="Marsh" name={"marsh"} />
      <CreditCardNew titular={titular} vencimiento={vencimiento} numero={numero}></CreditCardNew>

        <InputBasic
          type={"number"}
          name={"numero"}
          labelName={"Número de tarjeta"}
          placeholder={"0000 0000 0000 0000"}
          onChange={(e)=>setNumero(e.target.value)}
          value={numero}
          maxLength={16}
        />

        <InputBasic
          type={"text"}
          name={"vencimiento"}
          labelName={"Vencimiento"}
          placeholder={"06/23"}
          onChange={(e)=>setVencimiento(e.target.value)}
          value={vencimiento}
          maxLength={"5"}
        />

        <InputBasic
          type={"text"}
          name={"titular"}
          labelName={"Titular"}
          placeholder={"Nombre del titular"}
          onChange={(e)=>setTitular(e.target.value)}
          value={titular}
        />
    
        {/* Solicitud de Emisión */}
        <InfoBoard borderTop={true} headers={headers} data={data} loading={loading} />
        {/* Emisión */}
        <InfoBoard borderTop={true} headers={headers} data={data} loading={loading} />
        {/* Rechazadas */}
        <InfoBoard borderTop={true} headers={rechazadasHeaders} data={dataRechazadas} loading={loading} />
        {/* Historial de Solicitudes */}
        <InfoBoard borderTop={true} headers={historialHeaders} data={dataHistorial} loading={loading} />
        {/* Fichajes --> Cumplimiento Anual */}
        <InfoBoard borderTop={true} headers={headersCumplimiento} data={dataCumplimiento} loading={loading} />
        {/* Fichajes --> Excepciones */}
        <InfoBoard borderTop={true} headers={excepcionesHeaders} data={dataExcepciones} loading={loading} />
        {/* Siniestros */}
        <InfoBoard borderTop={true} headers={siniestrosHeaders} data={dataSiniestros} loading={loading} />

        <SpinnerLoading />
        <SkeletonLoading rows={10} />
        <IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} />
        <IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} />
        <IconButton backgroundColor={false} borderColor={false} textColor={true} icon={MenuActions} />


      {/*
    <Menu active={'estadisticas'} />
    <Wrapper>
        <Inner>
            <Titulo textColor={false}>Estadísticas</Titulo>
            <NewProgressBar />
        </Inner>
    </Wrapper>
      */}
    </div>
  );
};

export default Estadisticas;
