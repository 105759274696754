import { useState } from "react"
import BurgerMenu from "../BurgerMenu"
import NavMenu from "../NavMenu"

const Sidebar = ({ active }) => {

    const [open, setOpen] = useState(false)
    const user = JSON.parse(localStorage.getItem("userInfo"));

    return (
        <>
        <BurgerMenu open={open} setOpen={setOpen} />
        <NavMenu
            open={open}
            setOpen={setOpen}
            user={user}
            active={active}
        />
        </>
    )
}

export default Sidebar