import Modal from "../../../Modal"
import { ModalEditarOperador } from "../../../../pages/styled"
import Titulo from "../../../Titulo"
import { Grid, Col } from "../../../Grid"
import Button from "../../../Button"
import InputBasic from "../../../InputBasic"
import { ContainerBotones, Usuario, Icon } from "./styled"
import ErrorInput from "../../../ErrorInput"
import { InputFileRec2 } from "../../../Index"
import FileLoadedNew from "../../../FileLoadedNew"
import SpinnerLoading from "../../../SpinnerLoading"
import InputSelect from "../../../InputSelect"
// Icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { VscLock } from 'react-icons/vsc'
import { FaUserCircle } from 'react-icons/fa'
import { BsCreditCard2Back, BsCart3, BsPersonCircle } from 'react-icons/bs'
import docImage from '../../../../assets/images/doc_cuadrado.png'
// React
import { useState } from "react"
import Subtitulo from "../../../Subtitulo"

const ModalEditarObjetivoLeads = ({ isModalOpen, setIsModalOpen, data, form }) => {

    const { inputValues, handleChange, formErrors, handleValidation } = form
    const { canales, productos, meses, anios } = data
    
    return (
      <div>
        <Modal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        >
          <ModalEditarOperador>
            <Titulo margin={true}>
              Editar Objetivo
            </Titulo>
            <Grid colGap={21} rowGap={21}>
                <Col desktop={6}>
                    <InputSelect
                      name={"canal"}
                      labelName={"Canal"}
                      options={canales}
                      onChange={handleChange}
                      value={inputValues.canal}
                    />
                    {formErrors.canal && <span className="error">{formErrors.canal}</span>}
                </Col>
                <Col desktop={6}>
                    <InputSelect
                      name={"producto"}
                      labelName={"Producto"}
                      options={productos}
                      onChange={handleChange}
                      value={inputValues.producto}
                    />
                    {formErrors.producto && <span className="error">{formErrors.producto}</span>}
                </Col>
                <Col desktop={6}>
                    <InputSelect
                      name={"mes"}
                      labelName={"Mes"}
                      options={meses}
                      onChange={handleChange}
                      value={inputValues.mes}
                    />
                    {formErrors.mes && <span className="error">{formErrors.mes}</span>}
                </Col>
                <Col desktop={6}>
                    <InputSelect
                      name={"anio"}
                      labelName={"Año"}
                      options={anios}
                      onChange={handleChange}
                      value={inputValues.anio}
                    />
                    {formErrors.anio && <span className="error">{formErrors.anio}</span>}
                </Col>
                <Col desktop={6}>
                    <InputBasic
                      type={"number"}
                      name={"leads"}
                      labelName={"Leads"}
                      placeholder={"Ej: 150"}
                      onChange={handleChange}
                      value={inputValues.leads}
                    />
                    {formErrors.leads && <span className="error">{formErrors.leads}</span>}
                </Col>
                <Col desktop={6}>
                    <InputBasic
                      type={"number"}
                      name={"operaciones"}
                      labelName={"Operaciones"}
                      placeholder={"Ej: 150"}
                      onChange={handleChange}
                      value={inputValues.operaciones}
                    />
                    {formErrors.operaciones && <span className="error">{formErrors.operaciones}</span>}
                </Col>
                <Col desktop={12}>
                      <div className="buttonsContSiniestro">
                        <Button
                          backgroundColor={false}
                          borderColor={true}
                          textColor={true}
                          icon={false}
                          onClick={() => setIsModalOpen(false)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          backgroundColor={true}
                          borderColor={false}
                          textColor={false}
                          icon={false}
                          onClick={handleValidation}
                        >
                            Guardar cambios
                        </Button>
                      </div>
                  </Col>
            </Grid>
          </ModalEditarOperador>
        </Modal>
      </div>
    );
}

export default ModalEditarObjetivoLeads
