import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Info = styled.div`
   
  ${props => props.borderTop && css`
    & {
      border-top: 4px solid ${leerColor(colores.negro)};
      padding-top: calc(40px - 1em);
      margin-top: calc(40px + 1em);
    } 
  `}

  ${props => props.invisible === true && css`
     .cia, .cobertura{
      display: none;
    } 
    .header{
      grid-template-columns: 55px repeat(7, 1fr) 120px!important;
      /*grid-template-columns: 70px repeat(4, 125px) 1fr!important;*/
    }
    .headerConsultas {
      grid-template-areas: "id fIngreso cliente sector estado null null null accion"!important;
    }
    .headerRechazadas {
      grid-template-columns: 55px repeat(7, 1fr) 120px!important;
      grid-template-areas: "id operacion fIngreso fRechazo cliente producto motivo null accion"!important;
    }

    .container{
      grid-template-columns: 55px repeat(7, 1fr) 120px!important;
      grid-template-areas: "id operacion fIngreso vendedor cliente motivo producto fRechazo accion";
    }

    .containerConsultas{
      grid-template-columns: 55px repeat(7, 1fr) 120px!important;
      grid-template-areas: "id fIngreso cliente sector estado null null null accion"!important;
    }

    .containerRechazadas{
      grid-template-columns: 55px repeat(7, 1fr) 120px!important;
      grid-template-areas: "id operacion fIngreso fRechazo cliente producto motivo null accion"!important;
    }
  `}

  ${props => props.invisible === false && css`
    .motivo, .fecharechazo{
      display: none;
    } 
  `}

  .container {
    display: grid;
    grid-template-columns: 70px repeat(8, 125px) 70px;
    /* grid-auto-columns: 1fr; */
    grid-template-areas: "id operacion fIngreso vendedor cliente estado producto cia cobertura accion";
    width: 100%;
    padding: 20px 0;
    text-align: center;
    align-items: center;
    font-size: 16px;
    position: relative;
  }
  .dropDown{
    width: 70px;
    border-radius: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 95%;
    right: ${props => (props.isOperatorVisible ? '-1' : '-1.7%')};;
    z-index: 99;
    padding: 20px;
    background-color: ${leerColor(colores.blanco)};
    -webkit-box-shadow: 12px 12px 30px 0px rgba(177,178,180,0.2);    
    box-shadow: 12px 12px 30px 0px rgba(177,178,180,0.2);     
    color: ${leerColor(colores.grisModales)};
    //background-color: ${leerColor(colores.azul)};

    &::after{
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: white;
      //background-color: ${leerColor(colores.azul)};
      bottom: -10px;
      left: 50%;
      z-index: 99;
      -webkit-transform: translate(-50%, 0) rotate(45deg);
      -ms-transform: translate(-50%, 0) rotate(45deg);
      transform: translate(-50%, 0) rotate(45deg);
      animation: example 0.2s linear;
    }

    @keyframes example {
        0% {height: 0px;}
        100% {height: 20px;}
    }

    a:hover{
      color: ${leerColor(colores.celeste)};
      font-weight: 700;
      cursor: pointer;
    }
  }
  .dropDown a{
    text-decoration: none; 
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    text-align: left;
  }
  .dropDown a:last-of-type{
    padding-bottom: 0;
  }
  h5{
    color: ${leerColor(colores.celeste)};
     
    font-size: 18px;
    font-weight: 600;
    padding: 15px 40px 20px;
    margin: 0;
  }
  .dropUp{
    display: none;
  }

  .header {
    grid-template-areas: "id operacion fIngreso vendedor cliente estado producto cia cobertura accion";
    display: grid;
    grid-template-columns: 70px repeat(8, 125px) 70px;
    text-align: center;
    padding-bottom: 20px;
    h3 {
      color: ${leerColor(colores.celeste)};
      font-size: 18px;
      font-weight: 600;
      font-family: "DM Sans", sans-serif;
      &:first-of-type {
        // justify-self: left;
      }
      &:last-of-type {
        /*justify-self: right;*/
      }
    }
  }

    
/* Alineación en grid de header */
  h3.id {grid-area: id;}
  h3.operacion {grid-area: operacion;}
  h3.accion {grid-area: accion;}
  h3.fIngreso {grid-area: fIngreso;}
  h3.fRechazado {grid-area: fRechazo;}
  h3.cliente {grid-area: cliente;}
  h3.sector {grid-area: sector;}
  h3.estado {grid-area: estado;}
  h3.producto {grid-area: producto;}
  h3.motivo {grid-area: motivo;}


/* Alineación en grid de contenido en tabla */
  div.id {
    grid-area: id;
    display: flex;
    justify-content: space-evenly;
    //text-align: left;
  }

  div.operacion {
    grid-area: operacion;
  }

  div.fIngreso {
    grid-area: fIngreso;
  }

  div.vendedor {
    grid-area: vendedor;
  }

  div.operador {
    grid-area: operador;
  }

  div.cliente {
    grid-area: cliente;
  }

  div.estado {
    grid-area: estado;
  }

  div.producto {
    grid-area: producto;
  }

  div.cia {
    grid-area: cia;
  }

  div.cobertura {
    grid-area: cobertura;
  }

  div.accion {
    grid-area: accion;
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  // Estados // 
  .emitido{
    color: ${leerColor(colores.verde)};
    font-weight: 700;
  }
  .pendiente{
    color: ${leerColor(colores.naranjaEstados)};
    font-weight: 700;
  }
  .rechazado{
    color: ${leerColor(colores.rojo)};
    font-weight: 700;
  }
  .provisoria{
    color: ${leerColor(colores.celesteEstados)};
    font-weight: 700;
  }
  .pendientesuscripción{
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 700;
  }
  .pendienteaprobación{
    color: ${leerColor(colores.azul)};
    font-weight: 700;
  }
  // Siniestros //
  .siniestroPreNotif{
    background-color: ${leerColor(colores.celestePasos)};
  }
  .menuRechazadas{
    display: flex;
    flex-direction: row;
  }

  // SKELETON // 
  .containerSkeleton{
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    justify-content: space-between;
    text-align: center;
    align-items: center;
  }
  .skeleton{
    margin: 30px 0;
    width: 90px;
    line-height: 1.5;
  }
  .notShow{display:none;}

  .showOperator{
    grid-template-areas: "id operacion fIngreso vendedor operador cliente estado producto cia cobertura accion";
    grid-template-columns: 70px repeat(9, 110px) 70px;
  }
`;
export const ButtonAction = styled.a`
  cursor: pointer; 
   
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 12px;
  border-radius: 5px; 
  background-color: ${props => (props['data-backgroundcolor'] ? leerColor(colores.celeste) : leerColor(colores.blanco))}!important;
  border: 1px solid ${props => (props['data-bordercolor'] ? leerColor(colores.celeste) : 'none')};
  color: ${props => (props['data-textcolor'] ? leerColor(colores.celeste) : leerColor(colores.blanco))}!important;

  ${props => props.icon && css`
    content: url(${props => (props.icon)});
  `}

  :hover{
    opacity:0.7;
  }
`
export const NoHayBusquedas = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  svg{
    padding: 50px 0 20px;
  }
  h3{
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: ${leerColor(colores.grisEstados)};
  }
`
export const ModalHistorialContainer = styled.div`
  padding: 56px 70px;
  //height: 60vh!important; 
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-content: center;
  width:817px;
  .mensajesContainer{
    display: flex;
    flex-direction: column;
    align-content: center;
    overflow-y: scroll;
    max-height: 500px;
    padding-right: 30px;

    /* ScrollBar */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)}; 
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }
  }
  h1{ 
    margin-top:0;
    font-size: 28px;
  }
  h3{
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 22px;
  }

  .loadingContainer{
    display:flex;
    align-items:center;
    justify-content:center;
  }
 
  .editar-solicitud-btn-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 12px 0;

    a {
      text-decoration: none;
    }
  }
  .emitido{
    color: ${leerColor(colores.verde)};
    font-weight: 500;
  }
  .pendiente{
    color: ${leerColor(colores.naranjaEstados)};
    font-weight: 500;
  }
  .rechazado{
    color: ${leerColor(colores.rojo)};
    font-weight: 500;
  }
  .provisoria{
    color: ${leerColor(colores.celesteEstados)};
    font-weight: 500;
  }
  .suscripción{
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 500;
  }
  // Siniestros //
  .siniestroPreNotif{
    background-color: ${leerColor(colores.celestePasos)};
    font-weight: 500;
  }
  .menuRechazadas{
    display: flex;
    flex-direction: row;
  }
  .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  align-self: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${leerColor(colores.celeste)} transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

export const Icon = styled.img`
  width: 16px;
  position: absolute;
  top: 18px;
`;

export const ModalSusurroContainer = styled.div`
  padding: 48px 72px 42px 72px;
  width: 800px;

  .buttonCont {
    display: flex;
    justify-content: flex-end;
    margin:30px 0;
  }
`;

export const ModalEnviarEmail = styled.div`
  
    padding: 50px!important;
    text-align: center!important;

  > div >div {
    text-align: center;
  }
  h3{
    margin: 0;
    margin-top:20px;
    font-family: 'DM Sans',sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: ${leerColor(colores.grisModales)};
  }
  h3:nth-child(3) {
    margin: 0;
  }

  .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  align-self: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${leerColor(colores.celeste)} transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

`;

// NUEVOOOOOOOOOOOOOOOOOOOOO

export const Container = styled.div`
  ${props => props.borderTop && css`
      & {
        //border-top: 4px solid ${leerColor(colores.negro)};
        border-top: 1px solid rgb(240,240,240);
        padding-top: calc(40px - 1em);
        margin-top: calc(40px + 1em);
      } 
  `}
`;

export const TableHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => (props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(6, 1fr)')};
  //grid-template-columns: repeat(5, 1fr);
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: ${leerColor(colores.celeste)};
  text-align: center;
  margin:${props => (props.margin ? `80px 0 20px 0` : '40px 0 20px 0')};
`;

export const TableBody = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => (props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(6, 1fr)')};
  //grid-template-columns: repeat(5, 1fr);
  font-family: "DM Sans", sans-serif;
  color: #525252;
  text-align: center;
  margin: 20px 0;
  align-items: center;

  .editButton{
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;

    .tooltip{
      display: none;
      position:absolute;
      background-color: rgb(30,30,30);
      color: #fff;
      top:-45px;
      padding:8px;
      font-size:14px;
      text-align: center;
      font-weight:500;
      border-radius:5px;
      width: 120px;

      ::after{
        content: '';
        display: block;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
        top:100%;
        left:50%;
        position: absolute;
      }
    }

    :hover{
      .tooltip{
        display:block;
      }
    }

  }

  .actionsContainer{
    display: flex;
    justify-content: space-evenly;

    img{
      width:24px;
      cursor: pointer;
      
      :hover{
        opacity:0.8;
      }
    }

    .delete{
      width: 22px;
    }
  }

  .positiveDifference{
    color: ${leerColor(colores.verde)};
    font-weight: 600;
  }

  .negativeDifference{
    color: ${leerColor(colores.rojo)};
    font-weight: 600;
  }
`;