import Inner from "../../../components/Inner"
import Wrapper from "../../../components/Wrapper"
import Sidebar from "../../../components/Sidebar"
import useEditarSolicitud from "../../../hooks/useEditarSolicitud"
import SpinnerLoading from "../../../components/SpinnerLoading"
import NewTitle from "../../../components/NewTitle"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import NuevaSolicitudForm from "../../../components/SolicitudEmisionForm/NuevaSolicitudForm"
import AnulacionForm from "../../../components/SolicitudEmisionForm/AnulacionForm"
import { Container } from "./styled"
import EndosoSolicitudForm from "../../../components/SolicitudEmisionForm/EndosoSolicitudForm"

const EditarSolicitudEmision = () => {

    const { solicitud, selectsData } = useEditarSolicitud()

    const location = useLocation();
    const tipo = new URLSearchParams(location.search).get("tipoSolicitud");

    return (
        <Container>
            <Sidebar />
            <Wrapper>
                <Inner>
                    <NewTitle textColor={true} >Editar solicitud de Seguro</NewTitle>
                        {Object.values(solicitud).every(value => Object.keys(value).length > 0) ?
                            <>
                                {tipo == 1 && <NuevaSolicitudForm solicitud={solicitud} selectsData={selectsData} />}
                                {tipo == 2 && <AnulacionForm solicitud={solicitud} selectsData={selectsData} />}
                                {tipo == 3 && <EndosoSolicitudForm solicitud={solicitud} selectsData={selectsData} />}
                            </>
                        : 
                            <SpinnerLoading text="Cargando datos de la solicitud..." />
                        }
                </Inner>
            </Wrapper>
        </Container>
    )
}

export default EditarSolicitudEmision