import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const SelectDiv = styled.div`
  width: 100%;
  //max-width: 260px;
  //margin: 0px 20px 23px 0px;
  position: relative;
  min-width: 116px;

  i {
    position: absolute;
    right: 15px;
    top: calc(50% - 7px);
    width: 7px;
    height: 7px;
    display: block;
    background-color: ${leerColor(colores.blanco)};
    border-left: 3px solid ${leerColor(colores.gris)};
    border-bottom: 3px solid ${leerColor(colores.gris)};
    transform: rotate(-45deg);
    z-index: 59;
    border-radius: 10%;
  }

  select {
    width: 100%;
    height: 35px;
    padding: 0px 15px 0px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8em;
    color: ${leerColor(colores.grisEstados)};
    border: 1px solid ${leerColor(colores.gris)};
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px !important;
    background-color: white;
  }

  label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 15px;
    line-height: 0.75;
    font-size: 0.8em;
    font-weight: 400;
    color: ${leerColor(colores.gris)};
    cursor: text;
    z-index: 60;
  }

  // Input and Select On Focus Label
  select:focus + label {
    top: -0.6em;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7em;
    color: ${leerColor(colores.celeste)} !important;
    z-index: 59 !important;
  }

    // Inputs and Select Focus
  .select:focus {
    border: 1px solid ${leerColor(colores.celeste)};
    outline: none;
    z-index: 59 !important;
  }

    // Label Down
    .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px !important;
    font-weight: 600 !important;
    font-size: 0.7em !important;
    color: ${leerColor(colores.gris)} !important;
    z-index: 59 !important;
  }

  // Select default arrow
  select::-ms-expand {
    display: none;
  }

  // Select Option
  select * {
    color: ${leerColor(colores.negro)};
  }
  /*
  width: ${(props) => (props.size ? `100%` : `100%`)};
  display: flex;
  flex-direction: column;
  label{
     
    //color: ${leerColor(colores.negro)};
    color: ${(props) => (props.children[0].props.color === "error" ? `${leerColor(colores.rojo)}` : `${leerColor(colores.negro)}`)};
    font-size: 18px;
    padding-bottom: 10px;
    display: ${(props) => (props.size ? `none` : '')};
  }
  */
`

export const Select = styled.select` 
  /*
   
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 20px;
  //border: 1px solid ${leerColor(colores.gris)};
  border: ${(props) => (props.color === "error" ? `1px solid ${leerColor(colores.rojo)}` : `1px solid ${leerColor(colores.gris)}`)};
  option {
    color: ${leerColor(colores.negro)};
    background: white;
    display: flex;
    white-space: pre;
    min-height: 40px;
    padding: 12px 20px;
  }
  &::-ms-expand {
    display: none; 
  }
  &:focus-visible{
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  }
  @media (min-width: ${breakpoints.mobileGrande}) {

  }
  @media (min-width: ${breakpoints.tablet}) {
    
  }
  @media (min-width: ${breakpoints.laptop}) {

  }
  @media (min-width: ${breakpoints.laptopL}) {
    
  }
  @media (min-width: ${breakpoints.notebook}) {

  }
  */
`;
