import React from "react";
import { Error } from "./styled";

const ErrorInput = ({children}) => {
  return (
    <Error>
        {children}
    </Error>
    );
}

export default ErrorInput;
