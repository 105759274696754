import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const ArchivoCargado = styled.span`
   
  color: ${leerColor(colores.negro)};
  font-weight: 600;
`;

export const NombreArchivoCargado = styled.span`
   
  color: ${leerColor(colores.negro)};
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Error = styled.span`
   
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:8px;
`;

export const ThumbnailContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-around;
  background-color: hsl(0,0%,100%);
    -webkit-box-shadow: 12px 12px 30px 0px rgb(177 178 180 / 20%);
  box-shadow: 12px 12px 30px 0px rgb(177 178 180 / 20%);
  color: hsl(0,0%,32.2%);
  //padding: 20px 0;
  min-width: 450px;
  margin-top: 15px;
  border: 1px solid #f5f5f5;

  .cont{
    display:flex;
    align-items: center;
  }

  .cross{
    display:flex;
    padding: 0 25px;
    cursor:pointer;

    :hover{
      opacity:0.7;
    }
  }

  .file{
    display:flex;
    padding: 0 25px;
  }
`;

export const ThumbnailTextContainer = styled.div`
  display:flex; 
  flex-direction:column;
  align-items: flex-start;
  padding: 20px 25px;
  border-left: 1px solid #f1f1f1;
`;

export const ThumbnailImage = styled.img`
  width:40px;
  cursor:pointer;
  
  :hover{
    opacity: 0.7;
  }
`;

export const PreviewFile = styled.img`
  width:500px;
`;

export const Video = styled.video`  
  width: auto;
  height:50vh;
  margin: 0 auto;
`

export const Icon = styled.img`
  width: 25px;
`;

export const TickWrapper = styled.div`
    border: 2px solid #3D9F54;
    padding: 5px;
    border-radius: 50%;
    text-align: center;
    margin: 0 10px;
`;