import { FiFileText, FiFilm } from 'react-icons/fi';
import docVideo from '../assets/images/docVideo.png'
import docImage from '../assets/images/doc_cuadrado.png'
import DocumentGys from '../components/DocumentGys';
import ImgGys from '../components/ImgGys/Index';
import VideoGys from '../components/VideoGys/Index';
import downloadFile from '../utils/downloadFile';

export default function useFiles (handleChangeExternal, multiple) {

  const onFileUpload = (event) => {
    console.log("hEY")
    event.preventDefault();
    event.persist();
    const { name } = event.target;
    if(multiple){
      const files = [...event.target.files]
      const filesFormat = files.map(file => ({ image: file, name: file.name }))
      handleChangeExternal(name, filesFormat)
    } else {
      handleChangeExternal(name, {
        image: event.target.files[0],
        name: event.target.files[0].name,
      })
    }
  }

  const deleteFile = (fileName) => {
    handleChangeExternal(fileName, "")
  }

  const deleteFileMultiple = (file, files, inputName) => {
    console.log(file)
    console.log(files)
    console.log(inputName)
    if(file.name){
      console.log(file.name)
      const archivos = [...files].filter(archivo => archivo.name !== file.name)
      handleChangeExternal(inputName, archivos)
    } else {
      const archivos = [...files].filter(archivo => archivo.url_siniestro_adjunto !== file.url_siniestro_adjunto)
      handleChangeExternal(inputName, archivos)
    }
  }

  const sendFiles = async (file) => {
    /*
    let bodyFormData = new FormData();
    bodyFormData.append('fotos', file.image, file.name);
    try {
      const upFile = await postearArchivos(bodyFormData)
      const path = upFile.data;
      return path;
    } catch (error) { 
      console.log(error)
    }
    */
  };

  const fileLink = async (file) => {
    let url = null
    if(file?.image){
      url = URL.createObjectURL(file.image)
    } else {
      console.log(file)
      const apiFile = await downloadFile(file)
      console.log(url)
      url = apiFile
    }
    return url
  }

  const thumbnailImage = async (file) => {
    if(file !== null && file !== undefined){
      if(typeof(file) !== 'string'){
        const fileExtension = file.image.type
        if(fileExtension.includes("image")){
            return typeof(file.image) !== 'string' ? URL.createObjectURL(file.image) : file.image
        } else if(fileExtension.includes("video")){
            return <FiFilm />
        } else { 
            return <FiFileText />
        }
      } else {
        const fileExtension = file
        if(file.includes("image")){
          const apiFile = await downloadFile(file)
          return apiFile
        } else if(fileExtension.includes("video")){
          return <FiFilm />
        } else { 
          return <FiFileText />
        }
      }
    }

  }

  return {
    onFileUpload,
    deleteFile,
    deleteFileMultiple,
    sendFiles,
    thumbnailImage,
    fileLink
  }
}