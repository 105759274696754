import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;

  label {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    background: #ffffff;
    border: 1.7px solid #eaeaea;
    border-radius: 8px;
    padding: 0 20px;
    color: #6d6d6d;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;

    ::placeholder {
      opacity: 0;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  // Inputs Type Date Icon
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0.5;
    cursor: pointer;
  }

  // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px !important;
    font-weight: 600 !important;
    font-size: 0.7em !important;
    color: ${leerColor(colores.gris)} !important;
    z-index: 59 !important;
  }

  input:focus {
    border: 1px solid ${leerColor(colores.celeste)};
    outline: none;
    z-index: 59 !important;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
    }
  }

  input:focus + .label-container label {
    top: -0.6em;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7em;
    color: ${leerColor(colores.celeste)} !important;
    z-index: 59 !important;
  }

  /*
  display: flex;
  flex-direction: column;
  label{
     
    color: ${(props) =>
    props.children[0].props.color === "error"
      ? `${leerColor(colores.rojo)}`
      : `${leerColor(colores.negro)}`};
    font-size: 18px;
    padding-bottom: 10px;
  }
  */
`;
export const Input = styled.input`
  font-weight: 400;
  font-size: 16px;
  padding: 12px 20px;
  //border: 1px solid ${leerColor(colores.gris)};
  border: ${(props) =>
    props.color === "error"
      ? `1px solid ${leerColor(colores.rojo)}`
      : `1px solid ${leerColor(colores.gris)}`};
  &::-webkit-calendar-picker-indicator {
    background: url(${(props) => props.calendarPicker}) center/95% no-repeat;
  }
  &:focus-visible {
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  }
  ${(props) =>
    props.cancelReminder &&
    props.readOnly === true &&
    css`
      &::after {
        content: url(${(props) => props.cancelReminder});
        padding-right: 10px;
        display: inline-block;
        height: 20px;
        width: 20px;
      }
      border: none;
    `}
`;
