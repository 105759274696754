import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const CalculoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;

    img {
        width: 100px;
    }
    .plan-cotizacion {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;

        hr {
            margin: 0;
        }
    }

    .plan-boton {
        padding-left: 20px;
    }

    .plan-valor-producto {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        white-space: nowrap;
        color: ${leerColor(colores.celeste)};
        height: 35px;
        background-color: #F2F5FF;
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    .plan-parentesis {
        color: ${leerColor(colores.grisEstados)};
    }

    .plan-calculo {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
    }

    .plan-calculo-ejemplo {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .plan-calculo-ejemplo strong {
        //color: ${leerColor(colores.celeste)};
    }

    .plan-ejemplo {
        width: 100%;
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        box-sizing: border-box;
    }

    .plan-input-container {
        width: 100%;
    }
`;