import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
    .seleccion-operador-container {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .operadores {
      display: flex;
      position: absolute;
      top: -70px;
      gap: 10px;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0px 5px 30px #eaeaea;
      background-color: ${leerColor(colores.blanco)};
      border: 1px solid #eaeaea;
      justify-content: center;
    }

    .operadores::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid ${leerColor(colores.blanco)};
      bottom: -10px;
    }

    .plan-operador {
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 700;
        padding: 10px 10px;
        border-radius: 6px;
        box-sizing: border-box;
        color: ${leerColor(colores.celeste)};
        background-color: ${leerColor(colores.blanco)};
        height: 35px;
        cursor: pointer;
        border: 1px solid ${leerColor(colores.celeste)};
        white-space: nowrap;
    }

    .plan-operador:hover {
      opacity: 0.7;
    }

    .selected {
      background-color: ${leerColor(colores.celeste)};
      color: ${leerColor(colores.blanco)};
    }
`;