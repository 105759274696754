import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const TipoNotificacionContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 0 20px 20px 20px;

    .tipo-notificacion {
        font-size: 12px;
        border-radius: 6px;
        background-color: #f6f6f6;
        box-sizing: border-box;
        padding: 10px 16px;
        color: ${leerColor(colores.grisEstados)};
        cursor: pointer;
        font-weight: 500;
    }

    .selected {
        color: ${leerColor(colores.azulRebranding)};
        background-color: #e9efff;
    }
`