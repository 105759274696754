// Components
import Modal from "../../../Modal";
import { Grid, Col } from "../../../Grid";
import NewInputTextarea from "../../../NewInputTextarea";
// Hooks
import { useForm } from "../../../../hooks/useForm";
// Utils
import { gestionarRechazoReglas, modalRechazoReglas } from "../../../../utils/formRules";
import { gestionarRechazoForm, modalRechazoForm } from "../../../../utils/formInitialValues";
// Services
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";
import Success from "../../../Success";
import Divider from "../../../Divider";
import NewInputSelect from "../../../NewInputSelect";
import InputFile from "../../../InputFile";
import { updateReject } from "../../../../services/Rechazos";
import { useEffect, useState } from "react";

const ModalGestionarRechazo = ({ modal, estados, rechazo, getHistorial, getRechazo }) => {

  const { isModalOpen, handleModal } = modal;
  const user = JSON.parse(localStorage.getItem('userInfo'))
  const [success, setSuccess] = useState(false)
  const [optionsEstados, setOptionsEstados] = useState([])
  const { loading, setLoading } = useLoading()
  const medios_contacto = [
    {
      label: "WhatsApp",
      value: "WhatsApp",
      key: 1,
    },
    {
      label: "Email",
      value: "Email",
      key: 2,
    },
    {
      label: "Teléfono",
      value: "telefono",
      key: 3,
    },
  ];

  const closeModal = () => {
    setSuccess(false)
    handleModal()
  }

  const { inputValues, handleChange, handleChangeExternal, formErrors, handleValidation} = useForm(gestionarRechazo, gestionarRechazoReglas, gestionarRechazoForm)

  async function gestionarRechazo() {
    setLoading(true)
    const newRechazo = { ...rechazo, id_estado: inputValues.estado, comentario: inputValues.mensaje, via_contacto: inputValues.medio_contacto }
    try {
      const update = await updateReject(rechazo.id, newRechazo, user.id_usuario)
      if(update.type === "success"){
        getHistorial()
        getRechazo()
        setLoading(false)
        setSuccess(true)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(()=>{
    if(rechazo?.id_estado===8 || rechazo?.id_estado===11) {
      setOptionsEstados([
        { label: 'notificado', key:1, value:11},
        { label: 'resuelto positivo', key:2, value:5},
        { label: 'negativo derivado', key:3, value:6},
        { label: 'negativo cerrado', key:5, value:13},
        { label: 'promesa de pago', key:4, value:12}
      ])
    }
    if(rechazo?.id_estado===12) {
      setOptionsEstados([
        { label: 'notificado', key:1, value:11},
        { label: 'resuelto positivo', key:2, value:5},
        { label: 'negativo derivado', key:3, value:6},
        { label: 'negativo cerrado', key:4, value:13},
      ])
    }
  },[rechazo])

  return (
    <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Gestionar rechazo</NewTitle>
            <p>Modificá los detalles del rechazo</p>
          </div>
          <Divider />
          {!success ?
          <>
            <div className="data-container">
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={6}>
                  <NewInputSelect
                    type="number"
                    labelName="Estado"
                    name="estado"
                    placeholder="Estado"
                    options={optionsEstados}
                    onChange={handleChange}
                    value={inputValues.estado}
                  />
                  {formErrors.estado && (
                    <ErrorInput>{formErrors.estado}</ErrorInput>
                  )}
                </Col>
                <Col desktop={6}>
                  <NewInputSelect
                    type="number"
                    labelName="Medio de contacto"
                    name="medio_contacto"
                    placeholder="Medio de contacto"
                    options={medios_contacto}
                    onChange={handleChange}
                    value={inputValues.medio_contacto}
                  />
                  {formErrors.medio_contacto && (
                    <ErrorInput>{formErrors.medio_contacto}</ErrorInput>
                  )}
                </Col>
              </Grid>
              <Col desktop={12}>
              <NewInputTextarea
                name={"mensaje"}
                placeholder={"En caso de ser necesario, agregá un comentario"}
                labelName={"Comentario del operador"}
                onChange={handleChange}
                value={inputValues.mensaje}
                height={"120px"}
              ></NewInputTextarea>
              {formErrors.mensaje && (
                <ErrorInput>{formErrors.mensaje}</ErrorInput>
              )}
              </Col>
              {/* <Col desktop={12}>
                <InputFile
                  name="adjunto"
                  text="Adjuntar archivo"
                  labelName="Adjunto (en caso de ser necesario)"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={handleChangeExternal}
                  file={inputValues.adjunto}
                  height={"120px"}
                />
                {formErrors.adjunto && (
                  <ErrorInput>{formErrors.adjunto}</ErrorInput>
                )}
              </Col> */}
            </div>
            <Divider />
            <div className={"actionButtonsCont"}>
              <div className={"buttonsCont"}>
                <NewButton
                  onClick={handleModal}
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                  icon={false}
                >
                  Cancelar
                </NewButton>
                <NewButton
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={handleValidation}
                  disabled={loading}
                >
                  {loading ? "Gestionando..." : "Realizar gestión"}
                </NewButton>
              </div>
            </div>
          </>
          :
          <Success title="¡Gestión realizada!" description="El rechazo fue gestionado correctamente" handleModal={closeModal} />
          }
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalGestionarRechazo;
