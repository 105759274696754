import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const Div = styled.div`
  width: 320px;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: ${props => (props['data-backgroundcolor'] ? leerColor(colores.verde) : leerColor(colores.rojo))};
  color: ${leerColor(colores.blanco)};
  border: 1px solid ${leerColor(colores.blanco)};
`
export const Span = styled.span` 
   
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  padding: 25px 0;
  text-align: center;
  border: 2px dashed ${leerColor(colores.blanco)};  
  figure{
    width: 40px;
    margin: 0;
    padding-bottom: 20px;
  }
  img{
    width: 100%;
  }
  @media (min-width: ${breakpoints.mobileGrande}) {

  }
  @media (min-width: ${breakpoints.tablet}) {
    
  }
  @media (min-width: ${breakpoints.laptop}) {

  }
  @media (min-width: ${breakpoints.laptopL}) {
    
  }
  @media (min-width: ${breakpoints.notebook}) {

  }
`;
