import { useEffect, useState } from "react";
import { postBienAseguradoData, postPagoData } from "../services/Emision";
import axiosRequest from "../utils/axiosConfig";

export const usePago = () => {

  const postearPagoForm = async (pago, aseguradoID) => {
    if (aseguradoID) {
        const pagoData = {
          id_asegurado: aseguradoID,
          id_medio_pago: pago.medio,
          num_dato_facturacion: pago.num_dato_facturacion,
          banco_dato_facturacion: pago.banco_dato_facturacion,
          vencimiento_dato_facturacion: pago.vencimiento_dato_facturacion,
          titular_dato_facturacion: pago.titular_dato_facturacion,
          dni_dato_facturacion: pago.dni_dato_facturacion,
          marca_dato_facturacion: pago.marca_dato_facturacion,
          cuil_titular_dato_facturacion: pago.cuil_titular_dato_facturacion
        };
    
        try{
            const postPago = await postPagoData(pagoData)
            return postPago
        } catch (error){
            console.log(error)
        }
      }
  }

  const editarPagoForm = async (datosDeFacturacion, pago) => {
    if(datosDeFacturacion){
      const data = {
        id_medio_pago: pago.medio,
        num_dato_facturacion: parseInt(pago.medio) !== 4 ? pago.num_dato_facturacion : null,
        banco_dato_facturacion: parseInt(pago.medio) !== 4 ? pago.banco_dato_facturacion : null,
        vencimiento_dato_facturacion: parseInt(pago.medio) === 1 ? pago.vencimiento_dato_facturacion : null,
        titular_dato_facturacion: parseInt(pago.medio) === 1 || parseInt(pago.medio) === 2 ? pago.titular_dato_facturacion : null,
        dni_dato_facturacion: parseInt(pago.medio) === 1 ? pago.dni_dato_facturacion : null,
        marca_dato_facturacion: parseInt(pago.medio) === 1 ? pago.marca_dato_facturacion : null,
        cuil_titular_dato_facturacion: parseInt(pago.medio) === 2 ? pago.cuil_titular_dato_facturacion : null,
      }
      try{
        const editData = await axiosRequest.put(`/datos_facturacion/${datosDeFacturacion.id_dato_facturacion}`, data)
        return editData
      } catch (error){
        console.log(error)
      }
    }
  }

  return { 
    postearPagoForm,
    editarPagoForm
  }
};
