import React from "react";
import styled from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Form = styled.form`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 20px;

  // Inputs Container
  .inputs-container {
    width: 100%;
    margin: 30px 0px 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    h4 {
      width: 100%;
      margin: 0px 0px 0px 0px;
       
      font-weight: 600;
      font-size: 15px;
      color: ${leerColor(colores.grisModales)};
    }

    p {
      width: 100%;
      margin: 20px 0px 30px 0px;
      font-weight: 400;
      font-size: 13px;
      color: ${leerColor(colores.grisModales)};
    }
  }

  // Input Container
  .input-container,
  .select-container {
    width: 100%;
    max-width: 260px;
    margin: 0px 20px 23px 0px;
    position: relative;
  }

  // Select Compñia Container
  #select-compania-container {
    margin: 0px 0px 23px 0px !important;
  }

  // Inputs Type Date Icon
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0.5;
    cursor: pointer;
  }

  // Input, Select and Label
  .input-container input,
  .input-container label,
  .select-container select {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
  }

  // Select Label
  .select-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  // Inputs and Select
  .input-container input,
  .select-container select {
    width: 100%;
    height: 35px;
    padding: 0px 15px 0px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
     
    font-style: normal;
    font-weight: normal;
    font-size: 0.8em;
    color: ${leerColor(colores.gris)};
    border: 1px solid ${leerColor(colores.gris)};
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px !important;
    background-color: white;
  }

  // Label
  .input-container label,
  .select-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 15px;
    line-height: 0.75;
    font-size: 0.8em;
    font-weight: 400;
    color: ${leerColor(colores.gris)};
    cursor: text;
    z-index: 60;
  }

  // Input and Select On Focus Label
  .input-container input:focus + label,
  .select-container select:focus + label {
    top: -0.6em;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7em;
    color: ${leerColor(colores.celeste)} !important;
    z-index: 59 !important;
  }

  // Inputs and Select Focus
  .input-container input:focus,
  .select-container select:focus {
    border: 1px solid ${leerColor(colores.celeste)};
    outline: none;
    z-index: 59 !important;
  }

  // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: ${leerColor(colores.blanco)};
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px !important;
    font-weight: 600 !important;
    font-size: 0.7em !important;
    color: ${leerColor(colores.gris)} !important;
    z-index: 59 !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  // Select Container
  .select-container {
    position: relative;
    width: 100%;
    height: 35px;
  }

  // Select
  .select-container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    width: 100%;
    padding: 0px 15px 0px 15px;
    margin: 0px 0px 0px 0px;
    height: 100%;
    cursor: pointer;
    outline: 0;
    border: 1px solid ${leerColor(colores.gris)};
     
    font-style: normal;
    font-weight: normal;
    color: ${leerColor(colores.gris)};
    position: relative;
    transition: all 0.25s ease;
    z-index: 60;
    background-color: transparent;
    border-radius: 6px;
    -webkit-appearance: none;
    box-shadow: 0;
    border-radius: 3px;
    font-size: 0.75em;
  }

  // Select Arrow
  .select-container i {
    position: absolute;
    right: 15px;
    top: calc(50% - 7px);
    width: 7px;
    height: 7px;
    display: block;
    background-color: ${leerColor(colores.blanco)};
    border-left: 3px solid ${leerColor(colores.gris)};
    border-bottom: 3px solid ${leerColor(colores.gris)};
    transform: rotate(-45deg);
    z-index: 59;
    border-radius: 10%;
  }

  // Select default arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: ${leerColor(colores.negro)};
  }

  #input-container-rejectForm {
    // Input Container
    .input-container,
    .select-container {
      max-width: 230px;
    }
  }

  // Botones Container
  #botones-buscar-container,
  #botones-nuevo-pendiente-container,
  #botones-reject-container {
    width: 100%;
    margin: 10px 0px 30px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    a {
      margin: 0px 15px 0px 0px !important;
    }
  }

  #botones-nuevo-pendiente-container {
    //margin: 30px 0px 0px 0px;
    justify-content: space-between;

    a {
      margin: 0px 0px 0px 0px !important;
    }
  }

  // Hr
  hr {
    width: 100%;
    margin: 0px 0px 0px 0px;
    opacity: 0.2;
  }
`;

export const FormGroup = styled.div`
  color: ${leerColor(colores.gris)};
  display: inline-block;
  padding: 5px;
  width: auto;
`;

export const Label = styled.label`
  color: ${leerColor(colores.negro)};
  text-align: left;
  padding: 10px;
  display: block;
`;

export const Option = styled.option`
  padding: 10px;
  color: ${leerColor(colores.negro)};
  background: ${leerColor(colores.blanco)};
  border: 1px solid ${leerColor(colores.gris)};
  width: 100%;
`;

export const Select = styled.select`
  padding: 10px;
  color: ${leerColor(colores.negro)};
  background: ${leerColor(colores.blanco)};
  border: 1px solid ${leerColor(colores.gris)};
  width: 100%;

  &.inputError {
    border: 1px solid red !important;
  }

  &.edit-state {
    width: 200px;
  }

  focus: {
    border: 1px solid ${leerColor(colores.naranja)};
  }
`;

export const Input = styled.input`
  padding: 10px;
  color: ${leerColor(colores.negro)};
  background: ${leerColor(colores.blanco)};
  border: 1px solid ${leerColor(colores.gris)};
  width: 180px;
  &.fecha {
    width: 120px;
  }

  &.inputError {
    border: 1px solid red !important;
  }

  &:blur:invalid {
    border: 1px solid ${leerColor(colores.rojo)};
  }
  &#poliza {
    width: 80px;
    margin: 0px;
  }
`;

export const Message = styled.label`
  margin-bottom: 0.5em;
  color: ${leerColor(colores.gris)};
  display: block;
`;
