import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Error = styled.span`
  font-family: "DM Sans", sans-serif;
  color: ${leerColor(colores.rojo)};
  display:block;
  margin-top:8px;
  font-size: 14px;
`;

export const BuscarCliente = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
  div{
    width: 85%;
  }
`;

export const Icon = styled.img`
  width: 16px;
  margin-right:10px;
`;

export const RemoveContainer = styled.div`
  display:flex;
  padding: 10px 20px;
  margin-bottom:20px;
  color: hsl(0,0%,32.2%);
  background-color: ${leerColor(colores.blanco)};
  border: 1px solid ${leerColor(colores.rojo)};
  border-radius:5px;
  //width: 350px;
  cursor: pointer;
  justify-content: center;

  span{
    font-weight: 600;
    color: ${leerColor(colores.rojo)};
    font-size: 14px;
  }

  :hover{
    opacity: 0.8;
  }
`;

export const ModalClienteEncontrado = styled.div`
    width: 620px;
    align-items: center;
    padding: 42px 50px 36px;
    background-color: #fff;
    
  div {
    align-items: center; 
  }
  p {
    margin: 0;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 34px;
    text-align: center;
    color: ${leerColor(colores.grisModales)};
  }
  .buttonCont {
    display: flex;
    justify-content: center;
  }
`;