import styled from "styled-components";

const ConfirmationMain = styled.div`
  text-align:center;
  width: 500px;
  height: 120px;
  margin: 0 auto;
  justify-items: ${(props) => (props.centerItems ? "center" : "")};
`;


export { ConfirmationMain };
