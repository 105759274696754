import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const ButtonAction = styled.a`
  cursor: pointer;
  // 
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  //padding: 10px 12px;
  border-radius: 5px;
  background-color: ${(props) =>
    props['data-backgroundcolor']
      ? leerColor(colores.celeste)
      : leerColor(colores.blanco)}!important;
  border: 1px solid
    ${(props) => (props['data-bordercolor'] ? leerColor(colores.celeste) : "none")};
  color: ${(props) =>
    props['data-textcolor']
      ? leerColor(colores.celeste)
      : leerColor(colores.blanco)}!important;

  ${(props) =>
    props.icon &&
    css`
      content: url(${(props) => props.icon});
    `}

  :hover {
    opacity: 0.7;
    background-color: yellow;
  }
`;

export const Container = styled.span`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;

  .tooltip{
    display: none;
    position: absolute;
    background-color: rgb(30, 30, 30);
    color: #fff;
    top: -45px;
    padding: 8px;
    font-size: 14px;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    border-radius: 5px;
    width: 120px;
    z-index: 5000000000;

    ::after {
      content: "";
      display: block;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
      top: 100%;
      left: 50%;
      position: absolute;
    }
  }

  :hover{
      .tooltip{
        display:block;
      }
  }

  .size {
    font-size: 30px;
    display: flex;
  }

  .small {
    font-size: 20px;
  }
`;