import { Grid, Col } from "../Grid";
import { SolEmisionTitleContainer } from "../../pages/styled";
import Titulo from "../Titulo";
import Notificaciones from "../Notification";
import NewTitle from "../NewTitle";

const TitlePageSection = ({ children, title, description }) => {
  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={12}>
        <SolEmisionTitleContainer>
          <div className="title-container">
            <NewTitle textColor={false}>{title}</NewTitle>
            {description ? <p className="descripcion">{description}</p> : null}
          </div>
          <div className="container-acciones">
            {children}
            <Notificaciones />
          </div>
        </SolEmisionTitleContainer>
      </Col>
    </Grid>
  );
};

export default TitlePageSection;
