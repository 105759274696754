import { FiMoreHorizontal } from "react-icons/fi";
import MenuActions from "../../assets/iconos/menuActions.svg";
import { Container, ButtonAction } from "./styled";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const ActionsButton = ({ items, active, handleToggle, index }) => {
   
    return(
        <Container>
            {/*<ButtonAction data-backgroundcolor={false} data-bordercolor={true} data-textcolor={true} icon={MenuActions} onClick={() => handleToggle(index)} /> */}
            <span className="icono" onClick={() => handleToggle(index)}><FiMoreHorizontal/></span>
            <div className={active === index ? "dropDown" : "dropUp"}  key="dropdown">
              {items && items.map((item) => {
                return (
                  item.type === "link" 
                    ? <Link to={{ pathname: item.path }}><span>{item.text}</span></Link> 
                    : <span onClick={item.action}>{item.text}</span>
                )
              })}
            </div>
        </Container>
    )
}

export default ActionsButton