import { Button, Grid, Col } from "../../Index";
import {
  TickWrapper,
  SuccessTitle,
  ErrorTitle,
  LoadingContainer,
  IconErrorContainer,
  ErrorContainer,
  SuccessContainer,
} from "./styled.js";
import { useSolicitudContext } from "../../../context/SolicitudContext";
import SpinnerLoading from "../../SpinnerLoading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { VscError } from "react-icons/vsc";
import NewButton from "../../NewButton/index.jsx";
import AnimacionExito from "../../AnimacionExito"
import NewTitle from "../../NewTitle"

const EnviarSolicitud = () => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const { solicitud, asegurado, seguro } = useSolicitudContext();
  const { loading, error, errorMessage } = solicitud;

  console.log(seguro)

  return (
    <Grid colGap={21} rowGap={21} narrow={true} centerItems={true}>
      {loading && (
        <Col desktop={12}>
          <LoadingContainer>
            <SpinnerLoading text="Enviando solicitud..." />
          </LoadingContainer>
        </Col>
      )}

      {error && (
        <Col desktop={12}>
          <ErrorContainer>
            <div>
              <IconErrorContainer>
                <VscError></VscError>
              </IconErrorContainer>
              <ErrorTitle>Error al cargar solicitud</ErrorTitle>
            </div>
            <span>
              El error fue notificado al equipo de desarrollo para ser revisado.
            </span>
            <Link
              to={{
                pathname:
                  user.id_rol === 10
                    ? "/HistorialSolicitudes"
                    : "/SolicitudEmision",
              }}
            >
              <NewButton
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
              >
                {" "}
                Volver al inicio{" "}
              </NewButton>
            </Link>
          </ErrorContainer>
        </Col>
      )}

      {!loading && !error && (
                  <Col desktop={12}>

        <SuccessContainer>
          <Col desktop={12}>
            <AnimacionExito />
          </Col>
          <Col desktop={12}>
            <SuccessTitle>
              <NewTitle>¡Solicitud enviada a Emisión!</NewTitle>
              <p>Podes ver la solicitud creada en tu historial de solicitudes</p>
              <Link
              to={{
                pathname:
                  user.id_rol === 10
                    ? "/HistorialSolicitudes"
                    : "/SolicitudEmision",
              }}
            >
              <NewButton
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
              >
                Volver a {user.id_rol === 10
                    ? "Historial de Solicitudes"
                    : "Solicitudes de Emisión"}
              </NewButton>
            </Link>
            </SuccessTitle>
          </Col>
        </SuccessContainer>
        </Col>
      )}
    </Grid>
  );
};

export default EnviarSolicitud;
