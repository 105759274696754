import React from "react";
import {
  ThumbnailContainer,
  ThumbnailTextContainer,
  ArchivoCargado,
  ThumbnailImage,
  NombreArchivoCargado,
  Icon,
  IconContainer,
} from "./styled";
import deleteIcon from "../../assets/iconos/delete.svg";

const FileLoaded = ({
  file,
  filename,
  link,
  deleteFile,
  itemToDelete,
  isLarger,
  index,
}) => {
  /*

  *-*-* PROPS *-*-* 
  - isLarger: cambia algunos tamaños cuando el componente se muestra en la página de Emisión.
  - itemToDelete: item a borrar que se pasa como parametro a la función.
  - deleteFile: función para borrar el archivo subido. 
  - link: href que abre el archivo en otra pestaña.
  - filename: nombre del archivo a mostrar.
  - file: archivo subido para mostrar como thumbnail.

  */

  console.log(file)
  console.log(filename)
  console.log(link)

  return (
    <ThumbnailContainer isLarger={isLarger}>
      <div className="file-container">
        <div className="file">
          <a href={link} target="_blank" rel="noreferrer">
            <ThumbnailImage src={file} alt="" />
          </a>
        </div>
        <ThumbnailTextContainer isLarger={isLarger}>
          <ArchivoCargado key={`archivoInputFileRec`}>
            Archivo cargado:
          </ArchivoCargado>
          <NombreArchivoCargado isLarger={isLarger}>
            {filename}
          </NombreArchivoCargado>
        </ThumbnailTextContainer>
      </div>
      <IconContainer className="cross" isLarger={isLarger}>
        <Icon
          onClick={() =>
            deleteFile(itemToDelete ? itemToDelete : "", index ? index : "")
          }
          src={deleteIcon}
          alt="icono_borrar_archivo"
        />
      </IconContainer>
    </ThumbnailContainer>
  );
};

export default FileLoaded;
