import React from "react";
import Modal from "../../../Modal";
import { RechazoContainer } from "./styled";
import NewTitle from "../../../NewTitle";
import GoalConverter from "../../../GoalConverter";

const ModalConversorPrima = ({ modal }) => {
  const { isModalOpen, handleModal } = modal;
  
  return (
    <div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Conversor de prima</NewTitle>
            <p>
              Calculá objetivos mediante primas anuales
            </p>
          </div>
          <hr />
          <div className="data-container">
            <GoalConverter />
          </div>
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalConversorPrima;
