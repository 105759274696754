import { Button, Col } from "../Index";
import useFilters from "../../hooks/useFilters"
import { ClearFilter, ClearFilterContainer } from "../../pages/styled";
import { ImSearch } from "react-icons/im";
import { MdCleaningServices } from "react-icons/md";

const Filters = ({ inputs, values, data, setData, setHayBusquedas, setPageSelected, setFilterParams, requiredFilters }) => {

    const { inputValues, handleChange, handleSearch, clearFilters, buttonDisabledStatus, showDeleteFilters } = useFilters(values, data, setData, setHayBusquedas, setPageSelected, setFilterParams, requiredFilters)

    return(
        <>
            {inputs && inputs.map(input => {
                return (
                    <Col desktop={3}>
                        <input.component type={input.type} labelName={input.labelName} placeholder={input.placeholder} name={input.name} onChange={handleChange} value={inputValues[input.name]} options={input.options ? input.options : ''} />
                    </Col>
                )
            })}
            <Col desktop={1} alignBottom={true}>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={handleSearch}
                disabled={buttonDisabledStatus}
                tamaño="chico"
              >
                <span><ImSearch></ImSearch></span>
                Buscar
              </Button>
            </Col>

            {showDeleteFilters ? 
              <Col desktop={2} alignBottom={true}>
                <ClearFilterContainer onClick={clearFilters}>
                  <Button
                    borderColor={true}
                    textColor={true}
                    onClick={clearFilters}
                    target={"_blank"}
                    tamaño="chico"
                  >
                    <span>
                      <MdCleaningServices></MdCleaningServices>
                    </span>
                    Limpiar filtros
                  </Button>
                </ClearFilterContainer>
              </Col>
            : null}
        </>
    )
}

export default Filters