import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const Card = styled.div` 
    width: 290px;
    height: 182px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    border-radius: 14.5px;
    margin:60px auto;
    font-family: "Consolas", "Courier", monospace;
    position: relative;
    background: ${(props) => {
        switch (props.brand) {
            case "visa":
                return "linear-gradient(25deg, #0f509e, #1399cd)";
            case "americanexpress":
                return "linear-gradient(25deg, #0f509e, #11304e)";            
            case "mastercard":
                return "linear-gradient(25deg, #fbfbfb, #e8e9e5)";
            case "maestro":
                return "linear-gradient(25deg, #fbfbfb, #e8e9e5)";
            case "naranja":
                return "linear-gradient(25deg, #fe5000, #ff8b56)";   
            case "dinersclub":
            case "cabal":
            case "discover": 
            case "unionpay": 
            case "jcb":
            case "elo":
            case "hipercard": 
            case "mir":  
            case "hiper":                    
                return "linear-gradient(25deg, #fbfbfb, #e8e9e5)";  
        }
    }}; 

    /*
    .logo-container{
        background-color: blue;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    */

    .chip{
        width:30px;
        position: absolute;
        top: 26px;
        left: 20px;
    }

    .logo{
        width:${(props) => {
            switch (props.brand) {
                case "visa":
                    return "90px";
                case "americanexpress":
                    return "50px";
                case "mastercard":
                    return "80px";
                case "maestro":
                    return "60px";
                case "naranja":
                    return "40px";   
                case "dinersclub":
                    return "100px";
                case "cabal":    
                    return "40px";
                case "discover":   
                    return "100px";
                case "unionpay":
                    return "60px";
                case "jcb":
                    return "60px"; 
                case "elo":
                    return "80px";      
                case "hipercard":
                    return "80px";   
                case "mir": 
                    return "100px";   
                case "hiper": 
                    return "60px";   
                }
            }}; 
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .credit-card-content-container{
        position:relative;
        width:100%;
        display:flex;
        flex-direction: column;
        margin-top:40px;
        color: ${(props) => {
            switch (props.brand) {
            case "visa":
            case "americanexpress":
            case "naranja":   
                return "#fff";
            case "mastercard":
            case "maestro":
            case "cabal":    
            case "discover":
            case "unionpay":  
            case "jcb":
            case "elo":       
            case "hipercard":
            case "mir":
            case "hiper":             
                return "#000";  
            }
        }}; 

        .credit-card-number-container{
            font-size: 20px;
            padding:10px 20px;

        }

        .credit-card-name-container{
            font-size: 16px;
            padding:0px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .credit-card-expiration-date{
                display:flex;
                flex-direction:column;
            }
        }

        .credit-card-name{
            text-overflow: ellipsis;
            text-transform: uppercase;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
        }
    }
`;
