import styled from "styled-components";
import { leerColor, colores } from "../../constants";

export const Table = styled.table`
   
  font-weight: 600;
  font-size: 13.4px;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 100%;
  background-color: ${(props) =>
    props['data-backgroundcolor']
      ? leerColor(colores.celeste)
      : leerColor(colores.blanco)};
  border: none;
  border-collapse: collapse;
  color: ${(props) =>
    props['data-textcolor']
      ? leerColor(colores.celeste)
      : leerColor(colores.blanco)}!important;
  margin: 0 0 20px 0;

  td,
  th {
    border: none;
    padding: ${(props) => (props.padding ? props.padding : "20px 4px")};
    color: ${leerColor(colores.celeste)};
    text-align: center;
    border: ${(props) => (props.border ? "solid 1px #DDD" : "none")};
  }

  th,
  td {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  #td-botones {
    border: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  th button {
    width: auto;
    padding: 0px 5px 0px 5px;
    margin: 0px 0px 0px 0px;
    background-color: white;
    border: 0px;
  }

  td {
    color: #333;
    padding: ${(props) => (props.padding ? props.padding : "20px 4px")};
  }

  td.error_field {
    color: ${leerColor(colores.rojo)};
  }

  .colum-id {
    border-left: 0px !important;
  }

  .colum-detalles {
    border-right: 0px;
  }

  .colum-detalles button {
    background-color: white;
    border: 0px;
    font-size: 13.4px;
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    text-decoration: underline ${leerColor(colores.naranjaEstadosSuscripcion)};
    transition: 0.2s all;
  }

  .colum-detalles button:hover {
    text-decoration: none;
  }

  td.rojo {
    color: ${leerColor(colores.rojo)};
  }

  td.verde {
    color: ${leerColor(colores.verde)};
  }

  tfoot td {
    padding: 10px;
    border-bottom: 2px solid ${leerColor(colores.negro)};
  }

  tfoot > tr {
    padding: 10px;
  }

  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }

  tr.deleted td {
    color: ${leerColor(colores.rojo)};
    margin: 0;
  }

  #td-botones button {
    width: auto;
    margin: 20px 8px 0px 8px;
    padding: 9px 15px 9px 15px;
    background-color: ${leerColor(colores.celeste)};
    color: ${leerColor(colores.blanco)};
    border: 0px;
    outline: 0px;
    border-radius: 300px;
    font-size: 13px;
    font-weight: 500;
  }

  .no-premio td:nth-child(5) {
    background-color: #ff9794;
  }
  .no-premio td:nth-child(6) {
    background-color: #ff9794;
  }
  .no-premio td:nth-child(7) {
    background-color: #ff9794;
  }
  .no-premio td:nth-child(8) {
    background-color: #ff9794;
  }
  .no-premio td:nth-child(1n + 10) {
    background-color: #ff9794;
  }

  .ningun-premio {
    background-color: #ff9794;
  }

  .premio-hogar {
    background-color: #82fc4c;
  }
`;
